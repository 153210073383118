import * as defaultValues from "constants/defaultValue";
import lengths from "constants/lengths";

export class RequestPagination {
    constructor(
        page = defaultValues.page,
        pageSize = lengths.pageSize
    ) {
        this.page = Number(page);
        this.pageSize = Number(pageSize);
    }

    mapToJson() {
        return {
            'page': this.page,
            'page_size': this.pageSize,
        }
    }
}