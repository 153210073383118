
import styled from "styled-components";
import tw from "twin.macro";

const Content = styled.p`
    text-align: ${ (props) => props.alignment };
    color: ${(props) => props.customStyle.color};
    ${ (props) => props.column && `grid-column: span ${props.column} / span ${props.column}`};
    ${ tw`my-auto` }
    ${(props) => {
        if (props.margin.value.isEmpty()) { return null; }
        return `margin-${props.margin.position}:${props.margin.value}px;`
    }}
    font-size: ${ props => props.fontSize }px;
    font-weight: ${(props) => props.customStyle.fontWeight};
    opacity: ${props => props.customStyle.opacity};
    width: ${
        props => props.width
            ? props.width + "px"
            : "100%"
    };
    word-wrap: break-word;
    ${ props => props.className }
`;

export { Content }