import buttonStrings from "localizations/buttonStrings";

const statuses = {
    all: undefined,
    new: 0,
    sale: 1,
    pending: 2,
    sold: 3
}

export default {
    new: 0,
    sale: 1,
    pending: 2,
    sold: 3,
    statuses: [
        statuses.all,
        statuses.new,
        statuses.sale,
        statuses.pending
    ],
    getTitle: (statusId) => {
        const titles = {
            [statuses.all]: buttonStrings.every,
            [statuses.new]: buttonStrings.new,
            [statuses.sale]: buttonStrings.forSale,
            [statuses.pending]: buttonStrings.pending
        };
        return titles[statusId];
    }
}