import { reducer as formReducer } from 'redux-form';
import authReducer from "./authReducer";
import cardReducer from './cardReducer';
import commonReducer from './commonReducer';
import customerReducer from './customerReducer';
import employeeReducer from './employeeReducer';
import searchReducer from './searchReducer';
import otherReducer from './otherReducer';
import userReducer from './userReducer';
import confirmationModal from 'layouts/modals/ConfirmationModal/confirmationModal.reducer';
import cardPageReducer from 'pages/CardPage/cardpage.reducer';
import printCardReducer from 'pages/PrintCardPage/printCardReducer';

export default {
    auth: authReducer,
    customer: customerReducer,
    common: commonReducer,
    employee: employeeReducer,
    other: otherReducer,
    user: userReducer,
    card: cardReducer,
    search: searchReducer,
    form: formReducer,
    confirmationModal: confirmationModal,
    cardPage: cardPageReducer,
    printProperty: printCardReducer
}