import React from 'react';
import PropTypes from 'prop-types';
import CardSection from 'components/CardSection/CardSection';
import titleStrings from 'localizations/titleStrings';
import * as styled from './LandInformation.styled';
import fieldStrings from 'localizations/fieldStrings';
import HorizontaLabeledText from 'components/HorizontalLabeledText/HorizontalLabeledText';
import { connect } from 'react-redux';
import { Card } from 'models/objects/Card';
import { formatStrings } from 'localizations';
import * as defaultValues from 'constants/defaultValue';

const mapStateToProps = (state) => ({
  ...state.card,
});

const CardPageContentInnerLandInfo = (props) => {
  const setupLandArea = () => {
    const extraInfos = [];
    if (props.card.landInformation.isCorner) {
      extraInfos.push(fieldStrings.corner);
    }
    if (props.card.landInformation.isSide) {
      extraInfos.push(fieldStrings.bothSides);
    }
    return `${props.card.landInformation.roadWidth} [${extraInfos.join(',')}]`;
  };

  const renderContent = () => {
    return (
      <styled.Container>
        <HorizontaLabeledText
          title={fieldStrings.landArea}
          isValueBold={true}
          values={[
            `${props.card.landInformation.area.thousandFormat(
              2
            )} ㎡ [${props.card.landInformation.areaPy.thousandFormat(2)}PY]`,
          ]}
        />
        <HorizontaLabeledText
          title={fieldStrings.landUsage}
          values={[props.card.landInformation.usage]}
        />
        <HorizontaLabeledText
          title={fieldStrings.totalOfficialPrice}
          values={[
            `${props.card.landInformation.officialPrice.tenThousandFormat()} [${formatStrings.milionPerPyeong(
              props.card.landInformation.officialPricePy.thousandFormat(
                defaultValues.emptyInt
              )
            )}]`,
          ]}
        />
        <HorizontaLabeledText
          title={`${fieldStrings.nearStation} / ${fieldStrings.distance}`}
          values={[
            `${props.card.nearestStation} / ${props.card.distanceStation}m`,
          ]}
        />
        <HorizontaLabeledText
          title={fieldStrings.road}
          values={[`${setupLandArea()}`]}
        />
      </styled.Container>
    );
  };

  return (
    <CardSection
      title={titleStrings.landInfo}
      content={renderContent()}
    />
  );
};

CardPageContentInnerLandInfo.propTypes = {
  card: PropTypes.object,
};

CardPageContentInnerLandInfo.defaultProps = {
  card: new Card(),
};

export default connect(mapStateToProps, null)(CardPageContentInnerLandInfo);
