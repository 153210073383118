export class RequestDeleteEmployeeLoginHistory {
    constructor(
        loginHistoryIds = []
    ) {
        this.loginHistoryIds = loginHistoryIds;
    }

    mapToJson() {
        return {
            'ids': this.loginHistoryIds
        }
    }
}