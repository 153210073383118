import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import EmployeeListPage from '../pages/EmployeeListPage/EmployeeListPage';
import EmployeeLoginLogPage from '../pages/EmployeeLoginLogPage/EmployeeLoginLogPage';
import EmployeeCardLogPage from '../pages/EmployeeCardLogPage/EmployeeCardLogPage';
import PropTypes from 'prop-types';

const EmployeeRoutes = (props) => {
    return (
        <Switch>
            <Route
                exact
                path="/employee"
                render={
                    () => (<Redirect to="/employee/list" />)
                } />
            <Route
                path="/employee/list" 
                render={
                    () => {
                        return (
                            <EmployeeListPage
                                ref={ props.employeeListRef }
                                handleEditEmployee={ props.handleEditEmployee  }/>
                        )
                    }
                } />
            <Route
                path="/employee/login-log"
                render={
                    () => <EmployeeLoginLogPage ref={ props.employeeLoginLogRef }/>
                } />
            <Route
                path="/employee/card-log"
                component={
                    EmployeeCardLogPage
                } />
        </Switch>
    )
};

EmployeeRoutes.propTypes = {
    employeeListRef: PropTypes.object,
    employeeLoginLogRef: PropTypes.object,
    handleEditEmployee: PropTypes.func
};

export default EmployeeRoutes;