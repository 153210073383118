import defaultValues from "constants/defaultValues";
import { LandUsage } from "models/objects/LandUsage";

export class RequestCreateLand {
    constructor(
        landArea = null,
        category = defaultValues.emptyString,
        roadWidth,
        isCorner = false,
        isSide = false,
        usage = new LandUsage()
    ) {
        this.landArea = landArea;
        this.category = category;
        this.roadWidth = roadWidth;
        this.isCorner = isCorner;
        this.isSide = isSide;
        this.usage = usage;
    }

    mapToJson() {
        return {
            'custom_land_area': this.landArea,
            'category': this.category,
            'road_width': this.roadWidth,
            'is_road_corner': this.isCorner,
            'is_road_side': this.isSide
        }
    }
}