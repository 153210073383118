import * as defaultValues from "constants/defaultValue";
import unitExhanges from "constants/unitExhanges";

export class FloorInformation {
    constructor(
        id = defaultValues.emptyInt,
        floor = defaultValues.emptyString,
        area = defaultValues.emptyString,
        usage = defaultValues.emptyString,
        floorMergedDown = false,
        areaMergedDown = false,
        usageMergedDown = false,
        isExcluded = false
    ) {
        this.id = id;
        this.floor = floor;
        this.area = area;
        this.usage = usage;
        this.floorMergedDown = floorMergedDown;
        this.areaMergedDown = areaMergedDown;
        this.usageMergedDown = usageMergedDown;
        this.isExcluded = isExcluded;
    }

    getArea() {
        return Number(this.area);
    }

    getAreaInPy() {
        return (Number(this.area) * unitExhanges.meterSquareToPyeong).rounded(2);
    }

    isBasement() {
        if (
            this.floor.includes("지하")
            || this.floor.includes("지")
            || this.floor.includes("B")
        ) {
            return true;
        }
        return false;
    }

    getIsEmpty() {
        return this.floor.isEmpty()
            && this.area.isEmpty()
            && this.usage.isEmpty()
    }

    _mergeFlags = {
        [1]: "floorMergedDown",
        [2]: "areaMergedDown",
        [4]: "usageMergedDown"
    }

    _nameByIndex = {
        [1]: {
            name: "floor",
            mergeName: "floorMergedDown"
        },
        [2]: {
            name: "area",
            mergeName: "areaMergedDown"
        },
        [4]: {
            name: "usage",
            mergeName: "usageMergedDown"
        }
    }
    
    getMergeKeyByName(name) {
        for (const columnIndex of Object.keys(this._nameByIndex)) {
            if (this._nameByIndex[columnIndex].name != name) { continue; }
            return this._nameByIndex[columnIndex].mergeName;
        }
    }
    
    getNameByIndex(index) {
        return this._nameByIndex[index];
    }

    setAsMerged(column) {
        this[this._mergeFlags[column]] = true;
    }

    setAsUnMerge(column) {
        this[this._mergeFlags[column]] = false;
    }

    mapFromJson(json) {
        if (!json) { return this; }
        this.id = json['id'];
        this.floor = json['floor'];
        this.area = json['area'];
        this.usage = json['usage'];
        this.floorMergedDown = json['floor_merge_down'];
        this.areaMergedDown = json['area_merge_down'];
        this.usageMergedDown = json['usage_merge_down'];
        this.isExcluded = json['is_exclude'];
        return this;
    }

    mapToJson() {
        return {
            "id": this.id,
            "floor": this.floor,
            "area": this.area,
            "usage": this.usage,
            "floor_merge_down": this.floorMergedDown,
            "area_merge_down": this.areaMergedDown,
            "usage_merge_down": this.usageMergedDown,
            "is_exclude": this.isExcluded
        }
    }
}