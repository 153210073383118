import dateFormats from "constants/dateFormats";
import * as defaultValues from "constants/defaultValue";
import { Card } from "models/objects/Card";
import { Employee } from "models/objects/Employee";
import moment from "moment";

export class CardViewLog {
    constructor(
        id = defaultValues.emptyInt,
        employee = new Employee(),
        property = new Card(),
        ipAddress = defaultValues.emptyString,
        readingDate = defaultValues.emptyString
    ) {
        this.id = id;
        this.employee = employee;
        this.property = property;
        this.ipAddress = ipAddress;
        this.readingDate = readingDate;
    }

    mapFromJson(json) {
        if (!json) { return this; }
        this.id = json["id"].toString();
        this.employee = new Employee().mapFromJson(json["user"]);
        this.property = new Card().mapFromJson(json["property"]);
        this.ipAddress = json["ip_address"];
        this.readingDate = moment(
            json["reading_date"]
        ).format(dateFormats.dateTimeWithDots);
        return this;
    }
}