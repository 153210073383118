export default {
    transparent: "transparent",
    black: "#000000",
    white: "#FFFFFF",
    red: {
        default: "#FF5656"
    },
    blue: {
        default: "#5683FF"
    },
    gray: {
        darker: "#333333",
        dark: "#777777",
        default: "#DDDDDD",
        light: "#f9f9f9",
    },
}