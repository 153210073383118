import { Card } from "models/objects/Card";
import { Pagination } from "models/objects/Pagination";

export class ResponseFetchSearchProperty {
    constructor(
        properties = [],
        pagination = new Pagination()
    ) {
        this.properties = properties;
        this.pagination = pagination;
    }

    mapFromJson(json) {
        if (!json && !json['data']) { return this; }
        if (Array.isArray(json['data'])) {
            this.properties = json['data'].map(
                (json) => new Card().mapFromJson(json)
            );
        } else {
            this.properties = [new Card().mapFromJson(json['data'])]
        }
        this.pagination = new Pagination(
            json['currentPage'],
            json['totalItems'],
            json['totalPages']
        );
        return this;
    }
}