import styled from "@emotion/styled";
import tw from "twin.macro";

export const Container = styled.div`
    width: 500px;
    max-width: 90%;
    max-height: 90%;
    ${ tw `flex flex-col m-auto bg-white p-8 overflow-hidden` }
`;

export const CloseButton = styled.button`
    width: 20px;
    height: 20px;
    ${ tw `ml-auto` }
`;

export const Title = styled.h1`
    ${ tw `font-bold text-xl text-left` }
`;

export const FormContainer = styled.div`
    ${ tw`flex flex-col gap-5` }
`;

export const TextInputContainer = styled.div`
    ${ tw`flex` }
`;

export const SuffixText = styled.p`
    ${ tw`ml-3 my-auto` }
`;