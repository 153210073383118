import defaultValues from "constants/defaultValues";

export class Parking {
    constructor(
        id = defaultValues.emptyInt,
        name = defaultValues.emptyString,
        totalGoverment = defaultValues.emptyInt,
        totalPersonal = defaultValues.emptyInt
    ) {
        this.id = id;
        this.name = name;
        this.totalGoverment = totalGoverment;
        this.totalPersonal = totalPersonal;
    }

    mapFromJson(json) {
        if (!json) { return this; }
        this.id = json['id'];
        this.name = json['name'];
        this.totalGoverment = json['total_gov'];
        this.totalPersonal = json['total_personal'];
        return this;
    }
}