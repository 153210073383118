import { ButtonViewModel } from "components/core/button/ButtonViewModel";
import * as defaultValues from "constants/defaultValue";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { commonActionTypes } from "redux/types";

const TextInputModalPresenter = (
    placeholder = defaultValues.emptyString,
    action = defaultValues.emptyFunction
) => {
    const buttonViewModel = new ButtonViewModel()
    const [title, setTitle] = useState(defaultValues.emptyString);
    const [value, setValue] = useState(defaultValues.emptyString);
    const dispatch = useDispatch();

    const dismissModal = () => {
        dispatch({
            type: commonActionTypes.HIDE_MODAL
        });
    }

    const onInputChange = (input) => {
        setValue(input.value);
    }

    const primaryAction = () => {
        dismissModal();
    }

    return {
        dismissModal,
        onInputChange,
        setTitle,
        value,
        title,
        placeholder,
        action,
        primaryAction,
        buttonViewModel
    }
}
export default TextInputModalPresenter;