import { RequestCreateCard } from 'models/requests/RequestCreateCard';
import * as cardActions from 'redux/reducers/cardReducer';
import { useState } from 'react';

const useLeaseFormPresenter = () => {
  const [excludedRentalHistories, setExcludedRentalHistories] = useState([]);
  const [requestWriteCard, setRequestWriteCard] = useState(
    new RequestCreateCard()
  );

  const getMonthlyPrice = () => {
    return requestWriteCard.getTotalMonthlyRentalPrice().rounded(0);
  };

  const getMonthlyInterest = () => {
    const loanPrice = requestWriteCard.priceInformation.loanPrice;
    const interest = requestWriteCard.priceInformation.interest;
    return ((loanPrice * (interest / 100)) / 12).rounded(1);
  };

  const getMonthlyIncome = () => {
    const priceInformation = requestWriteCard.priceInformation;
    const adminRevenueRate = priceInformation.getAdminRevenueRate();
    const adminPrice = requestWriteCard.getTotalRentalAdminFee();
    let monthlyIncome = getMonthlyPrice() + adminPrice - adminRevenueRate;
    if (priceInformation.isMonthlyInterest) {
      monthlyIncome = monthlyIncome - getMonthlyInterest();
    }
    return monthlyIncome.rounded(0);
  };

  const getRentalHistories = () => {
    return requestWriteCard.rentalHistories.filter(
      (_, index) => !excludedRentalHistories.some((x) => x == index)
    );
  };

  const onMonthlyInterestCheck = (isCheck) => {
    let request = Object.assign(new RequestCreateCard(), requestWriteCard);
    request.priceInformation.isMonthlyInterest = isCheck;
    cardActions.updateRequestCreateCard(request);
  };

  return {
    getMonthlyPrice,
    getMonthlyIncome,
    getRentalHistories,
    onMonthlyInterestCheck,
    setExcludedRentalHistories,
    setRequestWriteCard,
  };
};
export default useLeaseFormPresenter;
