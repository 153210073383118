import styled from 'styled-components';
import tw from "twin.macro";

const Container = styled.div`
    width: 400px;
    max-width: 90%;
    max-height: 90%;
    ${tw `flex flex-col m-auto bg-white space-y-5 rounded-xl overflow-y-auto p-8`}
`;

export {
    Container
}
