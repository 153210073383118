import styled from "styled-components";
import tw from "twin.macro";

const Container = styled.div`
    max-width: 100%;
    ${ tw`pt-6 px-6 mx-auto` }
`;
const TopContainer = styled.div`
    ${ tw`flex gap-8 mb-10` }
`;
const RegularCardWrapper = styled.div`
    ${ tw`bg-red-500` }
`;
const TopLeadingContainer = styled.div`
    width: 30%;
`;
const TopTrailingContainer = styled.div`
    ${ tw`grid  gap-6 flex-1` }
    ${
        (props) => props.isAdmin
            ? tw`grid-cols-3`
            : tw`grid-cols-2`
    }
`;
const BottomContainer = styled.div`
    height: 500px;
    ${ tw`grid grid-cols-3 gap-6 mb-10` }
`;
const BottomLeadingWrapper = styled.div`
    ${ tw`col-span-1` }
`;
const BottomTrailingWrapper= styled.div`
    ${ tw`col-span-2` }
`;

export {
    Container,
    TopContainer,
    RegularCardWrapper,
    TopLeadingContainer,
    TopTrailingContainer,
    BottomContainer,
    BottomLeadingWrapper,
    BottomTrailingWrapper
}