export class RequestInquiryDocSetting {
    constructor(
        propertyId
    ){
        this.propertyId = propertyId;
    }

    mapToJson() {
        return {
            'property_id': this.propertyId
        }
    }
}