import defaultValues from "constants/defaultValues";
import { DocumentImage } from "models/objects/DocumentImage";

export class ResponseInquiryDocumentSetting {
    constructor(
        id = defaultValues.emptyString,
        useMain = true,
        useSummary = true,
        useRentalHistory = true,
        useFloorArea = true,
        images = defaultValues.emptyArray,
        pagesOrder = defaultValues.emptyArray
    ) {
        this.id = id;
        this.useMain = useMain;
        this.useSummary = useSummary;
        this.useRentalHistory = useRentalHistory;
        this.useFloorArea = useFloorArea;
        this.images = images;
        this.pagesOrder = pagesOrder;
    }

    mapFromJson(json) {
        if (!json) { return this; }
        this.id = json["id"];
        this.useMain = json["use_main"];
        this.useSummary = json["use_summary"]
        this.useRentalHistory = json["use_rental_history"]
        this.useFloorArea = json["use_floor"]
        this.images = json["images"].map(
            $0 => new DocumentImage().mapFromJson($0)
        );
        this.pagesOrder = json["pages_order"];
        return this;
    }
}