import styled from 'styled-components';
import tw from 'twin.macro';

const Wrapper = styled.div`
  ${(props) => props.maxHeight != 0 && `max-height: ${props.maxHeight}px;`}
  overflow-y: auto;
`;

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
`;

const Header = styled.th`
  background-color: white;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
`;

const Column = styled.td`
  ${(props) => props.isSelected && 'outline: green solid 1px;'}
  width: ${(props) => props.colWidth};
  ${tw`border-b overflow-hidden`}
`;

const FooterColumn = styled.td`
  width: 20%;
  background-color: white;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  ${tw`overflow-hidden`}
`;

export { Wrapper, Table, Header, Column, FooterColumn };
