import LocalizedStrings from 'react-localization';

export default new LocalizedStrings({
    en:{
        noWatchingCustomers: "There are no matching customer",
        notDuplicated: "Not Duplicated"
    },
    kr: {
        noWatchingCustomers: "검색결과가 없습니다",
        notDuplicated: "중복확인된 내용이 없습니다"
    }
});
