import React from 'react';
import { useSelector } from 'react-redux';
import zIndex from 'constants/zIndex';
import { commonActionTypes } from 'redux/types';
import { useDispatch } from 'react-redux';
import ConfirmationModal from 'layouts/modals/ConfirmationModal/ConfirmationModal';
import formStates from 'enumerations/formStates';
import * as confirmationModalActions from 'layouts/modals/ConfirmationModal/confirmationModal.reducer';
import * as commonActions from 'redux/reducers/commonReducer';
import * as cardActions from 'redux/reducers/cardReducer';
import cardService from 'redux/actions/cardActions';
import searchService from 'redux/actions/searchActions';
import { useHistory } from 'react-router';
import { useEffect } from 'react';
import * as defaultValues from 'constants/defaultValue';
import cardActionTypes from 'redux/types/cardActionTypes';
import { RequestViewProperty } from 'models/requests/RequestViewProperty';
import cardStatus from 'enumerations/cardStatus';
import queryString from 'query-string';
import * as searchActions from 'redux/reducers/searchReducer';
import { RequestSearchProperty } from 'models/requests/RequestSearchProperty';
import qs from 'qs';
import { titleStrings } from 'localizations';
import { toast } from 'react-toastify';

const useCardPage = () => {
  const cardReducer = useSelector((state) => state.card);
  const { pagination } = useSelector((state) => state.card);
  const requestWriteCard = useSelector((state) => state.card.requestWriteCard);
  const history = useHistory();
  const dispatch = useDispatch();

  const loadData = () => {
    if (history.location.pathname == '/search/detail') {
      searchService.fetchSearchProperty(
        new RequestSearchProperty().mapFromJson(
          qs.parse(queryString.parse(history.location.search))
        ),
        (response) => {
          cardActions.setupProperty(
            response.properties.first(),
            response.pagination
          );
        }
      );
      return;
    }
    const query = queryString.parse(history.location.search);
    const currentPage = query.page;
    cardActions.inquiryProperty(history.location, currentPage);
  };

  const showSaveConfirmationModal = (onSuccess) => {
    dispatch({
      type: commonActionTypes.SHOW_MODAL,
      payload: {
        zIndex: zIndex.defaultModal,
        content: (
          <ConfirmationModal
            title={titleStrings.savePropertyConfirmationTitle}
            primaryButtonTitle="예(YES)"
            secondaryButtonTitle="아니요(NO)"
            primaryAction={() => startSavingCard(onSuccess)}
            secondaryAction={onCancelCreateProperty}
          />
        ),
      },
    });
  };

  const onCancelCreateProperty = () => {
    if (cardReducer.formState != formStates.create) {
      return;
    }
    cardActions.revertPagination(
      Number(queryString.parse(history.location.search).page)
    );
    cardActions.changeFormState(formStates.read);
  };

  const startSavingCard = (onSuccess = defaultValues.emptyFunction) => {
    dispatch(confirmationModalActions.startLoading());
    requestWriteCard.floorInformations =
      requestWriteCard.floorInformations.filter(
        (floorInformation) => !floorInformation.getIsEmpty()
      );
    requestWriteCard.rentalHistories = requestWriteCard.rentalHistories.filter(
      (rentalHistory) => !rentalHistory.getIsEmpty()
    );
    if (cardReducer.formState == formStates.edit) {
      cardService.updatePropertyCard(
        requestWriteCard,
        () => didUpdateCardSucceed(onSuccess),
        (error) => {
          dispatch(confirmationModalActions.stopLoading());
          toast.error(error.data);
        }
      );
      return;
    }
    dispatch({
      type: cardActionTypes.CREATE_PROPERTY_CARD,
      payload: cardService.createPropertyCard(
        requestWriteCard,
        (property) => didCreateCardSucceed(property),
        (error) => {
          dispatch(confirmationModalActions.stopLoading());
          toast.error(error.message.korean);
        }
      ),
    });
  };

  const didSubmissionSucceed = (
    onSuccess = () => {
      return;
    }
  ) => {
    dispatch(confirmationModalActions.stopLoading());
    commonActions.hideModal();
    _updateQueryParam(pagination.currentPage);
    cardActions.changeFormState(formStates.read);
    onSuccess();
  };

  const didUpdateCardSucceed = (onSuccess) => {
    didSubmissionSucceed(onSuccess);
    loadData();
  };

  const didCreateCardSucceed = (card) => {
    if (
      history.location.pathname == '/search/detail' ||
      history.location.pathname == '/search/history'
    ) {
      searchActions.updateSearchRequest(new RequestSearchProperty());
      let request = new RequestSearchProperty();
      request.pagination.page = pagination.currentPage;
      request.pagination.pageSize = defaultValues.pageSize;
      cardActions.reloadSearchData(request, (response) => {
        request.pagination.page = response.pagination.totalPages - 1;
        history.push('/search/detail?' + qs.stringify(request.mapToJson()));
        cardActions.createPropertySucceed(card);
        dispatch(confirmationModalActions.stopLoading());
        commonActions.hideModal();
        cardActions.changeFormState(formStates.read);
      });
      return;
    }
    if (card.status != cardStatus.sale) {
      cardActions.revertPagination(
        queryString.parse(history.location.search).page
      );
      _updateQueryParam(pagination.currentPage);
    } else {
      _updateQueryParam(Number(cardReducer.pagination.totalItems));
      cardActions.createPropertySucceed(card);
    }
    didSubmissionSucceed();
  };

  const _updateQueryParam = (page) => {
    if (history.location.pathname == '/search/detail') {
      const request = new RequestSearchProperty().mapFromJson(
        qs.parse(queryString.parse(history.location.search))
      );
      request.pagination.page = page;
      history.push('/search/detail?' + qs.stringify(request.mapToJson()));
      return;
    }
    history.push(history.location.pathname + '?page=' + page);
  };

  useEffect(() => {
    return () => cardActions.setupInitialPage(defaultValues.emptyInt);
  }, defaultValues.emptyArray);

  useEffect(() => {
    loadData();
    if (history.location.pathname == '/search/history') {
      cardActions.inquiryProperty(history.location);
    }
  }, [history.location]);

  useEffect(() => {
    cardService.viewProperty(new RequestViewProperty(cardReducer.card.id));
  }, [cardReducer.card]);

  return {
    loadData,
    startSavingCard,
    showSaveConfirmationModal,
  };
};

export default useCardPage;
