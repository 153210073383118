import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/core/text/Text';
import * as styled from './PrintPageHeader.styled';
import * as defaultValues from 'constants/defaultValue';
import HighlightedTextStyle from 'components/core/text/styles/HighlightedTextStyle';
import LogoTextStyle from 'components/core/text/styles/LogoTextStyle';

const PrintPageHeader = (props) => {
  const renderLogo = () => {
    if (!props.isShowLogo) {
      return null;
    }
    return (
      <styled.LogoContainer>
        <Text
          style={LogoTextStyle}
          value="PP"
        />
      </styled.LogoContainer>
    );
  };

  return (
    <styled.Container>
      <Text
        style={HighlightedTextStyle}
        value={props.title}
      />
      {renderLogo()}
    </styled.Container>
  );
};

PrintPageHeader.propTypes = {
  title: PropTypes.string,
  isShowLogo: PropTypes.bool,
};

PrintPageHeader.defaultProps = {
  title: defaultValues.emptyString,
  isShowLogo: false,
};

export default PrintPageHeader;
