import defaultValues from "constants/defaultValues";

export class ParkingMethod {
    constructor(
        id = defaultValues.emptyInt,
        name = defaultValues.emptyString
    ) {
        this.id = id;
        this.name = name;
    }

    mapFromJson(json) {
        if (!json) { return this; }
        this.id = json['id'];
        this.name = json['name'];
        return this;
    }

    mapToJson() {
        return {
            'id': this.id,
            'name': this.name
        }
    }
}