import React from 'react'
import Spaces from 'constants/spaces';
import TableList from 'components/core/table-list/TableList';
import EmployeePageHeader from 'layouts/EmployeeLayouts/EmployeePageHeader';
import useEmployeeCardLogPage from './useEmployeeCardLogPage';
import * as styled from './EmployeeCardLogPage.styled';

const EmployeeCardLogPage = () => {
    const hook = useEmployeeCardLogPage();

    return (
        <styled.Container>
            <EmployeePageHeader
                pagination={ hook.pagination }
                onPageChange={ hook.onPageChange }
                onStartSearchAction={ hook.onStartSearchLogs }
                onStartDelete={ hook.onStartDeleteLogs }/>
            <TableList
                className="col-span-2"
                firstColumnPadding={ Spaces.small }
                datas={ hook.setupTableRows() }
                headers={ hook.setupTableHeaders() } />
        </styled.Container>
    )
}

export default EmployeeCardLogPage;