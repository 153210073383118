import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as styled from './PaginationView.styled';
import DoubleBackIconGray from 'assets/double_back_icon_gray.png';
import BackIconGray from 'assets/back_icon_gray.png';
import DoubleFowardIconGray from 'assets/double_foward_icon_gray.png';
import FowardIconGray from 'assets/foward_icon_gray.png';
import { titleStrings } from 'localizations';
import alignments from 'constants/alignments';
import sizes from 'constants/sizes';
import textInputType from 'enumerations/textInputType';
import TextInput from 'components/core/text-input/TextInput';
import { SmallBorderedTextInputStyle } from 'components/core/text-input/styles';
import * as defaultValues from 'constants/defaultValue';
import PaginationViewPresenter from './PaginationViewPresenter';

const PaginationView = (props) => {
    const hook = PaginationViewPresenter(
        props.page,
        props.onChange
    );

    useEffect(
        () => {
            hook.setTotalPages(props.totalPages)
        },
        [props.totalPages]
    )

    return (
        <styled.Container>
            <styled.ActionButton
                onClick={ hook.onMoveToFirstPage }>
                <styled.ActionIcon
                    src={ DoubleBackIconGray }/>
            </styled.ActionButton>
            <styled.ActionButton
                isLastItem={ true }
                onClick={ hook.onMoveToPreviousPage }>
                <styled.ActionIcon
                    src={ BackIconGray }/>
            </styled.ActionButton>
            <styled.PageTitle>
                { titleStrings.page }
            </styled.PageTitle>
            <TextInput
                alignment={ alignments.center }
                width={ sizes.textInput.medium }
                height={ sizes.textInput.small }
                style={ SmallBorderedTextInputStyle }
                value={
                    props.page === defaultValues.emptyString
                        ? props.page
                        : (Number(props.page) + 1).toString()
                }
                onChange={ (input) => hook._onInputPage(input.value) }/>
            <styled.PageTitle>
                { `${titleStrings.of} ${props.totalPages}` } 
            </styled.PageTitle>
            <styled.ActionButton
                onClick={ hook.onMoveToNextPage }>
                <styled.ActionIcon
                    src={ FowardIconGray }/>
            </styled.ActionButton>
            <styled.ActionButton
                isLastItem={ true }
                onClick={ hook.onMoveToLatestPage }>
                <styled.ActionIcon
                    src={ DoubleFowardIconGray }/>
            </styled.ActionButton>
        </styled.Container>
    )
}

PaginationView.propTypes = {
    totalPages: PropTypes.number,
    page: PropTypes.string,
    currentPage: PropTypes.number,
    onChange: PropTypes.func
};

PaginationView.defaultProps = {
    totalPages: defaultValues.emptyInt,
    page: defaultValues.emptyString,
    currentPage: null,
    onChange: defaultValues.emptyFunction
};

export default PaginationView;