import * as defaultValues from "constants/defaultValue";

export class Pagination {
    constructor(
        currentPage = defaultValues.page,
        totalItems = defaultValues.emptyInt,
        totalPages = defaultValues.emptyInt
    ) {
        this.currentPage = currentPage;
        this.totalItems = totalItems;
        this.totalPages = totalPages;
    }
}