import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LogoImageSrc from 'assets/horizontal_logo_black.png';
import { connect } from 'react-redux';
import { authActionTypes } from 'redux/types';
import PropTypes from 'prop-types';
import cookiesManager from 'managers/cookies.manager';
import * as styled from './NavigationBar.styled';

const mapStateToProps = (state) => ({
  ...state.other,
});

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => {
    dispatch({
      type: authActionTypes.LOGOUT,
    });
  },
});

class NavigationBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: cookiesManager.getCurrentUser(),
    };
  }
  static get propTypes() {
    return {
      onLogout: PropTypes.func,
    };
  }

  static defaultProps = {
    onLogout: () => {
      return;
    },
  };

  renderEmployeeLink = () => {
    if (!cookiesManager.getCurrentUser().isAdmin) {
      return;
    }
    return <Link to="/employee">회원</Link>;
  };

  render() {
    return (
      <styled.Wrapper>
        <div className="grid grid-cols-3 px-8 my-auto w-full">
          <div className="text-left flex">
            <styled.LogoImage
              className="my-auto"
              src={LogoImageSrc}
              alt="Property Partners Vertical Logo Black"
            />
          </div>
          <div>
            <nav className="grid grid-flow-col gap-x-16-">
              <Link to="/map">지도</Link>
              <Link to="/search">검색</Link>
              <Link to="/card?page=0">카드</Link>
              <Link to="/customer">고객</Link>
              {this.renderEmployeeLink()}
            </nav>
          </div>
          <div className="text-right flex">
            <div className="grid grid-flow-col gap-x-6 ml-auto">
              <button>{`${this.state.currentUser.name}님`}</button>
              <styled.VerticalLine />
              <button onClick={this.props.onLogout}>로그아웃</button>
            </div>
          </div>
        </div>
      </styled.Wrapper>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
