import React ,{ useState, useEffect, useCallback, Fragment,useRef } from  'react' ;
import { Redirect } from 'react-router-dom';
import { Dialog, Transition  } from '@headlessui/react'
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

import * as styled from './NaverMap.styled';

import LeftCard from './Map-TopHeader-Left/LeftCard';
import RightCard from './Map-TopHeader-Left/RightCard';
import CenterCard from './Map-TopHeader-Left/CenterCard';

import axios from 'axios';

import rullerIcon from './ruller.png';

import makeMarkerCluster from './MarkerCluster';
import Cookies from 'js-cookie';
import cardStatus from 'enumerations/cardStatus';
import useGeoLocation from './useGeoLocation';

function numberWithCommas(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function formatValue(value, suffix, decimalPlaces = 1) {
    const roundedValue = Number.isInteger(value) ? value : value.toFixed(decimalPlaces);
    const displayValue = parseFloat(roundedValue).toString();
    return displayValue + suffix;
}


const reloadOnce = () => {
    const reloadCount = sessionStorage.getItem('reloadCount');
    if (reloadCount < 2) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem('reloadCount');
    }
  };  


const NaverMap = () => {

    
    useEffect(() => {
        reloadOnce();
      }, []);
    
    
    
    const [isFromRefreshCallback, setIsFromRefreshCallback] = useState(false);
      
    const [filterData, setFilterData] = useState({
        name: "",
        owner: "",
        manager: "",
        address: "",
        nearest_station: "",
        road: "",
        min_road: "",
        max_road: "",
        is_road_side: false,
        is_road_corner: false,
        LandUsageBody : "선택",
        min_price: "",
        max_price: "",
        min_revenue: "",
        max_revenue: "",
        min_land_py: "",
        max_land_py: "",
        min_land_m: "",
        max_land_m: "",
        min_floor_py: "",
        max_floor_py: "",
        min_floor_m: "",
        max_floor_m: "",
        status_all: false,
        status_new: false,
        status_sale: false,
        status_pending: false,
    });

    
    
    // LEFT CARD
    const [runBoundaries, setRunBoundaries] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [openGovCard, setGovDataCard] = useState(false);
    const [openPopUpGovCreate, setOpenPopUpGovCreate] = useState(false);
    const [openPopUpGovCreate_fin, setOpenPopUpGovCreate_fin] = useState(false);
    const [govData, setGovData] = useState({
        address: "",
        area: 0,
        building_architecture: "",
        building_area: 36.1,
        building_name: "",
        built_at: "",
        bun_address: 13,
        city_code: 26440,
        district: "",
        district_code: 12200,
        elevator: 0,
        floor_area_ratio: 0,
        floor_ground_count: 1,
        floor_underground_count: 0,
        floors: [
            {
                "name": "지상 1.0",
                "area_m": 36.1,
                "area_py": 10.92025,
                "usage": "단독주택"
            }
        ],
        gov_parking: 0,
        ji_address: 23,
        land_area: 0,
        land_area_py: 0,
        land_category: "",
        land_category_code: 1,
        land_no: "",
        land_usage: "",
        no_owner: "",
        official_floor_area: 36.1,
        official_price_2017: "",
        owner_category: "",
        roof_architecture: "",
        street_address: "",
        total_area: 36.1,
        usage: "",
        permit_date : '',
    });
    const [openNowClick, setOpenNowClick] = useState(false);
    const [openModalPanorama, setOpenModalPanorama] = useState(false);
     
    const temp_locationPermission = useGeoLocation();
    const [locationPermission, setLocationPermission] = useState(temp_locationPermission);

    useEffect(() => {
        setLocationPermission(
            temp_locationPermission
        )
        setMapOptions((prevState) => ({
            ...prevState,
            zoom: 12,
            center: { 
                lat: temp_locationPermission.coordinates.lat, 
                lng: temp_locationPermission.coordinates.lng 
            },
        }));

        localStorage.setItem("lat", temp_locationPermission.coordinates.lat)
        localStorage.setItem("lng", temp_locationPermission.coordinates.lng)

        var center = {
            "y":temp_locationPermission.coordinates.lat,
            "_lat":temp_locationPermission.coordinates.lat,
            "x":temp_locationPermission.coordinates.lng,
            "_lng":temp_locationPermission.coordinates.lng,
        };
        
        localStorage.setItem('movemap', JSON.stringify(center));
        localStorage.setItem('movemap_zoom', 12);

    }, [temp_locationPermission])

    const [theStatus, setTheStatus] = useState("all");
    const [pinPoinViewType, setpinPoinViewType] = useState("price");
    const [holderCardResult, setHolderCardResult] = useState("");
    const [searchResult, setSearchResult] = useState("");
    const [pinPointResult, setPinPointResult] = useState([]);
   
    const [cardPreview,setCardPreview] = useState([]);

    const history = useHistory();
    const query = queryString.parse(history.location.search);
    const [triggerSearchQuery, setTriggerSearchQuery] = useState(false);

    useEffect(() => {
        // handleSearchCallback();
        if ('address' in query) {
            setFilterData((prevState) => ({
                ...prevState,
                name: query.address
            }));

            setTriggerSearchQuery(true);
        } else {
            handleSearchCallback();
        }
    }, []);

    
    useEffect(() => {
        if (triggerSearchQuery) {
            handleSearchWithBoundaries('searchBar');
            setTriggerSearchQuery(false);
            setSearchResult(query.address);
        }
    }, [triggerSearchQuery]);

    
    // RIGHT CARD
    const [theCadastral, setTheCadastral] = useState(false);
    const [distanceArea, setDistanceArea] = useState(false);
    const [theRoadView, setTheRoadView] = useState(false);
    const [thePano, setThePano] = useState(false);
    const [theMarker, setTheMarker] = useState([]);
    const [theMarkerInfoWindow, setTheMarkerIndoWindow] = useState([]);
    const [theMap, setTheMap] = useState(null);
    const [theNaver, setTheNaver] = useState(null);
    const [theNewGovData, setTheNewGovData] = useState(null);
        
    // MAPS
    
    const mapRef = useRef(null);
    const panoRef = useRef(null);

    const [mapOptions, setMapOptions] = useState({
        center: { 
            lat: locationPermission.lat, 
            lng: locationPermission.lng 
        },
        zoom: 12,
        zoomControl: false,
        mapTypeId: 'normal',
        logoControl: false
    });

    const handleMarkerClick = (theTarget) => {
        const theObjectData = holderCardResult.find(object => object.loopID === theTarget);

        const theBUILDING = theObjectData.building;
    
        setCardPreview(theBUILDING)

        console.log("cardPreview",cardPreview)
    }

    const closeCard = (index) =>{
        console.log("clicked", index);
        const newCardPreview = [...cardPreview];
        console.log("newCardPreview", newCardPreview);
        newCardPreview.splice(index, 1);

        setCardPreview(newCardPreview);
        
        console.log("cardPreview", cardPreview);
    }

    const handleOpenGovData = (UTMK) =>{
        console.log("govData",govData)
        setGovDataCard(true);
    }

    const closeGovCard = () => {
        setGovDataCard(false);
    }

    const openCreateCardOpen = () => {
        setOpenPopUpGovCreate(true)
    }

    const createCard = async () => {
        setOpenNowClick(true);
        setOpenPopUpGovCreate(false);
        closeGovCard()

        const token = Cookies.get('accessToken');
        const managerID_response = JSON.parse(Cookies.get('currentUser'));
        const managerID = managerID_response.id;
        const UTMK = JSON.parse(localStorage.getItem('gov_UTMK'));

        const theData = {
            "address": (govData.address ? govData.address : ""),
            "advertisement": false,
            "area": (govData.area ? govData.area : ""),
            "building_area": (govData.building_area ? govData.building_area : ""),
            "name": (govData.building_name ? govData.building_name : ""),
            "distance_station": 0,
            "floor_information": govData.floors.map(floor => {
                return {
                  "floor": floor.name.replace(/\.0/, "").replace("지상", ""),
                  "area": floor.area_m,
                  "usage": floor.other_usage == '소매점' ? '근린생활시설(소매점)' : floor.other_usage ,
                  "floor_merge_down": false,
                  "area_merge_down": false,
                  "usage_merge_down": false
                };
            }),
            "floor_underground_count": (govData.floor_underground_count ? govData.floor_underground_count : ""),
            "land_specific_information": [
                {
                    "number": (govData.land_no ? govData.land_no : ""),
                    "category": (govData.land_category ? govData.land_category : ""),
                    "area": (govData.land_area ? govData.land_area : 0),
                    "district": (govData.district ? govData.district : ""),
                    "restriction": "No",
                    "official_price": (govData.official_price_2017 ? govData.official_price_2017 : ""),
                    "usage": {"id": 0, "name": (govData.land_usage ? govData.land_usage : "")}
                    
                },
            ],
            "nearest_station": "",
            "notes": "",
            "no_owner": (govData.no_owner ? govData.no_owner : ""),
            "owner_category": (govData.owner_category ? govData.owner_category : ""),
            "status": 0,
            "street_address": (govData.street_address ? govData.street_address : ""),
            "manager_id":managerID,
            "longitude": UTMK.x ,
            "latitude": UTMK.y,
            "building_information": {
                "build_with": (govData.building_architecture ? govData.building_architecture : "") + ", " + (govData.roof_architecture ? govData.roof_architecture : ""),
                "custom_total_area": null ,
                "remodel_at": "",
                "usage": (govData.usage + ", " + govData.other_usage),
                "build_at": (govData.built_at && govData.built_at != "0" ? `${govData.built_at.substring(0, 4)}-${govData.built_at.substring(4, 6)}-${govData.built_at.substring(6, 8)}` : "0"),
                "court": "",
                "floor": `지하${(govData.floor_underground_count)}층/지상${(govData.floor_ground_count)}층`,
                "elevator": (govData.elevator ? govData.elevator : ""),
                "building_value": 0,
                "land_price": 0.0,
                "illegal_building": false,
                "group_building": false,
                "air_id": 0,
                "parking_id": 0,
                "total_parking_gov": (govData.gov_parking ? govData.gov_parking : ""),
                "total_parking_personal": 0,
                "permit_date": (govData.permit_date && govData.permit_date != "0" ? `${govData.permit_date.substring(0, 4)}-${govData.permit_date.substring(4, 6)}-${govData.permit_date.substring(6, 8)}` : "0"),
            },
        };

        const config = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            data : theData
        };

        console.log("dataSend",theData)


        
        const BUILDING_LIST_RESPONS = await axios(process.env.REACT_APP_API_ROOT+"/v1/property/create", config)
                                        .then(response =>{
                                            setOpenPopUpGovCreate_fin(true);
                                            console.log("createID",response.data.data.property.id)
                                            setTheNewGovData(response.data.data.property.id)
                                        })
                                        .catch(error => {
                                            alert("Error Unknown")
                                            setOpenNowClick(false)
                                        });

        
        setOpenNowClick(false);
    }

    const createCardGov_Success = () => {
        setOpenPopUpGovCreate_fin(false);

        const currentDomain = window.location.hostname;
        const newUrl = window.location.origin+`/search/detail?id=${theNewGovData}&page=0&page_size=1`;
        window.open(newUrl, "_blank");
    };
 
   const [markerGov, setMarkerGov] = useState(null);
   const [markerGov_position, setMarkerGov_position] = useState(null);

   var map = null;
   var naver = window.naver;

   const skipFirstMount = useRef(false);

    useEffect(() => {

        if (!skipFirstMount.current) {
            skipFirstMount.current = true;
            return;
        }
        
        var pano = null;
        var guideline = null;
        var distanceListeners = [];
        var areaListeners = [];
        var polyline = null;
        var polygon = null;
        var lastDistance = null;
        var _ms = [];
        var markers = [];

        if (!naver) return;

        if (mapRef.current) {
            mapRef.current.innerHTML = '';
            // map = null;
        }

        map = new naver.maps.Map(mapRef.current, { ...mapOptions });
    

        const cadastral  = new naver.maps.CadastralLayer({ map: map });
        const streetLayer  = new naver.maps.StreetLayer({ map: map });

        cadastral.setMap(theCadastral ? map : undefined);
        streetLayer.setMap(theRoadView ? map : undefined);

        setTheMap(map);
        setTheNaver(naver)

        setTheMarker([]);
        setTheMarkerIndoWindow([]);
    
        var bounds = new naver.maps.LatLngBounds();

        var locations_bounds = [];

        // SET BOUNDARIES
        if(runBoundaries){
            pinPointResult.map((thePin,idx)=>{
                let theBuilding = thePin.building;

                if(theStatus == 'all' || 
                (theStatus == 'new' && theBuilding[0].status == 0) ||
                (theStatus == 'sale' && theBuilding[0].status == 1) ||
                (theStatus == 'pending' && theBuilding[0].status == 2)
                ){
                    locations_bounds.push(new naver.maps.LatLng(thePin.y,thePin.x));
                }
                
            });

            for (var i = 0; i < locations_bounds.length; i++) {
                bounds.extend(locations_bounds[i]);
            }

            map.fitBounds(bounds);
        }

        // SHOW PIN POINT
        pinPointResult.map((thePin,idx)=>{
            
            let theBuilding = thePin.building;
            let theClass_bg = '';
            let theClass_text = '';
            let theItem_text = '';
            let theItem_div = '';
            let theItem_notif = '';
            
            switch (theBuilding[0].status){
                case 0: //NEW
                    theClass_bg = 'bg-[rgba(0,0,0,.75)]';
                    theClass_text = 'text-white';
                    break;
                case 1: //SALE
                    theClass_bg = 'bg-[rgba(222,0,23,.75)]';
                    theClass_text = 'text-white';
                    break;
                case 2: //pending
                    theClass_bg = 'bg-[rgba(221,221,221,.75)] border-solid border-1 border-black';
                    theClass_text = 'text-black';
                    break;
            }
            
            if (theBuilding.length > 1){
                var thePopDetail = theBuilding.map((object, index) => {
                    const value = pinPoinViewType === 'price' ? object.price * 0.0001 :
                                  pinPoinViewType === 'revenue' ? object.revenue :
                                  pinPoinViewType === 'land_area' ? object.land_area :
                                  pinPoinViewType === 'floor_area' ? object.floor_area :
                                  pinPoinViewType === 'land_price' ? object.land_price :
                                  object.price * 0.0001;
                  
                    const displayValue = Number.isInteger(value) ? value.toFixed(0) : value.toFixed(1);
                  
                    const status = object.status === 0 ? 'NEW' : object.status === 1 ? 'SALE' : 'PENDING';
                  
                    return `${index + 1}. ${status}: ${numberWithCommas(displayValue)}`;
                });
                  
                
                var thePopDetail_final = thePopDetail.join('<br>');

                
                theItem_notif = `
                    <div
                        class="absolute right-0 top-[-5px] w-fit"
                    >
                        <span 
                            class="
                                rounded-full bg-red-500 text-white px-2 py-1 pb-0
                            "
                        >
                            ${theBuilding.length}
                        </span>

                        <div
                            class="absolute right-0 top-[-5px] w-fit invisible"
                            thePopUpNotif
                        >
                            <div
                                class="
                                    w-fit
                                    absolute right-[0] top-[-1.8rem]
                                    transform translate-x-[90%] translate-y-[-60%]
                                "
                            >
                                <div
                                    class="
                                        w-[80px] leading-normal
                                        max-h-[70px] overflow-y-scroll
                                        p-2 text-black text-left
                                        bg-white shadow-md text-[8px]
                                        rounded-lg rounded-bl-none 
                                        flex-1   
                                    "
                                >
                                    ${numberWithCommas(thePopDetail_final)}
                                </div>
                            
                                <div class="w-[15px] overflow-hidden inline-block absolute left-0 bottom-[-1.5rem]">
                                    <div 
                                        class="
                                            w-[15px] h-[25px] bg-white shadow-md
                                            transform rotate-45 translate-x-[-15%]
                                            transform origin-top-right
                                        "
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                `;
            }
            
            switch (pinPoinViewType){
                case "price":
                  theItem_text = formatValue(theBuilding[0].price * 0.0001, '억',1);
                  break;
                case "revenue":
                  theItem_text = formatValue(theBuilding[0].revenue, '%',1);
                  break;
                case "land_area":
                  theItem_text = formatValue(theBuilding[0].land_area * 0.2525, '평',1);
                  break;
                case "floor_area":
                  theItem_text = formatValue(theBuilding[0].raw.building_information.total_area_py, '평',1);
                  break;
                case "land_price":
                  theItem_text = formatValue(theBuilding[0].raw.price_information.price_per_area, '만원',0);
                  break;
            }    
            
            let finalDiv = `
                <div
                    class="
                        w-fit
                    "
                >
                    <div
                        class="
                            w-fit
                            flex flex-row
                            shadow-md rounded-md rounded-bl-none
                            p-3 whitespace-nowrap 
                            ${theClass_text}
                            text-md
                            ${theClass_bg}
                        "
                        data="${idx}"
                    >
                        ${theItem_notif}
                        ${numberWithCommas(theItem_text)}
                    </div>
                    
                    <div class="w-3 overflow-hidden inline-block absolute left-0 bottom-[-1rem]">
                        <div 
                            class="
                                w-3 h-4 ${theClass_bg} shadow-md
                                transform rotate-45 translate-x-[-15%]
                                transform origin-top-right
                            "
                        ></div>
                    </div>
                
                </div>
            `

            if(theStatus == 'all' || 
            (theStatus == 'new' && theBuilding[0].status == 0) ||
            (theStatus == 'sale' && theBuilding[0].status == 1) ||
            (theStatus == 'pending' && theBuilding[0].status == 2)
            ){
                var thPosition = new naver.maps.LatLng(thePin.y,thePin.x);
            
                var marker = new naver.maps.Marker({
                    map: map,
                    position: thPosition,
                    title: (thePin.building)[0].address,
                    icon: {
                        content: `  <div>
                                        ${finalDiv}
                                    </div>`,
                        size: new naver.maps.Size(24, 32),
                        anchor: new naver.maps.Point(0,50)
                    },
                    zIndex: 100
                });

                markers.push(marker);

                naver.maps.Event.addListener(marker, 'click', function(e) {
                    console.log(e);

                    var theEvent = parseInt(e.domEvent.target.attributes.data.value)
                    handleMarkerClick(theEvent)
                });

                naver.maps.Event.addListener(marker, 'mouseover', function(e) {
                    var target = e.domEvent.target;
                    var thepopupnotif = target.closest('[thepopupnotif]');

                    const parentDiv = target;
                    const childDiv = parentDiv.querySelector('div[thepopupnotif]');

                    if(childDiv){
                        childDiv.classList.remove('invisible');
                    }
                
                });

                naver.maps.Event.addListener(marker, 'mouseout', function(e) {
                    const target = e.domEvent.target;
                    const thepopupnotif = target.closest('[thepopupnotif]');
                    const parentDiv = target;
                    const childDiv = parentDiv.querySelector('div[thepopupnotif]');

                    if(childDiv){
                        childDiv.classList.add('invisible');
                    }
                });


                setTheMarker([...theMarker, marker]);
            }
            
            
        });


        naver.maps.Event.addListener(map, 'center_changed', function(center) {
            localStorage.setItem('movemap', JSON.stringify(center));
        });
        
        naver.maps.Event.addListener(map, 'drag', function() {
            const center = JSON.parse(localStorage.getItem('movemap'));

            setMarkerGov(null);
            setMapOptions((state) => ({
                ...state,
                center: {
                    lat: center._lat,
                    lng: center._lng
                }
            }))
        });

        naver.maps.Event.addListener(map, 'zoom_changed', function(zoom) {
            const center = map.getCenter();

            localStorage.setItem('movemap_zoom', JSON.stringify(zoom));
            localStorage.setItem('movemap', JSON.stringify(center));

            setMapOptions((state) => ({
                ...state,
                zoom,
                center: {
                    lat: center._lat,
                    lng: center._lng
                }
            }))
        });

        naver.maps.onJSContentLoaded = function() {
            pano = new naver.maps.Panorama(panoRef.current, {
                position: new naver.maps.LatLng(37.3599605, 127.1058814),
                pov: {
                    pan: -133,
                    tilt: 0,
                    fov: 100
                }
            });

            setThePano(new naver.maps.Panorama(panoRef.current, {
                position: new naver.maps.LatLng(37.3599605, 127.1058814),
                pov: {
                    pan: -133,
                    tilt: 0,
                    fov: 100
                }
            }))
            
            

    
            naver.maps.Event.addListener(pano, 'pano_changed', function() {
                var latlng = pano.getPosition();
    
                if (!latlng.equals(map.getCenter())) {
                    map.setCenter(latlng);
                }

                
            });
            
        };

        naver.maps.Event.addListener(map, 'click', function(e) {
            if (streetLayer.getMap()) {

                console.log("thePano",thePano)

                if (!thePano) {
                    if (window.confirm("The panorama has not loaded yet. Do you want to reload the page?")) {
                        window.location.reload();
                    }
                }

                var latlng = e.coord;
                console.log(latlng)

                thePano.setPosition(latlng);
                setOpenModalPanorama(true)
            }
        });

        const _addMileStone = (coord, text, finisihed,from=false) => {
            if (!_ms) _ms = [];

            if(!finisihed){
                console.log("if")
                var marker = new naver.maps.Marker({
                    position: coord,
                    map: map,
                    icon: {
                        content: `<div style="border-radius: 100%; border: 4px solid #FF5656; width: 1rem;height: 1rem;position:absolute;z-index: 10;transform: translate(-50%,-50%);background-color:white"></div>`,
                    },
                });
            }
            
            var ms;

            if(from == 'area'){
                var theMarker = new naver.maps.Marker({
                    position: coord,
                    map: map,
                    icon: {
                        content: `<span class="bg-white shadow-md p-2 text-xs"><span>${numberWithCommas(text)}</span> 
                                    <button class="removeDistanceArea">
                                        <svg class='w-3 ml-1 cursor-pointer hover:opacity-50'
                                            viewBox="0 0 12 12" version="1.1"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <line x1="1" y1="11" 
                                                x2="11" y2="1" 
                                                stroke="#333333" 
                                                strokeWidth="2"/>
                                            <line x1="1" y1="1" 
                                                x2="11" y2="11" 
                                                stroke="#333333" 
                                                strokeWidth="2"/>
                                        </svg>
                                    </button>
                                </span>`,
                        anchor: new naver.maps.Point(-5, -5),
                    },
                });

                ms = theMarker;

                var buttonEl = theMarker.getElement().querySelector('.removeDistanceArea');
                naver.maps.Event.addDOMListener(buttonEl, 'click', function(e) {
                    setDistanceArea(false);
                    setDistanceArea('area');

                    console.log("BUTTON CLICKED",polygon);
                    console.log("BUTTON CLICKED",polygon);
                    
                    _ms = [];
                    polygon = [];
                    
                    e.stopPropagation(); // prevent click event from bubbling up to the marker
                    _clearMode()
                });
            }
            else{
                var theMarkers = new naver.maps.Marker({
                    position: coord,
                    map: map,
                    icon: {
                        content: `<span class="bg-white shadow-md p-2 text-xs"><span>${numberWithCommas(text)}</span> 
                                    <button class="removeDistanceArea">
                                        <svg class='w-3 ml-1 cursor-pointer hover:opacity-50'
                                            viewBox="0 0 12 12" version="1.1"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <line x1="1" y1="11" 
                                                x2="11" y2="1" 
                                                stroke="#333333" 
                                                strokeWidth="2"/>
                                            <line x1="1" y1="1" 
                                                x2="11" y2="11" 
                                                stroke="#333333" 
                                                strokeWidth="2"/>
                                        </svg>
                                    </button>
                                </span>`,
                        anchor: new naver.maps.Point(-5, -5),
                    },
                });

                var buttonEls = theMarkers.getElement().querySelector('.removeDistanceArea');
                naver.maps.Event.addDOMListener(buttonEls, 'click', function(e) {
                    setDistanceArea(false);
                    setDistanceArea('distance');

                    console.log("BUTTON CLICKED",polygon);
                    console.log("BUTTON CLICKED",polygon);
                    
                    _ms = [];
                    polygon = [];
                    
                    e.stopPropagation(); // prevent click event from bubbling up to the marker
                    _clearMode()
                });
            }
            _ms.push(ms)



            const button = ms.getElement().querySelector('.removeDistanceArea');

            // Attach an event listener to the button
            button.addEventListener('click', () => {
                _ms = []
            });
        }

        const _onMouseMoveDistance = (e) => {   
            const coord = map.getProjection().fromPageXYToCoord(new naver.maps.Point(e.pageX, e.pageY));

            if(guideline){
                const path = guideline.getPath();
        
                if (path.getLength() === 2) {
                    path.pop();
                }
            
                path.push(coord);
            }
            
        }

        const _onMouseMoveArea = (e) => {
            if (!polygon || polygon.length == 0) return;

                const proj = map.getProjection();
                const coord = proj.fromPageXYToCoord(new naver.maps.Point(e.pageX, e.pageY));
                const path = polygon.getPath();
                
            if (path.getLength() >= 2) {
                path.pop();
            }
    
            path.push(coord);
        };

        const _fromMetersToText = (meters) => {
            meters = meters || 0;
        
            const km = 1000;
            let text = meters;
        
            if (meters >= km) {
                text = parseFloat((meters / km).toFixed(1)) +'km';
            } else {
                text = parseFloat(meters.toFixed(1)) +'m';
            }
        
            return text;
        }

        const _fromSquareMetersToText = (squarMeters) => {
            squarMeters = squarMeters || 0;
    
            var squarKm = 1000 * 1000,
                text = squarMeters;
    
            if (squarMeters >= squarKm) {
                text = parseFloat((squarMeters / squarKm).toFixed(1)) + 'km<sup>2</sup>';
            } else {
                text = parseFloat(squarMeters.toFixed(1)) + 'm<sup>2</sup>';
            }
    
            return text;
        }

        const _finishDistance = (e) => {


            naver.maps.Event.removeListener(distanceListeners);
            distanceListeners = [];
        
            document.removeEventListener('mousemove.measure', _onMouseMoveDistance);
        
            if (guideline) {
                guideline.setMap(null);
                guideline = undefined;
            }
        
            if (polyline) {
                const path = polyline.getPath();
                const lastCoord = path.getAt(path.getLength() - 1);
                const distance = polyline.getDistance();
        
                polyline = undefined;
        
                // if (lastCoord) {
                //     _addMileStone(e.coord, _fromMetersToText(distance), true);
                // }
            }
        
        
            map.setCursor('auto');
        
            distanceListeners = [];
        };

        const _finishArea = () => {
            const paths = polygon.getPath();
            console.log("path",paths)
            naver.maps.Event.removeListener(areaListeners);
            
    
            if (polygon) {
                var path = polygon.getPath();
                path.pop();
    
                var squarMeters = polygon.getAreaSize(),
                    lastCoord = path.getAt(path.getLength() - 1);
    
                if (lastCoord) {
                    _addMileStone(lastCoord, _fromSquareMetersToText(squarMeters), true,'area');
                }
    
                polygon = [];
            }
    
            map.setCursor('auto');

            areaListeners = [];
        };

        const _clearMode = () => {
            console.log("ClearMOde")
            if (!distanceArea) return;
    
            if (distanceArea === 'distance') {
                if (polyline) {
                    polyline.setMap(null);
                    polyline = undefined;
                }
    
                _finishDistance();
    
                if (_ms) {
                    for (var i=0, ii=_ms.length; i<ii; i++) {
                        _ms[i].setMap(null);
                    }
    
                    _ms = [];
                }
            } else if (distanceArea === 'area') {
                if (polygon) {
                    polygon.setMap(null);
                    polygon = undefined;
                }
    
                _finishArea();
            }
        }

        const _onClickDistance = (e) => {
            const coord = e.coord;
    
            if (!polyline) {
                guideline = new naver.maps.Polyline({
                    strokeColor: '#FF5656',
                    strokeWeight: 3,
                    strokeStyle: [4, 4],
                    strokeOpacity: 1,
                    path: [coord],
                    map: map
                })
                
                document.addEventListener('mousemove', _onMouseMoveDistance, { once: false, capture: false });
            
                distanceListeners.push(naver.maps.Event.addListener(map, 'rightclick', _finishDistance));
                
                polyline = new naver.maps.Polyline({
                    strokeColor: '#FF5656',
                    strokeWeight: 4,
                    strokeOpacity: 1,
                    strokeLineCap:'round',
                    strokeLineJoin:'round',
                    path: [coord],
                    map: map
                });

                var marker = new naver.maps.Marker({
                    position: coord,
                    map: map,
                    icon: {
                        content: `<div style="border-radius: 100%; border: 4px solid #FF5656; width: 1rem;height: 1rem;position:absolute;z-index: 10;transform: translate(-50%,-50%);background-color:white"></div>`,
                    },
                });

                lastDistance = polyline.getDistance();
                
            } 
            else {
                guideline.setPath([e.coord]);
                polyline.getPath().push(coord);
    
                const distance = polyline.getDistance();
                console.log("coord click",coord)
                console.log("distance",distance)
                console.log("lastDistance",lastDistance)
                // _addMileStone(coord, _fromMetersToText(distance - lastDistance));
                _addMileStone(coord, _fromMetersToText(distance));

                
                lastDistance = distance;
            }
        }

        const _onClickArea = (e) => {
            const coord = e.coord;
    
            if (!polygon) { 
                polygon = new naver.maps.Polygon({
                    strokeColor: '#FF5656',
                    strokeOpacity: 1,
                    strokeWeight: 4,
                    fillColor: '#FF5656',
                    fillOpacity: 0.3,
                    paths: [coord],
                    map: map
                });

                document.addEventListener('mousemove', _onMouseMoveArea, { once: false, capture: false });
            
                areaListeners.push(naver.maps.Event.addListener(map, 'rightclick', _finishArea));
            } 
            else {
                polygon.getPath().push(coord);
            }
        }

        if(distanceArea != false){
            map.setCursor("url('"+rullerIcon+"'), default");

            if(distanceArea == 'distance'){
                naver.maps.Event.addListener(map, 'click', _onClickDistance);
            }
            if(distanceArea == 'area'){
                naver.maps.Event.addListener(map, 'click', _onClickArea);
            }
        }

        if(markerGov != null){
            console.log("govData",govData)
            var marker = new naver.maps.Marker({
                map: map,
                position: markerGov_position,
                title: "Add Card",
                icon: {
                    content: `  <div
                                    class="bg-white p-4 text-xs drop-shadow-md rounded-md border-solid border-[#707070] border-[1px] text-left cursor-default"
                                >
                                    <div>
                                        <text class="mb-6"><b>${govData.address}</b></text>

                                        <table class="table-auto border-separate border-spacing-2 ">
                                            <tbody>
                                                <tr>
                                                    <td class="text-[#777777]">빌딩이름</td>
                                                    <td>${govData.building_name}</td>
                                                </tr>
                                                <tr>
                                                    <td class="text-[#777777]">대지면적</td>
                                                    <td>${numberWithCommas(formatValue(govData.land_area, ''))}㎡(${numberWithCommas(formatValue(govData.land_area_py, ''))}평)</td>
                                                </tr>
                                                <tr>
                                                    <td class="text-[#777777]">연면적</td>
                                                    <td>${numberWithCommas(formatValue(govData.total_area, ''))}㎡(${numberWithCommas(formatValue(govData.total_area/3.306, ''))}평)</td>
                                                </tr>
                                                <tr>
                                                    <td class="text-[#777777]">규모</td>
                                                    <td>지하${(govData.floor_underground_count)}층/지상${(govData.floor_ground_count)}층</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <button class="border-[.5px] border-[#707070] w-full p-1 flex justify-center rounded-sm cursor-pointer hover:bg-[#F9F9F9]">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="h-4" color="#555555"><path fill-rule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clip-rule="evenodd"></path></svg>
                                        </button>
                                    </div>

                                    <div class="w-5 overflow-visible inline-block absolute left-[50%] bottom-[-1.3rem]">
                                        <div class="
                                                border-[#707070] border-b-[1px]  border-r-[1px]  
                                                w-5 h-5 bg-white
                                                transform rotate-45 translate-x-[-15%]
                                                transform origin-top-right
                                            "></div>
                                    </div>
                                </div>`,
                    size: new naver.maps.Size(24, 32),
                    anchor: new naver.maps.Point(105, 175)
                },
                zIndex: 100
            });

            naver.maps.Event.addListener(marker, 'click', function(e) {
                handleOpenGovData()
            });
        }

        naver.maps.Event.addListener(map, "dblclick", async function(e) {
            setOpenNowClick(true);

            const token = Cookies.get('accessToken');

            var latlng = new naver.maps.LatLng(e.coord._lat,e.coord._lng);
            

            const config = {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`
                }
            };
              
            const findBuilding = await axios(process.env.REACT_APP_API_ROOT+`/v1/map/locate?longitude=${latlng._lng}&latitude=${latlng._lat}`, config)
                                        .then(response => {
                                            if(response.status == 200 && response.data.data){
                                                var theBuilding = response.data.data;

                                                theBuilding.floors.sort(sortByFloor);

                                                const newGovData = {...govData, ...theBuilding};
                                                setGovData(newGovData);
                                                console.log("newGovData",newGovData)
                                                console.log("govData",govData)
                                                console.log("theBuilding",theBuilding)
                                                                        
                                                var _position = new naver.maps.LatLng(theBuilding.latitude,theBuilding.longitude);

                                                localStorage.setItem('gov_UTMK', JSON.stringify(_position));


                                                setMarkerGov_position(_position)

                                                setMarkerGov({
                                                    map: map,
                                                    position: _position,
                                                    title: "Add Card",
                                                    icon: {
                                                        content: `  <div
                                                                        class="bg-white p-4 text-xs drop-shadow-md rounded-md border-solid border-[#707070] border-[1px] text-left cursor-default"
                                                                    >
                                                                        <div>
                                                                            <text class="mb-6"><b>${theBuilding.address}</b></text>

                                                                            <table class="table-auto border-separate border-spacing-2 ">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td class="text-[#777777]">빌딩이름</td>
                                                                                        <td>${theBuilding.building_name}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td class="text-[#777777]">대지면적</td>
                                                                                        <td>${numberWithCommas(formatValue(theBuilding.land_area, ''))}㎡(${numberWithCommas(formatValue(theBuilding.land_area_py, ''))}평)</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td class="text-[#777777]">연면적</td>
                                                                                        <td>${numberWithCommas(formatValue(theBuilding.total_area, ''))}㎡(${numberWithCommas(formatValue(theBuilding.total_area/3.306, ''))}평)</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td class="text-[#777777]">규모</td>
                                                                                        <td>지하${(theBuilding.floor_underground_count)}층/지상${(theBuilding.floor_ground_count)}층</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>

                                                                            <button class="border-[.5px] border-[#707070] w-full p-1 flex justify-center rounded-sm cursor-pointer hover:bg-[#F9F9F9]">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="h-4" color="#555555"><path fill-rule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clip-rule="evenodd"></path></svg>
                                                                            </button>
                                                                        </div>

                                                                        <div class="w-5 overflow-visible inline-block absolute left-[50%] bottom-[-1.3rem]">
                                                                            <div class="
                                                                                    border-[#707070] border-b-[1px]  border-r-[1px]  
                                                                                    w-5 h-5 bg-white
                                                                                    transform rotate-45 translate-x-[-15%]
                                                                                    transform origin-top-right
                                                                                "></div>
                                                                        </div>
                                                                    </div>`,
                                                        size: new naver.maps.Size(24, 32),
                                                        anchor: new naver.maps.Point(105, 175)
                                                    },
                                                    zIndex: 100
                                                })

                           

                                                setMapPosition()
                                                
                                                
                                            }
                                            else if(!response.data.data){
                                                alert("Empty Result")
                                            }
                                            else{
                                                alert("Error Unknown")
                                            }
                                        })
                                        .catch(error => {
                                            alert("Error Unknown")
                                            setOpenNowClick(false)
                                        });

            

            setOpenNowClick(false);
        });

        // Marker Clustering
        (() => {
            const MarkerCluster = makeMarkerCluster(naver || window.naver);

            new MarkerCluster({
                minClusterSize: 0,
                maxZoom: 17, // default: 13
                map: map,
                markers: markers,
                gridSize: 120, // distance of the markers. default: 120
                disableClickZoom: false,
                icons: [
                    {
                        content: `<div style="cursor:pointer;width:50px;height:50px;line-height:50px;font-size:12px;color:white;text-align:center;background-color:rgba(0,0,0,.75);background-size:contain;border-radius:500px;"></div>`,
                        size: new naver.maps.Size(50, 50),
                        anchor: new naver.maps.Point(25, 25)
                    },
                ],
                stylingFunction: (cluster, count) => {
                    cluster.getElement().querySelector('div:first-child').innerText = count;
                }
            });
        })();

        // SET ZOOM
        if (map && !('addres' in query) && !runBoundaries) {
            // const newZoom = Number.parseInt(localStorage.getItem('movemap_zoom')) || mapOptions.zoom;
            const newZoom = mapOptions.zoom;

            if (isFromRefreshCallback) {
                var centerLocal_lat = localStorage.getItem("lat");
                var centerLocal_lng = localStorage.getItem("lng");

                var newCenter = { 
                    lat: centerLocal_lat, 
                    lng: centerLocal_lng 
                }

                map.setCenter(new naver.maps.LatLng(newCenter.lat, newCenter.lng));

                setIsFromRefreshCallback(false);
            } else {
                map.setCenter(new naver.maps.LatLng(mapOptions.center.lat, mapOptions.center.lng));
            }

            map.setZoom(newZoom);
        }
    }, [
        // mapOptions,theCadastral,theRoadView,locationPermission,openModalPanorama,thePano,pinPointResult,theStatus,pinPoinViewType,
        mapOptions.mapTypeId, theCadastral, theRoadView, locationPermission,openModalPanorama,thePano,pinPointResult,theStatus,pinPoinViewType,
        distanceArea, markerGov
    ]);

    useEffect(() => {
        if (map && mapOptions.mapTypeId !== map.getMapTypeId()) {
            const { zoom, center } = mapOptions;

            map.setZoom(zoom);
            map.setCenter(new naver.maps.LatLng(center.lat, center.lng));
            map.setMapType(mapOptions.mapTypeId);
        }
    }, [mapOptions]);




    // LEFT CARD
    const handleModalCallback = (e)=>{
        setIsOpen(!isOpen);
    };

    const handleSearchOnChangeCallback = (e) => {
        setFilterData({
          ...filterData,
          name: e,
        //   address:e,
        //   street_address:e,
        });
    };

    const handleRefreshCallback = () => {
        if ('address' in query) history.push('/map');

        setRunBoundaries(false);
        setIsFromRefreshCallback(true);
        setLocationPermission(
          temp_locationPermission
        )

        setSearchResult('');

        localStorage.setItem('movemap_zoom', 12);

        var centerLocal_lat = localStorage.getItem("lat");
        var centerLocal_lng = localStorage.getItem("lng");

        var newCenter = { 
            lat: centerLocal_lat, 
            lng: centerLocal_lng 
        }

        setMapOptions((state) => ({ 
            ...state, 
            mapTypeId: "normal" ,
            zoom: 12,
            center: newCenter
        }));

        setpinPoinViewType("price")
        setTheStatus('all')

        filterData.address = '';
        filterData.is_road_corner = false;
        filterData.is_road_side = false;
        filterData.manager = '';
        filterData.max_floor_m = '';
        filterData.max_floor_py = '';
        filterData.max_land_m = '';
        filterData.max_land_py = '';
        filterData.max_price = '';
        filterData.max_revenue = '';
        filterData.max_road = '';
        filterData.min_floor_m = '';
        filterData.min_floor_py = '';
        filterData.min_land_m = '';
        filterData.min_land_py = '';
        filterData.min_price = '';
        filterData.min_revenue = '';
        filterData.min_road = '';
        filterData.name = '';
        filterData.nearest_station = '';
        filterData.owner = '';
        filterData.road = '';
        filterData.status_all = false;
        filterData.status_new = false;
        filterData.status_pending = false;
        filterData.status_sale = false;
        filterData.LandUsageBody = "선택";
        filterData.LandUsageBody_text = '';

        setFilterData({
            ...filterData
        });

        console.log("refresh callback - filterdata",filterData)
        handleSearchCallback();

        setIsOpen(false)
    };

    const handleSearchCallback = async (from = false) => {
        setOpenNowClick(true);
        const token = Cookies.get('accessToken');
        // const token = cookiesManager.getAccessToken();



        console.log("filterData",filterData)

        const theFilterData = {
            ...filterData,
            'min_price' : filterData['min_price']*10000,
            'max_price' : filterData['max_price']*10000
        };

        if(Object.keys(theFilterData).length > 0){
            Object.keys(theFilterData).forEach(key => {
                if (theFilterData[key] == '' || theFilterData[key] === '선택') {
                    delete theFilterData[key];
                }
            });
        }

        theFilterData.status = [];

        if (filterData.status_new) {
            // delete theFilterData.status_new;
            theFilterData.status.push(cardStatus.new);
        }
        
        if (filterData.status_sale) {
            // delete theFilterData.status_sale;
            theFilterData.status.push(cardStatus.sale);
        }
        
        if (filterData.status_pending) {
            // delete theFilterData.status_pending;
            theFilterData.status.push(cardStatus.pending);
        }


        console.log("theFilterData",theFilterData)

        const theGetParam =   Object.entries(theFilterData)
                                    .map(([key, value]) => {
                                        // if (key === 'min_price' || key === 'max_price') {
                                        //     value *= 10000;
                                        // }
                                        // else 
                                        if(key == 'LandUsageBody' && value == '직접입력'){
                                            value = theFilterData["LandUsageBody_text"];
                                        }
                                        console.log(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
                                        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
                                    })
                                    .join('&');

        console.log("theGetParam",theGetParam)

        const config = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            data: {
                ...theFilterData,
            }
        };


        var theEndPoint;

        if(from == 'searchBar'){
            theEndPoint = `/v1/map/search/main`;
            // theEndPoint = `/v1/map/search?${theGetParam}`;
        } else{
            theEndPoint = `/v1/map/search?${theGetParam}`;
            // theEndPoint = `/v1/map/search`;
        }


        const BUILDING_LIST_RESPONS = await axios(process.env.REACT_APP_API_ROOT+theEndPoint, config)
                                        .catch(error => {
                                            alert("ERROR UNKNOWN")
                                            setOpenNowClick(false)
                                        });

        console.log("BUILDING_LIST_RESPONS.data",BUILDING_LIST_RESPONS.data.data)
        
        var LIST_BUILDING = null;

        if(BUILDING_LIST_RESPONS){
            LIST_BUILDING = BUILDING_LIST_RESPONS.data.data.map((data) => {
                var theBG = "";
                if((data.images)[0]){
                    theBG =  (data.images)[0].url ;
                }
                else{
                    theBG =  "";
                }
                
                var temp_data = {};
                temp_data.id = data.id;
                temp_data.status = data.status;
                temp_data.address = data.address;
                temp_data.advertisement = data.advertisement;
                temp_data.building_name = data.building_name;
                temp_data.floor_count = (data.floor_information).length;
                temp_data.floor_information = data.floor_information;
                temp_data.images = theBG;
                temp_data.price = data.price_information.price;
                temp_data.revenue = data.price_information.revenue;
                temp_data.land_area = data.land_information.area;
                temp_data.floor_area = data.building_information.first_area;
                temp_data.land_price = data.price_information.land_price;
                temp_data.raw = data;
                temp_data.x =  data.latitude;
                temp_data.y =  data.longitude;
    
                return temp_data;
            });
        }

        const groupedData = LIST_BUILDING.reduce((acc, curr) => {
            (acc[curr.address] = acc[curr.address] || []).push(curr);
            return acc;
        }, {});
        
        var theLoopID = 0;
        const LIST_BUILDING_FINAL = await Promise.all(Object.entries(groupedData).map(async ([key, value]) => {

            const newValue = {
                loopID: theLoopID,
                building: value,
                x: value[0].y,
                y: value[0].x
            }
            theLoopID++;

            return newValue;
        }));


        setHolderCardResult(LIST_BUILDING_FINAL);
        setPinPointResult(LIST_BUILDING_FINAL);  
        setOpenNowClick(false);  
    };

    const handleSearchWithBoundaries = (from = false) => {
        // if (filterData.name.trim().length > 0) {
            setRunBoundaries(true);
            handleSearchCallback(from);
        // }
    }

    const handleStatusCallback = (e) => {

        const centerLocal_JSON = JSON.parse(localStorage.getItem("movemap"));
        const centerLocal_lat = centerLocal_JSON._lat;
        const centerLocal_lng = centerLocal_JSON._lng;


        const newZoom = parseInt(localStorage.getItem('movemap_zoom')) || 12;
        // const newZoom = mapOptions.zoom || parseInt(localStorage.getItem('movemap_zoom')) || 12;

        var newCenter = { 
            lat: centerLocal_lat, 
            lng: centerLocal_lng 
        }

        setMapOptions((state) => ({ 
            ...state, 
            mapTypeId: "normal" ,
            zoom: newZoom,
            center: newCenter
        }));


        
        
        setTheStatus(e)
    };

    const setMapPosition = () =>{
        const centerLocal_JSON = JSON.parse(localStorage.getItem("movemap"));
        const centerLocal_lat = centerLocal_JSON._lat;
        const centerLocal_lng = centerLocal_JSON._lng;

        const newZoom = parseInt(localStorage.getItem('movemap_zoom')) || 12;
        // const newZoom = mapOptions.zoom || parseInt(localStorage.getItem('movemap_zoom')) || 12;

        var newCenter = { 
            lat: centerLocal_lat, 
            lng: centerLocal_lng 
        }

        setMapOptions((state) => ({ 
            ...state, 
            mapTypeId: "normal" ,
            zoom: newZoom,
            center: newCenter
        }));
    }

    const handleChangePinPointType = (e) =>{
        setpinPoinViewType(e)
    }

    // RIGHT CARD
    const handleOnclick_cadastral = () => {
        setDistanceArea(false);
        setTheCadastral(!theCadastral);

        setMapOptions((state) => ({ 
            ...state, 
            mapTypeId: "normal"
        }));
    };
    
    const handleOnclick_DistanceArea = (e) => {
        const centerLocal_JSON = JSON.parse(localStorage.getItem("movemap"));
        const centerLocal_lat = centerLocal_JSON._lat;
        const centerLocal_lng = centerLocal_JSON._lng;


        // const newZoom = parseInt(localStorage.getItem('movemap_zoom')) || 12;
        const newZoom = mapOptions.zoom || parseInt(localStorage.getItem('movemap_zoom')) || 12;

        var newCenter = { 
            lat: centerLocal_lat, 
            lng: centerLocal_lng 
        }

        setMapOptions((state) => ({ 
            ...state, 
            mapTypeId: "normal" ,
            zoom: newZoom,
            center: newCenter
        }));

        setDistanceArea(e)
    }

    const handleOnclick_roadView = () => {
        setDistanceArea(false);
        setTheRoadView(!theRoadView);
        
        const centerLocal_JSON = JSON.parse(localStorage.getItem("movemap"));
        const centerLocal_lat = centerLocal_JSON._lat;
        const centerLocal_lng = centerLocal_JSON._lng;


        // const newZoom = parseInt(localStorage.getItem('movemap_zoom')) || 12;
        const newZoom = mapOptions.zoom || parseInt(localStorage.getItem('movemap_zoom')) || 12;

        var newCenter = { 
            lat: centerLocal_lat, 
            lng: centerLocal_lng 
        }

        setMapOptions((state) => ({ 
            ...state, 
            mapTypeId: "normal" ,
            zoom: newZoom,
            center: newCenter
        }));
    };

    const handleOnclick_getMapType = (e) => {
        setMapOptions((state) => ({ 
            ...state,
            mapTypeId: e === "normal" ? "normal" : "hybrid",
         }));
    };

    // CENTER CARD
    const handleFilterOnChangeCallback = (targetName,theValue) =>{
        setFilterData({
            ...filterData,
            [targetName] : theValue
        })
    }

    // Sorting Floor
    const sortByFloor = (a, b) => {
        const floorA = a.name.split(' ')[0];
        const floorB = b.name.split(' ')[0];

        // Define the order of the floors
        const floorOrder = { '지하': 0, '지상': 1, '옥탑': 2 };

        // Get the floor numbers
        const numA = parseInt(a.name.split(' ')[1]);
        const numB = parseInt(b.name.split(' ')[1]);

        // Compare the floors based on their order
        if (floorOrder[floorA] < floorOrder[floorB]) {
            return -1; // floorA comes before floorB
        } else if (floorOrder[floorA] > floorOrder[floorB]) {
            return 1; // floorA comes after floorB
        } else {
            // If floors are the same, compare by their number
            if (numA < numB) {
            return -1;
            } else if (numA > numB) {
            return 1;
            } else {
            return 0;
            }
        }
    };
    

    return (
        <styled.Wrapper>

            {/* LOADING VIEW */}
            <Transition 
                show={openNowClick}
                enter="transition-opacity duration-200"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div
                    className='
                    flex place-content-center	
                    w-full h-full
                    absolute left-0 top-0 right-0 bottom-0
                    bg-[rgba(255,255,255,.5)]
                    z-[100]
                    '
                >
                    <img
                        // className='
                        //     m-auto
                        //     min-w-[300px]
                        //     h-1/2
                        // '
                        className='
                            m-auto
                            w-[100px]
                            h-[100px]
                        '
                        src='https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif'
                    />
                </div>
            </Transition>

            {/* GOV CARD CREATE CONFIRMATION */}
            <div
                className={`
                    ${openPopUpGovCreate ? 'z-[121] opacity-100' : 'z-[-2] opacity-0'}
                    w-full h-full
                    absolute left-0 bottom-0 top-0 right-0
                    bg-[rgba(0,0,0,.35)]
                    flex justify-center items-center
                `}
            >   
                <div className='bg-white py-14 px-20 w-fit h-fit rounded-md'>
                    <h6 className='mb-4 text-lg'><b>카드 생성을 하시겠습니까?</b></h6>

                    <div className='flex justify-center'>
                        <button onClick={createCard} className="min-w-[4rem] px-4 py-2 rounded-sm border-[#707070] border-[1px] hover:bg-[#F9F9F9] mr-4">네</button>
                        <button onClick={()=>{setOpenPopUpGovCreate(false)}} className="min-w-[4rem] px-4 py-2 rounded-sm border-[#333333] border-[1px] bg-[#333333] text-white hover:bg-[#707070] hover:border-[#707070]">아니요</button>
                    </div>
                </div>
            </div>

            {/* GOV CARD CREATE SUCCESS */}
            <div
                className={`
                    ${openPopUpGovCreate_fin ? 'z-[121] opacity-100' : 'z-[-2] opacity-0'}
                    w-full h-full
                    absolute left-0 bottom-0 top-0 right-0
                    bg-[rgba(0,0,0,.35)]
                    flex justify-center items-center
                `}
            >   
                <div className='bg-white py-14 px-20 w-fit h-fit rounded-md'>
                    <h6 className='mb-4 text-lg'><b>완료</b></h6>
                    <h6 className='mb-4 text-lg'>카드등록이 완료되었습니다.</h6> 
                    
                    <button onClick={createCardGov_Success} className="min-w-[4rem] px-4 py-2 rounded-sm border-[#707070] border-[1px] hover:bg-[#F9F9F9] mr-4">확인</button>
                </div>
            </div>
            
            {/* GOV CARD */}
            <div
                className={`
                    ${openGovCard ? 'z-[120] opacity-100' : 'z-[-2] opacity-0'}
                    w-[38%] xl:max-h-[82%] lg:max-h-[82%] md:max-h-[72%]
                    absolute left-0 xl:top-[18%] lg:top-[25%] md:top-[25%]
                    bg-white rounded-t-md shadow-md
                    p-2 overflow-y-scroll
                `}
            >   
                <div className='flex justify-between px-4 pt-4'>
                    <div>
                        <b className='mr-10 text-xl'>{govData.address}</b>
                        <button
                            className='
                                bg-[#333333] rounded-md shadow-md
                                text-white py-2 px-4 text-xs
                                hover:bg-[#ACACAC] hover:shadow-none
                                transition linear duration-500
                            '
                            onClick={openCreateCardOpen}
                        >
                            등록
                        </button>
                    </div>

                    <button onClick={closeGovCard}>
                        <svg className='w-4 cursor-pointer hover:opacity-50'
                            viewBox="0 0 12 12" version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <line x1="1" y1="11" 
                                x2="11" y2="1" 
                                stroke="#333333" 
                                strokeWidth="2"/>
                            <line x1="1" y1="1" 
                                x2="11" y2="11" 
                                stroke="#333333" 
                                strokeWidth="2"/>
                        </svg>
                    </button>
                </div>

                <div className='py-8 px-6 w-full text-left grid grid-cols-3 '>
                    <div className='p-2 px-6'>
                        대지면적<br/>
                        {numberWithCommas(formatValue(govData.land_area, ''))}㎡ ({numberWithCommas(formatValue(govData.land_area_py, ''))}평)
                    </div>

                    <div className='border-x-[#DDDDDD] border-x-[1px] p-2 px-6'>
                        연면적<br/>
                        {numberWithCommas(formatValue(govData.total_area, ''))}㎡ ({numberWithCommas(formatValue((govData.total_area/3.306), ''))}평)
                    </div>

                    <div className='p-2 px-6'>
                        규모<br/>
                        지하{(govData.floor_underground_count)}층/지상{(govData.floor_ground_count)}층
                    </div>
                </div>

                <div className='py-8 px-4 text-left text-md'>
                    <div className='border-solid border-black border-b-[1px] w-full pb-2 text-lg mb-2'>토지</div> {/* Land  */}

                    <div className='px-6'>
                        <div className='grid grid-cols-2 gap-4'>
                            <span>지목</span> {/* Land Category  */}
                            <span>{govData.land_category}</span>

                            <span>면적</span> {/* Area  */}
                            <span>{govData.land_area}㎡</span>

                            <span>소유구분</span> {/* Owner Category  */}
                            <span>{govData.owner_category}</span>

                            <span>소유(공유)인수</span> {/* No. of Owner  */}
                            <span>{govData.no_owner}</span>

                            <span>공시지가(원/㎡)</span> {/* 2017 official price (원/㎡)  */}
                            <span>{govData.official_price_2017}</span>
                        </div>
                    </div>
                </div>

                <div className='py-8 px-4 text-left text-md'>
                    <div className='border-solid border-black border-b-[1px] w-full pb-2 text-lg mb-2'>건축물</div> {/* Section Building  */}

                    <div className='px-6'>
                        <div className='grid grid-cols-2 gap-4'>
                            <span>주/기타용도</span>  {/* Usage  */}
                            <span>{govData.usage},{govData.other_usage}</span>

                            <span>대지면적(㎡)</span> {/* Land Area(㎡) */}
                            <span>{govData.land_area}</span>

                            <span>건축면적(㎡)</span> {/* 1st Floor Area(㎡)*/}
                            <span>{govData.building_area}</span>

                            <span>건폐율 (%)</span> {/* Coverage Ratio (%) */}
                            <span>{ govData.building_cover_ratio}</span>

                            <span>외필지 수</span> {/* No. Other building  */}
                            <span>{parseInt(govData.no_other_building)}</span>

                            <span>허가일자</span> {/* Use Permission */}
                            <span>{govData.permit_date && govData.permit_date != "0" ? `${govData.permit_date.substring(0, 4)}-${govData.permit_date.substring(4, 6)}-${govData.permit_date.substring(6, 8)}` : '-'}</span>


                            <span>건물 이름 </span> {/* Name */}
                            <span>{govData.building_name}</span>


                            <span>연면적(㎡)</span> {/* Floor Area(㎡) */}
                            <span>{govData.total_area}</span>

                            <span>용적률신청용 연면적(㎡)</span> {/* Official Floor Area (㎡) */}
                            <span>{govData.official_floor_area}</span>

                            <span>용적률(%)</span> {/* Floor area ratio (%) */}
                            <span>{govData.floor_area_ratio}</span>

                            <span>주구조/지붕구조</span> {/* Building and roof architecture */}
                            <span>{govData.building_architecture}/{govData.roof_architecture}</span>

                            <span>착공일자</span> {/* Built at */}
                            <span>{ govData.built_at && govData.built_at != "0" ? `${govData.built_at.substring(0, 4)}-${govData.built_at.substring(4, 6)}-${govData.built_at.substring(6, 8)}` : '-'}</span>

                            <span>주차</span> {/* Parking */}
                            <span>{govData.gov_parking}</span>
                        </div>
                    </div>
                </div>

                <div className='py-8 px-4 text-left text-md'>
                    <div className='border-solid border-black border-b-[1px] w-full pb-2 text-lg mb-2'>층별 정보</div> {/* Floor Information */}

                    <div className='px-6 text-center'>
                        <div>
                            <div className='grid grid-cols-3 gap-2'>
                                <span>층</span> {/* Floor */}
                                <span>면적</span> {/* Area */}
                                <span>용도</span> {/* Usage */}
                            </div>

                            {govData.floors.map(function(d,index){
                                return (
                                    <div className='grid grid-cols-3 gap-2' key={index}>
                                        <span>{d.name.replace(/\.0/, "").replace("지상", "")}</span>
                                        <span>{d.area_m}㎡</span>
                                        <span>{d.other_usage == '소매점' ? '근린생활시설(소매점)' : d.other_usage}</span>
                                    </div>
                                )
                            })}

                            
                        </div>
                    </div>
                </div>
            </div>

            {/* LEFT CARD */}
            <div className='
                    w-full md:w-[38%] rounded-md
                    max-h-[100%]
                    absolute top-0 left-0
                    z-[3]
                    flex flex-col
                '
            >
                <LeftCard
                    modalCallback={handleModalCallback}
                    searchOnChangeCallback={handleSearchOnChangeCallback}
                    refreshCallback={handleRefreshCallback}
                    searchCallback={handleSearchWithBoundaries}
                    statusCallback={handleStatusCallback}
                    pinPointTypeCallback = {handleChangePinPointType}
                    isOpen={isOpen}
                    searchResult={searchResult}
                    statusValue={theStatus}
                />

                {/* LIST CARD CONTAINER */}
                <div
                    className={`
                    ${cardPreview.length > 0 ? 'w-full overflow-x-hidden' : 'hidden'}`}
                    id="hideBarContent"
                >

                    <div>
                        {
                            cardPreview.map((theProperty, index) => {
                                console.warn("theProperty",theProperty)
                                var theBG = "";
                                if(theProperty.images || theProperty.images != ''){
                                    theBG =  theProperty.images ;
                                }
                                else{
                                    theBG =  "https://www.slntechnologies.com/wp-content/uploads/2017/08/ef3-placeholder-image.jpg";
                                }
                        
                                return(
                                    <div 
                                        className='
                                            w-[100%]
                                            opacity-1 transition-opacity duration-700 ease-linear
                                        '
                                        key={index}
                                    >

                                        <div className='flex drop-shadow-md bg-white'>
                                            <div
                                                style={{backgroundImage: `url(${theBG})`}} 
                                                className='
                                                    bg-[image:var(--image-url)]  bg-cover bg-no-repeat bg-center w-[50%] p-6 text-xl
                                                '
                                            >
                                                {
                                                    theProperty.raw.status == 0 ? (
                                                        <div className='border-[#000000] bg-white text-[#000000] border-[1px] rounded-[100%] w-fit p-4'>신규</div>
                                                    ) : 
                                                    theProperty.raw.status == 1 ? (
                                                        <div className='border-[#de0017] bg-white text-[#de0017] border-[1px] rounded-[100%] w-fit p-4'>매물</div>
                                                    ) : (
                                                        <div className='border-[#000000] bg-white text-[#000000] border-[1px] rounded-[100%] w-fit p-4'>보류</div>
                                                    )
                                                }
                                            </div>
                                            
                                            <div className='p-4 w-[50%]'>
                                                <div className='mb-10 flex justify-between'>
                                                    <div className='w-[80%]'>
                                                        <h1 className='text-left text-lg'>
                                                            {
                                                                theProperty.raw.building_name != '' ? (
                                                                    <b className='mr-4'>{theProperty.raw.building_name}</b>
                                                                ) : <b className=''></b>
                                                            }
                                                        </h1>

                                                        <h1 className='text-left text-lg'>                                                        
                                                            {
                                                                theProperty.raw.address != '' ? (
                                                                    <span>{theProperty.raw.address}</span>
                                                                ) : <span></span>
                                                            }
                                                        </h1>
                                                    </div>

                                                    <button onClick={() => closeCard(index)} className='justify-end self-start'>
                                                        <svg className='w-4 cursor-pointer hover:opacity-50'
                                                            viewBox="0 0 12 12" version="1.1"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <line x1="1" y1="11" 
                                                                x2="11" y2="1" 
                                                                stroke="#333333" 
                                                                strokeWidth="2"/>
                                                            <line x1="1" y1="1" 
                                                                x2="11" y2="11" 
                                                                stroke="#333333" 
                                                                strokeWidth="2"/>
                                                        </svg>
                                                    </button>
                                                </div>
                                            
                                                <div className='w-full'>
                                                    <table className='border-spacing-4 w-full'>
                                                    <tbody>
                                                    <tr>
                                                        <td>매매가격</td>
                                                        <td>수익률</td>
                                                        <td>대지평당가</td>
                                                        <td>연면적 평당가</td>
                                                    </tr>
                                                    
                                                    <tr>
                                                        <td><b>{numberWithCommas(formatValue(theProperty.raw.price_information.price * 0.0001, ''))}</b>억</td>
                                                        <td className='border-[#DDDDDD] border-solid border-x-2'><b>{numberWithCommas(formatValue(theProperty.raw.price_information.revenue, ''))}</b>%</td>
                                                        <td className='border-[#DDDDDD] border-solid border-r-2'><b>{numberWithCommas(formatValue(theProperty.raw.price_information.price_per_area, '만원',0))}</b></td>
                                                        <td><b>{numberWithCommas(formatValue(theProperty.raw.price_information.price_per_total_area, '만원',0))}</b></td>
                                                    </tr>
                                                    </tbody>
                                                    </table>
                                                </div>
                                            
                                                <div className='w-full my-10 text-left'>
                                                    <table className='w-full'>
                                                    <tbody>
                                                        <tr>
                                                        <td className='pb-2'>대지면적</td>
                                                        <td>{numberWithCommas(formatValue(theProperty.raw.land_information.area, ''))}㎡ [{numberWithCommas(formatValue(theProperty.raw.land_information.area_py, ''))} PY]</td>
                                                        </tr>
                                                        <tr>
                                                        <td className='pb-2'>연면적</td>
                                                        <td>{numberWithCommas(formatValue(theProperty.raw.building_information.total_area, ''))}㎡ [{numberWithCommas(formatValue(theProperty.raw.building_information.total_area_py, ''))} PY]</td>
                                                        </tr>
                                                        <tr>
                                                        <td className='pb-2'>규모</td>
                                                        <td>{theProperty.raw.building_information.floor}</td>
                                                        </tr>
                                                        <tr>
                                                        <td className='pb-2'>준공연도</td>
                                                        <td>{theProperty.raw.building_information.build_at}</td>
                                                        </tr>
                                                        <tr>
                                                        <td>용도지역</td>
                                                        <td>{theProperty.raw.land_information.usage}</td>
                                                        </tr>
                                                    </tbody>
                                                    </table>
                                                </div>
                                            
                                                <div>
                                                    <a href={window.location.origin+`/search/detail?id=${theProperty.id}&page=0&page_size=1`} target='blank'>
                                                        <button 
                                                        className='
                                                            bg-[#333333] hover:bg-[#818181]
                                                            rounded-md text-white w-full p-3
                                                        '
                                                        >
                                                            물건카드
                                                        </button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            
            {/* CENTER CARD */}
            <div 
                className={`
                    ${isOpen ? 'opacity-100 z-[100] ' : 'opacity-0 z-[-2]'}
                    transition-all duration-500
                    w-full md:min-w-[37%] xl:max-w-[40%] 
                    absolute top-32 md:top-0 left-0 md:left-[38%]
                `}
            >
                <CenterCard
                    onChangeCallback={handleFilterOnChangeCallback}
                    // submitCallback={handleSearchWithBoundaries}
                    submitCallback={handleSearchCallback}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    refreshCallback={handleRefreshCallback}
                />
            </div>
            
            {/* RIGHT CARD */}
            <RightCard
                parentCallback_Cadastral={handleOnclick_cadastral}
                parentCallback_DistanceArea={handleOnclick_DistanceArea}
                parentCallback_RoadView={handleOnclick_roadView}
                parentCallback={handleOnclick_getMapType}
            />

            {/* MAP REF */}
            <div ref={mapRef} style={{ width: '100%', height: '100%' }} id="mapRef"/>
                            
            {/* PANORAMA REF */}
            <div 
                className={`
                    ${openModalPanorama ? 'opacity-100 z-[100] ' : 'opacity-0 z-[-2]'}
                    transition-all duration-500
                    w-[100vw] h-full absolute top-0 left-0 right-0 bottom-0
                    bg-[rgba(255,2555,255,.5)]
                `}
            >
                <div ref={panoRef} className='w-[100%] h-[100%]' id='ee'/>

                <button
                    className='
                        bg-[#F9F9F9] hover:bg-[#EEEEEE] outline-none
                        rounded-md shadow-md
                        border-solid border-[1px] border-[#DDDDDD]
                        ml-2 p-2
                        absolute top-4 left-4
                        text-center
                    '
                    onClick={()=>{
                        setOpenModalPanorama(false)
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" 
                        width="16" height="16" fill="#000000"
                        className="bi bi-arrow-left transform translate-y-[10%]" viewBox="0 0 16 16"
                    > 
                        <path fillRule="evenodd" 
                            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 
                            0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 
                            8.5H14.5A.5.5 0 0 0 15 8z"
                        ></path> 
                    </svg>
                </button>
            </div>

        </styled.Wrapper>
    );
};


export default NaverMap;