import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid;
  margin-bottom: 0.5rem;
`;

export const LogoContainer = styled.div`
  display: flex;
`;
