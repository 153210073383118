import * as defaultValues from "constants/defaultValue";

export class RequestDeleteCardLogs {
    constructor(
        cardLogIds = defaultValues.emptyArray
    ) {
        this.cardLogIds = cardLogIds;
    }

    mapToJson() {
        return {
            'ids': this.cardLogIds
        }
    }
}