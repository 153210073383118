import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
import cookiesManager from 'managers/cookies.manager';

const superagent = superagentPromise(
    _superagent,
    global.Promise
);
const responseBody = (response) => response.body;
export default (baseUrl = process.env.REACT_APP_API_ROOT) => {
    let accessToken = cookiesManager.getAccessToken()
    const tokenHeader = req => {
        if (accessToken) {
          req.set('authorization', `Bearer ${accessToken}`);
          req.set('Content-Type', "application/json");
        }
    }
    return {
        delete: (
            path,
            body,
            onSuccess = () => { return; } ,
            onFailed = () => { return; }
        ) => {
            return superagent
                .del(`${baseUrl}${path}`)
                .send(body)
                .use(tokenHeader)
                .then((response) => {
                    if (onSuccess) {
                        onSuccess(response.body);
                    }
                    return responseBody(response);
                })
                .catch((error) => {
                    if (!error.response) { return; }
                    onFailed(error.response.body);
                    return responseBody(error.response);
                })
        },
        get: (
            path,
            request,
            onSuccess = () => { return; },
            onFailed = () => { return; },
        ) => {
            return superagent
                .get(`${baseUrl}${path}`)
                .query(request)
                .use(tokenHeader)
                .then((response) => {
                    if (onSuccess) {
                        onSuccess(response.body);
                    }
                    return responseBody(response);
                })
                .catch((error) => {
                    if (!error.response) { return; }
                    onFailed(error.response.body);
                    return responseBody(error.response);
                })
        },
        patch: (
            path,
            body,
            onSuccess = () => { return; },
            onFailed = () => { return; },
        ) => {
            return superagent
                .patch(`${baseUrl}${path}`, body)
                .use(tokenHeader)
                .then((response) => {
                    if (onSuccess) {
                        onSuccess(response.body);
                        return;
                    }
                    return responseBody(response);
                })
                .catch((error) => {
                    if (error.response) {
                        onFailed(error.response.body);
                    }
                    return responseBody(error.response);
                })
        },
        put: (
            path,
            body,
            onSuccess = () => { return; },
            onFailed = () => { return; },
        ) => {
            return superagent
                .put(`${baseUrl}${path}`, body)
                .use(tokenHeader)
                .then((response) => {
                    onSuccess(response.body);
                    return responseBody(response);
                })
                .catch((error) => {
                    if (error.response) {
                        onFailed(error.response.body);
                    }
                    return responseBody(error.response);
                })
        },
        post: (
            path,
            body,
            onSuccess = () => { return; },
            onFailed = () => { return; },
        ) => {
            return superagent
                .post(`${baseUrl}${path}`, body)
                .use(tokenHeader)
                .then((response) => {
                    if (onSuccess) {
                        onSuccess(response.body);
                    }
                    return responseBody(response);
                })
                .catch((error) => {
                    if (error.response) {
                        onFailed(error.response.body);
                    }
                    return responseBody(error.response);
                })
        },
        upload: (
            path,
            body,
            onSuccess = () => { return; },
            onFailed = () => { return; },
        ) => {
            return superagent
                .post(`${baseUrl}${path}`, body)
                .use(
                    (req) => {
                        req.set("authorization", `Bearer ${accessToken}`);
                    }
                )
                .then((response) => {
                    if (onSuccess) {
                        onSuccess(response.body);
                    }
                    return responseBody(response);
                })
                .catch((error) => {
                    if (error.response) {
                        onFailed(error.response.body);
                    }
                    return responseBody(error.response);
                })
        }
    }
}