export class RequestAddImageDocument {
  constructor(
    propertyId,
    image
  ) {
    this.propertyId = propertyId;
    this.image = image;
  }

  mapToJson() {
    return {
      "property_id": this.propertyId,
      "image": this.image.mapToJson()
    }
  }
}