import styled from "@emotion/styled";
import tw from "twin.macro";

export const Container = styled.div`
    height: 32px;
    ${ tw`grid grid-cols-5 gap-3` }
`;

export const SpacingWrapper = styled.div`
    ${ tw`col-span-1` }
`;

export const TitleWrapper = styled.div`
    border-style: solid;
    border-width: 1px;
    ${ tw`flex col-span-3 border-gray-300 rounded` }
`;

export const DropdownWrapper = styled.div`
    ${ tw`col-span-1` }
`;
