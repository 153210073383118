import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as styled from './CreateWorkHistoryModal.styled';
import CloseIconSrc from 'assets/close_icon_black.png';
import ImageAlt from 'constants/imageAlt';
import { titleStrings } from 'localizations';
import Text from 'components/core/text/Text';
import TextArea from 'components/core/text-area/TextArea';
import Button from 'components/core/button/Button';
import { DarkGrayButtonStyle } from 'components/core/button/styles';
import buttonStrings from 'localizations/buttonStrings';
import sizes from 'constants/sizes';
import spaces from 'constants/spaces';
import fieldStrings from 'localizations/fieldStrings';
import cookiesManager from 'managers/cookies.manager';
import { commonActionTypes } from 'redux/types';
import dateFormats from 'constants/dateFormats';
import moment from 'moment';
import defaultValues from 'constants/defaultValues';
import { observer } from 'mobx-react-lite';
import { useDispatch } from 'react-redux';
import cardActionTypes from 'redux/types/cardActionTypes';
import cardActions from 'redux/actions/cardActions';
import { RequestCreateWorkHistory } from 'models/requests/RequestCreateWorkHistory';
import { useSelector } from 'react-redux';
import { ButtonViewModel } from 'components/core/button/ButtonViewModel';

const CreateWorkHistoryModal = observer((props) => {
  const [buttonViewModel] = useState(new ButtonViewModel());
  const [description, setDescription] = useState(defaultValues.emptyString);
  const cardReducer = useSelector((state) => state.card);
  const dispatch = useDispatch();

  const handleDismiss = () => {
    dispatch({
      type: commonActionTypes.HIDE_MODAL,
    });
  };

  const onStartCreateWorkHistory = () => {
    buttonViewModel.startLoading();
    dispatch({
      type: cardActionTypes.CREATE_WORK_HISTORY,
      payload: cardActions.createWorkHistory(
        new RequestCreateWorkHistory(cardReducer.card.id, description),
        didCreateWorkHistorySucceed
      ),
    });
  };

  const didCreateWorkHistorySucceed = (workHistory) => {
    props.onCreateWorkHistorySuccess(workHistory);
    handleDismiss();
  };

  const onDescriptionTextAreaChange = (value) => {
    setDescription(value);
  };

  return (
    <styled.Container>
      <styled.CloseButton onClick={handleDismiss}>
        <img
          src={CloseIconSrc}
          alt={ImageAlt.closeIcon}
        />
      </styled.CloseButton>
      <styled.TitleText>{titleStrings.workHistory}</styled.TitleText>
      <styled.FieldContainer>
        <styled.FieldTitleWrapper>
          <Text value={fieldStrings.createdDate} />
        </styled.FieldTitleWrapper>
        <styled.FieldValueWrapper>
          <Text value={moment().format(dateFormats.date)} />
        </styled.FieldValueWrapper>
      </styled.FieldContainer>
      <styled.FieldContainer>
        <styled.FieldTitleWrapper>
          <Text value={fieldStrings.writer} />
        </styled.FieldTitleWrapper>
        <styled.FieldValueWrapper>
          <Text value={cookiesManager.getCurrentUser().username} />
        </styled.FieldValueWrapper>
      </styled.FieldContainer>
      <TextArea
        marginTop={spaces.medium}
        marginBottom={spaces.large}
        height={sizes.textArea.createWorkHistory.height}
        handleChange={onDescriptionTextAreaChange}
      />
      <Button
        action={onStartCreateWorkHistory}
        title={buttonStrings.add}
        height={sizes.button.small}
        viewModel={buttonViewModel}
        style={DarkGrayButtonStyle}
      />
    </styled.Container>
  );
});

CreateWorkHistoryModal.propTypes = {
  onCreateWorkHistorySuccess: PropTypes.func,
  hideModal: PropTypes.func,
};

CreateWorkHistoryModal.defaultProps = {
  onCreateWorkHistorySuccess: () => {
    return;
  },
  hideModal: () => {
    return;
  },
};

export default CreateWorkHistoryModal;
