import React from 'react';
import ColumnTable from 'components/core/column-table/ColumnTable';
import * as defaultValues from 'constants/defaultValue';
import * as commonActions from 'redux/reducers/commonReducer';
import * as confirmationModalActions from 'layouts/modals/ConfirmationModal/confirmationModal.reducer';
import customerService from 'redux/actions/customerAction';
import employeeService from 'redux/actions/employeeAction';
import { titleStrings } from 'localizations';
import { useState } from 'react';
import { useEffect } from 'react';
import {
  RequestFetchCustomerList,
  RequestFetchEmployeeList,
} from 'models/requests';
import buttonStrings from 'localizations/buttonStrings';
import { RequestDeleteCustomer } from 'models/requests/RequestDeleteCustomer';
import { Customer } from 'models/objects/Customer';
import { RequestUpdateCustomerCondition } from 'models/requests/RequestUpdateCustomerCondition';
import { RequestUpdateCustomerMemo } from 'models/requests/RequestUpdateCustomerMemo';
import ChangeManagerModal from 'layouts/modals/ChangeManagerModal';
import zIndex from 'constants/zIndex';
import SearchCustomerModal from 'layouts/modals/SearchCustomerModal/SearchCustomerModal';
import CreateCustomerModal from 'layouts/modals/CreateCustomerModal/CreateCustomerModal';
import cookiesManager from 'managers/cookies.manager';
import ConfirmationModal from 'layouts/modals/ConfirmationModal/ConfirmationModal';
import { useDispatch } from 'react-redux';
import { Pagination } from 'models/objects/Pagination';
import { useHistory } from 'react-router';
import * as customerAction from 'redux/reducers/customerReducer';

const useCustomerPage = () => {
  const dispatch = useDispatch();
  const [customers, setCustomers] = useState(defaultValues.emptyArray);
  const [employees, setEmployees] = useState(defaultValues.emptyArray);
  const [selectedEmployeeItem, setSelectedEmployeeItem] = useState({
    key: defaultValues.emptyInt,
    value: buttonStrings.all,
  });
  const [selectedCustomer, setSelectedCustomer] = useState(new Customer());
  const [checkedCustomers, setCheckedCustomers] = useState(
    defaultValues.emptyArray
  );
  const [customersCount, setCustomersCount] = useState(defaultValues.emptyInt);
  const [pagination, setPagination] = useState(new Pagination());
  const history = useHistory();

  const _loadEmployeeList = () => {
    employeeService.fetchEmployeeList(
      new RequestFetchEmployeeList(),
      _didFetchEmployeeSucceed
    );
  };

  const _loadCustomerList = (request = new RequestFetchCustomerList()) => {
    const employee = cookiesManager.getCurrentUser();
    request.managerId = employee.isAdmin
      ? selectedEmployeeItem.key
      : employee.id;
    customerService.fetchCustomerList(request, _didFetchCustomerSucceed);
  };

  const _flushData = () => {
    setCustomers(defaultValues.emptyArray);
    setSelectedCustomer(new Customer());
    setCheckedCustomers(defaultValues.emptyArray);
    setCustomersCount(defaultValues.emptyInt);
  };

  const _reloadCustomerList = () => {
    _flushData();
    _loadCustomerList();
  };

  const _didFetchCustomerSucceed = (response) => {
    var pagination = response.pagination;
    setPagination(pagination);
    setCustomers(response.customers);
    setCustomersCount(response.pagination.totalItems);
  };

  const _didFetchEmployeeSucceed = (response) => {
    setEmployees(response.employees);
  };

  const _checkAllCustomers = () => {
    setCheckedCustomers(
      checkedCustomers.length == customers.length
        ? defaultValues.emptyArray
        : customers
    );
  };

  const _checkCustomer = (isCheck, customer) => {
    let customers = checkedCustomers.filter(($0) => $0.id != customer.id);
    if (isCheck) {
      customers.push(customer);
    }
    setCheckedCustomers(customers);
  };

  const setupTableRows = () => {
    var customerTableItems = customers.map((customer, index) => [
      <ColumnTable
        key={customer.id}
        handleAction={(isCheck) => _checkCustomer(isCheck, customer)}
        isCheckbox={true}
        value={checkedCustomers.some(($0) => $0.id == customer.id)}
      />,
      <ColumnTable
        key={customer.id}
        title={(index + 1).toString()}
      />,
      <ColumnTable
        key={customer.id}
        title={customer.createdAt}
      />,
      <ColumnTable
        key={customer.id}
        title={customer.name}
      />,
      <ColumnTable
        key={customer.id}
        title={customer.phone}
      />,
      <ColumnTable
        key={customer.id}
        title={customer.companyPhone}
      />,
      <ColumnTable
        key={customer.id}
        title={customer.manager.name}
      />,
    ]);
    return customerTableItems;
  };

  const setupTableHeaders = () => {
    return [
      <ColumnTable
        key={defaultValues.emptyInt}
        handleAction={_checkAllCustomers}
        isCheckbox={true}
        value={
          !checkedCustomers.isEmpty() &&
          checkedCustomers.length == customers.length
        }
      />,
      <ColumnTable
        key={defaultValues.emptyInt}
        title={titleStrings.number}
      />,
      <ColumnTable
        key={defaultValues.emptyInt}
        title={titleStrings.createdAt}
      />,
      <ColumnTable
        key={defaultValues.emptyInt}
        title={titleStrings.name}
      />,
      <ColumnTable
        key={defaultValues.emptyInt}
        title={titleStrings.phone}
      />,
      <ColumnTable
        key={defaultValues.emptyInt}
        title={titleStrings.companyPhone}
      />,
      <ColumnTable
        key={defaultValues.emptyInt}
        title={titleStrings.manager}
      />,
    ];
  };

  const onSelectCustomer = (key) => {
    setSelectedCustomer(customers.find(($0) => $0.id == key));
  };

  const onConditionChange = (value) => {
    selectedCustomer.condition = value;
    setSelectedCustomer(Object.assign(new Customer(), selectedCustomer));
  };

  const onMemoChange = (value) => {
    selectedCustomer.memo = value;
    setSelectedCustomer(Object.assign(new Customer(), selectedCustomer));
  };

  const onSaveCondition = () => {
    customerService.updateCustomerNotes(
      new RequestUpdateCustomerCondition(
        selectedCustomer.id,
        selectedCustomer.condition
      )
    );
  };

  const onSaveMemo = () => {
    customerService.updateCustomerNotes(
      new RequestUpdateCustomerMemo(selectedCustomer.id, selectedCustomer.memo)
    );
  };

  const onPageChange = (page) => {
    var request = new RequestFetchCustomerList();
    request.pagination.page = page;
    customerAction.onChangePage(page);
    _loadCustomerList(request);
  };

  const startDeleteProperty = () => {
    dispatch(confirmationModalActions.startLoading());
    customerService.deleteCustomer(
      new RequestDeleteCustomer(checkedCustomers.map(($0) => $0.id)),
      _didDeleteCustomerSucceed
    );
  };

  const _didDeleteCustomerSucceed = () => {
    dispatch(confirmationModalActions.stopLoading());
    commonActions.hideModal();
    _reloadCustomerList();
  };

  const showDeleteConfirmationModal = () => {
    commonActions.showModal(
      zIndex.defaultModal,
      <ConfirmationModal
        title={titleStrings.deleteCustomerTitle}
        primaryButtonTitle="예(YES)"
        secondaryButtonTitle="아니요(NO)"
        primaryAction={() => startDeleteProperty()}
      />
    );
  };

  const showChangeButtonModal = () => {
    const selectedEmployee = employees.find(
      ($0) => $0.id == selectedEmployeeItem.key
    );
    if (!selectedEmployee) {
      return;
    }
    commonActions.showModal(
      zIndex.defaultModal,
      <ChangeManagerModal
        selectedManager={selectedEmployee}
        selectedCustomers={checkedCustomers}
        onChangeManagerSuccess={_reloadCustomerList}
      />
    );
  };

  const showSearchCustomerModal = () => {
    commonActions.showModal(zIndex.defaultModal, <SearchCustomerModal />);
  };

  const showCreateCustomerModal = () => {
    commonActions.showModal(
      zIndex.defaultModal,
      <CreateCustomerModal onCreateCustomerSuccess={_reloadCustomerList} />
    );
  };

  useEffect(() => _loadEmployeeList(), defaultValues.emptyArray);

  useEffect(() => _reloadCustomerList(), [selectedEmployeeItem]);

  return {
    setupTableHeaders,
    setupTableRows,
    setSelectedEmployeeItem,
    onSelectCustomer,
    onConditionChange,
    onMemoChange,
    onSaveCondition,
    onSaveMemo,
    showDeleteConfirmationModal,
    showChangeButtonModal,
    showSearchCustomerModal,
    showCreateCustomerModal,
    onPageChange,
    employees,
    selectedEmployeeItem,
    selectedCustomer,
    customersCount,
    pagination,
  };
};

export default useCustomerPage;
