import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CardPageContentTopMenu from 'layouts/PropertyCardLayouts/CardPageContentTopMenu/CardPageContentTopMenu';
import CardPageContentInner from 'layouts/PropertyCardLayouts/CardPageContentInner/CardPageContentInner';
import * as styled from './CardPageContent.styled';
import { RequestCreateCard } from 'models/requests/RequestCreateCard';

class CardPageContent extends Component {
  static get propTypes() {
    return {
      requestEditCard: PropTypes.object,
      selectedImage: PropTypes.object,
      onMoveToReadState: PropTypes.func,
      onEditRequestChange: PropTypes.func,
      onChangePropertyImage: PropTypes.func,
      onStartReloadData: PropTypes.func,
      onStartSavingProperty: PropTypes.func,
      onShowSavingConfirmation: PropTypes.func,
    };
  }

  static defaultProps = {
    requestEditCard: new RequestCreateCard(),
    selectedImage: null,
    onMoveToReadState: () => {
      return;
    },
    onEditRequestChange: () => {
      return;
    },
    onChangePropertyImage: () => {
      return;
    },
    onStartReloadData: () => {
      return;
    },
    onStartSavingProperty: () => {
      return;
    },
    onShowSavingConfirmation: () => {
      return;
    },
  };

  render() {
    return (
      <styled.Container>
        <CardPageContentTopMenu
          requestEditCard={this.props.requestEditCard}
          selectedImage={this.props.selectedImage}
          onMoveToReadState={this.props.onMoveToReadState}
          onEditRequestChange={this.props.onEditRequestChange}
          onStartReloadData={this.props.onStartReloadData}
          onStartSavingProperty={this.props.onStartSavingProperty}
          onShowSavingConfirmation={this.props.onShowSavingConfirmation}
        />
        <CardPageContentInner
          onChangePropertyImage={this.props.onChangePropertyImage}
          onEditRequestChange={this.props.onEditRequestChange}
        />
      </styled.Container>
    );
  }
}

export default CardPageContent;
