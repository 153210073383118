import styled from 'styled-components';
import tw from 'twin.macro';


const Wrapper = styled.div`
    ${tw`
        w-full rounded-md
        z-[3]
        
    `}
`;


const Container = styled.div`
    ${tw`
        w-full rounded-md shadow-md
        p-2 md:p-4
        border-solid border-t-[1px] border-[#DDDDDD]
        bg-white
        max-h-[200px]
    `}
`;

const InputSearchContainer = styled.div`
    ${tw`
        w-full max-w-[9/12] md:min-w-[300px]
        mr-1
        rounded-md
        border-solid border-[1px] border-[#DDDDDD]
        flex place-content-center justify-between
    `}
`;

const InputSearchBar = styled.input`
    ${tw`
        w-[87%]
        p-2
        justify-items-stretch
        focus:outline-none 
        rounded-l-md
    `}
`;

const InputSearch = styled.button`
    ${tw`
        w-[17%] md:w-[10%] 
        hover:bg-[#F9F9F9]
        flex justify-center
        rounded-r-md
    `}
    color: #555555;
`;

const ButtonExternal = styled.a`
    ${tw`
        bg-[#F9F9F9] hover:bg-[#EEEEEE]
        rounded-md
        border-solid border-[1px] border-[#DDDDDD]
        ml-1 p-2 first:ml-0
        outline-none 
        whitespace-nowrap
        flex items-center
    `};
`;

const ButtonFilter = styled.button`
    ${tw`
        bg-white hover:bg-[#F9F9F9]
        rounded-3xl w-20
        border-solid border-[1px] border-[#707070]
        mx-1
        outline-none
    `};
`;

const ButtonIcon = styled.button`
    ${tw`
        w-10 p-2 hover:bg-[#F9F9F9]
        rounded-md ml-1 md:ml-2
        border-solid border-[1px] border-[#DDDDDD]
        outline-none
    `};
`;

export {
    Wrapper,
    Container,
    InputSearchContainer,
    InputSearchBar,
    InputSearch,
    ButtonExternal,
    ButtonFilter,
    ButtonIcon
}