import { Customer } from "models/objects/Customer";
import { Pagination } from "models/objects/Pagination";

export class ResponseFetchCustomerList {
    constructor(
        customers = [],
        pagination = new Pagination()
    ) {
        this.customers = customers;
        this.pagination = pagination;
    }

    mapFromJson(json) {
        if (!json) { return this; }
        this.customers = json.data.map(
            (customerJson) => new Customer().mapFromJson(customerJson)
        )
        this.pagination = new Pagination(
            json['currentPage'],
            json['totalItems'],
            json['totalPages']
        );
        return this;
    }
}