import styled from "@emotion/styled";
import colors from "constants/colors";
import tw from "twin.macro";

export const Container = styled.div`
    ${ tw `flex flex-col h-full border-l border-r` }
`;

export const Header = styled.div`
    height: 50px;
    background-color: ${ colors.gray.default };
    ${ tw `flex justify-between py-3 text-left px-6` }
`;

export const Title = styled.div`
    ${ tw `my-auto` }
`;

export const TextArea = styled.textarea`
    resize: none;
    ${ tw `w-full text-left p-3 h-full` }
`;

export const SaveButton = styled.button`
    ${ tw `underline` }
    ${ props => props.disabled && tw`cursor-default opacity-25` }
`;