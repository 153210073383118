import { store } from "configs/store";
import { Pagination } from "models/objects/Pagination";
import { SearchPropertyResult } from "models/objects/SearchPropertyResult";
import { RequestSearchProperty } from "models/requests/RequestSearchProperty";
import searchActionTypes from "redux/types/searchActionTypes";
import * as cardActions from 'redux/reducers/cardReducer';
import * as commonActions from 'redux/reducers/commonReducer';
import * as defaultValues from "constants/defaultValue";
import searchActions from "redux/actions/searchActions";

const SELECT_PROPERTY = "search/SELECT_PROPERTY";
const UPDATE_PROPERTIES = "search/UPDATE_PROPERTIES";
const UPDATE_PAGINATION = "search/UPDATE_PAGINATION";
const ON_CHANGE_PAGE = "search/ON_CHANGE_PAGE";

const states = (state, action) => {
    return {
        [searchActionTypes.UPDATE_SEARCH_REQUEST]: () => {
            return {
                ...state,
                requestSearchProperty: action.payload
            }
        },
        [SELECT_PROPERTY]: () => {
            return {
                ...state,
                selectedIndex: action.payload.selectedIndex,
                property: action.payload.property
            }
        },
        [searchActionTypes.UPDATE_SEARCH_RESULT]: () => {
            return {
                ...state,
                searchResults: action.payload.properties,
                pagination: action.payload.pagination
            }
        },
        [UPDATE_PROPERTIES]: () => {
            return {
                ...state,
                searchResults: action.payload
            }
        },
        [UPDATE_PAGINATION]: () => {
            return {
                ...state,
                pagination: action.payload
            }
        },
        [ON_CHANGE_PAGE]:  () =>  {
            return {
                ...state,
                currentPage: action.payload
            }
        }
    }
}

export default (state = {}, action) => {
    if (!(action.type in states(state, action))) {
        return {
            ...state,
            requestSearchProperty: state.requestSearchProperty || new RequestSearchProperty(),
            property: state.property || new SearchPropertyResult(),
            searchResults: state.searchResults || defaultValues.emptyArray,
            pagination: state.pagination || new Pagination(),
            currentPage: state.currentPage || defaultValues.emptyInt,
            selectedIndex: state.selectedIndex || defaultValues.emptyInt
        };
    }
    return states(state, action)[action.type]();
}

export const updateSearchRequest = (request) => {
    if (!request.isRoadCorner
            && !request.isRoadSide) {
        request.isRoadSide = undefined;
        request.isRoadCorner = undefined;
    }
    if (request.isRoadCorner
        && request.isRoadSide == undefined) {
        request.isRoadSide = false;
    }
    if (request.isRoadCorner == undefined
        && request.isRoadSide) {
        request.isRoadCorner = false;
    }
    store.dispatch(
        {
            type: searchActionTypes.UPDATE_SEARCH_REQUEST,
            payload: request
        }
    )
}

export const selectProperty = (key) => {
    const searchResults = store.getState().search.searchResults;
    const index = searchResults.findIndex($0 => $0.id == key);
    return {
        type: SELECT_PROPERTY,
        payload: {
            selectedIndex: index + 1,
            property: searchResults[index]
        }
    }
}

export const updateProperties = (properties) => {
    store.dispatch(
        {
            type: UPDATE_PROPERTIES,
            payload: properties
        }
    )
}

export const updatePagination = (pagination) => {
    store.dispatch(
        {
            type: UPDATE_PAGINATION,
            payload: pagination
        }
    )
}

export const redirectToDetail = (propertyId) => {
    store.dispatch(selectProperty(propertyId));
    const selectedIndex = store.getState().search.selectedIndex;
    cardActions.updateCurrentPage(selectedIndex)
    cardActions.setupInitialPage(selectedIndex)
    window.open(
        '/search/detail?page=' + selectedIndex,
        '_blank',
        'noopener,noreferrer'
    );
}

export const updateSearchResult = (properties, pagination) => {
    store.dispatch({
        type: searchActionTypes.UPDATE_SEARCH_RESULT,
        payload: {
            properties: properties,
            pagination: pagination
        }
    })
}

export const flushData = () => {
    updateSearchResult(
        defaultValues.emptyArray,
        new Pagination()
    );
}

export const reloadData = (request) => {
    flushData();
    fetchProperties(request);
}

export const fetchProperties = (request) => {
    searchActions.fetchSearchProperty(
        request,
        (response) => updateSearchResult(
            response.properties,
            response.pagination
        )
    )
}

export const onChangePage = (page) => {
    store.dispatch(
        {
            type: ON_CHANGE_PAGE,
            payload: page
        }
    )
}