export default {
    name: "name",
    address: "address",
    streetAddress: "streetAddress",
    nearestStation: "nearestStation",
    distanceStation: "distanceStation",
    priceInformation: "priceInformation",
    paid: "paid",
    price: "price",
    adminPrice: "adminPrice",
    adminRevenueRate: "adminRevenueRate",
    loanPrice: "loanPrice",
    interest: "interest",
    landInformation: "landInformation",
    category: "category",
    roadWidth: "roadWidth",
    coverRatio: "coverRatio",
    ownerInformation: "ownerInformation",
    mobile: "mobile",
    phone: "phone",
    note: "note",
    buildingInformation: "buildingInformation",
    usage: "usage",
    builtAt: "builtAt",
    builtWith: "builtWith",
    floor: "floor",
    elevator: "elevator",
    buildingValue: "buildingValue",
    landPrice: "landPrice",
    isIllegalBuilding: "isIllegalBuilding",
    isGroupBuilding: "isGroupBuilding",
    landSpesificInformations: "landSpesificInformations",
    number: "number",
    area: "area",
    district: "district",
    restriction: "restriction",
    officialPrice: "officialPrice",
    workHistories: "workHistories",
    isCorner: "isCorner",
    isSide: "isSide"
}