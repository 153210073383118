export class RequestLogin {
    constructor(
        username,
        password,
        isAdmin
    ){
        this.username = username;
        this.password = password;
        this.isAdmin = isAdmin;
    }

    mapToJson() {
        return {
            'username': this.username,
            'password': this.password,
            'is_admin': this.isAdmin
        }
    }
}