import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as styled from './FloorInfoForm.styled';
import * as defaultValues from 'constants/defaultValue';
import CardSection from 'components/CardSection/CardSection';
import { titleStrings } from 'localizations';
import InsertRowIcon from 'assets/insert_row_icon.png';
import RemoveRowIcon from 'assets/remove_row_icon.png';
import TextInput from 'components/core/text-input/TextInput';
import { TableViewModel } from 'components/core/table-list/TableViewModel';
import { observer } from 'mobx-react-lite';
import Text from 'components/core/text/Text';
import textInputType from 'enumerations/textInputType';
import HighlightedTextStyle from 'components/core/text/styles/HighlightedTextStyle';
import { FloorInformation } from 'models/objects/FloorInformation';
import fieldNames from 'constants/fieldNames';
import alignments from 'constants/alignments';
import { useSelector } from 'react-redux';
import InactiveTextInputStyle from 'components/core/text-input/styles/InactiveTextInputStyle';
import PlainTextInputStyle from 'components/core/text-input/styles/PlainTextInputStyle';
import MergeTableView from 'components/core/merge-table/MergeTableView';
import MergeTableViewHook from 'components/core/merge-table/MergeTableViewHook';
import CheckboxInput from 'components/core/input-checkbox/CheckboxInput';
import useFloorInfoFormPresenter from './useFloorInfoFormPresenter';
import { RequestCreateCard } from 'models/requests/RequestCreateCard';
import floorInformationColumns from 'enumerations/floorInformationColumns';
import formStates from 'enumerations/formStates';
import InactiveTextStyle from 'components/core/text/styles/InactiveTextStyle';
import PlainTextStyle from 'components/core/text/styles/PlainTextStyle';

const FloorInfoListForm = observer((props) => {
  const [tableViewModel] = useState(() => new TableViewModel());
  const requestWriteCard = useSelector((state) => state.card.requestWriteCard);
  const cardReducer = useSelector((state) => state.card);
  const mergeTableViewHook = MergeTableViewHook();
  const hook = useFloorInfoFormPresenter();

  const onTextInputChange = (target, index) => {
    let floorInformations = hook.floorInformations;
    let _index = index;
    const mergeKey = floorInformations[index].getMergeKeyByName(target.name);
    while (floorInformations[_index][mergeKey]) {
      floorInformations[_index][target.name] = target.value;
      _index++;
    }
    floorInformations[_index][target.name] = target.value;
    props.handleInputValueChange({
      name: 'floorInformations',
      value: floorInformations,
    });
  };

  const createFloorInformation = () => {
    if (mergeTableViewHook.selectedCells.isEmpty()) {
      hook.floorInformations.insert(
        hook.floorInformations.length,
        new FloorInformation()
      );
      return;
    }
    const selectedCells = mergeTableViewHook.selectedCells.sort(
      (item01, item02) => item01.row - item02.row
    );
    const latestCell = selectedCells.last();
    if (!latestCell) {
      return;
    }
    const span = latestCell.rowSpan.isEmpty()
      ? defaultValues.emptyInt
      : latestCell.rowSpan;
    hook.floorInformations.insert(
      Math.max(...mergeTableViewHook.selectedCells.map((x) => x.row)) + span,
      new FloorInformation()
    );
  };

  const createNewTableRow = () => {
    createFloorInformation();
    props.handleInputValueChange({
      name: 'floorInformations',
      value: hook.floorInformations,
    });
  };

  const removeFloorInformation = () => {
    if (mergeTableViewHook.selectedCells.isEmpty()) {
      hook.floorInformations.splice(hook.floorInformations.length - 1, 1);
      return;
    }
    const selectedCells = mergeTableViewHook.selectedCells.sort(
      (item01, item02) => item01.row + item02.row
    );
    for (const selectedCell of selectedCells) {
      hook.floorInformations.splice(selectedCell.row, selectedCell.rowSpan);
    }
  };

  const removeFloorInfoItem = () => {
    removeFloorInformation();
    mergeTableViewHook.onRemoveCell();
    props.handleInputValueChange({
      name: 'floorInformations',
      value: hook.floorInformations,
    });
  };

  const didMergeSucceed = (selectedCells) => {
    for (const cell of selectedCells) {
      const floorInformation =
        hook.floorInformations[selectedCells.first().row];
      const columnName = floorInformationColumns.columnFormName(cell.column);
      const latestRow = selectedCells.last().row;
      for (let indexSpan = 0; indexSpan < cell.span; indexSpan++) {
        const index = cell.row + indexSpan;
        hook.floorInformations[index][columnName] =
          floorInformation[columnName];
        if (index < latestRow) {
          hook.floorInformations[index].setAsMerged(cell.column);
        }
      }
    }
    hook.setRequestWriteCard(requestWriteCard);
  };

  const renderRightNavigationMenu = () => {
    if (cardReducer.formState == formStates.read) {
      return null;
    }
    return (
      <styled.RightNavigationMenu>
        <button
          className="flex space-x-3"
          onClick={createNewTableRow}>
          <img
            src={InsertRowIcon}
            width={32}
            alt=""
          />
          <p className="text-primary my-auto">셀삽입</p>
        </button>
        <button
          className="flex space-x-3"
          onClick={removeFloorInfoItem}>
          <img
            src={RemoveRowIcon}
            width={32}
            alt=""
          />
          <p className="text-primary my-auto">셀삭제</p>
        </button>
      </styled.RightNavigationMenu>
    );
  };

  const renderHeaders = () => {
    return hook.tableHeaders.map((tableHeader, index) => {
      return <p key={index}>{tableHeader}</p>;
    });
  };

  const renderCheckboxColumn = (floorInformation, index) => {
    return (
      <CheckboxInput
        key={0}
        checked={floorInformation.isExcluded}
        onChange={() => hook.excludeFloorInfo(index)}
      />
    );
  };

  const renderTextInputColumn = (
    key = defaultValues.emptyInt,
    index = defaultValues.emptyInt,
    value = defaultValues.emptyString,
    name = defaultValues.emptyString,
    type = textInputType.text,
    isExcluded = false
  ) => {
    if (cardReducer.formState == formStates.read) {
      return renderTextColumn(key, value, isExcluded, type);
    }
    return (
      <TextInput
        key={key}
        alignment={alignments.center}
        name={name}
        type={type}
        onChange={(target) => onTextInputChange(target, index)}
        value={value}
        style={isExcluded ? InactiveTextInputStyle : PlainTextInputStyle}
      />
    );
  };

  const renderTextColumn = (key, value, isExcluded, type) => {
    return (
      <Text
        key={key}
        alignment={alignments.center}
        value={
          type == textInputType.currency
            ? value.toString().thousandFormat()
            : value
        }
        style={isExcluded ? InactiveTextStyle : PlainTextStyle}
      />
    );
  };

  const renderTableRow = (floorInformation, index) => {
    let tableRow = [
      {
        isMerged: floorInformation.floorMergedDown,
        content: renderTextInputColumn(
          floorInformation.id,
          index,
          floorInformation.floor,
          fieldNames.floor,
          textInputType.text,
          floorInformation.isExcluded
        ),
      },
      {
        isMerged: floorInformation.areaMergedDown,
        content: renderTextInputColumn(
          floorInformation.id,
          index,
          floorInformation.area,
          fieldNames.area,
          textInputType.currency,
          floorInformation.isExcluded
        ),
      },
      {
        isMerged: floorInformation.areaMergedDown,
        content: renderTextColumn(
          floorInformation.id,
          hook.getAreaInPy(floorInformation),
          floorInformation.isExcluded
        ),
      },
      {
        isMerged: floorInformation.usageMergedDown,
        content: renderTextInputColumn(
          floorInformation.id,
          index,
          floorInformation.usage,
          fieldNames.usage,
          textInputType.text,
          floorInformation.isExcluded
        ),
      },
    ];
    if (cardReducer.formState != formStates.read) {
      tableRow.insert(defaultValues.emptyInt, {
        isMerged: false,
        content: renderCheckboxColumn(floorInformation, index),
      });
    }
    return tableRow;
  };

  const renderRows = () => {
    return hook.floorInformations.map((floorInformation, index) => {
      return renderTableRow(floorInformation, index);
    });
  };

  const renderTableFooters = () => {
    let footers = [
      <Text
        key={1}
        alignment={alignments.center}
        style={HighlightedTextStyle}
        value="합계"
      />,
      <Text
        key={2}
        alignment={alignments.center}
        style={HighlightedTextStyle}
        value={hook.getTotalArea().toString().thousandFormat() + '(㎡)'}
      />,
      <Text
        key={3}
        alignment={alignments.center}
        style={HighlightedTextStyle}
        value={`
                    ${hook
                      .getTotalAreaInPy(props.excludedFloorInformations)
                      .toString()
                      .thousandFormat()} 평
                `}
      />,
      <div key={4} />,
    ];
    if (cardReducer.formState != formStates.read) {
      footers.insert(defaultValues.emptyInt, <div key={0} />);
    }
    return footers;
  };

  const renderContent = () => {
    return (
      <styled.Container>
        <styled.UnitDescriptionText>
          {`[${titleStrings.unit}: ${titleStrings.squareMeter} / ${titleStrings.pyeong}]`}
        </styled.UnitDescriptionText>
        <p className="hidden">{tableViewModel.rowTableViewModels.length}</p>
        <MergeTableView
          isMergeable={cardReducer.formState != formStates.read}
          headers={renderHeaders()}
          rows={renderRows()}
          footerColumns={renderTableFooters()}
          hook={mergeTableViewHook}
        />
      </styled.Container>
    );
  };

  useEffect(() => {
    mergeTableViewHook.setUnselectableColumns([0, 3]);
  }, defaultValues.emptyArray);

  useEffect(() => {
    hook.setRequestWriteCard(
      Object.assign(new RequestCreateCard(), cardReducer.requestWriteCard)
    );
    mergeTableViewHook.setOnMergeSuccess(() => didMergeSucceed);
  }, [cardReducer]);

  useEffect(() => {
    const mergedRows = [];
    const filledFloorInformations = hook.floorInformations.filter(
      (x) => x.id != defaultValues.emptyInt
    );
    for (const [index, floorInformation] of filledFloorInformations.entries()) {
      const restFloorInformations = filledFloorInformations.slice(
        index,
        filledFloorInformations.length - index
      );
      if (floorInformation.floorMergedDown) {
        let spanLength = 0;
        for (const floorInformation of restFloorInformations) {
          spanLength = spanLength + 1;
          if (!floorInformation.floor) {
            break;
          }
        }
        mergedRows.push({
          row: index,
          column: 1,
          span: spanLength,
        });
      }
      if (floorInformation.areaMergedDown) {
        let spanLength = 0;
        for (const floorInformation of restFloorInformations) {
          spanLength = spanLength + 1;
          if (!floorInformation.areaMergedDown) {
            break;
          }
        }
        mergedRows.push({
          row: index,
          column: 2,
          span: spanLength,
        });
      }
      if (floorInformation.usageMergedDown) {
        let spanLength = 0;
        for (const floorInformation of restFloorInformations) {
          spanLength = spanLength + 1;
          if (!floorInformation.usageMergedDown) {
            break;
          }
        }
        mergedRows.push({
          row: index,
          column: 4,
          span: spanLength,
        });
      }
    }
  }, [requestWriteCard]);

  return (
    <CardSection
      title={titleStrings.floorInformation}
      content={renderContent()}
      rightNavigationMenu={renderRightNavigationMenu()}
    />
  );
});

FloorInfoListForm.propTypes = {
  onExcludeFloorInformations: PropTypes.func,
};

FloorInfoListForm.defaultProps = {
  onExcludeFloorInformations: () => {
    return;
  },
};

export default FloorInfoListForm;
