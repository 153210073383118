import React from 'react';
import * as commonActions from 'redux/reducers/commonReducer';
import Modal from 'components/core/modal/Modal';
import PropTypes from 'prop-types';
import CloseIconSrc from 'assets/close_icon_black.png';
import ImageAlt from 'constants/imageAlt';
import CreateEmployeeForm from 'layouts/forms/CreateEmployeeForm/CreateEmployeeForm';
import { Employee } from 'models/objects/Employee';
import titleStrings from 'localizations/titleStrings';
import * as styled from './CreateEmployeeModal.styled';

const CreateEmployeeModal = (props) => {
    const renderContent = () => {
        return (
            <styled.Container>
                <styled.CloseButton
                    onClick={ commonActions.hideModal }>
                    <img
                        src={ CloseIconSrc }
                        alt={ ImageAlt.closeIcon } />
                </styled.CloseButton>
                <p className="font-bold text-xl text-left">
                    { 
                        props.employee.name.isEmpty()
                            ? titleStrings.createEmployee
                            : titleStrings.editEmployee
                    }
                </p>
                <CreateEmployeeForm 
                    selectedEmployee={ props.employee }/>
            </styled.Container>
        )
    }

    return (
        <Modal
            content={ renderContent() }
            isShow={ true } />
    );
}

CreateEmployeeModal.propTypes = {
    employee: PropTypes.object
};

CreateEmployeeModal.defaultProps = {
    employee: new Employee()
};

export default CreateEmployeeModal;