import React, { useRef } from 'react'
import PropTypes from 'prop-types';
import TableList from 'components/core/table-list/TableList';
import {
    PrimaryTableListStyle,
    LeftBorderedTableListStyle
} from 'components/core/table-list/styles/index';
import ChevronDownIconGray from 'assets/chevron_down_icon_gray.png';
import defaultValues from 'constants/defaultValues';
import * as styled from './DropdownInput.styled';
import paddingPositions from 'enumerations/paddingPositions';
import DropdownInputPresenter from './DropdownInputPresenter';
import { useEffect } from 'react';

const DropdownInput = (props) => {
    const presenter = DropdownInputPresenter()
    const inputElement = useRef(null);

    const didSelectOption = (index) => {
        presenter.setIsInputEditable(false);
        presenter.setIsShowOptions(false);
        props.handleSelectOption(
            props.options[index]
        );
    }

    const renderInput = () => {
        return (
            <styled.Input
                { ...props }
                type="text"
                onBlur={ () => presenter.setIsInputEditable(false) }
                ref={ inputElement }
                onChange={
                    (input) => {
                        props.onCustomValueChange(
                            {
                                name: input.target.name,
                                value: input.target.value,
                            }
                        )
                    }
                }
                name={ props.name }
                placeholder={ "선택" }
                value={
                    presenter.isInputEditable
                        ? props.customValue
                        : props.selectedOption.value
                }/>
        )
    }

    const renderLabel = () => {
        return (
            <styled.Label
                { ...props }>
                { props.labelTitle }
            </styled.Label>
        )
    }

    const RightIcon = () => {
        if(!props.isEnabled) { return null; }
        return (
            <styled.RightIcon
                { ...props }
                src={ ChevronDownIconGray }
                alt="" />
        )
    }

    const renderButton = () => {
        if(!props.isEnabled) { return null; }
        return (
            <styled.Button 
                { ...props }
                onClick={ presenter.onClick }/>
        )
    }

    const renderInputContainer = () => {
        return (
            <styled.InputContainer
                { ...props }>
                { renderInput() }
                <RightIcon />
                <OptionsContainer />
            </styled.InputContainer>
        )
    }

    const OptionsContainer = () => {
        if (!presenter.isShowOptions) { return null }
        return (
            <styled.OptionsContainer
                { ...props }>
                <styled.TableWrapper
                    { ...props }>
                    <TableList
                        datas={ props.options.map(
                            (option) => option.value
                        ) }
                        isClickable={ true }
                        style={ LeftBorderedTableListStyle }
                        handleAction={ didSelectOption }/>
                </styled.TableWrapper>
            </styled.OptionsContainer>
        )
    }

    useEffect(
        () => {
            if (!presenter.isInputEditable) { return; }
            inputElement.current.focus();
        },
        [presenter.isInputEditable]
    )

    return (
        <styled.Wrapper
            { ...props }>
            { renderLabel() }
            { renderInputContainer() }
            { renderButton() }
        </styled.Wrapper>
    )
}

DropdownInput.propTypes = {
    isEnabled: PropTypes.bool,
    height: PropTypes.number,
    listHeight: PropTypes.number,
    width: PropTypes.number,
    name: PropTypes.string,
    minWidth: PropTypes.number,
    maxWidth: PropTypes.number,
    options: PropTypes.array,
    labelTitle: PropTypes.string,
    handleSelectOption: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onCustomValueChange: PropTypes.func,
    margin: PropTypes.object,
    style: PropTypes.object,
    selectedOption: PropTypes.object,
    customValue: PropTypes.string
};

DropdownInput.defaultProps = {
    selectedIndex: defaultValues.emptyInt,
    isEnabled: true,
    height: defaultValues.emptyInt,
    listHeight: 175,
    width: defaultValues.emptyInt,
    name: defaultValues.emptyString,
    minWidth: defaultValues.emptyInt,
    maxWidth: defaultValues.emptyInt,
    options: [],
    labelTitle: defaultValues.emptyString,
    margin: {
        position: paddingPositions.left,
        value: defaultValues.emptyInt
    },
    style: PrimaryTableListStyle,
    handleSelectOption: () => { return; },
    onDoubleClick: null,
    onCustomValueChange: () => { return; },
    selectedOption: {
        key: defaultValues.emptyString,
        value: defaultValues.emptyString
    },
    customValue: defaultValues.emptyString
};

export default DropdownInput;