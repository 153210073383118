import { useState, useEffect } from 'react';

function useGeoLocation() {
    const [location, setLocation] = useState(
        !('geolocation' in navigator)
            ? {
                  loaded: false,
                  error: null,
                  coordinates: {
                      lat: '',
                      lng: ''
                  }
              }
            : {
                  loaded: true,
                  error: null,
                  coordinates: {
                      lat: sessionStorage.getItem('lat'),
                      lng: sessionStorage.getItem('lng')
                  }
              }
    );

    const onSuccess = (location) => {
        setLocation((state) => ({
            ...state,
            loaded: true,
            coordinates: {
                lat: location.coords.latitude,
                lng: location.coords.longitude
            }
        }));
    };

    const onError = (error) => {
        setLocation((state) => ({
            ...state,
            loaded: true,
            error: {
                code: error.code,
                message: error.message
            },
            coordinates: {
                lat: 37.542054,
                lng: 127.04967
            }
        }));
    };

    useEffect(() => {
        if (!('geolocation' in navigator)) {
            onError({
                code: 0,
                message: 'Geolocation not supported',
                coordinates: {
                    lat: 37.542054,
                    lng: 127.04967
                }
            });
        }

        navigator.geolocation.getCurrentPosition(onSuccess, onError);
    }, []);

    return location;
}

export default useGeoLocation;
