import React from 'react';
import PropTypes from 'prop-types';
import * as styled from './PrintCardForm.styled';
import Button from 'components/core/button/Button';
import {
  DarkGrayButtonStyle,
  WhiteButtonStyle,
} from 'components/core/button/styles';
import buttonStrings from 'localizations/buttonStrings';
import sizes from 'constants/sizes';
import CheckboxInput from 'components/core/input-checkbox/CheckboxInput';
import Text from 'components/core/text/Text';
import { titleStrings } from 'localizations';
import PageSettingInput from 'components/PageSettingInput/PageSettingInput';
import alignments from 'constants/alignments';
import * as propertyPrintPages from 'enumerations/propertyPrintPages';
import MainPageForm from 'components/MainPageForm/MainPageForm';
import usePrintCardForm from './usePrintCardForm';
import ImagePageInput from 'components/ImagePageInput/ImagePageInput';
import { useSelector } from 'react-redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const PrintCardForm = (props) => {
  const hook = usePrintCardForm();
  const pages = useSelector((state) => state.printProperty.pages);
  const imagePages = useSelector((state) => state.printProperty.imagePages);

  const getDisplayTitle = (key) => {
    const titles = {
      [propertyPrintPages.summary]: '매물정보',
      [propertyPrintPages.rentalHistory]: '임대내역',
      [propertyPrintPages.floorInfo]: '건축물대장',
    };
    return titles[key];
  };

  const renderPageForm = (page) => {
    if (page.type == propertyPrintPages.main) {
      return <MainPageForm />;
    }
    if (page.type == propertyPrintPages.image) {
      const imagePage = imagePages.find(($0) => $0.id == page.key);
      if (!imagePage) {
        return null;
      }
      return (
        <ImagePageInput
          {...imagePage}
          didDocumentSaved={props.didDocumentSaved}
          key={imagePage.key}
        />
      );
    }
    return (
      <PageSettingInput
        key={page.key}
        title={getDisplayTitle(page.type)}
        isExcluded={page.isExcluded}
        onChangeSetting={(setting) =>
          hook.onUpdatePrintStatus(page.type, setting)
        }
      />
    );
  };

  const renderPageForms = () => {
    return pages.map((page, index) => {
      return (
        <Draggable
          key={page.key}
          draggableId={page.key}
          index={index}>
          {(provided) => (
            <styled.InputWrapper
              ref={provided.innerRef}
              {...provided.dragHandleProps}
              {...provided.draggableProps}>
              {renderPageForm(page)}
            </styled.InputWrapper>
          )}
        </Draggable>
      );
    });
  };

  return (
    <styled.Container>
      <styled.ActionsContainer>
        <styled.LeadingActionsContainer>
          <Button
            action={hook.onDeleteImagePage}
            title={buttonStrings.delete}
            style={WhiteButtonStyle}
          />
          <Button
            action={hook.onTapAddImageButton}
            isLoading={hook.isAddingImage}
            title={buttonStrings.add}
            height={sizes.button.extraSmall}
            style={DarkGrayButtonStyle}
          />
        </styled.LeadingActionsContainer>
        <Button
          action={hook.printPages}
          isLoading={hook.isGeneratingPdf}
          title={buttonStrings.print}
          height={sizes.button.extraSmall}
          style={DarkGrayButtonStyle}
        />
      </styled.ActionsContainer>
      <styled.HeaderContainer>
        <styled.SideHeaderWrapper>
          <CheckboxInput />
        </styled.SideHeaderWrapper>
        <styled.MiddleHeaderWrapper>
          <Text
            alignment={alignments.center}
            value={titleStrings.subject}
          />
        </styled.MiddleHeaderWrapper>
        <styled.SideHeaderWrapper>
          <Text
            alignment={alignments.center}
            value={titleStrings.print}
          />
        </styled.SideHeaderWrapper>
      </styled.HeaderContainer>
      <styled.PageSettingContainer>
        <DragDropContext
          onDragEnd={hook.changeInputOrder}
          onDragStart={hook.onDragStart}>
          <Droppable
            droppableId="list-container"
            isDropDisabled={hook.isDragDisabled}>
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}>
                {renderPageForms()}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </styled.PageSettingContainer>
    </styled.Container>
  );
};

PrintCardForm.propTypes = {
  didDocumentSaved: PropTypes.func,
};

PrintCardForm.defaultProps = {
  didDocumentSaved: () => {
    return;
  },
};

export default PrintCardForm;
