const enums = {
    checkbox: 0,
    floor: 1,
    areaInMeter: 2,
    areaInPy: 3,
    usage: 4,
}

const columnFormName = (column) => {
    const columnNames = {
        [enums.checkbox]: "checkbox",
        [enums.floor]: "floor",
        [enums.areaInMeter]: "area",
        [enums.usage]: "usage"
    }
    return columnNames[column];
}

export default {
    enums: enums,
    columnFormName: columnFormName
}