import React from 'react';
import * as styled from './MapPage.styled';

// import NaverMaps from 'layouts/MapLayout/NaverMap';
import NaverMaps from 'layouts/MapLayout/NaverMap_dev';

function MapPage () {

    
   

    return(
        <styled.Wrapper>
            <styled.Container>
                <NaverMaps/>
            </styled.Container>
        </styled.Wrapper>
    )
}

export default MapPage;