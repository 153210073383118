import defaultValues from "constants/defaultValues";
import { Card } from "./Card";

export class PropertyHistory {
    constructor(
        id = defaultValues.emptyInt,
        property = new Card()
    ) {
        this.id = id;
        this.property = property;
    }

    mapFromJson(json) {
        if (!json) { return this; }
        this.id = json['id'];
        this.property = new Card().mapFromJson(json['property']);
        return this;
    }
}