import styled from 'styled-components';
import tw from 'twin.macro';

const Container = styled.div`
  ${tw`flex flex-col h-full`}
`;

const HeaderContainer = styled.div`
  height: 50px;
  ${tw`flex justify-between w-full px-3 py-2 border-b-4 border-black`}
`;

const HeaderTitle = styled.p`
  ${tw`text-primary font-bold text-lg text-left my-auto`}
`;

const ContentWrapper = styled.div`
  ${tw`flex-1`}
`;

export { Container, HeaderContainer, HeaderTitle, ContentWrapper };
