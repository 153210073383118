import defaultValues from "constants/defaultValues";
import lengths from "constants/lengths";
import { RequestPagination } from "./RequestPagination";

export class RequestFetchCustomerList {
    constructor(
        managerId = defaultValues.emptyString,
        name = defaultValues.emptyString,
        phone = defaultValues.emptyString,
        companyPhone = defaultValues.emptyString,
        pagination = new RequestPagination()
    ) {
        this.managerId = managerId;
        this.name = name;
        this.phone = phone;
        this.companyPhone = companyPhone;
        this.pagination = pagination;
    }

    mapToJson() {
        let body = {}
        if (!this.managerId.isEmpty()) {
            body['manager_id'] = this.managerId
        }
        if (!this.name.isEmpty()) {
            body['name'] = this.name
        }
        if (!this.phone.isEmpty()) {
            body['phone'] = this.phone
        }
        if (!this.companyPhone.isEmpty()) {
            body['company_phone'] = this.companyPhone
        }
        body["page"] = this.pagination.page;
        body["page_size"] = this.pagination.pageSize;
        return body
    }
}