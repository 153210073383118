import colors from 'constants/colors';
import styled from 'styled-components';
import tw from 'twin.macro';

const Container = styled.div`
  ${tw`flex justify-between border-b border-secondary px-8 py-6`}
`;
const ContentLeadingContainer = styled.div`
  ${tw`flex my-auto`}
`;
const ContentTrailingContainer = styled.div`
  ${tw`flex gap-3 `}
`;
const NameText = styled.p`
  ${tw`font-bold text-lg my-auto`}
`;
const AdsStatusText = styled.p`
  ${tw`px-4 my-auto border-r border-secondary`}
`;
const ManagerNameText = styled.p`
  ${tw`px-4 my-auto border-r border-secondary`}
`;
const CardIdTitleText = styled.p`
  ${tw`flex gap-2 pl-3 my-auto`}
`;
const CardIdText = styled.p`
  ${tw`font-bold pl-1 pr-8 border-r border-secondary my-auto`}
`;
const CardViewCountText = styled.p`
  ${tw`pl-6 pr-6 my-auto text-sm border-r border-secondary`}
`;
const CardStatusContainer = styled.div`
  ${tw`flex px-8 gap-6 border-r border-secondary`}
`;
const CardStatusButton = styled.button`
  color: ${(props) => (props.isSelected ? props.selectedColor : colors.black)};
  ${(props) => props.isSelected && tw`font-extrabold`}
`;
const ManagerDropdownWrapper = styled.div`
  ${tw`px-4 border-r border-secondary`}
`;
const BuildingIdContainer = styled.div`
  ${tw`flex`}
`;

export {
  Container,
  ContentLeadingContainer,
  ContentTrailingContainer,
  ManagerDropdownWrapper,
  BuildingIdContainer,
  NameText,
  AdsStatusText,
  ManagerNameText,
  CardIdTitleText,
  CardIdText,
  CardViewCountText,
  CardStatusContainer,
  CardStatusButton,
};
