import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as styled from './PrintCardPreview.styled';
import * as defaultValues from 'constants/defaultValue';
import sizes from 'constants/sizes';
import MainPrintPage from 'components/Prints/MainPrintPage/MainPrintPage';
import SummaryPrintPage from 'components/Prints/SummaryPrintPage/SummaryPrintPage';
import RentalHistoryPrintPage from 'components/Prints/RentalHistoryPrintPage/RentalHistoryPrintPage';
import FloorInfoPrintPage from 'components/Prints/FloorInforPrintPage/FloorInfoPrintPage';
import * as printCardSlice from 'pages/PrintCardPage/printCardReducer';
import * as propertyPrintPages from 'enumerations/propertyPrintPages';
import { useSelector } from 'react-redux';
import PageCardPreviewProperty from './PageCardPreviewProperty';
import { v4 as uuidv4 } from 'uuid';
import ImagePrintPage from 'components/Prints/ImagePrintPage/ImagePrintPage';

const PrintCardPreview = () => {
  const property = useSelector((state) => state.printProperty.property);
  const pages = useSelector((state) => state.printProperty.pages);
  const { rentalHistoryPages, floorInfoPages } = useSelector(
    (state) => state.printProperty
  );
  const imagePages = useSelector((state) => state.printProperty.imagePages);
  const [paperSize, setPaperSize] = useState({
    height: defaultValues.emptyInt,
    width: defaultValues.emptyInt,
  });
  const containerRef = useRef(null);

  const updatePageSize = () => {
    if (containerRef.current === null) {
      return;
    }
    const aspectRatio = sizes.paperSize.width / sizes.paperSize.height;
    setPaperSize({
      height: containerRef.current.clientWidth * aspectRatio,
      width: containerRef.current.clientWidth,
    });
  };

  const renderPage = (pageProperty) => {
    if (pageProperty.type == propertyPrintPages.main) {
      return (
        <MainPrintPage
          id={pageProperty.key}
          key={pageProperty.key}
        />
      );
    }
    if (pageProperty.type == propertyPrintPages.summary) {
      return (
        <SummaryPrintPage
          id={pageProperty.key}
          key={pageProperty.key}
        />
      );
    }
    return (
      <ImagePrintPage
        imagePage={imagePages.find(($0) => $0.id == pageProperty.key)}
      />
    );
  };

  const _renderRentalHistoryPages = () => {
    return rentalHistoryPages.map((item, index) => (
      <styled.PageWrapper
        key={index}
        height={paperSize.height}>
        <RentalHistoryPrintPage
          id={item.key}
          rentalHistories={item.datas}
          key={item.key}
        />
      </styled.PageWrapper>
    ));
  };

  const _renderFloorInfoPages = (page) => {
    return floorInfoPages.map((item, index) => (
      <styled.PageWrapper
        key={index}
        height={paperSize.height}>
        <FloorInfoPrintPage
          id={item.key}
          floorInformations={item.datas}
          key={item.key}
        />
      </styled.PageWrapper>
    ));
  };

  const renderPages = () => {
    return pages.map((page, index) => {
      if (page.type == propertyPrintPages.rentalHistory) {
        return _renderRentalHistoryPages(page);
      }
      if (page.type == propertyPrintPages.floorInfo) {
        return _renderFloorInfoPages(page);
      }
      return (
        <styled.PageWrapper
          key={index}
          height={paperSize.height}>
          {renderPage(page)}
        </styled.PageWrapper>
      );
    });
  };

  useEffect(() => {
    updatePageSize();
    window.addEventListener('resize', updatePageSize);
  }, defaultValues.emptyArray);

  return (
    <styled.Container>
      <styled.PagesContainer ref={containerRef}>
        {renderPages()}
      </styled.PagesContainer>
    </styled.Container>
  );
};

export default PrintCardPreview;
