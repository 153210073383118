import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import PrintPageHeader from 'components/PrintPageHeader/PrintPageHeader';
import * as styled from './RentalHistoryPrintPage.styled';
import MergeTableView from 'components/core/merge-table/MergeTableView';
import alignments from 'constants/alignments';
import HighlightedTextStyle from 'components/core/text/styles/HighlightedTextStyle';
import Text from 'components/core/text/Text';
import { useSelector } from 'react-redux';
import InactiveTextStyle from 'components/core/text/styles/InactiveTextStyle';
import PlainTextStyle from 'components/core/text/styles/PlainTextStyle';
import * as defaultValues from 'constants/defaultValue';
import MergeTableViewHook from 'components/core/merge-table/MergeTableViewHook';
import { titleStrings, tableHeaderStrings } from 'localizations';

const RentalHistoryPrintPage = (props) => {
  const wrapperRef = useRef(null);
  const containerRef = useRef(null);
  const headerTitles = [
    {
      title: '층',
      classNames: 'w-[5%]',
    },
    {
      title: '임대평수',
      classNames: 'w-[10%]',
    },
    {
      title: '업종',
      classNames: 'w-[20%]',
    },
    {
      title: '보증금',
      classNames: 'w-[10%]',
    },
    {
      title: '월세',
      classNames: 'w-[6%]',
    },
    {
      title: '관리비',
      classNames: 'w-[6%]',
    },
    {
      title: '계약일자',
      classNames: 'w-[16%]',
    },
    {
      title: '비고',
      classNames: 'w-[16%]',
    },
    {
      title: '평당임대료',
      classNames: 'w-[7%]',
    },
  ];
  const { property, rentalHistoryPages } = useSelector(
    (state) => state.printProperty
  );

  const _renderTextColumn = (key, value, isExcluded = false) => {
    return (
      <Text
        key={key}
        alignment={alignments.center}
        fontSize={14}
        value={value.toString()}
        style={isExcluded ? InactiveTextStyle : PlainTextStyle}
      />
    );
  };

  const _renderTextInput = (
    key = defaultValues.emptyInt,
    value = defaultValues.emptyString,
    isExcluded = false
  ) => {
    return _renderTextColumn(key, value, isExcluded);
  };

  const _renderTableRow = (rentalHistory, index) => {
    let tableRow = [
      {
        isMerged:
          index == props.rentalHistories.length - 1
            ? false
            : rentalHistory.isFloorMergedDown,
        content: _renderTextInput(
          rentalHistory.id,
          rentalHistory.floor,
          rentalHistory.isExcluded
        ),
      },
      {
        isMerged:
          index == props.rentalHistories.length - 1
            ? false
            : rentalHistory.isAreaMergedDown,
        content: _renderTextInput(
          rentalHistory.id,
          rentalHistory.areaInPY.thousandFormat(2),
          rentalHistory.isExcluded
        ),
      },
      {
        isMerged:
          index == props.rentalHistories.length - 1
            ? false
            : rentalHistory.isUsageMergedDown,
        content: _renderTextInput(
          rentalHistory.id,
          rentalHistory.usage,
          rentalHistory.isExcluded
        ),
      },
      {
        isMerged:
          index == props.rentalHistories.length - 1
            ? false
            : rentalHistory.isDepositMergedDown,
        content: _renderTextInput(
          rentalHistory.id,
          rentalHistory.deposit.thousandFormat(),
          rentalHistory.isExcluded
        ),
      },
      {
        isMerged:
          index == props.rentalHistories.length - 1
            ? false
            : rentalHistory.isMonthlyRentalMergedDown,
        content: _renderTextInput(
          rentalHistory.id,
          rentalHistory.monthlyFee.thousandFormat(),
          rentalHistory.isExcluded
        ),
      },
      {
        isMerged:
          index == props.rentalHistories.length - 1
            ? false
            : rentalHistory.isAdminPriceMergedDown,
        content: _renderTextInput(
          rentalHistory.id,
          rentalHistory.adminPrice.thousandFormat(),
          rentalHistory.isExcluded
        ),
      },
      {
        isMerged:
          index == props.rentalHistories.length - 1
            ? false
            : rentalHistory.isRentalDateMergedDown,
        content: _renderTextInput(
          rentalHistory.id,
          rentalHistory.rentalDate,
          rentalHistory.isExcluded
        ),
      },
      {
        isMerged:
          index == props.rentalHistories.length - 1
            ? false
            : rentalHistory.isNoteMergedDown,
        content: _renderTextInput(
          rentalHistory.id,
          rentalHistory.note,
          rentalHistory.isExcluded
        ),
      },
      {
        isMerged:
          index == props.rentalHistories.length - 1
            ? false
            : rentalHistory.isAreaMergedDown,
        content: _renderTextColumn(
          rentalHistory.id,
          rentalHistory.getRentPerPy().thousandFormat(),
          rentalHistory.isExcluded
        ),
      },
    ];
    return tableRow;
  };

  const renderRows = () => {
    return props.rentalHistories.map((rentalHistory, index) => {
      return _renderTableRow(rentalHistory, index);
    });
  };

  const renderFooter = () => {
    const latestPage = rentalHistoryPages.last();
    if (latestPage && props.id != latestPage.key) {
      return [];
    }
    let tableFooters = [
      <Text
        key={1}
        alignment={alignments.center}
        fontSize={14}
        style={HighlightedTextStyle}
        value="합계"
      />,
      <Text
        key={2}
        alignment={alignments.center}
        fontSize={14}
        value={property.getRentalTotalArea().toString().thousandFormat()}
        style={HighlightedTextStyle}
      />,
      <div key={3} />,
      <Text
        key={4}
        alignment={alignments.center}
        fontSize={14}
        value={property.getRentalTotalDeposit().toString().thousandFormat()}
        style={HighlightedTextStyle}
      />,
      <Text
        key={5}
        alignment={alignments.center}
        fontSize={14}
        value={property.getMonthlyRent().toString().thousandFormat()}
        style={HighlightedTextStyle}
      />,
      <Text
        key={6}
        alignment={alignments.center}
        fontSize={14}
        value={property.getRentalTotalAdminPrice().toString().thousandFormat()}
        style={HighlightedTextStyle}
      />,
      <div key={7} />,
      <div key={8} />,
      <Text
        key={9}
        alignment={alignments.center}
        fontSize={14}
        value={property.getRentalTotalRentPerPy().toString().thousandFormat()}
        style={HighlightedTextStyle}
      />,
    ];
    return tableFooters;
  };

  return (
    <styled.Wrapper ref={wrapperRef}>
      <styled.Container
        ref={containerRef}
        scale={
          wrapperRef.current?.clientWidth / containerRef.current?.clientWidth
        }
        data-id={props.id}>
        <PrintPageHeader
          title="임대내역"
          isShowLogo={true}
        />
        <styled.BodyContainer>
          <Text
            alignment={alignments.right}
            fontSize={14}
            margin={{ position: 'bottom', value: 8 }}
            value={'[단위: 평 / 만원] [VAT별도]'}
          />
          <MergeTableView
            hook={MergeTableViewHook()}
            headers={headerTitles}
            rows={renderRows()}
            columnClasses="h-10 text-xs"
            footerClasses="h-10 text-xs"
            footerColumns={renderFooter()}
            headerClassses="h-10"
          />
        </styled.BodyContainer>
      </styled.Container>
    </styled.Wrapper>
  );
};

RentalHistoryPrintPage.propTypes = {
  id: PropTypes.string,
  rentalHistories: PropTypes.array,
};

RentalHistoryPrintPage.defaultProps = {
  id: defaultValues.emptyString,
  rentalHistories: defaultValues.emptyArray,
};

export default RentalHistoryPrintPage;
