import React from 'react';
import TextInputModal from 'components/TextInputModal/TextInputModal';
import zIndex from 'constants/zIndex';
import { titleStrings } from 'localizations';
import { useDispatch } from 'react-redux';
import { commonActionTypes } from 'redux/types';
import { TextInputModalViewModel } from 'components/TextInputModal/TextInputModalViewModel';
import buttonStrings from 'localizations/buttonStrings';
import { useSelector } from 'react-redux';
import textInputType from 'enumerations/textInputType';
import * as cardActions from 'redux/reducers/cardReducer';
import * as commonActions from 'redux/reducers/commonReducer';
import * as defaultValues from 'constants/defaultValue';

const PriceInformationsHook = () => {
  const textInputModalViewModel = new TextInputModalViewModel(
    titleStrings.enterSalesAmount
  );
  const card = useSelector((state) => state.card.card);
  const cardReducer = useSelector((state) => state.card);
  const dispatch = useDispatch();

  const showEditPriceModal = () => {
    textInputModalViewModel.suffix = titleStrings.tenThousandWon;
    textInputModalViewModel.buttonViewModel.title = buttonStrings.registration;
    textInputModalViewModel.buttonViewModel.action = onStartUpdatePrice;
    textInputModalViewModel.textInputViewModel.type = textInputType.currency;
    dispatch({
      type: commonActionTypes.SHOW_MODAL,
      payload: {
        zIndex: zIndex.defaultModal,
        content: (
          <TextInputModal
            viewModel={textInputModalViewModel}
            onTapEnter={onStartUpdatePrice}
          />
        ),
      },
    });
  };

  const onStartUpdatePrice = () => {
    cardActions.updatePrice(textInputModalViewModel.textInputViewModel.value);
    commonActions.hideModal();
  };

  const getYearlyRevenue = () => {
    const isMonthlyInterest = card.priceInformation.isMonthlyInterest;
    const loanPrice = isMonthlyInterest
      ? card.priceInformation.loanPrice
      : defaultValues.emptyFloat;
    const rentalPrice = cardReducer.price;
    const yearlyIncome = card.priceInformation.monthlyIncome * 12;
    const income = rentalPrice - card.priceInformation.deposit - loanPrice;
    if (income.isEmpty()) {
      return defaultValues.emptyInt;
    }
    return ((yearlyIncome / income) * 100)
      .rounded(2)
      .toString()
      .thousandFormat();
  };

  const getPricePerArea = () => {
    if (card.landInformation.area.isEmpty()) {
      return defaultValues.emptyInt;
    }
    return (cardReducer.price / card.landInformation.areaPy)
      .rounded(0)
      .tenThousandFormat();
  };

  const getPricePerTotalArea = () => {
    if (card.getLandArea().isEmpty()) {
      return defaultValues.emptyInt;
    }
    return (cardReducer.price / card.buildingInformation.totalAreaInPY)
      .rounded(0)
      .tenThousandFormat();
  };

  return {
    showEditPriceModal,
    getPricePerArea,
    getYearlyRevenue,
    getPricePerTotalArea,
  };
};

export default PriceInformationsHook;
