import styled from '@emotion/styled';
import tw from 'twin.macro';
import Colors from 'constants/colors';

export const Container = styled.div`
  ${tw`h-full`}
  ${(props) => props.disabled && tw`bg-secondary`}
  ${(props) => props.disabled && `color: ${Colors.gray.dark}`}
`;

export const Button = styled.button`
  ${tw`h-full w-full rounded border border-gray-300`}
`;

export const FileNameLabel = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${tw`px-3`}
`;
