import defaultValues from "constants/defaultValues";
import { Employee } from "models/objects/Employee";
import Cookies from 'js-cookie';

const keys = {
    accessToken: "accessToken",
    currentUser: "currentUser",
    isPartner: "isPartner",
}

export default {
    setAccessToken: (token) => {
      
      Cookies.set(
        keys.accessToken,
        token
      )
    },
    getAccessToken: () => {
        const accessToken = Cookies.get(keys.accessToken)
        return accessToken ? accessToken : defaultValues.emptyString
    },
    setCurrentUser: (user) => {
      Cookies.set(keys.currentUser, JSON.stringify(user))
    },
    getCurrentUser: () => {
        return new Employee().mapFromJson(
            JSON.parse(
              Cookies.get(keys.currentUser) ?? null
            )
        )
    },
    setIsPartner: (isPartner) => {
      Cookies.set(keys.isPartner, isPartner)
    },
    getIsPartner: () => {
      const isPartner = Cookies.get(keys.isPartner) === 'true'
      return isPartner ? isPartner : false
    }
}