import { useState } from "react";
import * as defaultValues from "constants/defaultValue";

const PaginationViewPresenter = (
    page,
    onPageChange
) => {
    const [onPageWillChange, setOnPageWillChange] = useState(null);
    const [totalPages, setTotalPages] = useState(defaultValues.emptyInt);

    const _onInputPage = (page) => {
        _onPageChange(
            page == defaultValues.emptyString
                ? defaultValues.emptyString
                : page - 1
        );
    }

    const _onPageChange = (page) => {
        const _currentPage = page.toString() == defaultValues.emptyString
            ? defaultValues.emptyString
            : parseInt(page);
        if (onPageWillChange != null) {
            onPageWillChange(_currentPage);
            return;
        }
        changePage(_currentPage)
    }

    const changePage = (page) => {
        onPageChange(page);
    }

    const onMoveToFirstPage = () => {
        if (page == defaultValues.page + 1) { return; }
        _onPageChange(defaultValues.page)
    }

    const onMoveToPreviousPage = () => {
        if (page == defaultValues.page) { return; }
        _onPageChange(Number(page) - 1)
    }

    const onMoveToNextPage = () => {
        if (page >= totalPages - 1) { return; }
        _onPageChange(Number(page) + 1)
    }

    const onMoveToLatestPage = () => {
        if (page >= totalPages) { return; }
        _onPageChange(totalPages - 1)
    }

    return {
        _onInputPage,
        _onPageChange,
        onMoveToFirstPage,
        onMoveToPreviousPage,
        onMoveToNextPage,
        onMoveToLatestPage,
        setTotalPages,
        setOnPageWillChange,
        changePage
    }
}

export default PaginationViewPresenter;