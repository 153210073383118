import LocalizedStrings from 'react-localization';

export default new LocalizedStrings({
    en:{
        every: "Every",
        all: "All",
        reset: "Reset",
        search: "Search",
        checkDuplication: "Check Duplication",
        yes: "Yes",
        no: "No",
        saveNo: "NO",
        saveYes: "YES",
        confirm: "Confirm",
        input: "Input",
        edit: "Edit",
        createMember: "Create Member",
        delete: "Delete",
        save: "Save",
        changeManager: "Change Manager",
        registration: "Registration",
        newInput: "New Input",
        map: "Map",
        photo: "Photo",
        print: "Print",
        new: "New",
        forSale: "For Sale",
        pending: "Pending",
        add: "Add",
        preview: "Preview",
        compilation: "Edit"
    },
    kr: {
        every: "모두",
        all: "전체",
        reset:"초기화",
        search: "조합검색",
        checkDuplication: "중복확인",
        yes: "네",
        no: "아니요",
        saveNo: "아니요(NO)",
        saveYes: "네(YES)",
        confirm: "확인",
        input: "입력",
        edit: "수정",
        createMember: "회원등록",
        delete: "삭제",
        save: "저장",
        changeManager: "담당자 이전",
        registration: "등록",
        newInput: "신규입력",
        map: "지도",
        photo: "사진",
        print: "인쇄",
        new: "신규",
        forSale: "매물",
        pending: "보류",
        add: "추가",
        preview: "보기화면",
        compilation: "편집"
    }
});
