import defaultValues from "constants/defaultValues"

class DropdownInputViewModel {
    key = defaultValues.emptyInt
    value = defaultValues.emptyString
    
    constructor(key, value) {
        this.key = key;
        this.value = value;
    }
}

export default DropdownInputViewModel