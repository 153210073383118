import styled from "@emotion/styled";
import tw from "twin.macro";

export const Container = styled.div`
    ${ tw`space-y-3` }
`;

export const InputContainer = styled.div`
    height: 32px;
    ${ tw`grid grid-cols-5 gap-3` }
`;

export const CheckboxWrapper = styled.div`
    ${ tw`col-span-1` }
`;

export const TitleWrapper = styled.div`
    border-style: solid;
    border-width: 1px;
    ${ tw`flex col-span-2 border-gray-300 rounded` }
`;

export const CountSettingWrapper = styled.div`
    ${ tw`flex col-span-1` }
`;

export const ExclusionSettingWrapper = styled.div`
    ${ tw`col-span-1` }
`;

export const ImageSelectionContainer = styled.div`
    ${ tw`col-span-4 grid grid-cols-5 gap-3` }
`;

export const ImageSelectionTitle = styled.div`
    ${ tw`col-span-1` }
`;

export const SizeDropdownWrapper = styled.div`
    ${ tw`col-span-2` }
`;

export const ImageSelectionFileUpload = styled.div`
    ${
        props => props.imageCount == 1
            ? tw`col-span-2`
            : tw`col-span-4`
    }
`;
