import {
  ResponseInquiryDocumentSetting
} from 'models/responses/ResponseInquiryDocumentSetting';
import {
  ResponseAddImageDocument
} from 'models/responses';
import baseAction from 'redux/actions/baseAction';

const basePath = '/v1/document';

export default {
  saveDocumentSetting: (request, onSuccess) => {
    return baseAction().patch(
      `${basePath}/save`,
      request.mapToJson(),
      (response) => {
        onSuccess(
          new ResponseInquiryDocumentSetting().mapFromJson(
            response.data.document
          )
        );
      }
    );
  },
  inquiryDocumentSetting: (request, onSuccess) => {
    return baseAction().get(
      `${basePath}/get`,
      request.mapToJson(),
      (response) => {
        onSuccess(
          new ResponseInquiryDocumentSetting().mapFromJson(
            response.data.document
          )
        );
      }
    );
  },
  updatePageOrder: (request, onSuccess) => {
    return baseAction().patch(
      `${basePath}/save/order`,
      request.mapToJson(),
      onSuccess
    );
  },
  addImageDocument: (request, onSuccess, onFailed) => {
    return baseAction().post(
      `${basePath}/add-image`,
      request.mapToJson(),
      (response) => {
        console.log(response);
        onSuccess(new ResponseAddImageDocument().mapFromJson(response.data));
      },
      onFailed
    );
  },
  deleteImageDocument: (request, onSuccess, onFailed) => {
    return baseAction().post(
      `${basePath}/remove-image`,
      request.mapToJson(),
      onSuccess,
      onFailed
    );
  },
  updateImageTitle: (request, onSuccess, onFailed) => {
    return baseAction().patch(
      `${basePath}/update-title`,
      request.mapToJson(),
      onSuccess,
      onFailed
    );
  },
  updateImagePath: (request, onSuccess, onFailed) => {
    return baseAction().patch(
      `${basePath}/update-path`,
      request.mapToJson(),
      onSuccess,
      onFailed
    );
  },
  updatePrintStatus: (request, onSuccess, onFailed) => {
    return baseAction().patch(
      `${basePath}/update-key`,
      request.mapToJson(),
      onSuccess,
      onFailed
    );
  },
};