import styled from "@emotion/styled";
import tw from "twin.macro";

export const Wrapper = styled.div`
    ${ tw`h-full overflow-hidden flex flex-col` }
`;

export const ManagerInputContainer = styled.div`
    ${ tw`flex` }
`;

export const CustomerActionContainer = styled.div`
    ${ tw`flex w-full bg-white border-t gap-3 py-2 px-5` }
`;

export const CustomerAction = styled.button`
    ${ tw`underline` }
`;