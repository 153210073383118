import styled from "@emotion/styled";
import tw from "twin.macro";

export const Container = styled.div`
    ${ tw`grid grid-cols-5 gap-3` }
`;

export const PageSettingWrapper = styled.div`
    ${ tw`col-span-5` }
`;

export const InputContainer = styled.div`
    height: 32px;
    ${ tw`grid grid-cols-4 col-span-4 gap-3` }
`;

export const ManagerInputContainer = styled.div`
    height: 32px;
    ${ tw`grid grid-cols-4 col-span-4 gap-3` }
`;

export const ImageInputWrapper = styled.div`
    ${ tw`col-span-2` }
`;

export const ManagerInputWrapper = styled.div`
    ${ tw`col-span-3` }
`;

export const SideContentWrapper = styled.div`
    ${ tw`col-span-1` }
`;