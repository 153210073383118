import styled from "@emotion/styled";
import tw from "twin.macro";

export const Container = styled.div`
    width: 500px;
    max-width: 90%;
    max-height: 90%;
    ${ tw `flex flex-col m-auto bg-white p-8 overflow-hidden` }
`;

export const CloseButton = styled.button`
    width: 20px;
    height: 20px;
    ${ tw `ml-auto` }
`;