import styled from "styled-components";
import tw from "twin.macro";
import sizes from "constants/sizes";
import defaultValues from "constants/defaultValues";

const Wrapper = styled.button`
    min-height: ${(props) => {
        if (props.height.isEmpty()) { return '100%' }
        return `${props.height}px`
    }};
   ${(props) => {
        if (props.width.isEmpty()) { return null }
        if (props.width == sizes.button.full) { return 'width: 100%;' }
        return ` width: ${props.width}px;`
    }}
    padding-left: ${(props) => props.horizontalPadding}px;
    padding-right: ${(props) => props.horizontalPadding}px;
    background-color: ${(props) => props.style.backgroundColor};
    ${(props) => {
        return props.style.borderWidth != defaultValues.emptyInt
            && `
                border-color: ${props.style.borderColor};
                border-width: ${props.style.borderWidth}px;
            ` 
    }}
    ${tw `flex` }
    ${
        (props) => {
            let styles = []
            if (!props.marginLeft.isEmpty()) {
                styles.push(
                    `margin-left: ${props.marginLeft};`
                )
            }
            if (!props.marginRight.isEmpty()) {
                styles.push(
                    `margin-right: ${props.marginRight};`
                )
            }
            return styles.join(';');
        }
    }
    ${(props) => props.isRounded && tw`rounded-md` }
    :disabled {
        opacity: 0.5;
    }
`;

const Container = styled.div`
    ${tw `flex w-full my-auto` }
`;

const Icon = styled.img`
    min-width: 24px;
    height: 24px;
    ${(props) => props.isIncludeTitle && tw`mr-2` }
`;

const Title = styled.p`
    color: ${(props) => props.titleColor };
    ${ (props) => props.isUnderlined && "text-decoration: underline;" }
    ${tw `w-full` }
`;

export {
    Wrapper,
    Container,
    Icon,
    Title
}