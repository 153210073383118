import * as defaultValues from "constants/defaultValue";
import { Image } from "models/objects/Image";

export class RequestUpdatePropertyImage {
    constructor(
        propertyId = defaultValues.emptyString,
        imageInformation = Image()
    ) {
        this.propertyId = propertyId;
        this.imageInformation = imageInformation;
    }

    mapToJson() {
        return {
            'property_id': this.propertyId,
            'image_information': this.imageInformation
        }
    }
}