import React from 'react';
import Text from 'components/core/text/Text';
import * as styled from './MainPageForm.styled';
import PageSettingInput from 'components/PageSettingInput/PageSettingInput';
import EmptyView from 'components/EmptyView/EmptyView';
import alignments from 'constants/alignments';
import { BorderedDropdownInputStyle } from 'components/core/dropdown-input/styles';
import DropdownInput from 'components/core/dropdown-input/DropdownInput';
import useMainPageForm from './useMainPageForm';
import * as printPropertySlice from 'pages/PrintCardPage/printCardReducer';
import * as propertyPrintPages from 'enumerations/propertyPrintPages';
import { useSelector } from 'react-redux';
import FileInput from 'components/FileInput/FileInput';

const MainPageForm = () => {
  const hook = useMainPageForm();
  const imageOrientation = useSelector(
    (state) => state.printProperty.imageOrientation
  );

  return (
    <styled.Container>
      <styled.PageSettingWrapper>
        <PageSettingInput
          title="투자 제안서"
          isExcluded={hook.mainPage.isExcluded}
          onChangeSetting={hook.onUpdatePrintStatus}
        />
      </styled.PageSettingWrapper>
      <EmptyView />
      <styled.InputContainer>
        <styled.SideContentWrapper>
          <Text
            alignment={alignments.center}
            value={'이미지'}
          />
        </styled.SideContentWrapper>
        <styled.ImageInputWrapper>
          <FileInput onFileChange={hook.onImageChange} />
        </styled.ImageInputWrapper>
        <styled.SideContentWrapper>
          <DropdownInput
            selectedOption={imageOrientation}
            options={hook.orientationOptions}
            handleSelectOption={printPropertySlice.selectImageOrientation}
            style={BorderedDropdownInputStyle}
          />
        </styled.SideContentWrapper>
      </styled.InputContainer>
      <EmptyView />
      <styled.ManagerInputContainer>
        <styled.SideContentWrapper>
          <Text
            alignment={alignments.center}
            value={'담당'}
          />
        </styled.SideContentWrapper>
        <styled.ManagerInputWrapper>
          <DropdownInput
            selectedOption={hook.selectedManagerOption}
            options={hook.managerOptions}
            handleSelectOption={hook.onSelectManager}
            style={BorderedDropdownInputStyle}
          />
        </styled.ManagerInputWrapper>
      </styled.ManagerInputContainer>
    </styled.Container>
  );
};

export default MainPageForm;
