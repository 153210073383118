import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import PrintPageHeader from 'components/PrintPageHeader/PrintPageHeader';
import * as styled from './ImagePrintPage.styled';
import * as defaultValues from 'constants/defaultValue';
import ImagePageSize from 'enumerations/ImagePageSize';
import useImagePrintPage from './ImagePrintPage.viewModel';
import etcService from 'services/etcService';
import { RequestGetImage } from 'models/requests';

const AsyncImage = (props) => {
  const [blobSrc, setBlobSrc] = useState('');

  useEffect(() => {
    if (!props.src) {
      return;
    }
    const request = new RequestGetImage();
    request.type = 'document';
    request.fileName = props.src.split('/').at(-1);
    etcService.getImage(
      request,
      (imageUrl) => setBlobSrc(imageUrl),
      (error) => {
        return;
      }
    );
  }, [props.src]);

  return <styled.Image src={blobSrc} />;
};

AsyncImage.propTypes = {
  src: PropTypes.string,
};

const ImagePrintPage = (props) => {
  const { getImageUrl } = useImagePrintPage(props.imagePage.imagePaths);
  const wrapperRef = useRef(null);
  const containerRef = useRef(null);
  const [pageHeight, setPageHeight] = useState(defaultValues.emptyInt);

  const renderImages = () => {
    return props.imagePage.imagePaths.map((_, index) => {
      return (
        <styled.ImageWrapper
          key={index}
          pageHeight={pageHeight}
          imageSize={props.imagePage.size}>
          {getImageUrl(index) !== defaultValues.emptyString && (
            <AsyncImage src={getImageUrl(index)} />
          )}
        </styled.ImageWrapper>
      );
    });
  };

  useEffect(
    () => setPageHeight(containerRef.current.clientHeight),
    [containerRef]
  );

  return (
    <styled.Wrapper ref={wrapperRef}>
      <styled.Container
        ref={containerRef}
        scale={
          wrapperRef.current?.clientWidth / containerRef.current?.clientWidth
        }
        data-id={props.imagePage.id}>
        <PrintPageHeader
          title={props.imagePage.title}
          isShowLogo={true}
        />
        <styled.BodyContainer imageCount={props.imagePage.imagePaths.length}>
          {renderImages()}
        </styled.BodyContainer>
      </styled.Container>
    </styled.Wrapper>
  );
};

ImagePrintPage.propTypes = {
  imagePage: PropTypes.object,
};

export default ImagePrintPage;
