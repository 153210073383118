import styled from "styled-components";
import tw from "twin.macro";

const Container = styled.div`
    ${ tw`flex flex-col space-y-2.5 h-full pt-3` }
`;
const TextArea = styled.textarea`
    resize: none;
    ${tw `w-full text-left p-3 flex-1`}
    &:disabled {
        background-color: transparent;   
    }
`;

export {
    Container,
    TextArea
}