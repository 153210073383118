import * as defaultValues from "constants/defaultValue";
import { useState } from "react";

const MergeTableViewHook = () => {
    const [selectedCells, setSelectedCells] = useState([]);
    const [mergedRows, setMergedRows] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [unselectableColumns, setUnselectableColumns] = useState([]);
    const [columnChains, setColumnChains] = useState(defaultValues.emptyArray);
    const [onMergeSuccess, setOnMergeSuccess] = useState(() => defaultValues.emptyFunction);
    let onUnmergeSuccess = defaultValues.emptyFunction

    const isUnselectable = (column) => {
        if (unselectableColumns.some(x => x == column)) {
            return true;
        }
        return false;
    }

    const onSelectColumn = (columnItem) => {
        let _selectedColumns = selectedCells.sort(
            (item01, item02) => item01.row - item02.row
        );
        if (_selectedColumns.isEmpty()) {
            setSelectedCells(
                [columnItem]
            )
            return;
        }
        if (_selectedColumns.first().column != columnItem.column) {
            setSelectedCells([columnItem]);
            return;
        }
        const isAlreadySelected = _selectedColumns.some(
            $0 => $0.column == columnItem.column && $0.row == columnItem.row
        )
        if (isAlreadySelected) {
            setSelectedCells(
                columnItem.row > _selectedColumns.first().row
                &&  columnItem.row < _selectedColumns.last().row
                    ? defaultValues.emptyArray
                    : _selectedColumns.filter(
                        $0 => $0.row != columnItem.row
                    )
            )
            return;
        }
        if (columnItem.row != _selectedColumns.first().row - columnItem.rowSpan
            && columnItem.row != _selectedColumns.last().row + _selectedColumns.last().rowSpan) {
            setSelectedCells(defaultValues.emptyArray);
            return
        }
        setSelectedCells(
            [
                ..._selectedColumns,
                columnItem
            ]
        );
    }

    const startMergeColumns = (
        selectedCells,
        onMergeSuccess,
        onUnmergeSuccess
    ) => {
        if (selectedCells.isEmpty()) { return []; }
        if (selectedCells.length == 1) {
            setSelectedCells(defaultValues.emptyArray);
            let unmergeColumns = _startUnmergeColumns(selectedCells);
            onUnmergeSuccess(unmergeColumns);
            return;
        }
        let _selectedCells = selectedCells.sort(($0, $1) => $0.row - $1.row);
        let _mergedRows = defaultValues.emptyArray;
        for (const columnItem of _selectedCells) {
            for (let index = 0; index < columnItem.rowSpan; index++) {
                _mergedRows = [
                    ..._mergedRows,
                    {
                        row: columnItem.row + index,
                        column: columnItem.column,
                        isMerged: true
                    }
                ]
            }
        }
        _mergedRows.last().isMerged = false;
        setSelectedCells(defaultValues.emptyArray);
        onMergeSuccess(_mergedRows);
    }
    
    const _startUnmergeColumns = (selectedCells) => {
        if (selectedCells[0].rowSpan == 1) { return []; }
        let _unmergedColumns = [];
        for (let index = 0; index < selectedCells[0].rowSpan; index++) {
            _unmergedColumns = [
                ..._unmergedColumns,
                {
                    row: selectedCells[0].row + index,
                    column: selectedCells[0].column,
                    isMerged: false
                }
            ]
        }
        return _unmergedColumns.sort(
            ($0, $1) => $0.row - $1.row
        );
    }

    const onRemoveCell = () => {
        setMergedRows(
            mergedRows.filter(
                item01 => !selectedCells.some(
                    item02 => item02.row == item01.row
                )
            )
        )
        mergedRows.filter(
            item01 => !selectedCells.some(
                item02 => item02.row == item01.row
            )
        )
        setSelectedCells(
            defaultValues.emptyArray
        );
    }

    return {
        tableRows,
        mergedRows,
        selectedCells,
        onMergeSuccess,
        setSelectedCells,
        setTableRows,
        onSelectColumn,
        startMergeColumns,
        onRemoveCell,
        setUnselectableColumns,
        setOnMergeSuccess,
        isUnselectable,
        setColumnChains,
        columnChains,
        onUnmergeSuccess
    }
}

export default MergeTableViewHook;