import React from 'react';
import ColumnTable from 'components/core/column-table/ColumnTable';
import * as defaultValues from 'constants/defaultValue';
import { RequestFetchCardLogList } from 'models/requests/RequestFetchCardLogList';
import { useState } from 'react';
import { useEffect } from 'react';
import employeeService from 'redux/actions/employeeAction';
import { RequestDeleteCardLogs } from 'models/requests/RequestDeleteCardLogs';
import { Pagination } from 'models/objects/Pagination';

const useEmployeeCardLogPage = () => {
    const [request, setRequest] = useState(new RequestFetchCardLogList())
    const [pagination, setPagination] = useState(new Pagination());
    const [checkedViewLogs, setCheckedViewLogs] = useState(defaultValues.emptyArray);
    const [viewLogs, setViewLogs] = useState(defaultValues.emptyArray);

    const loadData = () => {
        employeeService.fetchCardLogList(
            request,
            _didFetchCardLogSucceed
        )
    }

    const _didFetchCardLogSucceed = (response) => {
        setPagination(response.pagination);
        setViewLogs(response.viewLogs);
    }

    const onStartSearchLogs = (
        keyword,
        startDate,
        endDate
    ) => {
        setViewLogs(defaultValues.emptyArray);
        setPagination(new Pagination())
        setRequest(
            new RequestFetchCardLogList(
                keyword,
                startDate,
                endDate
            )
        )
    }

    const onPageChange = (page) => {
        setViewLogs(defaultValues.emptyArray);
        const newRequest = Object.assign(
            new RequestFetchCardLogList(),
            request
        )
        newRequest.pagination.page = page;
        pagination.currentPage = page;
        setPagination(
            Object.assign(
                new Pagination(),
                pagination
            )
        );
        setRequest(newRequest);
    }

    const onStartDeleteLogs = () => {
        employeeService.deleteCardLogs(
            new RequestDeleteCardLogs(
                checkedViewLogs.map($0 => $0.id)
            ),
            _didDeleteCardLogSucceed
        )
    }

    const _didDeleteCardLogSucceed = () => {
        setViewLogs(defaultValues.emptyArray);
        setCheckedViewLogs(defaultValues.emptyArray);
        loadData();
    }

    const _setupTableCheckboxColumn = (key, value, action) => {
        return (
            <ColumnTable
                key={ key }
                handleAction={ action }
                isCheckbox={ true }
                value={ value } />
        )
    }

    const _setupTableTextColumn = (key, value) => {
        return (
            <ColumnTable
                key={ key }
                title={ value } />
        )
    }

    const _onCheckAllViewLogs = (isCheck) => {
        setCheckedViewLogs(
            isCheck
                ? viewLogs
                : defaultValues.emptyArray
        )
    }

    const _onCheckViewLog = (isCheck, viewLog) => {
        let viewLogs = checkedViewLogs.filter(
            $0 => $0.id != viewLog.id
        );
        if (isCheck) {
            viewLogs.push(viewLog);
        }
        setCheckedViewLogs(viewLogs)
    }

    const setupTableHeaders = () => {
        return [
            _setupTableCheckboxColumn(
                defaultValues.emptyInt,
                !viewLogs.isEmpty() && 
                    checkedViewLogs.length == viewLogs.length,
                _onCheckAllViewLogs
            ),
            _setupTableTextColumn(
                defaultValues.emptyInt,
                "번호"
            ),
            _setupTableTextColumn(
                defaultValues.emptyInt,
                "물건번호"
            ),
            _setupTableTextColumn(
                defaultValues.emptyInt,
                "이름"
            ),
            _setupTableTextColumn(
                defaultValues.emptyInt,
                "열람일시"
            ),
            _setupTableTextColumn(
                defaultValues.emptyInt,
                "접근아이피"
            )
        ]
    }

    const setupTableRows = () => {
        return viewLogs.map(
            (value, index) => [
                _setupTableCheckboxColumn(
                    index,
                    checkedViewLogs.some(
                        $0 => $0.id == value.id
                    ),
                    (isCheck) => _onCheckViewLog(isCheck, value)
                ),
                _setupTableTextColumn(
                    index,
                    (index + 1) + (request.pagination.pageSize * pagination.currentPage)
                ),
                _setupTableTextColumn(
                    index,
                    value.property.id.withZeroTrailingPrefix()
                ),
                _setupTableTextColumn(
                    index,
                    value.employee.name
                ),
                _setupTableTextColumn(
                    index,
                    value.readingDate
                ),
                _setupTableTextColumn(
                    index,
                    value.ipAddress
                )
            ]
        )
    }

    useEffect(
        () => loadData(),
        [request]
    )

    return {
        loadData,
        setupTableRows,
        setupTableHeaders,
        onStartSearchLogs,
        onStartDeleteLogs,
        onPageChange,
        pagination
    }
}

export default useEmployeeCardLogPage;