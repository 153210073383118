import defaultValues from "constants/defaultValues";

export class RequestCreateWorkHistory {
    constructor(
        propertyId = defaultValues.emptyInt,
        description = defaultValues.emptyString
    ) {
        this.propertyId = propertyId;
        this.description = description;
    }

    mapToJson() {
        return {
            'property_id': this.propertyId,
            'description': this.description
        }
    }
}