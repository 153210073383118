import styled from 'styled-components';
import tw from 'twin.macro';

const Wrapper = styled.div`
    height: ${
        props => {
            return props.height.isEmpty()
                ? " 100%"
                : props.height + "px"
        }
    };
    ${props => {
        return !props.labelTitle.isEmpty()
            && tw`grid grid-cols-4`
    }}
    ${tw `relative` }
    ${(props) => {
        if (props.margin.value.isEmpty()) { return null; }
        return `margin-${props.margin.position}:${props.margin.value}px;`
    }}
`;

const RightIcon = styled.img`
    ${tw `my-auto mr-3` }
    ${props => {
        return props.style.iconWidth
    }};
    ${props => {
        return props.style.iconHeight 
    }};
`;

const Input = styled.input`
    ${props => {
        return !props.isEnabled && tw`opacity-25`
    }}
    ${tw `outline-none w-full bg-transparent col-span-1 px-3` }
`;

const Label = styled.span`
    ${tw `text-left my-auto` }
`;

const Button = styled.button`
    ${tw `absolute inset-0 w-full h-full` }
`;

const InputContainer = styled.div`
    width: ${props => {
        return props.width.isEmpty()
            ? '100%'
            : `${props.width}px`
    }};
    ${ (props) => !props.minWidth.isEmpty() && `min-width: ${props.minWidth}px;`}
    ${ (props) => !props.maxWidth.isEmpty() && `max-width: ${props.maxWidth}px;`}
    ${tw `flex relative rounded bg-white h-full` }
    ${props => {
        return props.labelTitle && tw`col-span-3`
    }}
    ${props => {
        return !props.isEnabled && tw`bg-secondary`
    }}
    ${props => {
        return props.style.containerStyle
    }}
`;

const OptionsContainer = styled.div`
    z-index: 2;
    ${tw `absolute inset-0 top-10 w-full shadow bg-white` }
`;

const TableWrapper = styled.div`
    ${
        props => `max-height: ${props.listHeight}px;`
    }
    width: 100%;
    background-color: white;
    overflow-y: auto;
`;

export {
    Wrapper,
    RightIcon,
    Input,
    Label,
    Button,
    InputContainer,
    OptionsContainer,
    TableWrapper
}