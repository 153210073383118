import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CardSection from 'components/CardSection/CardSection';
import * as styled from './CardPageContentInnerAddress.styled';
import NaverMapIconColored from 'assets/naver_map_icon_colored.png'
import DaumMapIconColored from 'assets/daum_map_icon_colored.png';
import HomeMapIconBlack from 'assets/home_map_bordered_icon_black.png';
import titleStrings from 'localizations/titleStrings';
import defaultValues from 'constants/defaultValues';
import { useSelector } from 'react-redux';
import useCardAddress from './useCardAddress';

const CardPageContentInnerAddress = (props) => {
    const hook = useCardAddress();
    const property = useSelector(state => state.card.card)

    const renderContent = () => {
        return (
            <styled.Container>
                <styled.TopContainer>
                    <styled.AddressContainer>
                        <p>{ property.address }</p>
                        <p>{ property.streetAddress }</p>
                    </styled.AddressContainer>
                    <styled.MapActionContainer>
                        <styled.ActionButton onClick={ hook.onOpenNaverMap }>
                            <img
                                src={ NaverMapIconColored }
                                alt="" />
                        </styled.ActionButton>
                        <styled.ActionButton onClick={ hook.onOpenKakaoMap }>
                            <img
                                src={ DaumMapIconColored }
                                alt="" />
                        </styled.ActionButton>
                        <styled.ActionButton onClick={ hook.onStartMapPage }>
                            <img
                                src={ HomeMapIconBlack }
                                alt="" />
                        </styled.ActionButton>
                    </styled.MapActionContainer>
                </styled.TopContainer>
                <styled.ImageWrapper>
                    <styled.Image src={
                        property.images.isEmpty()
                            ? defaultValues.emptyString
                            : property.images[0].url
                    }/>
                </styled.ImageWrapper>
            </styled.Container>
        )
    }

    return (
        <CardSection
            title={ titleStrings.address }
            content={ renderContent() } />
    )
}

export default CardPageContentInnerAddress;