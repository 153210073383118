import * as defaultValues from "constants/defaultValue";
import unitExhanges from "constants/unitExhanges";

Number.prototype.isEmpty = function () {
    return this == defaultValues.emptyInt;
};

Number.prototype.tenThousandFormat = function () {
    return `${this.toLocaleString('kr-KR')} 만원`;
};

Number.prototype.percentage = function (rounded = defaultValues.emptyInt) {
    return `${ this.toFixed(rounded) }%`;
};

Number.prototype.convertToPy = function () {
    return this * unitExhanges.meterSquareToPyeong
}

Number.prototype.rounded = function (decimalPlace) {
    return parseFloat(this.toFixed(decimalPlace));
}

Number.prototype.thousandFormat = function (decimalPlace) {
    return this
        .rounded(decimalPlace)
        .toString()
        .thousandFormat();
}

Number.prototype.truncate = function (decimalPlace) {
  const f = Math.pow(10, Math.max(decimalPlace | 0, 0)) * 10;
  return Math.trunc(this * f) / f;
}