import React, { useEffect, useState } from 'react';
import { TextInputModalViewModel } from "components/TextInputModal/TextInputModalViewModel";
import zIndex from "constants/zIndex";
import formStates from "enumerations/formStates";
import { hintStrings, titleStrings } from "localizations";
import { RequestCreateCard } from "models/requests/RequestCreateCard";
import * as cardActions from "redux/reducers/cardReducer";
import * as commonActions from "redux/reducers/commonReducer";
import buttonStrings from 'localizations/buttonStrings';
import TextInputModal from 'components/TextInputModal/TextInputModal';
import defaultValues from 'constants/defaultValues';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import { RequestSearchProperty } from 'models/requests/RequestSearchProperty';
import qs from "qs";
import { Pagination } from 'models/objects/Pagination';

const useCardNavigation = (onStartSaveProperty) => {
    const history = useHistory();
    const property = useSelector(state => state.card.card);
    const formState = useSelector(state => state.card.formState);
    const pagination = useSelector(state => state.card.pagination);
    const [currentPage, setCurrentPage] = useState(defaultValues.emptyInt);
    const textInputModalViewModel = new TextInputModalViewModel()

    const showInputAddressPopup = () => {
        textInputModalViewModel.title = titleStrings.enterNewAddress;
        textInputModalViewModel.placeholder = hintStrings.createAddressPlaceholder;
        textInputModalViewModel.buttonViewModel.title = buttonStrings.confirm;
        textInputModalViewModel.buttonViewModel.action = _startPropertyForm;
        commonActions.showModal(
            zIndex.defaultModal,
            (
                <TextInputModal
                    onTapEnter={ _startPropertyForm }
                    viewModel={ textInputModalViewModel }/>
            )
        )
    }

    const startPrintCardPage = () => {
        window.open(
            "/card/" + property.id + "/print",
            '_blank',
            'noopener,noreferrer'
        );
    }

    const _startPropertyForm = () => {
        let requestCreateCard = new RequestCreateCard();
        requestCreateCard.address = textInputModalViewModel.textInputViewModel.value;
        requestCreateCard.setFloorInformations(defaultValues.emptyArray);
        requestCreateCard.setRentalHistory(defaultValues.emptyArray);
        setCurrentPage(pagination.totalPages);
        cardActions.updatePagination(
            new Pagination(
                pagination.totalPages,
                pagination.totalItems,
                pagination.totalPages + 1
            )
        );
        cardActions.updateRequestCreateCard(
            requestCreateCard
        )
        commonActions.hideModal();
        cardActions.changeFormState(formStates.create);
    }

    const onPageChange = (page) => {
        cardActions.updateCurrentPage(page);
        if (formState != formStates.read) {
            onStartSaveProperty(
                () =>  _updateCurrentPage(page)
            );
            return;
        }
        _updateCurrentPage(page);
    }

    const _updateCurrentPage = (page) => {
        if (history.location.pathname == "/search/detail") {
            const request = new RequestSearchProperty().mapFromJson(
                qs.parse(
                    queryString.parse(history.location.search)
                )
            );
            request.pagination.page = page;
            history.push(
                '/search/detail?'+ qs.stringify(request.mapToJson())
            );
            return;
        }
        history.push(
            history.location.pathname
                + "?page="
                + page
        );
    }

    const _setupInitialPage = () => {
        setCurrentPage(
            queryString.parse(
                history.location.search
            ).page
        );
    }

    useEffect(
        () => _setupInitialPage(),
        [history.location]
    )

    useEffect(
        () => {
            if (formState == formStates.create) {
                return;
            }
            _setupInitialPage();
        },
        [formState]
    )

    return {
        showInputAddressPopup,
        startPrintCardPage,
        onPageChange,
        currentPage
    }
}

export default useCardNavigation;