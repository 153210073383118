import defaultValues from "constants/defaultValues";
import { makeAutoObservable } from "mobx";

export class ColumnTableViewModel {
    value = "";
    
    constructor(
        customView = null,
        widthPercentage = null,
        isCheckbox = false,
        value = defaultValues.emptyString,
        action = () => { return; }
    ) {
        makeAutoObservable(this);
        this.customView = customView
        this.widthPercentage = widthPercentage;
        this.isCheckbox = isCheckbox;
        this.value = value;
        this.action = action
    }

    setValue = () => {
        this.value = "JOKOWI"
    }
}