import React from 'react';
import { Switch, Route } from 'react-router';
import MapPage from '../pages/MapPage';
import CardPage from '../pages/CardPage/CardPage';
import CustomerPage from '../pages/CustomerPage/CustomerPage';
import EmployeePage from '../pages/EmployeePage/EmployeePage';
import SearchPage from 'pages/SearchPage/SearchPage';
import PrintCardPage from 'pages/PrintCardPage/PrintCardPage';

const MainRoute = () => (
    <Switch>
        <Route exact path="/" component={ MapPage } />
        <Route path="/map" component={ MapPage } />
        <Route exact path="/search" component={ SearchPage } />
        <Route path="/search/detail" component={ CardPage } />
        <Route path="/search/history" component={ CardPage } />
        <Route exact path="/card" component={ CardPage} />
        <Route path="/card/:propertyId/print" component={ PrintCardPage } />
        <Route path="/customer" component={ CustomerPage } />
        <Route path="/employee" component={ EmployeePage } />
    </Switch>
);

export default MainRoute;