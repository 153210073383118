import defaultValues from "constants/defaultValues";

export class Image {
    constructor(
        id = defaultValues.emptyInt,
        url = defaultValues.emptyString
    ) {
        this.id = id;
        this.url = url;
    }

    mapFromJson(json) {
        if (!json) { return this; }
        this.id = json['id'];
        this.url = json['url'];
        return this;
    }

    mapToJson() {
        return {
            "id": this.id,
            "url": this.url
        }
    }
}