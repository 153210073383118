import { RequestCreatePriceInformation } from "models/requests/RequestCreatePriceInformation";
import * as defaultValues from "constants/defaultValue";

export class PriceInformation {
    constructor(
        id = defaultValues.emptyInt,
        paidPrice = defaultValues.emptyInt,
        totalPrice = defaultValues.emptyInt,
        landPrice = defaultValues.emptyInt,
        buildingPrice = defaultValues.emptyInt,
        pricePerArea = defaultValues.emptyInt,
        priceTotalArea = defaultValues.emptyInt,
        revenue = defaultValues.emptyInt,
        deposit = defaultValues.emptyInt,
        monthlyRate = defaultValues.emptyInt,
        monthlyIncome = defaultValues.emptyInt,
        adminPrice = defaultValues.emptyInt,
        adminRevenueRate = defaultValues.emptyInt,
        loanPrice = defaultValues.emptyInt,
        interest = defaultValues.emptyInt,
        isMonthlyInterest = false
    ) {
        this.id = id;
        this.paidPrice = paidPrice;
        this.totalPrice = totalPrice;
        this.landPrice = landPrice;
        this.buildingPrice = buildingPrice;
        this.pricePerArea = pricePerArea;
        this.priceTotalArea = priceTotalArea;
        this.revenue = revenue;
        this.deposit = deposit;
        this.monthlyRate = monthlyRate;
        this.monthlyIncome = monthlyIncome;
        this.adminPrice = adminPrice;
        this.adminRevenueRate = adminRevenueRate;
        this.loanPrice = loanPrice;
        this.interest = interest;
        this.isMonthlyInterest = isMonthlyInterest;
    }

    mapFromJson(json) {
        if (!json) { return this; }
        this.id = json['id'];
        this.paidPrice = json['paid'];
        this.totalPrice = json['price'];
        this.landPrice = json['land_price'];
        this.buildingPrice = json['building_price'];
        this.pricePerArea = json['price_per_area'];
        this.priceTotalArea = json['price_per_total_area'] ?? defaultValues.emptyFloat;
        this.revenue = json['revenue'];
        this.deposit = json['deposit'];
        this.monthlyRate = json['monthly_rate'] ?? defaultValues.emptyInt;
        this.monthlyIncome = json['monthly_income'];
        this.adminPrice = json['admin_price'];
        this.adminRevenueRate = json['admin_revenue_rate'];
        this.loanPrice = json['loan_price'] ?? defaultValues.emptyInt;
        this.interest = json['interest'];
        this.isMonthlyInterest = json['is_monthly_interest']
        return this;
    }

    convertToEditRequest() {
        const requestEditPriceInformation = new RequestCreatePriceInformation()
        requestEditPriceInformation.paid = this.paidPrice;
        requestEditPriceInformation.price = this.totalPrice;
        requestEditPriceInformation.adminPrice = this.adminPrice;
        requestEditPriceInformation.adminRevenueRate = this.adminRevenueRate;
        requestEditPriceInformation.loanPrice = this.loanPrice;
        requestEditPriceInformation.interest = this.interest;
        requestEditPriceInformation.isMonthlyInterest = this.isMonthlyInterest;
        return requestEditPriceInformation
    }
}