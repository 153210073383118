import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/core/button/Button';
import * as styled from './CardPageContentTopMenu.styled';
import TrashIconBlack from 'assets/trash_icon_black.png';
import PencilIconBlack from 'assets/pencil_icon_black.png';
import AddIconWhite from 'assets/add_icon_white.png';
import { WhiteButtonStyle } from 'components/core/button/styles';
import sizes from 'constants/sizes';
import buttonStrings from 'localizations/buttonStrings';
import { titleStrings } from 'localizations';
import SaveIconBlack from 'assets/save_icon_black.png';
import PreviewIconBlack from 'assets/preview_icon_black.png';
import { useDispatch, useSelector } from 'react-redux';
import cardActionTypes from 'redux/types/cardActionTypes';
import cardService from 'redux/actions/cardActions';
import * as cardActions from 'redux/reducers/cardReducer';
import * as commonActions from 'redux/reducers/commonReducer';
import * as confirmationModalActions from 'layouts/modals/ConfirmationModal/confirmationModal.reducer';
import { RequestDeleteCard } from 'models/requests/RequestDeleteCard';
import { ButtonViewModel } from 'components/core/button/ButtonViewModel';
import { RequestFetchCard } from 'models/requests/RequestFetchCard';
import colors from 'constants/colors';
import cardStatus from 'enumerations/cardStatus';
import DropdownInput from 'components/core/dropdown-input/DropdownInput';
import { PlainDropdownInputStyle } from 'components/core/dropdown-input/styles';
import { employeeAction } from 'redux/actions';
import { commonActionTypes, employeeActionTypes } from 'redux/types';
import { RequestFetchEmployeeList } from 'models/requests';
import { RequestCreateCard } from 'models/requests/RequestCreateCard';
import formStates from 'enumerations/formStates';
import ConfirmationModal from 'layouts/modals/ConfirmationModal/ConfirmationModal';
import zIndex from 'constants/zIndex';
import * as defaultValues from 'constants/defaultValue';
import cookiesManager from 'managers/cookies.manager';
import { useHistory } from 'react-router';
import useCardNavigation from './useCardNavigation';
import PaginationView from 'components/PaginationView/PaginationView';
import queryString from 'query-string';
import qs from 'qs';
import { RequestSearchProperty } from 'models/requests/RequestSearchProperty';
import { toast } from 'react-toastify';

function CardPageContentTopMenu(props) {
  const hook = useCardNavigation(props.onShowSavingConfirmation);
  const [saveButtonViewModel] = useState(new ButtonViewModel());
  const [deleteButtonViewModel] = useState(new ButtonViewModel());
  const [employees, setEmployees] = useState([]);
  const cardReducer = useSelector((state) => state.card);
  const { pagination } = useSelector((state) => state.card);
  const requestWriteCard = useSelector((state) => state.card.requestWriteCard);
  const history = useHistory();
  const dispatch = useDispatch();

  const onStartDeleteCard = () => {
    showDeleteCardConfirmationModal();
  };

  const startDeleteCard = () => {
    dispatch(confirmationModalActions.startLoading());
    dispatch({
      type: cardActionTypes.DELETE_PROPERTY_CARD,
      payload: cardService.deletePropertyCard(
        new RequestDeleteCard(cardReducer.card.id),
        didDeleteCardSucceed,
        (error) => {
          dispatch(confirmationModalActions.stopLoading());
          toast(error.data);
        }
      ),
    });
  };

  const didDeleteCardSucceed = () => {
    dispatch(confirmationModalActions.stopLoading());
    commonActions.hideModal();
    const currentPage = pagination.currentPage;
    cardActions.deletePropertySucceed();
    _updateCurrentPage(
      currentPage == defaultValues.emptyInt ? currentPage : currentPage - 1
    );
  };

  const _updateCurrentPage = (page) => {
    if (history.location.pathname == '/search/detail') {
      const request = new RequestSearchProperty().mapFromJson(
        qs.parse(queryString.parse(history.location.search))
      );
      request.pagination.page = page;
      history.push('/search/detail?' + qs.stringify(request.mapToJson()));
      return;
    }
    history.push(history.location.pathname + '?page=' + (page - 1));
  };

  const didRefreshCardSucceed = () => {
    props.onMoveToReadState();
  };

  const onTapStatusButton = (statusId) => {
    if (cardReducer.formState != formStates.read) {
      showChangeStatusConfirmationModal(statusId);
      return;
    }
    updateCardStatus(statusId);
  };

  const updateCardStatus = (statusId) => {
    requestWriteCard.status = statusId;
    cardActions.updateRequestCreateCard(requestWriteCard);
    commonActions.hideModal();
  };

  const onTapEditButton = () => {
    cardActions.changeFormState(formStates.edit);
  };

  const renderDeleteButton = () => {
    if (
      !cookiesManager.getCurrentUser().isAdmin ||
      cardReducer.formState == formStates.create
    ) {
      return null;
    }
    return (
      <Button
        action={onStartDeleteCard}
        title={buttonStrings.delete}
        height={sizes.button.small}
        icon={TrashIconBlack}
        style={WhiteButtonStyle}
        viewModel={deleteButtonViewModel}
      />
    );
  };

  const showPreviewConfirmationModal = () => {
    dispatch({
      type: commonActionTypes.SHOW_MODAL,
      payload: {
        zIndex: zIndex.defaultModal,
        content: (
          <ConfirmationModal
            title={titleStrings.savePropertyPreviewConfirmTitle}
            primaryButtonTitle="네(YES)"
            secondaryButtonTitle="아니요(NO)"
            primaryAction={props.onStartSavingProperty}
            secondaryAction={onCancelEditState}
          />
        ),
      },
    });
  };

  const onCancelEditState = () => {
    if (cardReducer.formState == formStates.create) {
      cardActions.onCancelCreateProperty();
    }
    props.onMoveToReadState();
  };

  const showChangeManagerConfirmationModal = (selectedEmployee) => {
    dispatch({
      type: commonActionTypes.SHOW_MODAL,
      payload: {
        zIndex: zIndex.defaultModal,
        content: (
          <ConfirmationModal
            title={`${cardReducer.requestWriteCard.manager.name} > ${selectedEmployee.name} 으로 변경하시겠습니까?`}
            primaryButtonTitle="예(YES)"
            secondaryButtonTitle="아니요(NO)"
            primaryAction={() => {
              updateSelectedManager(selectedEmployee);
            }}
          />
        ),
      },
    });
  };

  const showChangeStatusConfirmationModal = (selectedStatusId) => {
    dispatch({
      type: commonActionTypes.SHOW_MODAL,
      payload: {
        zIndex: zIndex.defaultModal,
        content: (
          <ConfirmationModal
            title={`${cardStatus.getTitle(
              selectedStatusId
            )} 으로 변경하시겠습니까?`}
            primaryButtonTitle="예(YES)"
            secondaryButtonTitle="아니요(NO)"
            primaryAction={() => updateCardStatus(selectedStatusId)}
          />
        ),
      },
    });
  };

  const showDeleteCardConfirmationModal = () => {
    dispatch({
      type: commonActionTypes.SHOW_MODAL,
      payload: {
        zIndex: zIndex.defaultModal,
        content: (
          <ConfirmationModal
            title={titleStrings.deletePropertyConfirmation}
            primaryButtonTitle="예(YES)"
            secondaryButtonTitle="아니요(NO)"
            primaryAction={() => startDeleteCard()}
          />
        ),
      },
    });
  };

  const renderSaveButton = () => {
    if (cardReducer.formState == formStates.read) {
      return null;
    }
    return (
      <Button
        title={buttonStrings.save}
        height={sizes.button.small}
        icon={SaveIconBlack}
        style={WhiteButtonStyle}
        viewModel={saveButtonViewModel}
        action={props.onShowSavingConfirmation}
      />
    );
  };

  const renderPreviewButton = () => {
    if (cardReducer.formState == formStates.read) {
      return null;
    }
    return (
      <Button
        action={showPreviewConfirmationModal}
        title={buttonStrings.preview}
        height={sizes.button.small}
        icon={PreviewIconBlack}
        style={WhiteButtonStyle}
      />
    );
  };

  const renderEditButton = () => {
    if (cardReducer.formState != formStates.read) {
      return null;
    }
    return (
      <Button
        title={buttonStrings.compilation}
        height={sizes.button.small}
        icon={PencilIconBlack}
        style={WhiteButtonStyle}
        action={onTapEditButton}
      />
    );
  };

  const renderBuildingNameTitle = () => {
    if (cardReducer.formState != formStates.read) {
      return null;
    }
    return <styled.NameText>{cardReducer.card.buildingName}</styled.NameText>;
  };

  const renderAdvertisementStatus = () => {
    if (cardReducer.formState != formStates.read) {
      return null;
    }
    return (
      <styled.AdsStatusText>
        {`광고 (${
          cardReducer.card.isAdvertisementAllowed ? '가능' : '불가능'
        })`}
      </styled.AdsStatusText>
    );
  };

  const renderBuildingId = () => {
    if (cardReducer.formState != formStates.read) {
      return null;
    }
    return (
      <styled.BuildingIdContainer>
        <styled.CardIdTitleText>
          <p>{titleStrings.itemNumber}</p>
          <p>{cardReducer.card.id.toString().withZeroTrailingPrefix()}</p>
        </styled.CardIdTitleText>
        <styled.CardIdText>{cardReducer.card.buildingId}</styled.CardIdText>
      </styled.BuildingIdContainer>
    );
  };

  const updateSelectedManager = (manager) => {
    requestWriteCard.manager = manager;
    commonActions.hideModal();
    dispatch({
      type: cardActionTypes.UPDATE_WRITE_CARD_REQUEST,
      payload: requestWriteCard,
    });
  };

  const onSelectOption = (managerId) => {
    const selectedEmployee = employees.find(
      (employee) => employee.id == managerId
    );
    if (requestWriteCard.propertyId != defaultValues.emptyInt) {
      showChangeManagerConfirmationModal(selectedEmployee);
      return;
    }
    updateSelectedManager(selectedEmployee);
  };

  const renderCardCreator = () => {
    if (cardReducer.formState != formStates.read) {
      const options = employees.map((employee) => {
        return {
          key: employee.id,
          value: employee.name,
        };
      });
      return (
        <styled.ManagerDropdownWrapper>
          <DropdownInput
            width={150}
            height={sizes.textInput.small}
            listHeight={500}
            style={PlainDropdownInputStyle}
            options={options}
            selectedOption={{
              key: requestWriteCard.manager.id,
              value: requestWriteCard.manager.name,
            }}
            handleSelectOption={(item) => {
              onSelectOption(item.key);
            }}
          />
        </styled.ManagerDropdownWrapper>
      );
    }
    return (
      <styled.ManagerNameText>
        {cardReducer.card.manager.name}
      </styled.ManagerNameText>
    );
  };

  const fetchEmployeeList = () => {
    dispatch({
      type: employeeActionTypes.FETCH_EMPLOYEE_LIST,
      payload: employeeAction.fetchEmployeeList(
        new RequestFetchEmployeeList(),
        didFetchEmployeeListSucceed
      ),
    });
  };

  const _renderPaginationView = () => {
    return (
      <PaginationView
        onChange={hook.onPageChange}
        page={hook.currentPage}
        totalPages={cardReducer.pagination.totalPages}
      />
    );
  };

  const _renderCreateButton = () => {
    if (cardReducer.formState != formStates.read) {
      return null;
    }
    return (
      <Button
        action={hook.showInputAddressPopup}
        title={buttonStrings.newInput}
        icon={AddIconWhite}
        height={sizes.button.small}
      />
    );
  };

  const _renderPrintButton = () => {
    if (cardReducer.formState != formStates.read) {
      return null;
    }
    return (
      <Button
        action={hook.startPrintCardPage}
        title={buttonStrings.photo + '/' + buttonStrings.print}
        height={sizes.button.small}
        style={WhiteButtonStyle}
      />
    );
  };

  const didFetchEmployeeListSucceed = (response) => {
    setEmployees(response.employees);
  };

  useEffect(() => {
    fetchEmployeeList();
  }, []);

  return (
    <styled.Container>
      <styled.ContentLeadingContainer>
        {renderBuildingNameTitle()}
        {renderAdvertisementStatus()}
        {renderCardCreator()}
        {renderBuildingId()}
        <styled.CardStatusContainer>
          <styled.CardStatusButton
            onClick={() => {
              onTapStatusButton(cardStatus.new);
            }}
            selectedColor={colors.black}
            isSelected={
              cardReducer.formState == formStates.read
                ? cardReducer.card.status == cardStatus.new
                : requestWriteCard.status == cardStatus.new
            }>
            {buttonStrings.new}
          </styled.CardStatusButton>
          <styled.CardStatusButton
            onClick={() => {
              onTapStatusButton(cardStatus.sale);
            }}
            selectedColor={colors.red.default}
            isSelected={
              cardReducer.formState == formStates.read
                ? cardReducer.card.status == cardStatus.sale
                : requestWriteCard.status == cardStatus.sale
            }>
            {buttonStrings.forSale}
          </styled.CardStatusButton>
          <styled.CardStatusButton
            onClick={() => {
              onTapStatusButton(cardStatus.pending);
            }}
            selectedColor={colors.gray.dark}
            isSelected={
              cardReducer.formState == formStates.read
                ? cardReducer.card.status == cardStatus.pending
                : requestWriteCard.status == cardStatus.pending
            }>
            {buttonStrings.pending}
          </styled.CardStatusButton>
        </styled.CardStatusContainer>
      </styled.ContentLeadingContainer>
      <styled.ContentTrailingContainer>
        {_renderPaginationView()}
        {renderDeleteButton()}
        {_renderCreateButton()}
        {_renderPrintButton()}
        {renderSaveButton()}
        {renderPreviewButton()}
        {renderEditButton()}
      </styled.ContentTrailingContainer>
    </styled.Container>
  );
}

CardPageContentTopMenu.propTypes = {
  onMoveToReadState: PropTypes.func,
  requestEditCard: PropTypes.object,
  selectedImage: PropTypes.object,
  onEditRequestChange: PropTypes.func,
  onStartSavingProperty: PropTypes.func,
  onShowSavingConfirmation: PropTypes.func,
};

CardPageContentTopMenu.defaultProps = {
  onMoveToReadState: () => {
    return;
  },
  onEditRequestChange: () => {
    return;
  },
  requestEditCard: new RequestCreateCard(),
  selectedImage: null,
  onStartSavingProperty: defaultValues.emptyFunction,
  onShowSavingConfirmation: () => {
    return;
  },
};

export default CardPageContentTopMenu;
