import React from 'react';
import PropTypes from 'prop-types';
import * as defaultValues from 'constants/defaultValue';
import * as styled from './TextInputModal.styled';
import TextInputModalPresenter from './TextInputModalPresenter';
import CloseIconSrc from 'assets/close_icon_black.png';
import ImageAlt from 'constants/imageAlt';
import TextInput from 'components/core/text-input/TextInput';
import { BorderedTextInputStyle } from 'components/core/text-input/styles';
import sizes from 'constants/sizes';
import Button from 'components/core/button/Button';
import { DarkGrayButtonStyle } from 'components/core/button/styles';
import { TextInputModalViewModel } from './TextInputModalViewModel';
import { observer } from 'mobx-react';

const TextInputModal = observer(
    (props) => {
        const presenter = TextInputModalPresenter();

        const renderSuffix = () => {
            if (props.viewModel.suffix.isEmpty()) { return null; }
            return (
                <styled.SuffixText>
                    { props.viewModel.suffix }
                </styled.SuffixText>
            )
        }

        return (
            <styled.Container>
                <styled.CloseButton
                    onClick={ presenter.dismissModal }>
                    <img
                        src={ CloseIconSrc }
                        alt={ ImageAlt.closeIcon } />
                </styled.CloseButton>
                <styled.FormContainer>
                    <styled.Title>
                        { props.viewModel.title }
                    </styled.Title>
                    <styled.TextInputContainer>
                        <TextInput
                            isAutoFocus={ true }
                            placeholder={ props.viewModel.placeholder }
                            height={ sizes.textInput.small }
                            style={ BorderedTextInputStyle }
                            onChange={ presenter.onInputChange }
                            type={ props.viewModel.textInputViewModel.type }
                            onTapEnter={ props.onTapEnter }
                            viewModel={ props.viewModel.textInputViewModel }
                            value={ presenter.value } />
                        { renderSuffix() }
                    </styled.TextInputContainer>
                    <Button
                        action={ presenter.primaryAction } 
                        height={ sizes.button.small }
                        viewModel={ props.viewModel.buttonViewModel }
                        style={ DarkGrayButtonStyle } />
                </styled.FormContainer>
            </styled.Container>
        )
    }
);

TextInputModal.propTypes = {
    placeholder: PropTypes.string,
    buttonTitle: PropTypes.string,
    onTapEnter: PropTypes.func,
    viewModel: PropTypes.object
};

TextInputModal.defaultProps = {
    title: defaultValues.emptyString,
    placeholder: defaultValues.emptyString,
    buttonTitle: defaultValues.emptyString,
    onTapEnter: defaultValues.emptyFunction,
    viewModel: new TextInputModalViewModel()
};
export default TextInputModal;