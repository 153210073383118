import React from 'react'
import * as styled from './CardPageContentInnerNote.styled';
import CardSection from 'components/CardSection/CardSection';
import titleStrings from 'localizations/titleStrings';
import { useSelector } from 'react-redux';

const CardPageContentInnerNote = () => {
    const cardReducer = useSelector(state => state.card);

    const renderContent = () => {
        return (
            <styled.Container>
                <styled.TextArea
                    disabled={ true }
                    value={ cardReducer.card.notes }/>
            </styled.Container>
        )
    }

    return (
        <CardSection
            title={ titleStrings.note }
            content={ renderContent() } />
    )
}

export default CardPageContentInnerNote