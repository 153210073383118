import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import * as styled from './ConfirmationModal.styled';
import Button from 'components/core/button/Button';
import sizes from 'constants/sizes';
import {
  DarkGrayButtonStyle,
  WhiteButtonStyle,
} from 'components/core/button/styles';
import Text from 'components/core/text/Text';
import HighlightedTextStyle from 'components/core/text/styles/HighlightedTextStyle';
import alignments from 'constants/alignments';
import { useDispatch } from 'react-redux';
import { commonActionTypes } from 'redux/types';
import defaultValues from 'constants/defaultValues';
import { ButtonViewModel } from 'components/core/button/ButtonViewModel';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import buttonStrings from 'localizations/buttonStrings';

const ConfirmationModal = observer((props) => {
  const reducer = useSelector((state) => state.confirmationModal);
  const buttonViewModel = new ButtonViewModel();
  const dispatch = useDispatch();

  const hideModal = () => {
    dispatch({
      type: commonActionTypes.HIDE_MODAL,
    });
  };

  const onTapSecondaryAction = () => {
    hideModal();
    props.secondaryAction();
  };

  const primaryAction = () => {
    props.primaryAction();
  };

  useEffect(() => {
    buttonViewModel.isLoading = reducer.isLoading;
  }, [reducer.isLoading]);

  return (
    <styled.Container>
      <Text
        alignment={alignments.center}
        value={props.title}
        style={HighlightedTextStyle}
      />
      <div className="grid grid-cols-2 gap-3 px-5">
        <Button
          action={onTapSecondaryAction}
          height={sizes.button.small}
          style={WhiteButtonStyle}
          title={props.secondaryButtonTitle}
        />
        <Button
          action={primaryAction}
          height={sizes.button.small}
          style={DarkGrayButtonStyle}
          viewModel={buttonViewModel}
          title={props.primaryButtonTitle}
        />
      </div>
    </styled.Container>
  );
});

ConfirmationModal.propTypes = {
  primaryAction: PropTypes.func,
  title: PropTypes.string,
  primaryButtonTitle: PropTypes.string,
  secondaryButtonTitle: PropTypes.string,
  secondaryAction: PropTypes.func,
};

ConfirmationModal.defaultProps = {
  title: defaultValues.emptyString,
  primaryButtonTitle: buttonStrings.no,
  secondaryButtonTitle: buttonStrings.yes,
  primaryAction: () => {
    return;
  },
  secondaryAction: () => {
    return;
  },
};

export default ConfirmationModal;
