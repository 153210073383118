import LocalizedStrings from 'react-localization';

export default new LocalizedStrings({
    en:{
        enterSearchKeyword: "Enter Search Keyword",
        selectDate: "Select Date",
        pleaseEnterNote: "Please Enter Note",
        distance: "Distance",
        createAddressPlaceholder: "Ex) 111-1.2.114 Nonhyeon-dong, Gangnam-gu, Seoul"
    },
    kr: {
        enterSearchKeyword: "검색어 입력",
        selectDate: "날짜선택",
        pleaseEnterNote: "메모를 입력하세요",
        distance: "거리",
        createAddressPlaceholder: "예) 서울시 강남구 논현동 111-1.2.114"
    }
});
