import * as defaultValues from "constants/defaultValue";
import { PriceInformation } from "models/objects/PriceInformation";
import { LandInformation } from "models/objects/LandInformation";
import { OwnerInformation } from "models/objects/OwnerInformation";
import { BuildingInformation } from "models/objects/BuildingInformation";
import { FloorInformation } from "models/objects/FloorInformation";
import { RentalHistory } from "models/objects/RentalHistory";
import { Employee } from "./Employee";
import { WorkHistory } from "./WorkHistory";
import { LandSpesificInformation } from "./LandSpesificInformation";
import unitExhanges from "constants/unitExhanges";

export class Card {
    constructor(
        id = defaultValues.emptyInt,
        buildingId = defaultValues.emptyString,
        address = defaultValues.emptyString,
        streetAddress = defaultValues.emptyString,
        buildingName = defaultValues.emptyString,
        nearestStation = defaultValues.emptyString,
        distanceStation = defaultValues.emptyInt,
        notes = defaultValues.emptyString,
        views = defaultValues.emptyInt,
        manager = new Employee(),
        isAdvertisementAllowed = false,
        officialPricePy = defaultValues.emptyInt,
        priceInformation = new PriceInformation(),
        landInformation = new LandInformation(),
        ownerInformation = new OwnerInformation(),
        buildingInformation = new BuildingInformation(),
        workHistories = [],
        landSpesificInformations = [],
        floorInfos = [],
        rentalHistories = [],
        images = [],
        status = defaultValues.emptyString
    ) {
        this.id = id;
        this.buildingId = buildingId;
        this.address = address;
        this.streetAddress = streetAddress;
        this.buildingName = buildingName;
        this.nearestStation = nearestStation;
        this.distanceStation = distanceStation;
        this.notes = notes;
        this.views = views;
        this.manager = manager;
        this.isAdvertisementAllowed = isAdvertisementAllowed;
        this.officialPricePy = officialPricePy;
        this.priceInformation = priceInformation;
        this.landInformation = landInformation;
        this.ownerInformation = ownerInformation;
        this.buildingInformation = buildingInformation;
        this.workHistories = workHistories;
        this.landSpesificInformations = landSpesificInformations;
        this.floorInfos = floorInfos;
        this.rentalHistories = rentalHistories;
        this.images = images;
        this.status = status;
    }

    mapFromJson(json) {
        if (!json) { return this; }
        this.id = json['id'].toString();
        this.buildingId = json['building_id'];
        this.buildingName = json['building_name'];
        this.nearestStation = json['nearest_station'];
        this.distanceStation = json['distance_station'];
        this.notes = json['notes'];
        this.views = json['views'];
        this.status = json['status'];
        this.manager = new Employee().mapFromJson(json['manager']);
        this.address = json['address'];
        this.streetAddress = json['street_address'];
        this.isAdvertisementAllowed = json['advertisement'];
        this.priceInformation = new PriceInformation().mapFromJson(json['price_information']);
        this.landInformation = new LandInformation().mapFromJson(json['land_information']);
        this.ownerInformation = new OwnerInformation().mapFromJson(json['owner_information']);
        this.buildingInformation = new BuildingInformation().mapFromJson(json['building_information']);
        this.images = json['images'];
        if (json['work_histories']) {
            this.workHistories = json['work_histories'].map(
                (workHistory) => new WorkHistory().mapFromJson(workHistory)
            )
        }
        if (json['land_specific_information']) {
            this.landSpesificInformations = json['land_specific_information'].map(
                (landSpesificInformation) => new LandSpesificInformation().mapFromJson(landSpesificInformation)
            )
        }
        if (json['floor_information']) {
            this.floorInfos = json['floor_information'].map(
                (floorInfo) => new FloorInformation().mapFromJson(floorInfo)
            )
        }
        if (json['rental_histories']) {
            this.rentalHistories = json['rental_histories'].map(
                (rentalHistory) => new RentalHistory().mapFromJson(rentalHistory)
            )
        }
        return this;
    }
}

Card.prototype.getRentalTotalArea = function() {
    return this.rentalHistories.reduce(
        ($0, $1) => {
            if ($1.isExcluded) {
                return $0;
            }
            if ($1.isAreaMergedDown) {
                return $0 + defaultValues.emptyInt;
            }
            return $0 + parseFloat($1.getRentalArea());
        },
        defaultValues.emptyInt
    ).rounded(2);
}

Card.prototype.getMonthlyRent = function() {
    return this.rentalHistories.reduce(
        ($0, $1) => {
            if ($1.isExcluded) {
                return $0;
            }
            if ($1.isMonthlyRentalMergedDown) {
                return $0 + defaultValues.emptyInt;
            }
            return $0 + parseFloat($1.getMonthlyRental());
        },
        defaultValues.emptyInt
    ).rounded(2);
}

Card.prototype.getRentalTotalDeposit = function() {
    return this.rentalHistories.reduce(
        ($0, $1) => {
            if ($1.isExcluded) {
                return $0;
            }
            if ($1.isDepositMergedDown) {
                return $0 + defaultValues.emptyInt;
            }
            return $0 + parseFloat($1.getDeposit());
        },
        defaultValues.emptyInt
    ).rounded(2);
}

Card.prototype.getRentalTotalAdminPrice = function() {
    return this.rentalHistories.reduce(
        ($0, $1) => {
            if ($1.isExcluded) {
                return $0;
            }
            if ($1.isAdminPriceMergedDown) {
                return $0 + defaultValues.emptyInt;
            }
            return $0 + parseFloat($1.getAdminFee());
        },
        defaultValues.emptyInt
    ).rounded(2);
}

Card.prototype.getRentalTotalRentPerPy = function() {
    return (this.getMonthlyRent() / this.getRentalTotalArea()).rounded(2)
}

Card.prototype.getLandArea = function() {
    return this.landSpesificInformations.reduce(
        ($0, $1) => $0 + $1.getArea(),
        defaultValues.emptyInt
    )
}

Card.prototype.getFloorArea = function() {
    return this.floorInfos.reduce(
        ($0, $1) => {
            if ($1.isExcluded) {
                return $0;
            }
            if ($1.areaMergedDown) {
                return $0 + defaultValues.emptyInt;
            }
            return $0 + parseFloat($1.getArea());
        },
        defaultValues.emptyInt
    ).rounded(2)
}

Card.prototype.getPricePerTotalArea = function() {
    return (this.priceInformation.totalPrice / this.buildingInformation.totalAreaInPY).rounded(0);
}

Card.prototype.getPricePerPy = function() {
    const landAreaPy = this.getLandArea() * unitExhanges.meterSquareToPyeong;
    return (this.priceInformation.totalPrice / landAreaPy).rounded(0);
}