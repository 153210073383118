import { useSelector } from 'react-redux';
import jsPDF from 'jspdf';
import defaultValues from 'constants/defaultValues';
import { useState } from 'react';
import * as propertyPrintPages from 'enumerations/propertyPrintPages';
import documentService from 'services/documentService';
import * as printPageActions from 'pages/PrintCardPage/printCardReducer';
import html2canvas from 'html2canvas';
import { RequestUpdateOrder } from 'models/requests/RequestUpdateOrder';
import { useParams } from 'react-router';
import {
  RequestDeleteImageDocument,
  RequestAddImageDocument,
  RequestUpdatePrintStatus,
} from 'models/requests';
import ImageDocument from 'models/objects/ImageDocument';

const usePrintCardForm = () => {
  const {
    property,
    pages,
    rentalHistoryPages,
    floorInfoPages,
    imagePages,
    checkedImagePages,
    isAddingImage,
  } = useSelector((state) => state.printProperty);
  const { propertyId } = useParams();
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
  const pdf = jsPDF('l', 'px', 'a4');
  const [isDragDisabled, setIsDragDisabled] = useState(false);

  const printPages = async () => {
    setIsGeneratingPdf(true);
    /// Remove default page from jsPDF
    pdf.deletePage(1);
    for (const [index, page] of pages.entries()) {
      if (page.isExcluded) {
        continue;
      }
      if (page.type == propertyPrintPages.rentalHistory) {
        await _generateRentalHistoryPage();
        continue;
      }
      if (page.type == propertyPrintPages.floorInfo) {
        await _generateFloorInfoPage();
        continue;
      }
      pdf.addPage();
      await _generatePage(index, page);
    }
    setIsGeneratingPdf(false);
    const priceInThousand =
      Number(property.priceInformation.totalPrice) / 10000;
    await pdf.save(priceInThousand + '억_' + property.address + '.pdf', {
      returnPromise: true,
    });
  };

  const _generatePage = async (index, page) => {
    const parentElement = document.querySelector(`[data-id="${page.key}"]`);
    parentElement.style.position = 'relative';
    const element = document
      .querySelector(`[data-id="${page.key}"]`)
      .cloneNode(true);
    element.style.position = 'absolute';
    element.style.scale =
      1075 / (1075 * Number(getComputedStyle(parentElement).scale));
    parentElement.insertBefore(element, parentElement.firstChild);
    const canvas = await html2canvas(element, {
      scale: 3,
      allowTaint: false,
      useCORS: true,
    });
    parentElement.removeChild(element);
    const pageWidth = pdf.internal.pageSize.getWidth();
    const imgProps = pdf.getImageProperties(canvas);
    pdf.addImage(
      canvas,
      'JPG',
      defaultValues.emptyInt,
      defaultValues.emptyInt,
      pageWidth,
      (imgProps.height * pageWidth) / imgProps.width,
      page.key
    );
  };

  const _generateRentalHistoryPage = async () => {
    for (const [index, page] of rentalHistoryPages.entries()) {
      pdf.addPage();
      await _generatePage(index, page);
    }
  };

  const _generateFloorInfoPage = async () => {
    for (const [index, page] of floorInfoPages.entries()) {
      pdf.addPage();
      await _generatePage(index, page);
    }
  };

  const onDeleteImagePage = () => {
    printPageActions.removePrintPages();
    printPageActions.resetCheckedImage();

    const request = new RequestDeleteImageDocument();
    request.propertyId = propertyId;
    request.ids = checkedImagePages;
    documentService.deleteImageDocument(
      request,
      () => {
        return;
      },
      () => {
        return;
      }
    );
  };

  const onDragStart = (task) => {
    if (task.draggableId !== defaultValues.emptyInt.toString()) {
      setIsDragDisabled(false);
      return;
    }
    setIsDragDisabled(true);
  };

  const changeInputOrder = (item) => {
    if (!item.destination) return;
    const [reorderedItem] = pages.splice(item.source.index, 1);
    pages.splice(item.destination.index, 0, reorderedItem);
    const pagesOrder = pages.map((page) => {
      if (page.type == propertyPrintPages.image) {
        let imagePage = imagePages.find(
          (imagePage) => page.key === imagePage.id
        );
        return '{0}{1}'.format(propertyPrintPages.image, imagePage.id);
      }
      return page.type;
    });
    printPageActions.handleUpdatePageOrder(pages);
    documentService.updatePageOrder(
      new RequestUpdateOrder(property.id, pagesOrder),
      () => {
        return;
      }
    );
  };

  const onUpdatePrintStatus = (type, setting) => {
    printPageActions.updatePropertyStatus(type, setting.key);

    let key = defaultValues.emptyString;
    switch (type) {
      case propertyPrintPages.summary:
        key = 'summary';
        break;
      case propertyPrintPages.floorInfo:
        key = 'floor';
        break;
      case propertyPrintPages.rentalHistory:
        key = 'rental';
        break;
      default:
        break;
    }

    const request = new RequestUpdatePrintStatus();
    request.propertyId = propertyId;
    request.key = key;
    request.isIncluded = setting.key == defaultValues.emptyInt;

    documentService.updatePrintStatus(
      request,
      () => {
        return;
      },
      () => {
        return;
      }
    );
  };

  const onTapAddImageButton = () => {
    printPageActions.addImageDocument();

    const request = new RequestAddImageDocument();
    request.propertyId = propertyId;
    request.image = new ImageDocument();
    documentService.addImageDocument(
      request,
      (response) => {
        // Update page order immediately
        const pagesOrders = pages.map((page) => {
          if (page.type == propertyPrintPages.image) {
            let imagePage = imagePages.find(
              (imagePage) => page.key === imagePage.id
            );
            return '{0}{1}'.format(propertyPrintPages.image, imagePage.id);
          }
          return page.type;
        });
        const _pageOrders = [
          ...pagesOrders,
          '{0}{1}'.format(propertyPrintPages.image, response.imageDocument.id),
        ];
        documentService.updatePageOrder(
          new RequestUpdateOrder(property.id, _pageOrders),
          () => {
            printPageActions.didImageAddedSucceed(response.imageDocument);
          }
        );
      },
      () => {
        printPageActions.didImageAddedFailed();
      }
    );
  };

  return {
    printPages,
    isAddingImage,
    isDragDisabled,
    isGeneratingPdf,
    onDeleteImagePage,
    onDragStart,
    changeInputOrder,
    onUpdatePrintStatus,
    onTapAddImageButton,
  };
};

export default usePrintCardForm;
