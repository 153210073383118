import React, { useState } from 'react';
import Button from '../../components/core/button/Button';
import PropTypes from 'prop-types';
import {
    DarkGrayButtonStyle,
    WhiteButtonStyle
} from 'components/core/button/styles';
import Sizes from 'constants/sizes';
import { ButtonStrings, FieldStrings } from 'localizations';
import HorizontalTextInput from 'components/core/horizontal-text-input/HorizontalTextInput';
import tw from 'twin.macro';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { ButtonViewModel } from 'components/core/button/ButtonViewModel';
import defaultValues from 'constants/defaultValues';
import { useDispatch } from 'react-redux';
import { RequestFetchCustomerList } from 'models/requests';
import { customerActionTypes } from 'redux/types';
import { customerAction } from 'redux/actions';

const Container = styled.div`
    ${tw `flex flex-col mt-5 gap-y-3`}
`;

const ButtonContainer = styled.div`
    ${tw `grid grid-cols-2 gap-4`}
`;

const SearchCustomerForm = observer((props) => {
    const [buttonViewModel] = useState(new ButtonViewModel());
    const [name, setName] = useState(defaultValues.emptyString);
    const [mobilePhone, setMobilePhone] = useState(defaultValues.emptyString);
    const [companyPhone, setCompanyPhone] = useState(defaultValues.emptyString);
    const dispatch = useDispatch();

    const resetState = () => {
        setName(defaultValues.emptyString);
        setMobilePhone(defaultValues.emptyString);
        setCompanyPhone(defaultValues.emptyString);
        props.onResetState();
    }

    const onStartFetchCustomerList = () => {
        buttonViewModel.startLoading();
        dispatch({
            type: customerActionTypes.SEARCH_CUSTOMER_LIST,
            payload: customerAction.fetchCustomerList(
                new RequestFetchCustomerList(
                    defaultValues.emptyString,
                    name,
                    mobilePhone,
                    companyPhone
                ),
                didFetchCustomerListSucceed
            )
        })
    }

    const didFetchCustomerListSucceed = (response) => {
        buttonViewModel.stopLoading()
        props.onFetchCustomerSuccess(response.customers);
    }

    const renderNameInput = () => {
        return (
            <HorizontalTextInput
                name="name"
                title={ FieldStrings.representativeName }
                onChange={ (input) => setName(input.value) }
                value={ name }/>
        )
    }

    const renderPhoneInput = () => {
        return (
            <HorizontalTextInput
                name="phone"
                title={ FieldStrings.phone }
                onChange={ (input) => setMobilePhone(input.value) }
                value={ mobilePhone }/>
        )
    }

    const renderCompanyPhoneInput = () => {
        return (
            <HorizontalTextInput
                name="companyPhone"
                title={ FieldStrings.companyPhone }
                onChange={ (input) => setCompanyPhone(input.value) }
                value={ companyPhone }/>
        )
    }

    return (
        <Container>
            { renderNameInput() }
            { renderPhoneInput() }
            { renderCompanyPhoneInput() }
            <ButtonContainer>
                <Button
                    action={ resetState }
                    height={ Sizes.button.small }
                    style={ WhiteButtonStyle }
                    title={ ButtonStrings.reset } />
                <Button
                    action={ onStartFetchCustomerList }
                    height={ Sizes.button.small }
                    style={ DarkGrayButtonStyle }
                    title={ ButtonStrings.search }
                    viewModel={ buttonViewModel } />
            </ButtonContainer>
        </Container>
    )
});

SearchCustomerForm.propTypes = {
    name: PropTypes.string,
    isLoading: PropTypes.bool,
    onFetchCustomerSuccess: PropTypes.func,
    onResetState: PropTypes.func
};

SearchCustomerForm.defaultProps = {
    isLoading: false,
    onFetchCustomerSuccess: () => { return; },
    onResetState: () => { return; }
};

export default SearchCustomerForm;