import React from 'react';
import PropTypes from 'prop-types';
import * as styled from './PageSettingInput.styled';
import Text from 'components/core/text/Text';
import * as defaultValues from 'constants/defaultValue';
import alignments from 'constants/alignments';
import DropdownInput from 'components/core/dropdown-input/DropdownInput';
import { BorderedDropdownInputStyle } from 'components/core/dropdown-input/styles';
import usePageSettingInput from './usePageSettingInput';

const PageSettingInput = (props) => {
  const hook = usePageSettingInput(props.isExcluded, props.onChangeSetting);

  return (
    <styled.Container>
      <styled.SpacingWrapper />
      <styled.TitleWrapper>
        <Text
          alignment={alignments.center}
          value={props.title}
        />
      </styled.TitleWrapper>
      <styled.DropdownWrapper>
        <DropdownInput
          selectedOption={hook.selectedSetting}
          options={hook.printSettingOptions}
          handleSelectOption={hook.onSelectSetting}
          style={BorderedDropdownInputStyle}
        />
      </styled.DropdownWrapper>
    </styled.Container>
  );
};

PageSettingInput.propTypes = {
  title: PropTypes.string,
  isExcluded: PropTypes.bool,
  onChangeSetting: PropTypes.func,
};

PageSettingInput.defaultProps = {
  title: defaultValues.emptyString,
  isExcluded: false,
  onChangeSetting: () => {
    return;
  },
};

export default PageSettingInput;
