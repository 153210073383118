import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/core/button/Button';
import * as styled from './EmployeePageHeader.styled';
import * as defaultValues from 'constants/defaultValue';
import sizes from 'constants/sizes';
import TrashIconBlack from 'assets/trash_icon_black.png';
import { WhiteButtonStyle } from 'components/core/button/styles';
import buttonStrings from 'localizations/buttonStrings';
import SearchEmployeeForm from 'layouts/forms/SearchEmployeeForm';
import PaginationView from 'components/PaginationView/PaginationView';
import { Pagination } from 'models/objects/Pagination';

const EmployeePageHeader = (props) => {
    const _renderPagination = () => {
        return (
            <PaginationView
                page={ props.pagination.currentPage }
                totalPages={ props.pagination.totalPages }
                onChange={ props.onPageChange }/>
        )
    }

    return (
        <styled.ActionWrapper>
            <styled.ButtonWrapper>
                <Button
                    action={ props.onStartDelete }
                    height={ sizes.button.small }
                    icon={ TrashIconBlack }
                    style={ WhiteButtonStyle }
                    title={ buttonStrings.delete } />
                { props.customButtons }
                { _renderPagination() }
            </styled.ButtonWrapper>
            <SearchEmployeeForm onSubmit={ props.onStartSearchAction } />
        </styled.ActionWrapper>
    )
}

EmployeePageHeader.propTypes = {
    customButtons: PropTypes.array,
    onStartDelete: PropTypes.func,
    onStartSearchAction: PropTypes.func,
    onPageChange: PropTypes.func,
    pagination: PropTypes.object
};

EmployeePageHeader.defaultProps = {
    customButtons: defaultValues.emptyArray,
    onStartDelete: defaultValues.emptyFunction,
    onStartSearchAction: defaultValues.emptyFunction,
    onPageChange: defaultValues.emptyFunction,
    pagination: new Pagination()
};

export default EmployeePageHeader;