import styled from 'styled-components';
import tw from "twin.macro";
import zIndex from "constants/zIndex";

const Wrapper = styled.div`
    height: 100px;
    width: 100%;
    z-index: ${ zIndex.navigationBar };
    ${tw `fixed flex bg-white`}
`;

const LogoImage = styled.img`
    width: 250px;
`;

const VerticalLine = styled.div`
    ${tw `h-full w-px bg-secondary`}
`;

export {
    Wrapper,
    LogoImage,
    VerticalLine
}