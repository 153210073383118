import {
    employeeActionTypes
} from 'redux/types';
import {
    ResponseFetchEmployeeList
} from "models/responses";
import employeeService from 'redux/actions/employeeAction';
import { store } from 'configs/store';
import * as defaultValues from 'constants/defaultValue';
import { RequestFetchEmployeeList } from 'models/requests';
import { Employee } from 'models/objects/Employee';
import { Pagination } from 'models/objects/Pagination';

const SELECT_EMPLOYEE = "employee/SELECT_EMPLOYEE";
const FLUSH_DATA = "employee/FLUSH_DATA";
const CHECK_EMPLOYEE = "employee/CHECK_EMPLOYEE";
const FETCH_EMPLOYEE_LIST = "employee/FETCH_EMPLOYEE_LIST";
const UPDATE_EMPLOYEE_MEMO = "employee/UPDATE_EMPLOYEE_MEMO";
const ON_CHANGE_PAGE = "employee/ON_CHANGE_PAGE";
const FLUSH_PAGINATION = "employee/FLUSH_PAGINATION";

const states = (state, action) => {
    return {
        [employeeActionTypes.FETCH_EMPLOYEE_LIST]: () => {
            return {
                ...state,
                responseFetchEmployeeList: action.payload
                    ? new ResponseFetchEmployeeList().mapFromJson(action.payload.data)
                    : new ResponseFetchEmployeeList(),
                error: action.error ? action.payload : null
            }
        },
        [SELECT_EMPLOYEE]: () => {
            return {
                ...state,
                employee: action.payload
            }
        },
        [FETCH_EMPLOYEE_LIST]: () => {
            return {
                ...state,
                requestFetchEmployees: action.payload.request,
                employees: action.payload.employees,
                pagination: action.payload.pagination
            }
        },
        [FLUSH_DATA]: () => {
            return {
                ...state,
                employees: defaultValues.emptyArray,
                checkedEmployees: defaultValues.emptyArray
            }
        },
        [CHECK_EMPLOYEE]: () => {
            return {
                ...state,
                checkedEmployees: action.payload
            }
        },
        [UPDATE_EMPLOYEE_MEMO]: () => {
            return {
                ...state,
                employees: action.payload
            }
        },
        [ON_CHANGE_PAGE]: () => {
            return {
                ...state,
                pagination: action.payload
            }
        },
        [FLUSH_PAGINATION]: () => {
            return  {
                pagination: new Pagination()
            }
        }
    }
}

export default (state = {}, action) => {
    if (!(action.type in states(state, action))) {
        return {
            ...state,
            requestFetchEmployees: state.requestFetchEmployees || new RequestFetchEmployeeList(),
            employee: state.employee || new Employee(),
            employees: state.employees || defaultValues.emptyArray,
            pagination: state.pagination || new Pagination(),
            checkedEmployees: state.checkedEmployees || defaultValues.emptyArray,
        }
    }
    return states(state, action)[action.type]();
}

export const selectEmployee = (employee) => {
    store.dispatch(
        {
            type: SELECT_EMPLOYEE,
            payload: employee
        }
    )
}

export const reloadData = (
    request = store.getState().employee.requestFetchEmployees
) => {
    store.dispatch(
       { type: FLUSH_DATA }
    )
    fetchEmployeeList(request);
}

export const flushPagination = () => {
    store.dispatch(
        {
            type: FLUSH_PAGINATION
        }
    )
}

export const fetchEmployeeList = (request) => {
    employeeService.fetchEmployeeList(
        request,
        (response) => store.dispatch(
            {
                type: FETCH_EMPLOYEE_LIST,
                payload: {
                    request: request,
                    employees: response.employees,
                    pagination: response.pagination
                }
            }
        )
    )
    
}

export const checkEmployee = (employees) => {
    store.dispatch(
        {
            type: CHECK_EMPLOYEE,
            payload: employees
        }
    )
}

export const updateEmployeeMemo = (employeeId, memo) => {
    let employees = store.getState().employee.employees;
    let index = store.getState().employee.employees.findIndex(
        $0 => $0.id == employeeId
    );
    if (!employees.isValidIndex(index)) { return; }
    employees[index].memo = memo
    store.dispatch(
        {
            type: UPDATE_EMPLOYEE_MEMO,
            payload: employees
        }
    )
}

export const onChangePage = (page) => {
    const pagination = store.getState().employee.pagination;
    pagination.currentPage = page;
    store.dispatch(
        {
            type: ON_CHANGE_PAGE,
            payload: Object.assign(
                new Pagination(),
                pagination
            )
        }
    )
    const request = store.getState().employee.requestFetchEmployees;
    request.pagination.page = page;
    reloadData(
        Object.assign(
            new RequestFetchEmployeeList(),
            request
        )
    )
}