import styled from "styled-components";
import tw from "twin.macro";

const Container = styled.div`
    ${ tw`grid grid-cols-2 gap-x-5 gap-y-3 py-4 w-full` }
`;
const FieldContainer = styled.div`
${ tw`grid grid-cols-2 col-span-2` }
`;
const MonthlyInterestWrapper = styled.div`
${ tw`col-span-2 auto-cols-max` }
`;

export {
    Container,
    FieldContainer,
    MonthlyInterestWrapper
}