import React from 'react';
import PropTypes from 'prop-types';
import CardSection from 'components/CardSection/CardSection';
import titleStrings from 'localizations/titleStrings';
import * as styled from './PriceInformations.styled';
import fieldStrings from 'localizations/fieldStrings';
import colors from 'constants/colors';
import HorizontaLabeledText from 'components/HorizontalLabeledText/HorizontalLabeledText';
import EditIcon from 'assets/edit_icon.png';
import { connect, useSelector } from 'react-redux';
import { Card } from 'models/objects/Card';
import PriceInformationsHook from './PriceInformationsHook';
import cardReducer from 'redux/reducers/cardReducer';

const mapStateToProps = (state) => ({
  ...state.card,
});

const PriceInformations = (props) => {
  const hook = PriceInformationsHook();
  const cardReducer = useSelector((state) => state.card);

  const renderPaidLabeledText = () => {
    if (!props.isAdmin) {
      return null;
    }
    return (
      <HorizontaLabeledText
        title={fieldStrings.paid}
        values={[props.card.priceInformation.paidPrice.tenThousandFormat()]}
      />
    );
  };

  const renderSalePrice = () => {
    return (
      <styled.InfoContainer>
        <styled.TitleLabel>{fieldStrings.salePrice}</styled.TitleLabel>
        <styled.ValueContainer>
          <styled.ValueLabel>
            {cardReducer.price.tenThousandFormat()}
          </styled.ValueLabel>
          <styled.EditPriceButton onClick={hook.showEditPriceModal}>
            <styled.EditIcon src={EditIcon} />
          </styled.EditPriceButton>
        </styled.ValueContainer>
      </styled.InfoContainer>
    );
  };

  const renderContent = () => {
    return (
      <styled.Container>
        <styled.TopContainer>
          {renderPaidLabeledText()}
          {renderSalePrice()}
          <HorizontaLabeledText
            title={fieldStrings.landPrice}
            values={[hook.getPricePerArea()]}
          />
          <HorizontaLabeledText
            title={fieldStrings.pricePerPY}
            values={[hook.getPricePerTotalArea()]}
          />
        </styled.TopContainer>
        <styled.BottomContainer>
          <HorizontaLabeledText
            title={fieldStrings.revenue}
            isValueBold={true}
            values={[hook.getYearlyRevenue() + '%']}
          />
          <HorizontaLabeledText
            title={fieldStrings.deposit}
            values={[props.card.priceInformation.deposit.tenThousandFormat()]}
          />
          <HorizontaLabeledText
            title={fieldStrings.monthlyRent}
            values={[
              props.card.priceInformation.monthlyRate.tenThousandFormat(),
            ]}
          />
          <HorizontaLabeledText
            title={fieldStrings.managementFee}
            values={[
              props.card.priceInformation.adminPrice.tenThousandFormat(),
            ]}
          />
          <HorizontaLabeledText
            title={fieldStrings.totalIncome}
            values={[
              props.card.priceInformation.monthlyIncome.tenThousandFormat(),
              `[관리지출: ${props.card.priceInformation.adminRevenueRate.tenThousandFormat()}]`,
            ]}
          />
          <HorizontaLabeledText
            title={fieldStrings.loanPrice}
            values={[props.card.priceInformation.loanPrice.tenThousandFormat()]}
          />
        </styled.BottomContainer>
      </styled.Container>
    );
  };

  return (
    <CardSection
      title={titleStrings.priceInfo}
      content={renderContent()}
    />
  );
};

PriceInformations.propTypes = {
  isAdmin: PropTypes.bool,
  card: PropTypes.object,
};

PriceInformations.defaultProps = {
  isAdmin: false,
  card: new Card(),
};

export default connect(mapStateToProps, null)(PriceInformations);
