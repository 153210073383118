import React, { Component } from 'react'
import styled from 'styled-components';
import tw from "twin.macro"
import Modal from 'components/core/modal/Modal';
import PropTypes from 'prop-types';
import {
    titleStrings
} from 'localizations/index'
import defaultValues from 'constants/defaultValues';
import zIndex from 'constants/zIndex';

class AlertDialogModal extends Component {
    static get propTypes() { 
        return {
            isShowing: PropTypes.bool,
            title: PropTypes.string,
            subtitle: PropTypes.string,
            primaryButton: PropTypes.element,
            secondaryButton: PropTypes.element,
            onDismiss: PropTypes.func,
            zIndex: PropTypes.number
        }; 
    }

    static defaultProps = {
        isShowing: false,
        subtitle: defaultValues.emptyString,
        primaryButton: null,
        secondaryButton: null,
        onDismiss: () => {
            return;
        },
        zIndex: zIndex.defaultModal
    };

    customerListHeaders = [
        titleStrings.createdAt,
        titleStrings.name,
        titleStrings.phone,
        titleStrings.companyPhone,
        titleStrings.manager
    ];

    onDismiss = () => {
        this.props.onDismiss();
        this.hideModal();
    }

    renderSubtitleText = () => {
        const Subtitle = styled.div`
            ${tw `mb-5`}
        `;
        if (this.props.subtitle == defaultValues.emptyString) {
            return;
        }
        return (
            <Subtitle>
                { this.props.subtitle }
            </Subtitle>
        )
    }

    render() {
        const Container = styled.div`
            width: 400px;
            max-width: 90%;
            max-height: 90%;
            ${tw `flex flex-col m-auto bg-white p-8`}
        `;

        const Title = styled.div`
            ${tw `font-bold text-xl mb-3`}
        `;

        const ButtonContainer = styled.div`
            ${
                () => {
                    if (this.props.primaryButton 
                            && this.props.secondaryButton) {
                        return tw `grid gap-4 grid-cols-2`;
                    }
                    return tw `grid gap-4`;
                }
            }
            
        `;

        const PrimaryButton = () => {
            return this.props.primaryButton;
        }
        const SecondaryButton = () => {
            return this.props.secondaryButton;
        }

        const content = (
            <Container>
                <Title>
                    { this.props.title }
                </Title>
                { this.renderSubtitleText() }
                <ButtonContainer>
                    <PrimaryButton />
                    <SecondaryButton />
                </ButtonContainer>
            </Container>
        )

        return (
            <Modal
                zIndex={ this.props.zIndex }
                content={ content }
                isShow={ this.props.isShowing } />
        );
    }
}

export default AlertDialogModal;