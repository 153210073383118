import defaultValues from "constants/defaultValues";

export class RequestUpdateCustomerManager {
    constructor(
        customerIds = [],
        newManagerId = defaultValues.emptyInt
    ) {
        this.customerIds = customerIds;
        this.newManagerId = newManagerId;
    }

    mapToJson() {
        return {
            'ids': this.customerIds,
            'manager_id': this.newManagerId
        }
    }
}