import { combineReducers, configureStore } from '@reduxjs/toolkit';
import reducers from 'redux/reducers';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import {
    authMiddleware,
    promiseMiddleware
} from 'middlewares';
import { connectRouter } from 'connected-react-router';

export const history = createBrowserHistory();
export const store = configureStore({
    reducer: combineReducers({
        router: connectRouter(history),
        ...reducers
    }),
    middleware: () => {
        if (process.env.NODE_ENV === 'production') {
            return [
                routerMiddleware(history),
                promiseMiddleware,
                authMiddleware
            ]
        } else {
            return [
                routerMiddleware(history),
                promiseMiddleware,
                authMiddleware
            ]
        }
    }
});