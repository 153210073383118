import { Employee } from "models/objects/Employee";
import { Pagination } from "models/objects/Pagination";

export class ResponseFetchEmployeeList {
    constructor(
        employees = [],
        pagination = new Pagination()
    ) {
        this.employees = employees;
        this.pagination = pagination;
    }

    mapFromJson(json) {
        if (!json) { return this; }
        this.employees = json.data.map(
            (employeeJson) => new Employee().mapFromJson(employeeJson)
        )
        this.pagination = new Pagination(
            json['currentPage'],
            json['totalItems'],
            json['totalPages']
        );
        return this;
    }
}