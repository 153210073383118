import React from 'react';
import PropTypes from 'prop-types';
import TableList from 'components/core/table-list/TableList';
import { PrimaryTableListStyle } from 'components/core/table-list/styles';
import * as styled from './SearchResultLayout.styled';
import PaginationView from 'components/PaginationView/PaginationView';
import useSearchResultLayout from './useSearchResultLayout';
import { useSelector } from 'react-redux';

const SearchResultLayout = (props) => {
    const hook = useSearchResultLayout();
    const searchReducer = useSelector(state => state.search);

    const renderTableList = () => {
        return (
            <TableList
                items={ hook.setupPropertyTableItems() }
                headers={ props.presenter.tableHeaders }
                onDoubleClickRow={ hook.onSelectProperty }
                style={ PrimaryTableListStyle }  />
        )
    }

    return (
        <div>
            <styled.HeaderContainer>
                <styled.TotalItemsTitle>
                    { `검색결과 총 ${ searchReducer.pagination.totalItems }개` }
                </styled.TotalItemsTitle>
                <PaginationView
                    onChange={ hook.didPageChanged }
                    page={ searchReducer.currentPage }
                    totalPages={ searchReducer.pagination.totalPages }/>
            </styled.HeaderContainer>
            { renderTableList() }
        </div>
    )
}

SearchResultLayout.propTypes = {
    presenter: PropTypes.object
};

SearchResultLayout.defaultProps = {
    presenter: null
};

export default SearchResultLayout;