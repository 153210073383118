import styled from 'styled-components';
import tw from 'twin.macro';

const Wrapper = styled.div`
    ${tw`
        w-full
        h-full
        flex items-center justify-center
        z-[0]
    `}
`;




export {
    Wrapper
}