import defaultValues from "constants/defaultValues";
import {
    commonActionTypes,
    otherActionTypes
} from 'redux/types';

const states = (state, action) => {
    return {
        [otherActionTypes.INQUIRY_IP_ADDRESS]: () => {
            return {
                ...state,
                ipAddress: action.payload
                    ? action.payload.ip
                    : defaultValues.emptyString,
                errors: action.error
                    ? action.payload.errors
                    : null
            }
        },
        [commonActionTypes.RESET]: () => {
            return {
                ...state,
                ipAddress: defaultValues.emptyString
            }
        }
    }
}

export default (state = {}, action) => {
    if (!(action.type in states(state, action))) {
        return state;
    }
    return states(state, action)[action.type]();
}