import defaultValues from "constants/defaultValues";
import { useEffect, useState } from "react";

const useFileInput = (props) => {
    const [fileName, setFileName] = useState(defaultValues.emptyString)

    const onFileChange = (event) => {
        if (event.target.files[0] == undefined) { return; }
        const file = event.target.files[0];
        if (file.size >= 5000000) {
            props.onError("이미지 용량을 줄여주세요 (최대 : 5mb)")
            return;
        }
        setFileName(file.name);
        props.onFileChange(file);
        return;
    }

    useEffect(
        () => {
            setFileName(
                !props.fileUrl.isEmpty()
                    ? props.fileUrl.split("/").last()
                    : "이미지 첨부 or image_name"
            )
        },
        [props.fileUrl]
    )

    return {
        onFileChange,
        fileName
    }
}

export default useFileInput;