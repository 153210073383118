import { Card } from "models/objects/Card";
import { Pagination } from "models/objects/Pagination";

export class ResponseInquiryCard {
    constructor(
        card = new Card(),
        pagination = new Pagination()
    ) {
        this.card = card;
        this.pagination = pagination;
    }

    mapFromJson(json) {
        if (!json && !json['data']) { return this; }
        this.card = new Card().mapFromJson(json['data']);
        this.pagination = new Pagination(
            json['currentPage'],
            json['totalItems'],
            json['totalPages']
        );
        return this;
    }
}