import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import CardSection from 'components/CardSection/CardSection';
import * as styled from './LeaseForm.styled';
import titleStrings from 'localizations/titleStrings';
import HorizontalTextInput from 'components/core/horizontal-text-input/HorizontalTextInput';
import fieldStrings from 'localizations/fieldStrings';
import operators from 'constants/operators';
import CheckboxInput from 'components/core/input-checkbox/CheckboxInput';
import fieldNames from 'constants/fieldNames';
import defaultValues from 'constants/defaultValues';
import textInputType from 'enumerations/textInputType';
import { useSelector } from 'react-redux';
import useLeaseFormPresenter from './useLeaseFormPresenter';
import cookiesManager from 'managers/cookies.manager';

const LeaseForm = (props) => {
  const cardReducer = useSelector((state) => state.card);
  const requestWriteCard = useSelector((state) => state.card.requestWriteCard);
  const hook = useLeaseFormPresenter();

  const handleInputValueChange = (input) => {
    const priceInformation = requestWriteCard.priceInformation;
    priceInformation[input.name] = input.value;
    props.handleInputValueChange({
      name: fieldNames.priceInformation,
      value: priceInformation,
    });
  };

  const setupDeposit = () => {
    return requestWriteCard.getTotalRentalDeposit().rounded(0);
  };

  const setupMonthlyRevenue = () => {
    return (setupYearlyRevenue() / 12).rounded(1);
  };

  const setupMonthlyPrice = () => {
    return requestWriteCard.getTotalMonthlyRentalPrice().rounded(0);
  };

  const setupYearlyRevenue = () => {
    const priceInformation = requestWriteCard.priceInformation;
    const loanPrice = requestWriteCard.priceInformation.loanPrice;
    const rentalPrice = Number(requestWriteCard.priceInformation.price);
    const yearlyIncome = hook.getMonthlyIncome() * 12;
    let income = rentalPrice - setupDeposit();
    if (priceInformation.isMonthlyInterest) {
      income = income - loanPrice;
    }
    if (income.isEmpty()) {
      return defaultValues.emptyInt;
    }
    return ((yearlyIncome / income) * 100).rounded(2);
  };

  const setupTotalPaidPrice = () => {
    const price = requestWriteCard.priceInformation.getRentalPrice();
    const deposit = setupDeposit();
    const loanPrice = requestWriteCard.priceInformation.loanPrice;
    return requestWriteCard.priceInformation.isMonthlyInterest
      ? (price - deposit - loanPrice).rounded(0)
      : (price - deposit).rounded(0);
  };

  const setupMonthlyInterest = () => {
    const loanPrice = requestWriteCard.priceInformation.getLoanPrice();
    const interest = requestWriteCard.priceInformation.getInterestRate();
    return ((loanPrice * (interest / 100)) / 12).rounded(1);
  };

  const setupLoanRate = () => {
    const price = requestWriteCard.priceInformation.getRentalPrice();
    const loanPrice = requestWriteCard.priceInformation.loanPrice;
    return ((loanPrice / price) * 100).rounded(2);
  };

  const renderPaidInput = () => {
    if (cookiesManager.getIsPartner()) {
      return <div />;
    }
    return (
      <HorizontalTextInput
        title={fieldStrings.paid}
        titleColumn={1}
        inputColumn={1}
        suffixText={fieldStrings.tenThousand}
        type={textInputType.currency}
        name={fieldNames.paid}
        onChange={handleInputValueChange}
        value={requestWriteCard.priceInformation.paid}
      />
    );
  };

  const renderContent = () => {
    return (
      <styled.Container>
        {renderPaidInput()}
        <HorizontalTextInput
          title={fieldStrings.totalFloorAreaInPyeong}
          titleColumn={1}
          inputColumn={1}
          suffixText={fieldStrings.tenThousand}
          isDisabled={true}
          type={textInputType.currency}
          value={`${requestWriteCard.pricePerLandArea()}`}
        />
        <HorizontalTextInput
          title={fieldStrings.salePrice}
          titleColumn={1}
          inputColumn={1}
          suffixText={fieldStrings.tenThousand}
          type={textInputType.currency}
          name={fieldNames.price}
          onChange={handleInputValueChange}
          value={requestWriteCard.priceInformation.price}
        />
        <HorizontalTextInput
          title={fieldStrings.purchaseFunds}
          titleColumn={1}
          inputColumn={1}
          suffixText={fieldStrings.tenThousand}
          isDisabled={true}
          type={textInputType.currency}
          value={setupTotalPaidPrice().toString()}
        />
        <HorizontalTextInput
          title={fieldStrings.deposit}
          titleColumn={1}
          inputColumn={1}
          suffixText={fieldStrings.tenThousand}
          isDisabled={true}
          type={textInputType.currency}
          value={setupDeposit().toString()}
        />
        <HorizontalTextInput
          title={fieldStrings.monthlyROI}
          titleColumn={1}
          inputColumn={1}
          suffixText={operators.percent}
          isDisabled={true}
          type={textInputType.currency}
          value={setupMonthlyRevenue().toString()}
        />
        <HorizontalTextInput
          title={fieldStrings.monthlyPrice}
          titleColumn={1}
          inputColumn={1}
          suffixText={fieldStrings.tenThousand}
          isDisabled={true}
          type={textInputType.currency}
          value={setupMonthlyPrice().toString()}
        />
        <HorizontalTextInput
          title={fieldStrings.annualROI}
          titleColumn={1}
          inputColumn={1}
          suffixText={operators.percent}
          isDisabled={true}
          type={textInputType.currency}
          value={setupYearlyRevenue().toString()}
        />
        <HorizontalTextInput
          title={fieldStrings.managementFee}
          titleColumn={1}
          inputColumn={1}
          suffixText={fieldStrings.tenThousand}
          type={textInputType.currency}
          name={fieldNames.adminPrice}
          onChange={handleInputValueChange}
          isDisabled={true}
          value={requestWriteCard.getTotalRentalAdminFee()}
        />
        <HorizontalTextInput
          title={fieldStrings.managementIncome}
          titleColumn={1}
          inputColumn={1}
          suffixText={fieldStrings.tenThousand}
          type={textInputType.currency}
          name={fieldNames.adminRevenueRate}
          onChange={handleInputValueChange}
          value={requestWriteCard.priceInformation.adminRevenueRate}
        />
        <HorizontalTextInput
          title={fieldStrings.loanPrice}
          titleColumn={1}
          inputColumn={1}
          suffixText={fieldStrings.tenThousand}
          type={textInputType.currency}
          name={fieldNames.loanPrice}
          onChange={handleInputValueChange}
          value={requestWriteCard.priceInformation.loanPrice}
        />
        <HorizontalTextInput
          title={fieldStrings.interestRate}
          titleColumn={1}
          inputColumn={1}
          suffixText={operators.percent}
          type={textInputType.currency}
          name={fieldNames.interest}
          onChange={handleInputValueChange}
          value={requestWriteCard.priceInformation.interest}
        />
        <styled.MonthlyInterestWrapper>
          <HorizontalTextInput
            titleColumn={1}
            inputColumn={3}
            title={fieldStrings.monthlyInterest}
            suffixText={fieldStrings.tenThousand}
            isDisabled={true}
            type={textInputType.currency}
            value={setupMonthlyInterest().toString()}
            leadingView={
              <CheckboxInput
                checked={requestWriteCard.priceInformation.isMonthlyInterest}
                onChange={hook.onMonthlyInterestCheck}
              />
            }
          />
        </styled.MonthlyInterestWrapper>
        <HorizontalTextInput
          title={fieldStrings.monthlyIncome}
          titleColumn={1}
          inputColumn={1}
          suffixText={fieldStrings.tenThousand}
          isDisabled={true}
          type={textInputType.currency}
          value={hook.getMonthlyIncome()}
        />
        <HorizontalTextInput
          title={fieldStrings.debtRatio}
          titleColumn={1}
          inputColumn={1}
          suffixText={operators.percent}
          isDisabled={true}
          type={textInputType.currency}
          value={setupLoanRate().toString()}
        />
      </styled.Container>
    );
  };

  useEffect(() => {
    hook.setRequestWriteCard(requestWriteCard);
  }, [requestWriteCard]);

  useEffect(() => {
    hook.setExcludedRentalHistories(props.excludedRentalHistories);
  }, [props.excludedRentalHistories]);

  return (
    <CardSection
      title={titleStrings.lease}
      content={renderContent()}
    />
  );
};

LeaseForm.propTypes = {
  handleInputValueChange: PropTypes.func,
  excludedRentalHistories: PropTypes.array,
};

LeaseForm.defaultProps = {
  handleInputValueChange: () => {
    return;
  },
  excludedRentalHistories: [],
};

export default LeaseForm;
