import { ResponseFetchSearchHistories } from "models/responses/ResponseFetchSearchHistories";
import { ResponseFetchSearchProperty } from "models/responses/ResponseFetchSearchProperty";
import BaseAction from "./baseAction"

const servicePath = "/v1/property";

export default {
    fetchSearchHistories: (request, onSuccess) => {
        return BaseAction().get(
            `${ servicePath }/search-history`,
            request.mapToJson(),
            (response) => {
                onSuccess(
                    new ResponseFetchSearchHistories().mapFromJson(
                        response.data
                    )
                );
            }
        )
    },
    deleteSearchHistory: (request, onSuccess) => {
        return BaseAction().delete(
            `${ servicePath }/delete-search`,
            request.mapToJson(),
            onSuccess
        )
    },
    fetchSearchProperty: (request, onSuccess) => {
        return BaseAction().post(
            `${ servicePath }/search`,
            request.mapToJson(),
            (response) => {
                onSuccess(
                    new ResponseFetchSearchProperty().mapFromJson(
                        response.data
                    )
                );
            }
        )
    },
    createSearchHistory: (
        request,
        onSuccess = () => { return; }
    ) => {
        return BaseAction().post(
            `${ servicePath }/add-search`,
            request.mapToJson(),
            onSuccess
        )
    }
}