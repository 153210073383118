import React from 'react';
import { useEffect } from 'react';
import employeeService from 'redux/actions/employeeAction';
import * as commonActions from 'redux/reducers/commonReducer';
import * as employeeActions from 'redux/reducers/employeeReducer';
import * as defaultValues from 'constants/defaultValue';
import * as confirmationModalActions from 'layouts/modals/ConfirmationModal/confirmationModal.reducer';
import { RequestFetchEmployeeList } from 'models/requests';
import { useSelector } from 'react-redux';
import CreateEmployeeModal from 'layouts/modals/CreateEmployeeModal/CreateEmployeeModal';
import ColumnTable from 'components/core/column-table/ColumnTable';
import zIndex from 'constants/zIndex';
import buttonStrings from 'localizations/buttonStrings';
import { titleStrings } from 'localizations';
import { Employee } from 'models/objects/Employee';
import { RequestDeleteEmployee } from 'models/requests/RequestDeleteEmployee';
import { useState } from 'react';
import { RequestUpdateEmployeeMemo } from 'models/requests/RequestUpdateEmployeeMemo';
import ConfirmationModal from 'layouts/modals/ConfirmationModal/ConfirmationModal';
import { useDispatch } from 'react-redux';

const useEmployeeListPage = () => {
  const dispatch = useDispatch();
  const [selectedEmployee, setSelectedEmployee] = useState(new Employee());
  const employeeReducer = useSelector((state) => state.employee);
  const { pagination } = useSelector((state) => state.employee);
  const _request = useSelector((state) => state.employee.requestFetchEmployees);

  const onSelectEmployee = (employeeId) => {
    setSelectedEmployee(
      employeeReducer.employees.find(($0) => $0.id == employeeId)
    );
  };

  const showEmployeeForm = (employee = new Employee()) => {
    commonActions.showModal(
      zIndex.defaultModal,
      <CreateEmployeeModal employee={employee} />
    );
  };

  const onStartSearchEmployee = (keyword, startDate, endDate) => {
    employeeActions.flushPagination();
    employeeActions.reloadData(
      new RequestFetchEmployeeList(keyword, startDate, endDate)
    );
  };

  const onStartDeleteEmployee = () => {
    commonActions.showModal(
      zIndex.defaultModal,
      <ConfirmationModal
        title={'회원을 삭제하시겠습니까?'}
        primaryButtonTitle={buttonStrings.yes}
        secondaryButtonTitle={buttonStrings.no}
        primaryAction={deleteEmployee}
        secondaryAction={commonActions.hideModal}
      />
    );
  };

  const deleteEmployee = () => {
    dispatch(confirmationModalActions.startLoading());
    employeeService.deleteEmployee(
      new RequestDeleteEmployee(
        employeeReducer.checkedEmployees.map(($0) => $0.id)
      ),
      () => {
        dispatch(confirmationModalActions.stopLoading());
        commonActions.hideModal();
        employeeActions.reloadData();
      }
    );
  };

  const onCheckEmployee = (isCheck, employee) => {
    let employees = employeeReducer.checkedEmployees.filter(
      ($0) => $0.id != employee.id
    );
    if (isCheck) {
      employees.push(employee);
    }
    employeeActions.checkEmployee(employees);
  };

  const onCheckAllEmployee = () => {
    employeeActions.checkEmployee(
      employeeReducer.checkedEmployees == employeeReducer.employees
        ? defaultValues.emptyArray
        : employeeReducer.employees
    );
  };

  const onSaveMemo = () => {
    employeeActions.updateEmployeeMemo(
      selectedEmployee.id,
      selectedEmployee.memo
    );
    employeeService.updateEmployeeMemo(
      new RequestUpdateEmployeeMemo(selectedEmployee.id, selectedEmployee.memo)
    );
  };

  const onMemoValueChange = (value) => {
    let employee = Object.assign(new Employee(), selectedEmployee);
    employee.memo = value;
    setSelectedEmployee(employee);
  };

  const setupEmployeeCheckboxColumn = (key, value, action) => {
    return (
      <ColumnTable
        key={key}
        handleAction={action}
        isCheckbox={true}
        value={value}
      />
    );
  };

  const setupEmployeeTextColumn = (key, value) => {
    return (
      <ColumnTable
        key={key}
        title={value}
      />
    );
  };

  const setupEmployeeHeaders = () => {
    return [
      setupEmployeeCheckboxColumn(
        defaultValues.emptyInt,
        !employeeReducer.employees.isEmpty() &&
          employeeReducer.checkedEmployees.length ==
            employeeReducer.employees.length,
        onCheckAllEmployee
      ),
      setupEmployeeTextColumn(defaultValues.emptyInt, titleStrings.number),
      setupEmployeeTextColumn(defaultValues.emptyInt, titleStrings.username),
      setupEmployeeTextColumn(
        defaultValues.emptyInt,
        titleStrings.employeeName
      ),
      setupEmployeeTextColumn(defaultValues.emptyInt, titleStrings.phone),
      setupEmployeeTextColumn(defaultValues.emptyInt, titleStrings.email),
      setupEmployeeTextColumn(defaultValues.emptyInt, titleStrings.rank),
      setupEmployeeTextColumn(defaultValues.emptyInt, titleStrings.correction),
    ];
  };

  const setupEmployeeActionColumn = (key, employeeId) => {
    return (
      <ColumnTable
        key={key}
        isButton={true}
        title={buttonStrings.edit}
        handleAction={() => showEmployeeForm(employeeId)}
      />
    );
  };

  const setupEmployeeRows = () => {
    return employeeReducer.employees.map((employee, index) => [
      setupEmployeeCheckboxColumn(
        employee.id,
        employeeReducer.checkedEmployees.some(($0) => $0.id == employee.id),
        (isCheck) => onCheckEmployee(isCheck, employee)
      ),
      setupEmployeeTextColumn(
        employee.id,
        index + 1 + _request.pagination.pageSize * pagination.currentPage
      ),
      setupEmployeeTextColumn(employee.id, employee.username),
      setupEmployeeTextColumn(employee.id, employee.name),
      setupEmployeeTextColumn(employee.id, employee.phoneNumber),
      setupEmployeeTextColumn(employee.id, employee.email),
      setupEmployeeTextColumn(employee.id, employee.role.name),
      setupEmployeeActionColumn(employee.id, employee),
    ]);
  };

  const onPageChange = (page) => {
    employeeActions.onChangePage(page);
  };

  useEffect(() => employeeActions.reloadData(), defaultValues.emptyArray);

  return {
    showEmployeeForm,
    setupEmployeeHeaders,
    setupEmployeeRows,
    onStartDeleteEmployee,
    onStartSearchEmployee,
    onSelectEmployee,
    onMemoValueChange,
    onSaveMemo,
    onPageChange,
    selectedEmployee,
    pagination,
  };
};

export default useEmployeeListPage;
