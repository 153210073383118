import * as defaultValues from "constants/defaultValue";

export class RequestCreatePriceInformation {
    constructor(
        paid,
        price,
        adminPrice,
        adminRevenueRate,
        loanPrice,
        interest,
        isMonthlyInterest = false
    ) {
        this.paid = paid;
        this.price = price;
        this.adminPrice = adminPrice;
        this.adminRevenueRate = adminRevenueRate;
        this.loanPrice = loanPrice;
        this.interest = interest;
        this.isMonthlyInterest = isMonthlyInterest;
    }

    getPaidPrice() {
        return Number(this.paid).truncate(3);
    }

    getRentalPrice() {
        return Number(this.price)
    }

    getAdminRevenueRate() {
        return Number(this.adminRevenueRate)
    }

    getLoanPrice() {
        return Number(this.loanPrice)
    }

    getInterestRate() {
        return Number(this.interest)
    }

    mapToJson() {
        return {
            'paid': this.getPaidPrice() || defaultValues.emptyInt,
            'price': this.getRentalPrice() || defaultValues.emptyInt,
            'admin_revenue_rate': this.getAdminRevenueRate() || defaultValues.emptyInt,
            'loan_price': this.getLoanPrice() || defaultValues.emptyInt,
            'interest': this.getInterestRate() || defaultValues.emptyInt,
            'is_monthly_interest': this.isMonthlyInterest
        }
    }
}