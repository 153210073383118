import styled from "@emotion/styled";
import sizes from "constants/sizes";
import tw from "twin.macro";

const Container = styled.div`
    ${ tw`px-8 py-5 border-r` }
`;
const ButtonContainer = styled.div`
    ${ tw`grid grid-cols-2 gap-5` }
`;
const FormContainer = styled.div`
    ${ tw`mt-5 space-y-3` }
`;
const InputContainer = styled.div`
    display: flex;
`;
const LabelWrapper = styled.div`
    width: 25%;
    ${ tw`my-auto` }
`;
const InputFieldWrapper = styled.div`
    width: 75%;
    height: ${ sizes.textInput.small }px;
`;
const StatusCheckboxContainer = styled.div`
    height: ${ sizes.textInput.small }px;
    ${ tw`grid grid-cols-4 my-auto` }
`;
const RoadWidthInputContainer = styled.div`
    height: ${ sizes.textInput.small }px;
    ${ tw`grid grid-cols-2 my-auto` }
`;
const RoadWidthCheckboxContainer = styled.div`
    display: flex;
    ${ tw`gap-3 my-auto` }
`;
const RangeContainer = styled.div`
    ${ tw`grid grid-cols-5 my-auto` }
`;
const RangeInputWrapper = styled.div`
    ${ tw`col-span-2` }
`;
const RangeSeparator = styled.div`
    ${ tw`my-auto` }
`;
const UsageInputContainer = styled.div`
    height: ${ sizes.textInput.small }px;
    ${ tw`grid grid-cols-2 gap-5` }
`;

export {
    Container,
    ButtonContainer,
    FormContainer,
    InputContainer,
    LabelWrapper,
    InputFieldWrapper,
    StatusCheckboxContainer,
    RoadWidthInputContainer,
    RoadWidthCheckboxContainer,
    RangeContainer,
    RangeInputWrapper,
    RangeSeparator,
    UsageInputContainer
}