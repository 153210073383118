import React from 'react';
import PropTypes from 'prop-types';
import * as styled from './MergeTableView.styled';
import * as defaultValues from 'constants/defaultValue';
import MergeTableViewHook from './MergeTableViewHook';
import classNames from 'classnames';

const MergeTableView = (props) => {
  let hook = MergeTableViewHook();

  const renderHeader = () => {
    return (
      <tr>
        {props.headers.map((header, index) => (
          <styled.Header
            key={index}
            className={classNames(props.headerClassses, {
              [header.classNames]: header.classNames !== undefined,
              ['w-20%']: header.classNames === undefined,
            })}>
            {header.title === undefined ? header : header.title}
          </styled.Header>
        ))}
      </tr>
    );
  };

  const renderColumn = (isMerged, rowIndex, columnIndex, span, content) => {
    return (
      <styled.Column
        key={columnIndex}
        className={`${props.columnClasses}`}
        rowSpan={span}
        isSelected={props.hook.selectedCells.some((selectedColumn) => {
          return (
            selectedColumn.row == rowIndex &&
            selectedColumn.column == columnIndex
          );
        })}
        onClick={() => {
          if (!props.isMergeable) {
            return;
          }
          if (props.hook.isUnselectable(columnIndex)) {
            return;
          }
          props.hook.onSelectColumn({
            row: rowIndex,
            column: columnIndex,
            isMerged: isMerged,
            rowSpan: span,
          });
        }}>
        {content}
      </styled.Column>
    );
  };

  const renderRows = () => {
    if (props.rows.isEmpty()) {
      return;
    }
    let tableRows = [];
    let latestMergedColumns = [];
    for (const [rowIndex, tableRow] of props.rows.entries()) {
      let tableColumns = [];
      for (const [columnIndex, column] of tableRow.entries()) {
        if (column.isMerged) {
          let index = latestMergedColumns.findIndex(
            ($0) => $0.column == columnIndex
          );
          if (index >= 0) {
            latestMergedColumns[index].span =
              latestMergedColumns[index].span + 1;
            continue;
          }
          latestMergedColumns = [
            ...latestMergedColumns,
            {
              isMerged: column.isMerged,
              row: rowIndex,
              column: columnIndex,
              span: 2,
              content: column.content,
            },
          ];
          continue;
        }
        const latestMergedColumn = latestMergedColumns.find(
          ($0) => $0.column == columnIndex
        );
        if (latestMergedColumn) {
          tableRows[latestMergedColumn.row].insert(latestMergedColumn.column, {
            isMerged: latestMergedColumn.isMerged,
            rowSpan: latestMergedColumn.span,
            content: latestMergedColumn.content,
            column: latestMergedColumn.column,
          });
          latestMergedColumns = latestMergedColumns.filter(
            ($0) => $0.column != columnIndex
          );
          continue;
        }
        tableColumns = [
          ...tableColumns,
          {
            isMerged: column.isMerged,
            rowSpan: 1,
            content: column.content,
            column: columnIndex,
          },
        ];
      }
      tableRows = [...tableRows, tableColumns];
    }
    tableRows = tableRows.map((columns) =>
      columns.sort(($0, $1) => $0.column - $1.column)
    );
    return tableRows.map((rows, rowIndex) => (
      <tr key={rowIndex}>
        {rows
          .filter(($0) => $0 !== null)
          .map((columnItem) =>
            renderColumn(
              columnItem.isMerged,
              rowIndex,
              columnItem.column,
              columnItem.rowSpan,
              columnItem.content
            )
          )}
      </tr>
    ));
  };

  const renderFooterColumns = () => {
    return props.footerColumns.map((footerColumn, index) => (
      <styled.FooterColumn
        key={index}
        className={props.footerClasses}>
        {footerColumn}
      </styled.FooterColumn>
    ));
  };

  const renderFooter = () => {
    return <tr>{renderFooterColumns()}</tr>;
  };

  return (
    <styled.Wrapper maxHeight={props.maxHeight}>
      <styled.Table>
        <thead>{renderHeader()}</thead>
        <tbody>
          {renderRows()}
          {renderFooter()}
        </tbody>
      </styled.Table>
    </styled.Wrapper>
  );
};

MergeTableView.propTypes = {
  isMergeable: PropTypes.bool,
  headers: PropTypes.array,
  rows: PropTypes.array,
  columnHeight: PropTypes.number,
  columnClasses: PropTypes.string,
  footerClasses: PropTypes.string,
  footerColumns: PropTypes.array,
  headerClassses: PropTypes.string,
  hook: PropTypes.object,
  maxHeight: PropTypes.number,
  onUnmergeSuccess: PropTypes.func,
};

MergeTableView.defaultProps = {
  isMergeable: true,
  headers: [],
  rows: [],
  columnHeight: 50,
  columnClasses: 'h-14',
  footerClasses: 'h-14',
  footerColumns: [],
  headerClassses: 'h-14',
  unselectableColumns: [],
  maxHeight: defaultValues.emptyInt,
  onUnmergeSuccess: defaultValues.emptyFunction,
};

export default MergeTableView;
