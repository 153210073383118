import tw from "twin.macro";
import styled from "styled-components";

const Button = styled.button`
    ${ tw`underline` }
`;
const ValueText = styled.p`
    text-align: ${(props) => props.alignment};
`;

export {
    Button,
    ValueText
}