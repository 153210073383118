import defaultValues from "constants/defaultValues";

export class RequestUpdateEmployeeMemo {
    constructor(
        employeeId = defaultValues.emptyString,
        memo = defaultValues.emptyString
    ) {
        this.employeeId = employeeId;
        this.memo = memo;
    }

    mapToJson() {
        return {
            'id': this.employeeId,
            'memo': this.memo
        }
    }
}