import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from 'configs/store';

ReactDOM.render((
  <Provider store={ store }>
      <App />
    </Provider>
), document.getElementById('root'));

reportWebVitals();
