import moment from "moment/moment";
import 'moment-timezone';
import dateFormats from "constants/dateFormats";

export const formatToUTC = (dateString, format = dateFormats.date) => {
    if (dateString.isEmpty()) {
        return dateString;
    }
    if (format == dateFormats.iso8601) {
        return moment
            .tz(dateString, moment.tz.guess())
            .utc()
            .toISOString()
    }
    return moment
        .tz(dateString, moment.tz.guess())
        .utc()
        .format(format)
}

export const formatToString = (dateString, dateFormat) => {
    if (dateString.isEmpty()) {
        return dateString;
    }
    let utcDate = moment.utc(dateString).toDate();
    return moment(utcDate)
        .local()
        .format(dateFormat)
}