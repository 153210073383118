import dateFormats from "constants/dateFormats";
import defaultValues from "constants/defaultValues";
import moment from "moment";
import { Employee } from "./Employee";

export class Customer {
    constructor(
        id = defaultValues.emptyInt,
        name = defaultValues.emptyString,
        phone = defaultValues.emptyString,
        companyPhone = defaultValues.emptyString,
        memo = defaultValues.emptyString,
        condition = defaultValues.emptyString,
        manager = new Employee(),
        createdAt = defaultValues.emptyString
    ) {
        this.id = id;
        this.name = name;
        this.phone = phone;
        this.companyPhone = companyPhone;
        this.memo = memo;
        this.condition = condition;
        this.manager = manager;
        this.createdAt = createdAt;
    }

    mapFromJson(json) {
        if (!json) { return this; }
        this.id = json['id'];
        this.name = json['name'];
        this.phone = json['phone'];
        this.companyPhone = json['company_phone'];
        this.memo = json['memo'];
        this.condition = json['condition'];
        this.manager = new Employee().mapFromJson(json['manager']);
        this.createdAt = moment(json['created_at']).format(dateFormats.dateTime);
        return this;
    }
}