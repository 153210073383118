import React from 'react';
import PropTypes from 'prop-types';
import * as styled from './LandLedgerForm.styled';
import CardSection from 'components/CardSection/CardSection';
import { titleStrings } from 'localizations';
import InsertColumnIcon from 'assets/insert_column_icon.png';
import RemoveColumnIcon from 'assets/remove_column_icon.png';
import fieldStrings from 'localizations/fieldStrings';
import { BorderedTextInputStyle } from 'components/core/text-input/styles';
import TextInput from 'components/core/text-input/TextInput';
import sizes from 'constants/sizes';
import DropdownInput from 'components/core/dropdown-input/DropdownInput';
import fieldNames from 'constants/fieldNames';
import textInputType from 'enumerations/textInputType';
import { RequestLandSpesificInformation } from 'models/requests/RequestCreateLandSpesificInformation';
import { LandUsage } from 'models/objects/LandUsage';
import alignments from 'constants/alignments';
import { observer } from 'mobx-react-lite';
import { useSelector } from 'react-redux';
import useLandLedgerFormPresenter from './useLandLedgerFormPresenter';
import { BorderedDropdownInputStyle } from 'components/core/dropdown-input/styles';

const CardPageContentInnerLandLedger = observer((props) => {
  const cardReducer = useSelector((state) => state.card);
  const presenter = useLandLedgerFormPresenter();

  const setupItems = () => {
    const landSpesificInformations =
      cardReducer.requestWriteCard.landSpesificInformations;
    landSpesificInformations.push(new RequestLandSpesificInformation());
    props.handleInputValueChange({
      name: fieldNames.landSpesificInformations,
      value: landSpesificInformations,
    });
  };

  const handleInputValueChange = (target, fieldName) => {
    const landSpesificInformations =
      cardReducer.requestWriteCard.landSpesificInformations;
    landSpesificInformations[target.name][fieldName] = target.value;
    props.handleInputValueChange({
      name: fieldNames.landSpesificInformations,
      value: landSpesificInformations,
    });
  };

  const renderNumberInput = (value, columnIndex) => {
    return (
      <TextInput
        alignment={alignments.center}
        height={sizes.landLedgerCell.height}
        minWidth={sizes.landLedgerCell.width}
        maxWidth={sizes.landLedgerCell.width}
        style={BorderedTextInputStyle}
        name={`${columnIndex}`}
        onChange={(target) => {
          handleInputValueChange(target, fieldNames.number);
        }}
        value={value}
      />
    );
  };

  const renderNominationInput = (value, columnIndex) => {
    return (
      <TextInput
        alignment={alignments.center}
        height={sizes.landLedgerCell.height}
        minWidth={sizes.landLedgerCell.width}
        maxWidth={sizes.landLedgerCell.width}
        style={BorderedTextInputStyle}
        name={`${columnIndex}`}
        onChange={(target) => {
          handleInputValueChange(target, fieldNames.category);
        }}
        value={value}
      />
    );
  };

  const renderAreaInput = (value, columnIndex) => {
    return (
      <TextInput
        alignment={alignments.center}
        height={sizes.landLedgerCell.height}
        minWidth={sizes.landLedgerCell.width}
        maxWidth={sizes.landLedgerCell.width}
        style={BorderedTextInputStyle}
        type={textInputType.currency}
        name={`${columnIndex}`}
        onChange={(target) => {
          handleInputValueChange(target, fieldNames.area);
        }}
        value={value}
      />
    );
  };

  const renderUseAreaInput = (value, columnIndex) => {
    return (
      <DropdownInput
        alignment={alignments.center}
        height={sizes.landLedgerCell.height}
        minWidth={sizes.landLedgerCell.width}
        maxWidth={sizes.landLedgerCell.width}
        style={BorderedDropdownInputStyle}
        name={`${columnIndex}`}
        customValue={value.name}
        options={presenter.usageItems}
        selectedOption={{
          key: value.id,
          value: value.name,
        }}
        handleSelectOption={(item) => {
          handleInputValueChange(
            {
              name: columnIndex,
              value: new LandUsage(item.key, item.value),
            },
            fieldNames.usage
          );
        }}
        onCustomValueChange={(input) => {
          let landUsage = new LandUsage();
          landUsage.name = input.value;
          landUsage.isCustom = true;
          handleInputValueChange(
            {
              name: columnIndex,
              value: landUsage,
            },
            fieldNames.usage
          );
        }}
      />
    );
  };

  const renderEarthAreaInput = (value, columnIndex) => {
    return (
      <TextInput
        alignment={alignments.center}
        height={sizes.landLedgerCell.height}
        minWidth={sizes.landLedgerCell.width}
        maxWidth={sizes.landLedgerCell.width}
        style={BorderedTextInputStyle}
        name={`${columnIndex}`}
        onChange={(target) => {
          handleInputValueChange(target, fieldNames.district);
        }}
        value={value}
      />
    );
  };

  const renderRestrictionInput = (value, columnIndex) => {
    return (
      <TextInput
        alignment={alignments.center}
        height={sizes.landLedgerCell.height}
        minWidth={sizes.landLedgerCell.width}
        maxWidth={sizes.landLedgerCell.width}
        style={BorderedTextInputStyle}
        name={`${columnIndex}`}
        onChange={(target) => {
          handleInputValueChange(target, fieldNames.restriction);
        }}
        value={value}
      />
    );
  };

  const renderOfficialPriceInput = (value, columnIndex) => {
    return (
      <TextInput
        alignment={alignments.center}
        height={sizes.landLedgerCell.height}
        minWidth={sizes.landLedgerCell.width}
        maxWidth={sizes.landLedgerCell.width}
        style={BorderedTextInputStyle}
        type={textInputType.currency}
        name={`${columnIndex}`}
        onChange={(target) => {
          handleInputValueChange(target, fieldNames.officialPrice);
        }}
        value={value}
      />
    );
  };

  const removeItemColumns = () => {
    const landSpesificInformations =
      cardReducer.requestWriteCard.landSpesificInformations;
    landSpesificInformations.pop();
    props.handleInputValueChange({
      name: fieldNames.landSpesificInformations,
      value: landSpesificInformations,
    });
  };

  const setupUserAreaItems = () => {
    if (!cardReducer.requestWriteCard.landSpesificInformations) {
      return null;
    }
    return cardReducer.requestWriteCard.landSpesificInformations.map(
      (landSpesificInformation, index) => {
        return renderTableColumn(index, landSpesificInformation);
      }
    );
  };

  const renderTableColumn = (columnIndex, landSpesificInformation) => {
    return (
      <div
        key={columnIndex}
        className="flex flex-col gap-y-5">
        {renderNumberInput(
          presenter.getNumber(landSpesificInformation),
          columnIndex
        )}
        {renderNominationInput(landSpesificInformation.category, columnIndex)}
        {renderAreaInput(landSpesificInformation.area, columnIndex)}
        {renderUseAreaInput(landSpesificInformation.usage, columnIndex)}
        {renderEarthAreaInput(landSpesificInformation.district, columnIndex)}
        {renderRestrictionInput(
          landSpesificInformation.restriction,
          columnIndex
        )}
        {renderOfficialPriceInput(
          landSpesificInformation.officialPrice,
          columnIndex
        )}
      </div>
    );
  };

  const renderRightActions = () => {
    return (
      <div className="flex space-x-6 px-3">
        <button
          className="flex space-x-3"
          onClick={setupItems}>
          <img
            src={InsertColumnIcon}
            width={32}
            alt=""
          />
          <p className="text-primary my-auto">열삽입</p>
        </button>
        <button
          className="flex space-x-3"
          onClick={removeItemColumns}>
          <img
            src={RemoveColumnIcon}
            width={32}
            alt=""
          />
          <p className="text-primary my-auto">열삭제</p>
        </button>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <styled.Container>
        <div className="flex overflow-hidden">
          <div className="flex flex-col text-left space-y-5 py-3">
            <styled.FieldTextWrapper>
              <styled.Text>{fieldStrings.number}</styled.Text>
            </styled.FieldTextWrapper>
            <styled.FieldTextWrapper>
              <styled.Text>{fieldStrings.nomination}</styled.Text>
            </styled.FieldTextWrapper>
            <styled.FieldTextWrapper>
              <styled.Text>{fieldStrings.areaInSquareMeter}</styled.Text>
            </styled.FieldTextWrapper>
            <styled.FieldTextWrapper>
              <styled.Text>{fieldStrings.landUsage}</styled.Text>
            </styled.FieldTextWrapper>
            <styled.FieldTextWrapper>
              <styled.Text>{fieldStrings.earthArea}</styled.Text>
            </styled.FieldTextWrapper>
            <styled.FieldTextWrapper>
              <styled.Text>{fieldStrings.restrictions}</styled.Text>
            </styled.FieldTextWrapper>
            <styled.FieldTextWrapper>
              <styled.Text>
                {`${fieldStrings.officialPrice}(${fieldStrings.krw}/${titleStrings.squareMeter})`}
              </styled.Text>
            </styled.FieldTextWrapper>
          </div>
          <div className="flex overflow-x-auto gap-x-3 py-3">
            {setupUserAreaItems()}
          </div>
        </div>
      </styled.Container>
    );
  };

  if (props.isHidden) {
    return null;
  }
  return (
    <CardSection
      title={titleStrings.landLedger}
      rightNavigationMenu={renderRightActions()}
      content={renderContent()}
    />
  );
});

CardPageContentInnerLandLedger.propTypes = {
  isHidden: PropTypes.bool,
  handleInputValueChange: PropTypes.func,
  landUsages: PropTypes.array,
};

CardPageContentInnerLandLedger.defaultProps = {
  isHidden: false,
  isWriteState: false,
  handleInputValueChange: () => {
    return;
  },
};

export default CardPageContentInnerLandLedger;
