import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import * as styled from './MainPrintPage.styled';
import * as defaultValues from 'constants/defaultValue';
import LogoImageSrc from 'assets/logo_horizontal_white.png';
import Text from 'components/core/text/Text';
import HighlightedTextStyle from 'components/core/text/styles/HighlightedTextStyle';
import { useSelector } from 'react-redux';
import { formatToString } from 'helpers/dateFormatterHelper';
import dateFormats from 'constants/dateFormats';
import moment from 'moment';
import useMainPrintPage from './MainPrintPage.viewModel';

const MainPrintPage = (props) => {
  const viewModel = useMainPrintPage();
  const wrapperRef = useRef(null);
  const containerRef = useRef(null);
  const property = useSelector((state) => state.printProperty.property);
  const manager = useSelector((state) => state.printProperty.manager);
  const imageOrientation = useSelector(
    (state) => state.printProperty.imageOrientation
  );

  return (
    <styled.Wrapper ref={wrapperRef}>
      <styled.Container
        ref={containerRef}
        scale={
          wrapperRef.current?.clientWidth / containerRef.current?.clientWidth
        }
        data-id={props.id}>
        <styled.BackgroundImage src={viewModel.getImageUrl()} />
        <div className="absolute inset-0 bg-black opacity-50" />
        <styled.CoverImage
          image={viewModel.getImageUrl()}
          imageOrientation={imageOrientation}
          className="relative object-contain w-full h-full z-10"
        />
        <div className="absolute inset-0 z-10 bg-gradient-to-r from-black opacity-70" />
        <styled.OverlayContainer className="absolute inset-0 z-20">
          <div className="flex flex-col flex-grow w-[50%]">
            <styled.LogoImage
              src={LogoImageSrc}
              alt=""
            />
            <styled.PropertyInfoContainer>
              <Text
                className="!text-white"
                fontSize={21}
                value={property.buildingName + ' 투자제안서'}
                style={HighlightedTextStyle}
              />
              <Text
                className="!text-white"
                fontSize={21}
                value={formatToString(moment().format(), dateFormats.yearMonth)}
              />
              <styled.MiddleContainer>
                <Text
                  className="!text-white"
                  fontSize={21}
                  value={property.address}
                />
                <Text
                  className="!text-white"
                  fontSize={21}
                  value={property.streetAddress}
                />
              </styled.MiddleContainer>
              <div className="flex gap-6">
                <Text
                  className="!text-white !w-[auto]"
                  fontSize={21}
                  value={manager.name}
                  style={HighlightedTextStyle}
                />
                <Text
                  className="!text-white !w-[auto]"
                  fontSize={21}
                  value={manager.role.name}
                />
              </div>
              <Text
                className="!text-white"
                fontSize={21}
                value={'T: ' + manager.phoneNumber}
              />
              <Text
                className="!text-white"
                fontSize={21}
                value={'E: ' + manager.email}
              />
            </styled.PropertyInfoContainer>
          </div>
          <styled.DisclaimerContainer>
            <ul>
              <styled.DisclaimerListItem className="!text-white">
                본 자료에는 법률상 공개가 금지되거나 공개되면 안되는 매도인의
                비밀정보가 포함되어 있을 수 있습니다.
              </styled.DisclaimerListItem>
              <styled.DisclaimerListItem className="!text-white">
                본 자료가 본인에게 불필요할 경우 즉시 파기하여 주시기 바라며,
                당사의 동의 없이 제 3자에게 무단 복제, 배포, 전송, 변경할 수
                없습니다.
              </styled.DisclaimerListItem>
              <styled.DisclaimerListItem className="!text-white">
                본 내용은 매도자의 진술에 의한 것이므로 사실과 차이가 있을 수
                있습니다.
              </styled.DisclaimerListItem>
            </ul>
          </styled.DisclaimerContainer>
        </styled.OverlayContainer>
      </styled.Container>
    </styled.Wrapper>
  );
};

MainPrintPage.propTypes = {
  id: PropTypes.string,
};

MainPrintPage.defaultProps = {
  id: defaultValues.emptyString,
};

export default MainPrintPage;
