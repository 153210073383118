import defaultValues from "constants/defaultValues";

export class RequestCreateSearchHistory {
    constructor(
        id = defaultValues.emptyString
    ) {
        this.id = id;
    }

    mapToJson() {
        return {
            'id': this.id
        }
    }
}