import React from 'react';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import useFileInput from './useFileInput';
import * as styled from './FileInput.styled';
import defaultValues from 'constants/defaultValues';

const FileInput = (props) => {
  const hook = useFileInput(props);
  const inputRef = useRef(null);

  return (
    <styled.Container disabled={props.disabled}>
      <styled.Button onClick={() => inputRef.current.click()}>
        <styled.FileNameLabel>{hook.fileName}</styled.FileNameLabel>
      </styled.Button>
      <input
        disabled={props.disabled}
        type="file"
        ref={inputRef}
        className={'hidden'}
        accept="image/png, image/jpeg"
        onChange={hook.onFileChange}
      />
    </styled.Container>
  );
};

FileInput.propTypes = {
  disabled: PropTypes.bool,
  fileUrl: PropTypes.string,
  onFileChange: PropTypes.func,
  onError: PropTypes.func,
};

FileInput.defaultProps = {
  disabled: false,
  fileUrl: defaultValues.emptyString,
  onFileChange: () => {
    return;
  },
  onError: () => {
    return;
  },
};

export default FileInput;
