import { ResponseFetchCustomerList } from "models/responses";
import { ResponseCheckDuplication } from "models/responses/ResponseCheckDuplication";
import BaseAction from "./baseAction"

const basePath = "/v1/customer";
export default {
    fetchCustomerList: (
        request,
        onSuccess = () => { return; }
    ) => {
        return BaseAction().get(
            `${basePath}/list`,
            request.mapToJson(),
            (response) => {
                onSuccess(
                    new ResponseFetchCustomerList()
                        .mapFromJson(response.data)
                );
            }
        )
    },
    checkDuplication: (
        request,
        onSuccess = () => { return; }
    )  => {
        return BaseAction().get(
            basePath + "/check-duplicate",
            request.mapToJson(),
            (response) => {
                onSuccess(
                    new ResponseCheckDuplication().mapFromJson(response)
                );
            }
        )
    },
    createCustomer: (
        request,
        onSuccess = () => { return; }
    ) => {
        return BaseAction().post(
            `${basePath}/create`,
            request.mapToJson(),
            (response) => onSuccess(response.message)
        )
    },
    deleteCustomer: (
        request,
        onSuccess = () => { return; }
    ) => {
        return BaseAction().delete(
            `${basePath}/delete`,
            request.mapToJson(),
            onSuccess
        )
    },
    updateCustomerNotes: (
        request,
        onSuccess = () => { return; }
    ) => {
        return BaseAction().patch(
            `${basePath}/update-notes`,
            request.mapToJson(),
            onSuccess
        )
    },
    changeCustomerManager: (
        request,
        onSuccess = () => { return; }
    ) => {
        return BaseAction().patch(
            `${basePath}/update-manager`,
            request.mapToJson(),
            onSuccess
        )
    }
}