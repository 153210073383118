import ButtonStrings from './buttonStrings';
import DialogMessageStrings from './dialogMessageStrings';
import EmptyStateStrings from './emptyStateStrings';
import FieldStrings from './fieldStrings';
import titleStrings from './titleStrings';
import hintStrings from './hintStrings';
import tableHeaderStrings from './tableHeaderStrings';
import formatStrings from './formatStrings';
import errorMessagesStrings from './errorMessagesStrings';

const language = "kr"

ButtonStrings.setLanguage(language)
DialogMessageStrings.setLanguage(language)
EmptyStateStrings.setLanguage(language)
FieldStrings.setLanguage(language)
titleStrings.setLanguage(language)
hintStrings.setLanguage(language)
formatStrings.setLanguage(language)
tableHeaderStrings.setLanguage(language)
errorMessagesStrings.setLanguage(language)

export {
    ButtonStrings,
    DialogMessageStrings,
    EmptyStateStrings,
    FieldStrings,
    titleStrings,
    hintStrings,
    formatStrings,
    tableHeaderStrings,
    errorMessagesStrings
}