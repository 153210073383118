/* eslint-disable */
function makeMarkerClustering(naver) {
    var MarkerClustering = function(options) {

        // This is the default value.
        this.DEFAULT_OPTIONS = {
            // The map to put the cluster marker on.
            map: null,
            // The markers to configure the cluster markers.
            markers: [],
            // Whether to zoom when clicking the cluster marker.
            disableClickZoom: true,
            // Minimum number of markers to form a cluster.
            minClusterSize: 2,
            // The maximum zoom level to represent with cluster markers. Above that zoom level, it exposes the markers that make up the cluster.
            maxZoom: 13,
            // The size of the grid to organize the clusters on. Units are pixels.
            gridSize: 100,
            // The icon for the cluster marker. Icons, symbols, and HTML marker types provided by the NAVER Maps JavaScript API v3 can all be used.
            icons: [],
            // Determine the index of which icon to select from the cluster marker's icon array.
            indexGenerator: [10, 100, 200, 500, 1000],
            // Whether to set the position of the cluster marker to the average coordinates of the markers constituting the cluster.
            averageCenter: false,
            // This is a callback function called when updating the cluster marker. 
            //This function allows you to manipulate elements, such as displaying counts on cluster markers.
            stylingFunction: function() {}
        };

        this._clusters = [];

        this._mapRelations = null;
        this._markerRelations = [];

        this.setOptions(naver.maps.Util.extend({}, this.DEFAULT_OPTIONS, options), true);
        this.setMap(options.map || null);
    };

    naver.maps.Util.ClassExtend(MarkerClustering, naver.maps.OverlayView, {
        onAdd: function() {
            var map = this.getMap();

            this._mapRelations = naver.maps.Event.addListener(map, 'idle', naver.maps.Util.bind(this._onIdle, this));

            if (this.getMarkers().length > 0) {
                this._createClusters();
                this._updateClusters();
            }
        },

        draw: naver.maps.Util.noop,

        onRemove: function() {
            naver.maps.Event.removeListener(this._mapRelation);

            this._clearClusters();

            this._geoTree = null;
            this._mapRelation = null;
        },

        /**
         * Set marker clustering options. Only set options are reflected.
         * @param { Object | string} newOptions options
         */
        setOptions: function(newOptions) {
            var _this = this;

            if (typeof newOptions === 'string') {
                var key = newOptions,
                    value = arguments[1];

                _this.set(key, value);
            } else {
                var isFirst = arguments[1];

                naver.maps.Util.forEach(newOptions, function(value, key) {
                    if (key !== 'map') {
                        _this.set(key, value);
                    }
                });

                if (newOptions.map && !isFirst) {
                    _this.setMap(newOptions.map);
                }
            }
        },

        /**
         * Returns marker clustering options. If no specific option name is specified, all options are returned.
         * @param {string} key Option name to be returned
         * @return {Any} options
         */
        getOptions: function(key) {
            var _this = this,
                options = {};

            if (key !== undefined) {
                return _this.get(key);
            } else {
                naver.maps.Util.forEach(_this.DEFAULT_OPTIONS, function(value, key) {
                    options[key] = _this.get(key);
                });

                return options;
            }
        },

        /**
         * Returns the minimum number of markers that make up a cluster.
         * @return {number} minimum number of markers to form a cluster
         */
        getMinClusterSize: function() {
            return this.getOptions('minClusterSize');
        },

        /**
         * Set the minimum number of markers to make up a cluster.
         * @param {number} size The minimum number of markers to make up a cluster
         */
        setMinClusterSize: function(size) {
            this.setOptions('minClusterSize', size);
        },

        /**
         * Returns the maximum zoom level at which to expose cluster markers.
         * @return {number} the maximum zoom level at which to expose cluster markers
         */
        getMaxZoom: function() {
            return this.getOptions('maxZoom');
        },

        /**
         * Set the maximum zoom level to expose cluster markers.
         * @param {number} zoom The maximum zoom level at which to expose cluster markers.
         */
        setMaxZoom: function(zoom) {
            this.setOptions('maxZoom', zoom);
        },

        /**
         * Returns the size of the grid to organize the clusters on. Units are pixels.
         * @return {number} Grid size to form clusters
         */
        getGridSize: function() {
            return this.getOptions('gridSize');
        },

        /**
         * Set the grid size to configure the cluster. Units are pixels.
         * @param {number} size Grid size to form clusters
         */
        setGridSize: function(size) {
            this.setOptions('gridSize', size);
        },

        /**
         * Returns the index generator that determines the icon for the cluster marker.
         * @return {Array | Function} index generator
         */
        getIndexGenerator: function() {
            return this.getOptions('indexGenerator');
        },

        /**
         * Set the index generator that determines the icon for the cluster marker.
         * @param {Array | Function} indexGenerator index generator
         */
        setIndexGenerator: function(indexGenerator) {
            this.setOptions('indexGenerator', indexGenerator);
        },

        /**
         * Returns the markers to organize into clusters.
         * @return {Array.<naver.maps.Marker>} markers to configure as clusters
         */
        getMarkers: function() {
            return this.getOptions('markers');
        },

        /**
         * Set markers to organize into clusters.
         * @param {Array.<naver.maps.Marker>} markers Markers to organize into clusters
         */
        setMarkers: function(markers) {
            this.setOptions('markers', markers);
        },

        /**
         * Returns the cluster marker icon.
         * @return {Array.<naver.maps.Marker~ImageIcon | naver.maps.Marker~SymbolIcon | naver.maps.Marker~HtmlIcon>} cluster marker icon
         */
        getIcons: function() {
            return this.getOptions('icons');
        },

        /**
         * Set the cluster marker icon.
         * @param {Array.<naver.maps.Marker~ImageIcon | naver.maps.Marker~SymbolIcon | naver.maps.Marker~HtmlIcon>} icons Cluster marker icons
         */
        setIcons: function(icons) {
            this.setOptions('icons', icons);
        },

        /**
         * Returns a styling function that allows you to manipulate the elements of the cluster marker.
         * @return {Funxtion} callback function
         */
        getStylingFunction: function() {
            return this.getOptions('stylingFunction');
        },

        /**
         * Set up a styling function that can manipulate the elements of the cluster marker.
         * @param {Function} func Callback function
         */
        setStylingFunction: function(func) {
            this.setOptions('stylingFunction', func);
        },

        /**
         * Returns whether the zoom operation is performed when the cluster marker is clicked.
         * @return {boolean} whether to zoom
         */
        getDisableClickZoom: function() {
            return this.getOptions('disableClickZoom');
        },

        /**
         * Set whether or not to perform a zoom operation when clicking a cluster marker.
         * @param {boolean} flag Whether or not to zoom
         */
        setDisableClickZoom: function(flag) {
            this.setOptions('disableClickZoom', flag);
        },

        /**
         * Returns whether or not to set the position of the cluster marker to the average coordinates of the markers constituting the cluster.
         * @return {boolean} Whether to cluster by average coordinates
         */
        getAverageCenter: function() {
            return this.getOptions('averageCenter');
        },

        /**
         * Set whether to set the location of the cluster marker to the average coordinates of the markers constituting the cluster.
         * @param {boolean} averageCenter Whether to cluster by average coordinates
         */
        setAverageCenter: function(averageCenter) {
            this.setOptions('averageCenter', averageCenter);
        },

        // KVO event handler
        changed: function(key, value) {
            if (!this.getMap()) return;

            switch (key) {
                case 'marker':
                case 'minClusterSize':
                case 'gridSize':
                case 'averageCenter':
                    this._redraw();
                    break;
                case 'indexGenerator':
                case 'icons':
                    this._clusters.forEach(function(cluster) {
                        cluster.updateIcon();
                    });
                    break;
                case 'maxZoom':
                    this._clusters.forEach(function(cluster) {
                        if (cluster.getCount() > 1) {
                            cluster.checkByZoomAndMinClusterSize();
                        }
                    });
                    break;
                case 'stylingFunction':
                    this._clusters.forEach(function(cluster) {
                        cluster.updateCount();
                    });
                    break;
                case 'disableClickZoom':
                    var exec = 'enableClickZoom';

                    if (value) {
                        exec = 'disableClickZoom';
                    }

                    this._clusters.forEach(function(cluster) {
                        cluster[exec]();
                    });
                    break;
            }
        },

        /**
         * Generates clusters for markers within the current map boundary area.
         * @private
         */
        _createClusters: function() {
            var map = this.getMap();

            if (!map) return;

            var bounds = map.getBounds(),
                markers = this.getMarkers();

            for (var i = 0, ii = markers.length; i < ii; i++) {
                var marker = markers[i],
                    position = marker.getPosition();

                if (!bounds.hasLatLng(position)) continue;

                var closestCluster = this._getClosestCluster(position);

                closestCluster.addMarker(marker);

                this._markerRelations.push(naver.maps.Event.addListener(marker, 'dragend', naver.maps.Util.bind(this._onDragEnd, this)));
            }
        },

        /**
         * Update the icon and text of the cluster.
         * @private
         */
        _updateClusters: function() {
            var clusters = this._clusters;

            for (var i = 0, ii = clusters.length; i < ii; i++) {
                clusters[i].updateCluster();
            }
        },

        /**
         * Remove all clusters.
         * @private
         */
        _clearClusters: function() {
            var clusters = this._clusters;

            for (var i = 0, ii = clusters.length; i < ii; i++) {
                clusters[i].destroy();
            }

            naver.maps.Event.removeListener(this._markerRelations);

            this._markerRelations = [];
            this._clusters = [];
        },

        /**
         * Remove all created clusters and recreate them.
         * @private
         */
        _redraw: function() {
            this._clearClusters();
            this._createClusters();
            this._updateClusters();
        },

        /**
         * Returns the nearest cluster from the passed latitude/longitude. If not, it creates a new cluster and returns it.
         * @param {naver.maps.LatLng} position latitude/longitude
         * @return {Cluster} cluster
         */
        _getClosestCluster: function(position) {
            var proj = this.getProjection(),
                clusters = this._clusters,
                closestCluster = null,
                distance = Infinity;

            for (var i = 0, ii = clusters.length; i < ii; i++) {
                var cluster = clusters[i],
                    center = cluster.getCenter();

                if (cluster.isInBounds(position)) {
                    var delta = proj.getDistance(center, position);

                    if (delta < distance) {
                        distance = delta;
                        closestCluster = cluster;
                    }
                }
            }

            if (!closestCluster) {
                closestCluster = new Cluster(this);
                this._clusters.push(closestCluster);
            }

            return closestCluster;
        },

        /**
         * The map's Idle state event handler.
         */
        _onIdle: function() {
            this._redraw();
        },

        /**
         * Drag end event handler for each marker.
         */
        _onDragEnd: function() {
            this._redraw();
        }
    });

    /**
     * Define clusters with markers.
     * @param {MarkerClustering} markerClusterer
     */
    var Cluster = function(markerClusterer) {
        this._clusterCenter = null;
        this._clusterBounds = null;
        this._clusterMarker = null;
        this._relation = null;

        this._clusterMember = [];

        this._markerClusterer = markerClusterer;
    };

    Cluster.prototype = {
        constructor: Cluster,

        /**
         * Add markers to clusters.
         * @param {naver.maps.Marker} marker The marker to add to the cluster
         */
        addMarker: function(marker) {
            if (this._isMember(marker)) return;

            if (!this._clusterCenter) {
                var position = marker.getPosition();

                this._clusterCenter = position;
                this._clusterBounds = this._calcBounds(position);
            }

            this._clusterMember.push(marker);
        },

        /**
         * Remove the cluster.
         */
        destroy: function() {
            naver.maps.Event.removeListener(this._relation);

            var members = this._clusterMember;

            for (var i = 0, ii = members.length; i < ii; i++) {
                members[i].setMap(null);
            }

            this._clusterMarker.setMap(null);

            this._clusterMarker = null;
            this._clusterCenter = null;
            this._clusterBounds = null;
            this._relation = null;

            this._clusterMember = [];
        },

        /**
         * Returns the cluster centroid.
         * @return {naver.maps.LatLng} cluster center point
         */
        getCenter: function() {
            return this._clusterCenter;
        },

        /**
         * Returns the cluster boundary area.
         * @return {naver.maps.LatLngBounds} cluster boundary area
         */
        getBounds: function() {
            return this._clusterBounds;
        },

        /**
         * Returns the number of markers that make up the cluster.
         * @return {number} number of markers that make up the cluster
         */
        getCount: function() {
            return this._clusterMember.length;
        },

        /**
         * Returns the current cluster member marker object.
         * @return {naver.maps.Marker[]} The set of marker objects that make up the cluster
         */
        getClusterMember: function() {
            return this._clusterMember;
        },

        /**
         * Returns whether the passed latitude/longitude lies within the cluster boundary region.
         * @param {naver.maps.LatLng} latlng latitude/longitude
         * @return {boolean} Whether the location is within the cluster boundary area
         */
        isInBounds: function(latlng) {
            return this._clusterBounds && this._clusterBounds.hasLatLng(latlng);
        },

        /**
         * Zoom operation is performed when clicking the cluster marker.
         */
        enableClickZoom: function() {
            if (this._relation) return;

            var map = this._markerClusterer.getMap();

            this._relation = naver.maps.Event.addListener(this._clusterMarker, 'click', naver.maps.Util.bind(function(e) {
                map.morph(e.coord, map.getZoom() + 1);
            }, this));
        },

        /**
         * Do not zoom when clicking on a cluster marker.
         */
        disableClickZoom: function() {
            if (!this._relation) return;

            naver.maps.Event.removeListener(this._relation);
            this._relation = null;
        },

        /**
         * If no cluster marker exists, create a cluster marker and update the cluster marker.
         * - cluster marker icon
         * - number of markers
         * - Whether cluster markers are exposed
         */
        updateCluster: function() {
            if (!this._clusterMarker) {
                var position;

                if (this._markerClusterer.getAverageCenter()) {
                    position = this._calcAverageCenter(this._clusterMember);
                } else {
                    position = this._clusterCenter;
                }

                this._clusterMarker = new naver.maps.Marker({
                    position: position,
                    map: this._markerClusterer.getMap()
                });

                if (!this._markerClusterer.getDisableClickZoom()) {
                    this.enableClickZoom();
                }
            }

            this.updateIcon();
            this.updateCount();

            this.checkByZoomAndMinClusterSize();
        },

        /**
         * Depending on conditions, cluster markers are exposed or not exposed.
         */
        checkByZoomAndMinClusterSize: function() {
            var clusterer = this._markerClusterer,
                minClusterSize = clusterer.getMinClusterSize(),
                maxZoom = clusterer.getMaxZoom(),
                currentZoom = clusterer.getMap().getZoom();

            if (this.getCount() < minClusterSize) {
                this._showMember();
            } else {
                this._hideMember();

                if (maxZoom <= currentZoom) {
                    this._showMember();
                }
            }
        },

        /**
         * Update the number of markers that make up the cluster.
         */
        updateCount: function() {
            var stylingFunction = this._markerClusterer.getStylingFunction();

            stylingFunction && stylingFunction(this._clusterMarker, this.getCount());
        },

        /**
         * Update cluster marker icons.
         */
        updateIcon: function() {
            var count = this.getCount(),
                index = this._getIndex(count),
                icons = this._markerClusterer.getIcons();

            index = Math.max(index, 0);
            index = Math.min(index, icons.length - 1);

            this._clusterMarker.setIcon(icons[index]);
        },

        /**
         * Expose the markers that make up the cluster. At this time, we do not expose cluster markers.
         * @private
         */
        _showMember: function() {
            var map = this._markerClusterer.getMap(),
                marker = this._clusterMarker,
                members = this._clusterMember;

            for (var i = 0, ii = members.length; i < ii; i++) {
                members[i].setMap(map);
            }

            if (marker) {
                marker.setMap(null);
            }
        },

        /**
         * Does not expose the markers that make up the cluster. This time exposes cluster markers.
         * @private
         */
        _hideMember: function() {
            var map = this._markerClusterer.getMap(),
                marker = this._clusterMarker,
                members = this._clusterMember;

            for (var i = 0, ii = members.length; i < ii; i++) {
                members[i].setMap(null);
            }

            if (marker && !marker.getMap()) {
                marker.setMap(map);
            }
        },

        /**
         * Returns the cluster bounding area centered on the passed latitude/longitude and extended by the grid size.
         * @param {naver.maps.LatLng} position latitude/longitude
         * @return {naver.maps.LatLngBounds} cluster boundary area
         * @private
         */
        _calcBounds: function(position) {
            var map = this._markerClusterer.getMap(),
                bounds = new naver.maps.LatLngBounds(position.clone(), position.clone()),
                mapBounds = map.getBounds(),
                proj = map.getProjection(),
                map_max_px = proj.fromCoordToOffset(mapBounds.getNE()),
                map_min_px = proj.fromCoordToOffset(mapBounds.getSW()),
                max_px = proj.fromCoordToOffset(bounds.getNE()),
                min_px = proj.fromCoordToOffset(bounds.getSW()),
                gridSize = this._markerClusterer.getGridSize() / 2;

            max_px.add(gridSize, -gridSize);
            min_px.add(-gridSize, gridSize);

            var max_px_x = Math.min(map_max_px.x, max_px.x),
                max_px_y = Math.max(map_max_px.y, max_px.y),
                min_px_x = Math.max(map_min_px.x, min_px.x),
                min_px_y = Math.min(map_min_px.y, min_px.y),
                newMax = proj.fromOffsetToCoord(new naver.maps.Point(max_px_x, max_px_y)),
                newMin = proj.fromOffsetToCoord(new naver.maps.Point(min_px_x, min_px_y));

            return new naver.maps.LatLngBounds(newMin, newMax);
        },

        /**
         * Returns an index to determine which icon to expose based on the number of markers that make up the cluster.
         * @param {number} count the number of markers that make up the cluster
         * @return {number} index
         * @private
         */
        _getIndex: function(count) {
            var indexGenerator = this._markerClusterer.getIndexGenerator();

            if (naver.maps.Util.isFunction(indexGenerator)) {
                return indexGenerator(count);
            } else if (naver.maps.Util.isArray(indexGenerator)) {
                var index = 0;

                for (var i = index, ii = indexGenerator.length; i < ii; i++) {
                    var factor = indexGenerator[i];

                    if (count < factor) break;

                    index++;
                }

                return index;
            }
        },

        /**
         * Returns whether the passed marker already belongs to a cluster.
         * @param {naver.maps.Marker} marker marker
         * @return {boolean} whether it belongs to a cluster
         * @private
         */
        _isMember: function(marker) {
            return this._clusterMember.indexOf(marker) !== -1;
        },

        /**
         * Returns the center coordinates of the passed markers.
         * @param {Array.<naver.maps.Marker>} markers array of markers
         * @return {naver.maps.Point} Center coordinates of markers
         * @private
         */
        _calcAverageCenter: function(markers) {
            var numberOfMarkers = markers.length;
            var averageCenter = [0, 0];

            for (var i = 0; i < numberOfMarkers; i++) {
                averageCenter[0] += markers[i].position.x;
                averageCenter[1] += markers[i].position.y;
            }

            averageCenter[0] /= numberOfMarkers;
            averageCenter[1] /= numberOfMarkers;

            return new naver.maps.Point(averageCenter[0], averageCenter[1]);
        }


    };

    return MarkerClustering
}

export default makeMarkerClustering;
