export default {
    REDIRECT: "REDIRECT",
    ASYNC_START: "ASYNC_START",
    ASYNC_END: "ASYNC_END",
    RESET: "RESET",
    SHOW_SUCCESS_MESSAGE: "SHOW_SUCCESS_MESSAGE",
    RESET_MESSAGE_MODAL: "RESET_MESSAGE_MODAL",
    START_DESTINATION_PAGE: "START_DESTINATION_PAGE",
    SHOW_MODAL: "SHOW_MODAL",
    HIDE_MODAL: "HIDE_MODAL"
}