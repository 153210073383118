import defaultValues from "constants/defaultValues";
import cookiesManager from "managers/cookies.manager";
import { authActionTypes } from "redux/types";

var latestAction = {};

const saveTokenToJwt = () => {
    if (!latestAction.payload.data){ return; }
    cookiesManager.setAccessToken(
        latestAction.payload.data.token
    )
}

const removeTokenFromJwt = () => {
    cookiesManager.setAccessToken(
        defaultValues.emptyString
    )
    cookiesManager.setCurrentUser(
        null
    );
}

const authActions = {
    [authActionTypes.LOGIN]: saveTokenToJwt,
    [authActionTypes.LOGOUT]: removeTokenFromJwt
}

const authAction = (type) => {
    if (!(type in authActions)){
        return () => { return; }
    }
    return authActions[type];
}

export default () => next => action => {
    latestAction = action;
    if (action.error || action.subtype) {
        next(action);
        return;
    }
    authAction(action.type)();
    next(action);
};