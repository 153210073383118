import { EmployeeLoginHistory } from "models/objects/EmployeeLoginHistory";
import { Pagination } from "models/objects/Pagination";

export class ResponseFetchEmployeeLoginHistory {
    constructor(
        loginHistories = [],
        pagination = new Pagination()
    ) {
        this.loginHistories = loginHistories;
        this.pagination = pagination;
    }

    mapFromJson(json) {
        if (!json) { return this; }
        this.loginHistories = json.data.map(
            (loginHistoryJson) => new EmployeeLoginHistory().mapFromJson(loginHistoryJson)
        )
        this.pagination = new Pagination(
            json['currentPage'],
            json['totalItems'],
            json['totalPages']
        );
        return this;
    }
}