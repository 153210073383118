import styled from 'styled-components';
import tw from "twin.macro";

const Container = styled.div`
    width: 450px;
    max-width: 90%;
    max-height: 90%;
    ${tw `flex flex-col m-auto bg-white overflow-y-auto p-8`}
`;

const CloseButton = styled.button`
    width: 20px;
    height: 20px;
    ${tw `ml-auto`}
`;

export {
    Container,
    CloseButton
}
