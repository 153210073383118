import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as styled from './RentalHistoryForm.styled';
import CardSection from 'components/CardSection/CardSection';
import { titleStrings, tableHeaderStrings } from 'localizations';
import MergeIconWhite from 'assets/merge_icon.png';
import InsertRowIcon from 'assets/insert_row_icon.png';
import RemoveRowIcon from 'assets/remove_row_icon.png';
import { observer } from 'mobx-react-lite';
import { useSelector } from 'react-redux';
import MergeTableView from 'components/core/merge-table/MergeTableView';
import MergeTableViewHook from 'components/core/merge-table/MergeTableViewHook';
import fieldNames from 'constants/fieldNames';
import alignments from 'constants/alignments';
import TextInput from 'components/core/text-input/TextInput';
import CheckboxInput from 'components/core/input-checkbox/CheckboxInput';
import InactiveTextInputStyle from 'components/core/text-input/styles/InactiveTextInputStyle';
import PlainTextInputStyle from 'components/core/text-input/styles/PlainTextInputStyle';
import textInputType from 'enumerations/textInputType';
import Text from 'components/core/text/Text';
import HighlightedRedTextStyle from 'components/core/text/styles/HighlightedRedTextStyle';
import HighlightedTextStyle from 'components/core/text/styles/HighlightedTextStyle';
import { RentalHistory } from 'models/objects/RentalHistory';
import useRentalHistoryPresenter from './useRentalHistoryPresenter';
import * as defaultValues from 'constants/defaultValue';
import rentalHistoryColumns from 'enumerations/rentalHistoryColumns';
import formStates from 'enumerations/formStates';
import InactiveTextStyle from 'components/core/text/styles/InactiveTextStyle';
import PlainTextStyle from 'components/core/text/styles/PlainTextStyle';

const CardPageContentInnerRentalHistoryForm = observer((props) => {
  const requestWriteCard = useSelector((state) => state.card.requestWriteCard);
  const cardReducer = useSelector((state) => state.card);
  const hook = useRentalHistoryPresenter();
  const mergeTableViewHook = MergeTableViewHook();

  const createRentalHistory = () => {
    if (mergeTableViewHook.selectedCells.isEmpty()) {
      requestWriteCard.rentalHistories.insert(
        requestWriteCard.rentalHistories.length,
        new RentalHistory()
      );
      return;
    }
    const selectedCells = mergeTableViewHook.selectedCells.sort(
      (item01, item02) => item01.row - item02.row
    );
    const latestCell = selectedCells.last();
    if (!latestCell) {
      return;
    }
    const span = latestCell.rowSpan.isEmpty()
      ? defaultValues.mergeSpan
      : latestCell.rowSpan;
    requestWriteCard.rentalHistories.insert(
      Math.max(...mergeTableViewHook.selectedCells.map((x) => x.row)) + span,
      new RentalHistory()
    );
  };

  const insertNewColumnItem = () => {
    createRentalHistory();
    props.handleInputValueChange({
      name: 'rentalHistories',
      value: requestWriteCard.rentalHistories,
    });
  };

  const removeRentalHistory = () => {
    if (mergeTableViewHook.selectedCells.isEmpty()) {
      requestWriteCard.rentalHistories.splice(
        requestWriteCard.rentalHistories.length - 1,
        defaultValues.mergeSpan
      );
      return;
    }
    const selectedCells = mergeTableViewHook.selectedCells.sort(
      (item01, item02) => item01.row + item02.row
    );
    for (const selectedCell of selectedCells) {
      requestWriteCard.rentalHistories.splice(
        selectedCell.row,
        selectedCell.rowSpan
      );
    }
  };

  const removeCellItem = () => {
    removeRentalHistory();
    mergeTableViewHook.onRemoveCell();
    props.handleInputValueChange({
      name: 'rentalHistories',
      value: requestWriteCard.rentalHistories,
    });
  };

  const renderRightNavigationMenu = () => {
    if (cardReducer.formState == formStates.read) {
      return null;
    }
    return (
      <styled.RightNavigationMenu>
        <button
          className="flex space-x-3"
          onClick={() => {
            mergeTableViewHook.startMergeColumns(
              mergeTableViewHook.selectedCells,
              hook.onMergeSuccess,
              hook.onUnmergeSuccess
            );
          }}>
          <img
            src={MergeIconWhite}
            width={32}
            alt=""
          />
          <p className="text-primary my-auto">병합하고 가운데 맞춤</p>
        </button>
        <button
          className="flex space-x-3"
          onClick={insertNewColumnItem}>
          <img
            src={InsertRowIcon}
            width={32}
            alt=""
          />
          <p className="text-primary my-auto">셀삽입</p>
        </button>
        <button
          className="flex space-x-3"
          onClick={removeCellItem}>
          <img
            src={RemoveRowIcon}
            width={32}
            alt=""
          />
          <p className="text-primary my-auto">셀삭제</p>
        </button>
      </styled.RightNavigationMenu>
    );
  };

  const renderHeaders = () => {
    return hook.tableHeaders.map((tableHeader, index) => {
      return <p key={index}>{tableHeader}</p>;
    });
  };

  const onTextInputChange = (target, index) => {
    const rentalHistories = requestWriteCard.rentalHistories;
    let _index = index;
    const mergeKey = rentalHistories[index].getMergeKeyByName(target.name);
    while (rentalHistories[_index][mergeKey]) {
      rentalHistories[_index][target.name] = target.value;
      _index++;
    }
    rentalHistories[_index][target.name] = target.value;
    props.handleInputValueChange({
      name: 'rentalHistories',
      value: rentalHistories,
    });
  };

  const _renderCheckboxColumn = (floorInformation, index) => {
    return (
      <CheckboxInput
        key={0}
        checked={floorInformation.isExcluded}
        onChange={() => hook.onExcludeRentalHistory(index)}
      />
    );
  };

  const _renderTextColumn = (key, value, type, isExcluded = false) => {
    return (
      <Text
        key={key}
        alignment={alignments.center}
        value={
          type == textInputType.currency
            ? value.toString().thousandFormat()
            : value
        }
        style={isExcluded ? InactiveTextStyle : PlainTextStyle}
      />
    );
  };

  const _renderTextInput = (
    key = defaultValues.emptyInt,
    index = defaultValues.emptyInt,
    value = defaultValues.emptyString,
    name = defaultValues.emptyString,
    type = textInputType.text,
    isExcluded = false
  ) => {
    if (cardReducer.formState == formStates.read) {
      return _renderTextColumn(key, value, type, isExcluded);
    }
    return (
      <TextInput
        key={key}
        alignment={alignments.center}
        type={type}
        name={name}
        onChange={(target) => onTextInputChange(target, index)}
        value={value}
        style={isExcluded ? InactiveTextInputStyle : PlainTextInputStyle}
      />
    );
  };

  const _renderTableRow = (rentalHistory, index) => {
    let tableRow = [
      {
        isMerged: rentalHistory.isFloorMergedDown,
        content: _renderTextInput(
          rentalHistory.id,
          index,
          rentalHistory.floor,
          fieldNames.floor,
          textInputType.text,
          rentalHistory.isExcluded
        ),
      },
      {
        isMerged: rentalHistory.isAreaMergedDown,
        content: _renderTextInput(
          rentalHistory.id,
          index,
          rentalHistory.areaInPY,
          'areaInPY',
          textInputType.currency,
          rentalHistory.isExcluded
        ),
      },
      {
        isMerged: rentalHistory.isUsageMergedDown,
        content: _renderTextInput(
          rentalHistory.id,
          index,
          rentalHistory.usage,
          fieldNames.usage,
          textInputType.text,
          rentalHistory.isExcluded
        ),
      },
      {
        isMerged: rentalHistory.isDepositMergedDown,
        content: _renderTextInput(
          rentalHistory.id,
          index,
          rentalHistory.deposit,
          'deposit',
          textInputType.currency,
          rentalHistory.isExcluded
        ),
      },
      {
        isMerged: rentalHistory.isMonthlyRentalMergedDown,
        content: _renderTextInput(
          rentalHistory.id,
          index,
          rentalHistory.monthlyFee,
          'monthlyFee',
          textInputType.currency,
          rentalHistory.isExcluded
        ),
      },
      {
        isMerged: rentalHistory.isAdminPriceMergedDown,
        content: _renderTextInput(
          rentalHistory.id,
          index,
          rentalHistory.adminPrice,
          fieldNames.adminPrice,
          textInputType.currency,
          rentalHistory.isExcluded
        ),
      },
      {
        isMerged: rentalHistory.isRentalDateMergedDown,
        content: _renderTextInput(
          rentalHistory.id,
          index,
          rentalHistory.rentalDate,
          'rentalDate',
          textInputType.text,
          rentalHistory.isExcluded
        ),
      },
      {
        isMerged: rentalHistory.isNoteMergedDown,
        content: _renderTextInput(
          rentalHistory.id,
          index,
          rentalHistory.note,
          fieldNames.note,
          textInputType.text,
          rentalHistory.isExcluded
        ),
      },
      {
        isMerged: rentalHistory.isAreaMergedDown,
        content: _renderTextColumn(
          rentalHistory.id,
          hook.getRentFee(rentalHistory).toString().thousandFormat(),
          rentalHistory.isExcluded
        ),
      },
    ];
    if (cardReducer.formState != formStates.read) {
      tableRow.insert(defaultValues.emptyInt, {
        isMerged: false,
        content: _renderCheckboxColumn(rentalHistory, index),
      });
    }
    return tableRow;
  };

  const renderRows = () => {
    return hook.rentalHistories.map((rentalHistory, index) => {
      return _renderTableRow(rentalHistory, index);
    });
  };

  const renderFooter = () => {
    let tableFooters = [
      <Text
        key={1}
        alignment={alignments.center}
        style={HighlightedTextStyle}
        value="합계"
      />,
      <Text
        key={2}
        alignment={alignments.center}
        value={hook.getTotalAreaInPy().rounded(2).toString().thousandFormat()}
        style={HighlightedTextStyle}
      />,
      <div key={3} />,
      <Text
        key={4}
        alignment={alignments.center}
        value={`${hook.setupTotalDeposit()}`}
        style={HighlightedTextStyle}
      />,
      <Text
        key={5}
        alignment={alignments.center}
        value={hook.getTotalMonthlyFee().toString().thousandFormat()}
        style={HighlightedTextStyle}
      />,
      <Text
        key={6}
        alignment={alignments.center}
        value={`${hook.setupTotalAdminPrice()}`}
        style={HighlightedTextStyle}
      />,
      <div key={7} />,
      <div key={8} />,
      <Text
        key={9}
        alignment={alignments.center}
        value={`${hook.setupTotalRentFee()}`}
        style={HighlightedTextStyle}
      />,
    ];
    if (cardReducer.formState != formStates.read) {
      tableFooters.insert(defaultValues.emptyInt, <div key={0} />);
    }
    return tableFooters;
  };

  const renderContent = () => {
    return (
      <styled.Container>
        <styled.UnitDescriptionText>
          {`[${titleStrings.unit}: ${titleStrings.pyeong} / ${titleStrings.tenThousandWon}/ ${tableHeaderStrings.vatExcluded}]`}
        </styled.UnitDescriptionText>
        <MergeTableView
          isMergeable={cardReducer.formState != formStates.read}
          headers={renderHeaders()}
          rows={renderRows()}
          footerColumns={renderFooter()}
          hook={mergeTableViewHook}
        />
      </styled.Container>
    );
  };

  useEffect(() => {
    hook.setRequestWriteCard(cardReducer.requestWriteCard);
    mergeTableViewHook.setOnMergeSuccess(() => hook.didMergeSucceed);
  }, [cardReducer]);

  useEffect(() => {
    hook.setExcludedCells(props.excludedRentalHistories);
  });

  useEffect(() => {
    hook.excludedRentalHistories = props.excludedRentalHistories;
    mergeTableViewHook.setUnselectableColumns([0, 9]);
    mergeTableViewHook.setColumnChains([
      [
        rentalHistoryColumns.enums.monthlyFee,
        rentalHistoryColumns.enums.totalRentFee,
      ],
    ]);
  }, defaultValues.emptyArray);

  return (
    <CardSection
      title={titleStrings.rentalHistory}
      content={renderContent()}
      rightNavigationMenu={renderRightNavigationMenu()}
    />
  );
});

CardPageContentInnerRentalHistoryForm.propTypes = {
  onExcludeCell: PropTypes.func,
  excludedRentalHistories: PropTypes.array,
};

CardPageContentInnerRentalHistoryForm.defaultProps = {
  onExcludeCell: () => {
    return;
  },
  excludedRentalHistories: [],
};

export default CardPageContentInnerRentalHistoryForm;
