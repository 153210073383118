import defaultValues from "constants/defaultValues";
import fieldStrings from "localizations/fieldStrings";
import { RequestCreateLand } from "models/requests/RequestCreateLand";

export class LandInformation {
    constructor(
        id = defaultValues.emptyInt,
        area = defaultValues.emptyInt,
        areaPy = defaultValues.emptyFloat,
        category = defaultValues.emptyString,
        areaRatio = defaultValues.emptyInt,
        roadWidth = defaultValues.emptyInt,
        officialPrice = defaultValues.emptyInt,
        officialPricePy = defaultValues.emptyInt,
        isCorner = false,
        isSide = false,
        usage = defaultValues.emptyString
    ) {
        this.id = id;
        this.area = area;
        this.areaPy = areaPy;
        this.category = category;
        this.areaRatio = areaRatio;
        this.roadWidth = roadWidth;
        this.officialPrice = officialPrice;
        this.officialPricePy = officialPricePy;
        this.isCorner = isCorner;
        this.isSide = isSide;
        this.usage = usage;
    }

    mapFromJson(json) {
        if (!json) { return this; }
        this.id = json['id'];
        this.area = json['area'];
        this.areaPy = json['area_py'] ?? defaultValues.emptyFloat;
        this.category = json['category'];
        this.areaRatio = json['cover_ratio'];
        this.roadWidth = json['road_width'];
        this.officialPrice = json['official_price'];
        this.officialPricePy = json['official_price_py'];
        this.isCorner = json['is_road_corner'];
        this.isSide = json['is_road_side'];
        this.usage = json['usage'];
        return this;
    }

    convertToEditRequest() {
        const requestEditLand = new RequestCreateLand();
        requestEditLand.category = this.category;
        requestEditLand.usage = this.usage;
        requestEditLand.roadWidth = this.roadWidth;
        requestEditLand.isCorner = this.isCorner;
        requestEditLand.isSide = this.isSide;
        return requestEditLand
    }

    getRoadPosition = () => {
        let extraInfos = defaultValues.emptyArray;
        if (this.isCorner) {
            extraInfos = [
                ...extraInfos,
                fieldStrings.corner
            ];
        }
        if (this.isSide) {
            extraInfos = [
                ...extraInfos,
                fieldStrings.bothSides
            ];
        }
        return extraInfos.join(",");
    }
}