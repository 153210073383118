import React from 'react'
import * as defaultValues from 'constants/defaultValue';
import TableList from 'components/core/table-list/TableList'
import { CenterBorderedTableListStyle } from 'components/core/table-list/styles/index';
import Modal from 'components/core/modal/Modal';
import PropTypes from 'prop-types';
import CloseIconSrc from 'assets/close_icon_black.png'
import ImageAlt from 'constants/imageAlt'
import {
    titleStrings
} from 'localizations/index'
import SearchCustomerForm from 'layouts/forms/SearchCustomerForm';
import * as commonActions from 'redux/reducers/commonReducer';
import * as styled from './SearchCustomerModal.styled';
import { useState } from 'react';

const SearchCustomerModal = () => {
    const [isSearchComplete, setIsSearchComplete] = useState(false);
    const [customerTableItems, setCustomerTableItems] = useState(defaultValues.emptyArray)

    const _flushData = () => {
        setIsSearchComplete(false);
        setCustomerTableItems(defaultValues.emptyArray);
    }

    const customerListHeaders = [
        titleStrings.name,
        titleStrings.phone,
        titleStrings.companyPhone,
        titleStrings.manager
    ];

    const onFetchCustomerSuccess = (customers) => {
        setupCustomerTableItems(customers);
    }

    const setupCustomerTableItems = (customers) => {
        setCustomerTableItems(
            customers.map(
                (customer) => [
                    customer.name,
                    customer.phone,
                    customer.companyPhone,
                    customer.manager.name
                ]
            )
        )
    };

    const renderCustomerTableList = () => {
        return (
            <TableList
                className="mt-5"
                isHidden={ !isSearchComplete }
                datas={ customerTableItems }
                headers={ customerListHeaders }
                style={ CenterBorderedTableListStyle }  />
        )
    }

    const renderContent = () => {
        return (
            <styled.Container>
                <styled.CloseButton
                    onClick={ commonActions.hideModal }>
                    <img
                        src={ CloseIconSrc }
                        alt={ ImageAlt.closeIcon } />
                </styled.CloseButton>
                <p className="font-bold text-xl text-left">
                    { titleStrings.search }
                </p>
                <SearchCustomerForm
                    onResetState={ _flushData }
                    onFetchCustomerSuccess={ onFetchCustomerSuccess } />
                { renderCustomerTableList() }
            </styled.Container>
        )
    }

    return (
        <Modal
            content={ renderContent() }
            isShow={ true } />
    );
}

SearchCustomerModal.propTypes = {
    onDismissModal: PropTypes.func
};

SearchCustomerModal.defaultProps = {
    onDismissModal: () => { return; }
};

export default SearchCustomerModal;