import * as defaultValues from "constants/defaultValue";

export class RequestViewProperty {
    constructor(
        propertyId = defaultValues.emptyInt
    ) {
        this.propertyId = propertyId
    }

    mapToJson() {
        return {
            'property_id': this.propertyId
        }
    }
}