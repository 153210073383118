import React from 'react';
import PropTypes from 'prop-types';
import * as styled from './CardPageContentInner.styled';
import cookiesManager from 'managers/cookies.manager';
import CardPageContentInnerReadState from 'layouts/PropertyCardLayouts/CardPageContentInnerReadState/CardPageContentInnerReadState';
import CardPageContentInnerWriteState from 'layouts/PropertyCardLayouts/CardPageContentInnerWriteState/CardPageContentInnerWriteState';
import { useSelector } from 'react-redux';
import { Card } from 'models/objects/Card';
import formStates from 'enumerations/formStates';

const CardPageContentAddress = (props) => {
  const cardReducer = useSelector((state) => state.card);
  const currentUser = cookiesManager.getCurrentUser();

  const renderContent = () => {
    if (cardReducer.formState == formStates.read) {
      return <CardPageContentInnerReadState currentUser={currentUser} />;
    }
    return (
      <CardPageContentInnerWriteState
        currentCard={cardReducer.card}
        currentUser={currentUser}
        onEditRequestChange={props.onEditRequestChange}
        onChangePropertyImage={props.onChangePropertyImage}
      />
    );
  };

  return <styled.Container>{renderContent()}</styled.Container>;
};

CardPageContentAddress.propTypes = {
  card: PropTypes.object,
  onEditRequestChange: PropTypes.func,
  onChangePropertyImage: PropTypes.func,
};

CardPageContentAddress.defaultProps = {
  card: new Card(),
  onEditRequestChange: () => {
    return;
  },
  onChangePropertyImage: () => {
    return;
  },
};

export default CardPageContentAddress;
