export default {
    FETCH_EMPLOYEE_LIST: "FETCH_EMPLOYEE_LIST",
    FETCH_LOGIN_HISTORY_LIST: "FETCH_LOGIN_HISTORY_LIST",
    FETCH_ALL_ROLE: "FETCH_ALL_ROLE",
    CREATE_EMPLOYEE: "CREATE_EMPLOYEE",
    SELECT_EMPLOYEE_TAB: "SELECT_EMPLOYEE_TAB",
    DELETE_EMPLOYEE: "DELETE_EMPLOYEE",
    DELETE_LOGIN_HISTORY: "DELETE_LOGIN_HISTORY",
    UPDATE_EMPLOYEE: "UPDATE_EMPLOYEE",
    UPDATE_EMPLOYEE_MEMO: "UPDATE_EMPLOYEE_MEMO"
}