import dateFormats from "constants/dateFormats";
import defaultValues from "constants/defaultValues";
import moment from "moment";
import { Employee } from "./Employee";

export class EmployeeLoginHistory {
    constructor(
        id = defaultValues.emptyInt,
        ipAddress = defaultValues.emptyString,
        lastLogin = defaultValues.emptyString,
        lastActivity = defaultValues.emptyString,
        employee = new Employee()
    ) {
        this.id = id;
        this.ipAddress = ipAddress;
        this.lastLogin = lastLogin;
        this.lastActivity = lastActivity;
        this.employee = employee;
    }

    mapFromJson(json) {
        if (!json) { return this; }
        this.id = json['id'];
        this.ipAddress = json['ip_address'];
        this.lastLogin = moment(json['last_login']).format(dateFormats.dateTime);
        this.lastActivity = json['last_activity'];
        this.employee = new Employee().mapFromJson(json['user']);
        return this;
    }
}