import defaultValues from "constants/defaultValues";

export class RequestCheckCustomer {
    constructor(
        phone = defaultValues.emptyString,
        companyPhone = defaultValues.emptyString,
    ) {
        this.phone = phone;
        this.companyPhone = companyPhone;
    }

    mapToJson() {
        return {
            "phone": this.phone,
            "company_phone": this.companyPhone
        }
    }
}