import { makeAutoObservable } from "mobx";

export class BuildingFormViewModel {
    airConditioners = [];
    parkingMethods = [];
    onInputValueChange = () => { return }
    
    constructor() {
        makeAutoObservable(this);
    }

    setupSelectedAirConditionerItem = (airConditioner) => {
        return {
            key: airConditioner.id,
            value: airConditioner.name
        }
    }

    setupSelectedParkingMethodItem = (parkingMethod) => {
        return {
            key: parkingMethod.id,
            value: parkingMethod.name
        }
    }

    onSelectAirConditioner = (item) => {
        const selectedAirConditioner = this.airConditioners.find((airConditioner => {
            return airConditioner.id == item.key
        }));
        this.onInputValueChange({
            name: "airConditioner",
            value: selectedAirConditioner
        });
    }

    onSelectParkingMethod = (item) => {
        const selectedParkingMethod = this.parkingMethods.find((parkingMethod) => {
            return parkingMethod.id == item.key
        })
        this.onInputValueChange({
            name: "parkingMethod",
            value: selectedParkingMethod
        });
    }
    
    setupAirConditionerItems = () => {
        return this.airConditioners.map((airConditioner) => {
            return {
                key: airConditioner.id,
                value: airConditioner.name
            }
        });
    }

    setupParkingMethodItems = () => {
        return this.parkingMethods.map((parkingMethod) => {
            return {
                key: parkingMethod.id,
                value: parkingMethod.name
            }
        });
    }

    didFetchAirConditionerSucceed = (airConditioners) => {
        this.airConditioners = airConditioners;
        this.setupAirConditionerItems()
    }

    didFetchParkingMethodsSucceed = (parkingMethods) => {
        this.parkingMethods = parkingMethods;
    }
}