import styled from "styled-components";
import tw from "twin.macro";

const Container = styled.div`
    ${ tw`pt-4 h-full space-y-4` }
`;
const TopHeaderContainer = styled.div`
    ${ tw`flex` }
`;
const ButtonWrapper = styled.div`
    ${ tw`ml-auto` }
`;
export {
    Container,
    TopHeaderContainer,
    ButtonWrapper
}