import React, { useEffect, useState } from 'react';
import CardPageContent from 'layouts/PropertyCardLayouts/CardPageContent/CardPageContent';
import CardPageTopMenu from 'layouts/PropertyCardLayouts/CardPageTopMenu/CardPageTopMenu';
import cardActionTypes from 'redux/types/cardActionTypes';
import * as styled from './CardPage.styled';
import * as cardActions from 'redux/reducers/cardReducer';
import { useDispatch } from 'react-redux';
import { observer } from 'mobx-react-lite';
import { RequestCreateCard } from 'models/requests/RequestCreateCard';
import formStates from 'enumerations/formStates';
import defaultValues from 'constants/defaultValues';
import useCardPage from './useCardPage';
import cardReducer from 'redux/reducers/cardReducer';
import { useSelector } from 'react-redux';

const CardPage = observer(() => {
  const hook = useCardPage();
  const [requestEditCard, setRequestEditCard] = useState(
    new RequestCreateCard()
  );
  const [selectedImage, setSelectedImage] = useState(null);
  const dispatch = useDispatch();

  const onEditRequestChange = (request) => {
    setRequestEditCard(request);
  };

  const onMoveToReadState = () => {
    cardActions.changeFormState(formStates.read);
    setSelectedImage(null);
    reloadData();
  };

  const reloadData = () => {
    hook.loadData();
  };

  useEffect(
    () => () => {
      dispatch({ type: cardActionTypes.RESET_STATE });
    },
    defaultValues.emptyArray
  );

  return (
    <styled.Wrapper>
      <CardPageContent
        requestEditCard={requestEditCard}
        selectedImage={selectedImage}
        onEditRequestChange={onEditRequestChange}
        onChangePropertyImage={onEditRequestChange}
        onStartReloadData={reloadData}
        onStartSavingProperty={() => hook.startSavingCard()}
        onShowSavingConfirmation={() => hook.showSaveConfirmationModal()}
        onMoveToReadState={onMoveToReadState}
      />
    </styled.Wrapper>
  );
});

export default CardPage;
