import React from 'react';
import Button from '../../../components/core/button/Button';
import PropTypes from 'prop-types';
import { DarkGrayButtonStyle } from 'components/core/button/styles';
import Sizes from 'constants/sizes';
import { FieldStrings } from 'localizations';
import HorizontalTextInput from 'components/core/horizontal-text-input/HorizontalTextInput';
import buttonType from 'enumerations/buttonType';
import sizes from 'constants/sizes';
import buttonStrings from 'localizations/buttonStrings';
import { Employee } from 'models/objects/Employee';
import DropdownInput from 'components/core/dropdown-input/DropdownInput';
import { BorderedDropdownInputStyle } from 'components/core/dropdown-input/styles';
import DropdownInputViewModel from 'components/core/dropdown-input/DropdownInputViewModel';
import * as defaultValues from 'constants/defaultValue';
import { useEffect } from 'react';
import * as styled from './CreateEmployeeForm.styled';
import useCreateEmployeeForm from './useCreateEmployeeForm';
import Text from 'components/core/text/Text';
import HighlightedRedTextStyle from 'components/core/text/styles/HighlightedRedTextStyle';

const CreateEmployeeForm = (props) => {
  const hook = useCreateEmployeeForm();

  const renderUsernameInput = () => {
    return (
      <HorizontalTextInput
        name={'username'}
        title={FieldStrings.username}
        isDisabled={!props.selectedEmployee.username.isEmpty()}
        onChange={hook.onInputChange}
        value={hook.employee.username}
      />
    );
  };

  const renderNameInput = () => {
    return (
      <HorizontalTextInput
        name={'name'}
        title={FieldStrings.employeeName}
        onChange={hook.onInputChange}
        value={hook.employee.name}
      />
    );
  };

  const renderPasswordInput = () => {
    return (
      <HorizontalTextInput
        name={'password'}
        title={FieldStrings.password}
        onChange={hook.onInputChange}
        value={hook.employee.password}
      />
    );
  };

  const renderPhoneInput = () => {
    return (
      <HorizontalTextInput
        name={'phoneNumber'}
        title={FieldStrings.phone}
        onChange={hook.onInputChange}
        value={hook.employee.phoneNumber}
      />
    );
  };

  const renderEmailInput = () => {
    return (
      <HorizontalTextInput
        name={'email'}
        title={FieldStrings.email}
        onChange={hook.onInputChange}
        value={hook.employee.email}
      />
    );
  };

  const renderRoleInput = () => {
    return (
      <DropdownInput
        name={'role'}
        customValue={hook.employee.role.name}
        height={sizes.textInput.small}
        labelTitle={FieldStrings.rank}
        selectedOption={hook.selectedRole}
        onCustomValueChange={hook.onInputRole}
        handleSelectOption={hook.onSelectRole}
        style={BorderedDropdownInputStyle}
        options={setupRoleTableItems()}
      />
    );
  };

  const renderMacAddressInput = () => {
    return (
      <HorizontalTextInput
        name={'macAddress'}
        title={FieldStrings.ipAddress}
        onChange={hook.onInputChange}
        value={hook.employee.macAddress}
      />
    );
  };

  const setupRoleTableItems = () => {
    return hook.roles.map(
      (role) => new DropdownInputViewModel(role.id, role.name)
    );
  };

  const renderMessage = () => {
    if (hook.errorMessage.isEmpty()) {
      return null;
    }
    return (
      <styled.MessageWrapper>
        <Text
          value={hook.errorMessage}
          style={HighlightedRedTextStyle}
        />
      </styled.MessageWrapper>
    );
  };

  const renderContent = () => {
    return (
      <div>
        {renderMessage()}
        <styled.InputContainer>
          {renderUsernameInput()}
          {renderNameInput()}
          {renderPasswordInput()}
          {renderPhoneInput()}
          {renderEmailInput()}
          {renderRoleInput()}
          {renderMacAddressInput()}
        </styled.InputContainer>
        <Button
          action={hook.onStartCreateEmployee}
          height={Sizes.button.small}
          style={DarkGrayButtonStyle}
          title={
            props.selectedEmployee.name.isEmpty()
              ? buttonStrings.registration
              : buttonStrings.edit
          }
          width={sizes.button.full}
          viewModel={hook.buttonViewModel}
          type={buttonType.submit}
        />
      </div>
    );
  };

  useEffect(() => {
    hook.setEmployee(props.selectedEmployee);
    hook.setOnCreateEmployeeSuccess(() => props.onCreateEmployeeSuccess);
  }, defaultValues.emptyArray);

  return renderContent();
};

CreateEmployeeForm.propTypes = {
  name: PropTypes.string,
  selectedEmployee: PropTypes.object,
  onCreateEmployeeSuccess: PropTypes.func,
  fetchAllRoleList: PropTypes.func,
  reset: PropTypes.func,
  buttonTitle: PropTypes.string,
};

CreateEmployeeForm.defaultProps = {
  selectedEmployee: new Employee(),
  onCreateEmployeeSuccess: () => {
    return;
  },
  reset: () => {
    return;
  },
  fetchAllRoleList: () => {
    return;
  },
};

export default CreateEmployeeForm;
