import { store } from 'configs/store';
import defaultValues from 'constants/defaultValues';
import zIndex from 'constants/zIndex';
import { Message } from 'models/objects/Message';
import {
    authActionTypes,
    commonActionTypes,
    employeeActionTypes
} from 'redux/types';

const states = (state, action) => {
    return {
        [commonActionTypes.ASYNC_START]: () => {
            return {
                ...state,
                inProgress: true
            }
        },
        [commonActionTypes.REDIRECT]: () => {
            return {
                ...state,
                redirectTo: null
            }
        },
        [authActionTypes.LOGIN]: () => {
            return {
                ...state,
                redirectTo: action.error ? null : '/map'
            }
        },
        [authActionTypes.LOGOUT]: () => {
            return {
                ...state,
                redirectTo: '/'
            }
        },
        [employeeActionTypes.SELECT_EMPLOYEE_TAB]: () => {
            return {
                ...state,
                redirectTo: action.payload
            }
        },
        [commonActionTypes.SHOW_SUCCESS_MESSAGE]: () => {
            return {
                ...state,
                successMessage: action.payload
            }
        },
        [commonActionTypes.RESET_MESSAGE_MODAL]: () => {
            return {
                ...state,
                successMessage: new Message(),
                errorMessage: defaultValues.emptyString
            }
        },
        [commonActionTypes.START_DESTINATION_PAGE]: () => {
            return {
                ...state,
                redirectTo: action.payload.destination,
                redirectData: action.payload.data ?? null
            }
        },
        [commonActionTypes.RESET]: () => {
            return {
                ...state,
                redirectData: null
            }
        },
        [commonActionTypes.SHOW_MODAL]: () => {
            return {
                ...state,
                zIndex: action.payload.zIndex || zIndex.defaultModal,
                modalContent: action.payload.content
            }
        },
        [commonActionTypes.HIDE_MODAL]: () => {
            return {
                ...state,
                modalContent: null
            }
        }
    }
}

export default (state = {}, action) => {
    if (!(action.type in states(state, action))) {
        return {
            ...state,
            successMessage: state.successMessage || new Message()
        }
    }
    return states(state, action)[action.type]();
}

export const redirectTo = (destination, data) => {
    store.dispatch(
        {
            type: commonActionTypes.START_DESTINATION_PAGE,
            payload: {
                destination: destination,
                data: data
            }
        }
    )
}

export const showModal = (zIndex, content) => {
    store.dispatch(
        {
            type: commonActionTypes.SHOW_MODAL,
            payload: {
                zIndex: zIndex.defaultModal,
                content: content
            }
        }
    )
}

export const hideModal = () => {
    store.dispatch(
        {
            type: commonActionTypes.HIDE_MODAL
        }
    )
}

export const showMessageModal = (message) => {
    store.dispatch(
        {
            type: commonActionTypes.SHOW_SUCCESS_MESSAGE,
            payload: message
        }
    )
}