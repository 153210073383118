import LocalizedStrings from 'react-localization';

export default new LocalizedStrings({
    en:{
        milionPerPyeong: (price = 0) => {
            return `${ price } million won per pyeong`
        },
        priceInPyeong: (price = 0) => {
            return `${ price } pyeong`
        }
    },
    kr: {
        milionPerPyeong: (price = 0) => {
            return `평당 ${ price }만원`
        },
        priceInPyeong: (price = 0) => {
            return `${ price }만원`
        }
    }
});
