import React from 'react';
import TextInputModal from 'components/TextInputModal/TextInputModal';
import * as defaultValues from 'constants/defaultValue';
import zIndex from 'constants/zIndex';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { commonActionTypes } from 'redux/types';
import { hintStrings, titleStrings } from 'localizations';
import buttonStrings from 'localizations/buttonStrings';
import { TextInputModalViewModel } from 'components/TextInputModal/TextInputModalViewModel';
import * as commonActions from 'redux/reducers/commonReducer';
import * as cardActions from 'redux/reducers/cardReducer';
import { RequestCreateCard } from 'models/requests/RequestCreateCard';
import { useSelector } from 'react-redux';
import ConfirmationModal from 'layouts/modals/ConfirmationModal/ConfirmationModal';
import PaginationViewPresenter from 'components/PaginationView/PaginationViewPresenter';
import { useHistory } from 'react-router';
import formStates from 'enumerations/formStates';
import { useEffect } from 'react';
import queryString from 'query-string';

const useCardTopMenu = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const paginationViewPresenter = PaginationViewPresenter();
  const textInputModalViewModel = new TextInputModalViewModel();
  const cardReducer = useSelector((state) => state.card);
  const [saveProperty, setSavePropety] = useState(
    () => defaultValues.emptyFunction
  );
  const [startReadState, setStartReadState] = useState(
    () => defaultValues.emptyFunction
  );
  const [currentPage, setCurrentPage] = useState(defaultValues.emptyInt);
  const showInputAddressPopup = () => {
    textInputModalViewModel.title = titleStrings.enterNewAddress;
    textInputModalViewModel.placeholder = hintStrings.createAddressPlaceholder;
    textInputModalViewModel.buttonViewModel.title = buttonStrings.confirm;
    textInputModalViewModel.buttonViewModel.action = startPropertyForm;
    dispatch({
      type: commonActionTypes.SHOW_MODAL,
      payload: {
        zIndex: zIndex.defaultModal,
        content: (
          <TextInputModal
            onTapEnter={startPropertyForm}
            viewModel={textInputModalViewModel}
          />
        ),
      },
    });
  };

  const startPropertyForm = () => {
    let requestCreateCard = new RequestCreateCard();
    requestCreateCard.address =
      textInputModalViewModel.textInputViewModel.value;
    requestCreateCard.setFloorInformations(defaultValues.emptyArray);
    requestCreateCard.setRentalHistory(defaultValues.emptyArray);
    cardActions.updateRequestCreateCard(requestCreateCard);
    commonActions.hideModal();
    cardActions.changeFormState(formStates.create);
  };

  const showSaveConfirmationModal = (selectedPage) => {
    dispatch({
      type: commonActionTypes.SHOW_MODAL,
      payload: {
        zIndex: zIndex.defaultModal,
        content: (
          <ConfirmationModal
            title="변경사항을 저장 하시겠습니까a? (Save?)"
            primaryButtonTitle="네(YES)"
            secondaryButtonTitle="아니요(NO)"
            primaryAction={() => {
              saveProperty(() => didCreatePropertySucceed(selectedPage));
            }}
            secondaryAction={onCancelEditPage}
          />
        ),
      },
    });
  };

  const onCancelEditPage = () => {
    cardActions.changeFormState(formStates.read);
  };

  const didCreatePropertySucceed = (selectedPage) => {
    cardActions.updateCurrentPage(selectedPage);
    paginationViewPresenter.changePage(selectedPage);
  };

  const onStartPageChange = (page) => {
    if (cardReducer.formState != formStates.read) {
      saveProperty(() => updatePage(page));
      return;
    }
    updatePage(page);
    if (history.location.pathname == '/card') {
      cardActions.inquirySaleProperty(page);
      return;
    }
    cardActions.inquiryProperty(history.location);
  };

  const updatePage = (page) => {
    history.push(history.location.pathname + '?page=' + page);
  };

  const startPrintCardPage = () => {
    window.open(
      '/card/' + cardReducer.card.id + '/print',
      '_blank',
      'noopener,noreferrer'
    );
  };

  const _setupInitialPage = () => {
    const query = queryString.parse(history.location.search);
    setCurrentPage(query.page);
  };

  useEffect(() => _setupInitialPage(), [history.location]);

  useEffect(() => {
    if (cardReducer.formState == formStates.create) {
      setCurrentPage(cardReducer.pagination.totalPages);
      return;
    }
    _setupInitialPage();
  }, [cardReducer.formState]);

  return {
    showInputAddressPopup,
    setSavePropety,
    setStartReadState,
    onStartPageChange,
    showSaveConfirmationModal,
    startPrintCardPage,
    paginationViewPresenter,
    currentPage,
  };
};

export default useCardTopMenu;
