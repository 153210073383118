import styled from "styled-components";
import tw from "twin.macro";

const Container = styled.div`
    ${ tw`flex flex-col gap-5 py-4 w-full` }
`;
const IsFixCheckboxWrapper = styled.div`
    ${ tw`flex ml-auto col-span-2` }
`;
const OuterContentContainer = styled.div`
    ${ tw`grid grid-cols-4` }
`;
const AirConditionerDropdownWrapper = styled.div`
    ${ tw`col-span-2` }
`;
const FullFieldContainer = styled.div`
    ${ tw`flex col-span-1` }
`;
const ActualCourtFieldContainer = styled.div`
    ${ tw`grid grid-cols-3` }
`;
const DoubleColumnFieldContainer = styled.div`
    ${ tw`grid grid-cols-2` }
`;
const HalfContentContainer = styled.div`
    ${ tw`grid grid-cols-2 col-span-2` }
    ${ props => props.isTrailing && tw`ml-2` }
`;

export {
    Container,
    IsFixCheckboxWrapper,
    OuterContentContainer,
    HalfContentContainer,
    AirConditionerDropdownWrapper,
    FullFieldContainer,
    ActualCourtFieldContainer,
    DoubleColumnFieldContainer
}