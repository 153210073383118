import * as defaultValues from "constants/defaultValue";
import dateFormats from "constants/dateFormats";
import moment from "moment";

String.prototype.isEmpty = function () {
    return this.toString() == defaultValues.emptyString;
};

String.prototype.formatAsDate = function () {
    if (this.isEmpty()) { return defaultValues.emptyString; }
    return moment.utc(this).format(dateFormats.dateWithDots);
};

String.prototype.thousandFormat = function () {
    if (this.isEmpty()) { return defaultValues.emptyString; }
    return this.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
String.prototype.removethousandFormat = function () {
    if (this.isEmpty()) { return defaultValues.emptyString; }
    return this.replace(/,/g, defaultValues.emptyString);
};

String.prototype.withZeroTrailingPrefix = function () {
    return String(this).padStart(8, '0')
};

String.prototype.format = function() {
    let formatted = String(this);
    for (var i = 0; i < arguments.length; i++) {
        formatted = formatted.replace(
            new RegExp('\\{'+i+'\\}', 'gi'),
            arguments[i]
        );
    }
    return formatted;
};