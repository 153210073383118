import defaultValues from "constants/defaultValues";
import * as paginationSize from "constants/paginationSize";
import * as dateFormatterHelper from "helpers/dateFormatterHelper";
import { Pagination } from "models/objects/Pagination";
import { RequestPagination } from "./RequestPagination";

export class RequestFetchEmployeeList {
    constructor(
        keyword = defaultValues.emptyString,
        startDate = defaultValues.emptyString,
        endDate = defaultValues.emptyString,
        pagination = new RequestPagination()
    ) {
        this.keyword = keyword;
        this.startDate = startDate;
        this.endDate = endDate;
        this.pagination = pagination;
    }

    mapFromObject(anyObject) {
        this.keyword = anyObject.keyword;
        this.startDate = anyObject.startDate;
        this.endDate = anyObject.endDate;
        return this;
    }

    mapToJson() {
        return {
            'keyword': this.keyword,
            'page': this.pagination.page,
            'page_size': this.pagination.pageSize,
            'start_date': this.startDate,
            'end_date': this.endDate,
        }
    }
}