import styled from "styled-components";
import tw from "twin.macro";

const Container = styled.div`
    ${ tw`h-full` }
`;
const FieldTextWrapper = styled.div`
    min-width: 125px;
    height: 32px;
    ${ tw`flex` }
`;
const Text = styled.p`
    ${ tw`my-auto` }
`;

export {
    Container,
    FieldTextWrapper,
    Text
}