import styled from 'styled-components';
import tw from 'twin.macro';

const Wrapper = styled.div`
    ${tw`h-full`}
`;

const ContentWrapper = styled.div`
    ${
        props => `
            padding-top: ${ props.paddingTop }px;
        `
    }
    ${tw`h-full`}
`;

export {
    Wrapper,
    ContentWrapper
}