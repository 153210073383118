import { useState } from 'react';

const usePageSettingInput = (isExcluded, onChangeSetting) => {
  const printSettingOptions = [
    { key: 0, value: 'O' },
    { key: 1, value: 'X' },
  ];
  const [selectedSetting, setSelectedSetting] = useState(
    isExcluded ? printSettingOptions[1] : printSettingOptions[0]
  );

  const onSelectSetting = (setting) => {
    setSelectedSetting(setting);
    onChangeSetting(setting);
  };

  return {
    printSettingOptions,
    selectedSetting,
    onSelectSetting,
  };
};

export default usePageSettingInput;
