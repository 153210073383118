import React,{useEffect, useState} from 'react';
import * as styled from './LeftCard.styled';
import { AdjustmentsVerticalIcon, ArrowPathIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'

import Dropdown from 'components/Map/LeftCard/Dropdown';

import axios, {isCancel, AxiosError} from 'axios';


const LeftCard = (props) => {
    const theProps = props;

    const [inputSearch, setInputSearch] = useState('')
    const [modalState, setModalState] = useState(theProps.isOpen)
    const [status, setStatus] = useState(theProps.statusValue)

    const triggerModal = () => {
        setModalState(!modalState)
        theProps.modalCallback(modalState);
    }

    const triggerStatus = (e) => {
        let theTarget = e.target.id;
        setStatus(theTarget)
        theProps.statusCallback(theTarget);
    }

    const triggerOnChangeSearch = (e) =>{
        let theValue = e.target.value;

        setInputSearch(theValue);
        theProps.searchOnChangeCallback(theValue);
    }

    const triggerRefresh = () => {
        theProps.refreshCallback();
        setInputSearch('');
        setStatus('all')
    }

    const triggerSearchClick = () => {
        if (inputSearch.trim().length <= 0) return;

        theProps.searchCallback('searchBar');
    }

    const handlePinPointType = (e) => {
        theProps.pinPointTypeCallback(e);
    }

    
    useEffect(() => {
        // if (theProps.searchResult) {
            setInputSearch(theProps.searchResult);
        // }
    }, [theProps.searchResult])


    return(
        <styled.Wrapper>
            <styled.Container>
                <div className='
                        flex xl:flex-row lg:flex-col md:flex-col
                        p-2 space-between
                    '
                >
                    <styled.InputSearchContainer>
                        <styled.InputSearchBar
                            onChange={triggerOnChangeSearch}
                            value={inputSearch}
                        />
                        <styled.InputSearch
                            onClick={triggerSearchClick}
                        >
                            <MagnifyingGlassIcon className="p-2" aria-hidden="true" color='#555555'/>
                        </styled.InputSearch>
                    </styled.InputSearchContainer>
                    
                    <div
                        className='
                            flex xl:mt-0 lg:mt-2 md:mt-2
                        '
                    >
                        <styled.ButtonExternal
                            target='blank' 
                            href='https://www.valueupmap.com/'
                        >
                            밸류맵
                        </styled.ButtonExternal>

                        <styled.ButtonExternal
                            target='blank' 
                            href='https://www.disco.re/'
                        >
                            디스코
                        </styled.ButtonExternal>

                        <styled.ButtonExternal
                            target='blank' 
                            href='https://www.bdsplanet.com/main.ytp'
                        >
                            부동산플래닛
                        </styled.ButtonExternal>
                    </div>
                </div>
                
                <div className='
                        p-2 xl:pt-2 lg:pt-0 md:pt-0 
                        flex xl:flex-row lg:flex-col md:flex-col
                    '
                >
                    <div className='flex min-w-3/12 w-full'>
                        <Dropdown
                            parentCallback={handlePinPointType}
                        />
                    </div>

                    <div className='
                            flex min-w-9/12 w-full justify-end mt-0 
                            xl:mt-0 lg:mt-2 md:mt-2 
                        '
                    >
                        <button 
                            className={`
                                ${status == 'all' ? 'bg-[#F9F9F9]':'bg-white'}
                                bg-white hover:bg-[#F9F9F9]
                                rounded-md md:rounded-3xl 
                                w-11 md:w-20
                                border-solid border-[0.5px] md:border-[1px] border-[#707070]
                                mx-1 outline-none
                                xl:ml-1 lg:ml-0 md:ml-0 
                            `}
                            onClick={triggerStatus} 
                            id='all'
                        >
                            전체
                        </button>

                        <button 
                            className={`
                                ${status == 'new' ? 'bg-black text-white border-black':'bg-white border-[#707070]'}
                                hover:bg-black 
                                hover:text-white
                                rounded-md md:rounded-3xl 
                                w-11 md:w-20
                                border-solid border-[0.5px] md:border-[1px] hover:border-black
                                mx-1
                                outline-none
                            `}
                            onClick={triggerStatus} 
                            id='new'
                        >
                            신규
                        </button>

                        <button 
                            className={`
                                ${status == 'sale' ? 'bg-[#DE0017] border-[#DE0017] text-white':'bg-white border-[#707070]'}
                                hover:bg-[#DE0017]
                                hover:text-white
                                rounded-md md:rounded-3xl 
                                w-11 md:w-20
                                border-solid border-[0.5px] md:border-[1px] hover:border-[#DE0017]
                                mx-1
                                outline-none
                            `}
                            onClick={triggerStatus} 
                            id='sale'
                        >
                            매물
                        </button>

                        <button 
                            className={`
                                ${status == 'pending' ? 'bg-[#DDDDDD] border-[#DDDDDD]':'bg-white border-[#707070]'}
                                hover:bg-[#DDDDDD]
                                hover:text-white
                                rounded-md md:rounded-3xl 
                                w-11 md:w-20
                                border-solid border-[0.5px] md:border-[1px] hover:border-[#DDDDDD]
                                mx-1
                                outline-none
                            `}
                            onClick={triggerStatus} 
                            id='pending'
                        >
                            보류
                        </button>

                        <styled.ButtonIcon
                            onClick={triggerModal}
                        >
                            <AdjustmentsVerticalIcon aria-hidden="true" color='#121212'/>
                        </styled.ButtonIcon>

                        <styled.ButtonIcon
                            onClick={triggerRefresh}
                        >
                            <ArrowPathIcon aria-hidden="true" color='#121212'/>
                        </styled.ButtonIcon>
                    </div>
                </div>
            </styled.Container>
        </styled.Wrapper>
    )
}

export default LeftCard;