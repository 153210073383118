import DropdownInputViewModel from "components/core/dropdown-input/DropdownInputViewModel";
import defaultValues from "constants/defaultValues";
import { useEffect, useState } from "react";
import cardActions from "redux/actions/cardActions";

const useLandLedgerFormPresenter = () => {
    const [usageItems, setUsageItems] = useState(defaultValues.emptyArray);

    const loadData = () => {
        cardActions.fetchLandUsages(
            didFetchUsagesSucceed
        )
    }

    const didFetchUsagesSucceed = (response) => {
        const usageItems = response.map(
            (usage) => {
                const dropDownViewModel = new DropdownInputViewModel()
                dropDownViewModel.key = usage.id
                dropDownViewModel.value = usage.name
                return dropDownViewModel
            }
        );
        setUsageItems(usageItems);
    }

    const getNumber = (landSpesificInformation) => {
        const number = landSpesificInformation.number;
        return number == defaultValues.emptyInt
            ? defaultValues.emptyString
            : number
    }

    useEffect(
        () => loadData(),
        [defaultValues.emptyArray]
    )

    return {
        getNumber,
        usageItems
    }
}
export default useLandLedgerFormPresenter;