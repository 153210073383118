import { Customer } from "models/objects/Customer";

export class ResponseCheckDuplication {
    constructor(
        customers = []
    ) {
        this.customers = customers;
    }

    mapFromJson(json) {
        if (!json.data) { return this; }
        this.customers = json.data.map(
            (customerJson) => new Customer().mapFromJson(customerJson)
        )
        return this;
    }
}