const enums = {
    checkbox: 0,
    floor: 1,
    areaInPy: 2,
    usage: 3,
    deposit: 4,
    monthlyFee: 5,
    adminPrice: 6,
    rentalDate: 7,
    note: 8,
    totalRentFee: 9
}

const columnFormName = (column) => {
    const columnNames = {
        [enums.checkbox]: "checkbox",
        [enums.floor]: "floor",
        [enums.areaInPy]: "areaInPY",
        [enums.usage]: "usage",
        [enums.deposit]: "deposit",
        [enums.monthlyFee]: "monthlyFee",
        [enums.adminPrice]: "adminPrice",
        [enums.rentalDate]: "rentalDate",
        [enums.note]: "note"
    }
    return columnNames[column];
}

export default {
    enums: enums,
    columnFormName: columnFormName
}