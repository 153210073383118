import React, { Component } from 'react'
import PropTypes from 'prop-types';
import AlertDialogModal from './AlertDialogModal';
import Button from 'components/core/button/Button';
import sizes from 'constants/sizes';
import { WhiteButtonStyle } from 'components/core/button/styles';
import buttonStrings from 'localizations/buttonStrings';
import { connect } from 'react-redux';
import { commonActionTypes } from 'redux/types';
import { Message } from 'models/objects/Message';
import zIndex from 'constants/zIndex';

const mapDispatchToProps = dispatch => ({
    resetMessageModal: () => {
        dispatch({
            type: commonActionTypes.RESET_MESSAGE_MODAL
        })
    }
});

class SuccessModal extends Component {
    constructor(props) {
        super(props);
        this.alertDialogModal = React.createRef();
    }

    static get propTypes() { 
        return {
            message: PropTypes.object,
            primaryButtonAction: PropTypes.func,
            isShowing: PropTypes.bool,
            resetMessageModal: PropTypes.func
        }; 
    }

    state = {
        isShowing: this.props.isShowing
    }

    static defaultProps = {
        message: new Message(),
        primaryButtonAction: () => { return; },
        onTapPrimaryButton: () => { return; }
    };

    renderPrimaryButton = () => {
        return (
            <Button
                action={ this.handleDismiss }
                height={ sizes.button.small }
                style={ WhiteButtonStyle }
                title={ buttonStrings.yes } />
        );
    }

    handleDismiss = () => {
        this.props.resetMessageModal();
        this.props.primaryButtonAction();
    }

    render() {
        return (
            <AlertDialogModal
                ref={ this.alertDialogModal }
                zIndex={ zIndex.sucessModal }
                isShowing={ this.props.isShowing }
                title={ this.props.message.title }
                subtitle={ this.props.message.subtitle }
                primaryButton={ this.renderPrimaryButton() } />
        )
    }
}

export default connect(
    null,
    mapDispatchToProps
)(SuccessModal);