import defaultValues from "constants/defaultValues";
import separators from "constants/separators";
import unitExhanges from "constants/unitExhanges";
import { ParkingMethod } from "models/objects/ParkingMethod";
import { RequestCreateBuildingInformation } from "models/requests/RequestCreateBuildingInformation";
import { AirConditioner } from "./AirConditioner";
import { Parking } from "./Parking";

export class BuildingInformation {
    constructor(
        id = defaultValues.emptyInt,
        totalArea = defaultValues.emptyInt,
        totalAreaInPY = defaultValues.emptyInt,
        firstArea = defaultValues.emptyInt,
        firstAreaInPy = defaultValues.emptyInt,
        buildingToCover = defaultValues.emptyInt,
        totalAreaRatio = defaultValues.emptyInt,
        floorAreaRatio = defaultValues.emptyInt,
        totalParkingGoverment = defaultValues.emptyInt,
        totalParkingPersonal = defaultValues.emptyInt,
        floor = defaultValues.emptyString,
        builtAt = defaultValues.emptyString,
        remodelAt = defaultValues.emptyString,
        builtWith = defaultValues.emptyString,
        usage = defaultValues.emptyString,
        parkingMethod = new ParkingMethod(),
        airConditioner = new AirConditioner(),
        elevatorStatus = defaultValues.emptyString,
        isIllegalBuilding = false,
        isGroupBuilding = false,
        parking = new Parking(),
        permitDate = defaultValues.emptyString,
    ) {
        this.id = id;
        this.totalArea = totalArea;
        this.totalAreaInPY = totalAreaInPY;
        this.firstArea = firstArea;
        this.firstAreaInPy = firstAreaInPy;
        this.buildingToCover = buildingToCover;
        this.totalAreaRatio = totalAreaRatio;
        this.floorAreaRatio = floorAreaRatio;
        this.totalParkingGoverment = totalParkingGoverment;
        this.totalParkingPersonal = totalParkingPersonal;
        this.floor = floor;
        this.builtAt = builtAt;
        this.builtWith = builtWith;
        this.remodelAt = remodelAt;
        this.usage = usage,
        this.parkingMethod = parkingMethod;
        this.airConditioner = airConditioner;
        this.elevatorStatus = elevatorStatus;
        this.isIllegalBuilding = isIllegalBuilding;
        this.isGroupBuilding = isGroupBuilding;
        this.parking = parking;
        this.permitDate = permitDate;
    }

    mapFromJson(json) {
        if (!json) { return this; }
        this.id = json['id'];
        this.totalArea = json['total_area'];
        this.totalAreaInPY = json['total_area_py'];
        this.firstArea = json['firstArea'];
        this.firstAreaInPy = json['first_area_py'];
        this.buildingToCover = json['building_to_cover'];
        this.totalAreaRatio = json['building_to_cover_ratio'];
        this.floorAreaRatio = json['floor_area_ratio'];
        this.totalParkingGoverment = json['total_parking_gov'];
        this.totalParkingPersonal = json['total_parking_personal'];
        this.floor = json['floor'];
        this.builtAt = json['build_at'];
        this.builtWith = json['build_with'];
        this.remodelAt = json['remodel_at'];
        this.usage = json['usage'];
        this.parkingMethod = new ParkingMethod().mapFromJson(json['parking']);
        this.airConditioner = new AirConditioner().mapFromJson(json['air_conditioning']);
        this.elevatorStatus = json['elevator'];
        this.isIllegalBuilding = json['illegal_building'];
        this.isGroupBuilding = json['group_building'];
        this.parking = new Parking().mapFromJson(json['parking']);
        this.permitDate = json['permit_date'];
        return this;
    }

    convertToEditRequest() {
        const requestEditBuildingInformation = new RequestCreateBuildingInformation();
        requestEditBuildingInformation.remodelAt = this.remodelAt;
        requestEditBuildingInformation.usage = this.usage;
        requestEditBuildingInformation.buildAt = this.builtAt;
        requestEditBuildingInformation.builtWith = this.builtWith;
        requestEditBuildingInformation.airConditioner = this.airConditioner;
        requestEditBuildingInformation.parkingMethod = this.parkingMethod;
        requestEditBuildingInformation.floor = this.floor;
        requestEditBuildingInformation.elevator = this.elevatorStatus;
        requestEditBuildingInformation.isIllegalBuilding = this.isIllegalBuilding;
        requestEditBuildingInformation.isGroupBuilding = this.isGroupBuilding;
        requestEditBuildingInformation.totalParkingGoverment = this.parking.totalGoverment;
        requestEditBuildingInformation.totalParkingPersonal = this.parking.totalPersonal;
        requestEditBuildingInformation.permitDate = this.permitDate;
        return requestEditBuildingInformation
    }

    getRemodelAt = () => {
        return this.remodelAt.isEmpty()
            ? separators.hypen
            : this.remodelAt
    }

    getBuildingToCoverPy = () => {
        return this.buildingToCover / unitExhanges.pyeongToMeterSquare;
    }
}