import styled from "@emotion/styled";
import tw from "twin.macro";

const HeaderContainer = styled.div`
    ${ tw`flex justify-between m-5` }
`;
const TotalItemsTitle = styled.p`
    ${ tw`my-auto` }
`;

export {
    HeaderContainer,
    TotalItemsTitle
}