import React, { Component } from 'react'
import PropTypes from 'prop-types';
import defaultValues from 'constants/defaultValues';
import CheckboxInput from 'components/core/input-checkbox/CheckboxInput';
import * as styled from "./ColumnTable.styled";
import alignments from 'constants/alignments';
import { ColumnTableViewModel } from './ColumnTableViewModel';

class ColumnTable extends Component {
    constructor(props) {
        super(props);
        this.checkboxInput = React.createRef();
    }

    static get propTypes() {
        return {
            identifier: PropTypes.string,
            isButton: PropTypes.bool,
            isCheckbox: PropTypes.bool,
            value: PropTypes.bool,
            title: PropTypes.string,
            handleAction: PropTypes.func,
            alignment: PropTypes.string,
            viewModel: PropTypes.object
        };
    }

    static defaultProps = {
        identifier: defaultValues.emptyString,
        isButton: false,
        isCheckbox: false,
        value: false,
        title: defaultValues.emptyString,
        handleAction: () => { return; },
        alignment: alignments.center,
        viewModel: new ColumnTableViewModel()
    };

    renderCheckboxInput = () => {
        if (!this.props.isCheckbox || this.props.viewModel.isCheckbox) { return; }
        return (
            <CheckboxInput
                ref={ this.checkboxInput }
                checked={ this.props.value }
                onChange={ this.handleAction }/>
        )
    }

    renderButtonInput = () => {
        if (!this.props.isButton) { return; }
        return (
            <styled.Button
                onClick={
                    () => {
                        this.handleAction(
                            this.props.identifier
                        )
                    }
                }>
                { this.props.title }
            </styled.Button>
        )
    }

    renderTitle = () => {
        if (!this.props.title
            || this.props.isButton) { return; }
        return (
            <styled.ValueText
                alignment={ this.props.alignment }>
                { this.props.title }
            </styled.ValueText>
        )
    }

    handleAction = (value) => {
        this.props.handleAction(
            value,
            this.props.identifier
        );
    }

    render() {
        return (
            <div>
                { this.renderCheckboxInput() }
                { this.renderButtonInput() }
                { this.renderTitle() }
            </div>
        )
    }
}

export default ColumnTable;