export default {
    navigationBar: {
        height: 100
    },
    button: {
        extraSmall: 32,
        small: 40,
        large: 56,
        extraLarge: 64,
        largest: 72,
        full: -1
    },
    border: {
        small: 1,
        medium: 2,
        large: 5,
        extraLarge: 8
    },
    checkbox: {
        small: 21,
        large: 24
    },
    lottie: {
        small: 32,
        medium: 48,
        large: 64
    },
    managerInputDropdown: {
        width: 175
    },
    textInput: {
        small: 36,
        medium: 48,
        large: 64
    },
    landLedgerCell: {
        height: 32,
        width: 175
    },
    image: {
        small: 24,
        medium: 48,
        large: 72
    },
    textArea: {
        cardItemDetail: {
            height: 150
        },
        createWorkHistory: {
            height: 150
        }
    },
    paperSize: {
        width: 595,
        height: 842
    },
    mainPage: {
        verticalWidthRatio: 0.5,
        horizontalWidthRatio: 0.65
    }
}