import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import * as styled from './WorkHistoryList.styled';
import CardSection from 'components/CardSection/CardSection';
import { 
    titleStrings,
    tableHeaderStrings
} from 'localizations';
import { CenterBorderedTableListStyle } from 'components/core/table-list/styles';
import Button from 'components/core/button/Button';
import { WhiteButtonStyle } from 'components/core/button/styles';
import buttonStrings from 'localizations/buttonStrings';
import CreateWorkHistoryModal from 'layouts/modals/CreateWorkHistoryModal/CreateWorkHistoryModal';
import { observer } from 'mobx-react-lite';
import { useSelector } from 'react-redux';
import MergeTableView from 'components/core/merge-table/MergeTableView';
import Text from 'components/core/text/Text';
import MergeTableViewHook from 'components/core/merge-table/MergeTableViewHook';
import alignments from 'constants/alignments';

const WorkHistoryList = observer((props) => {
    const [workHistories, setWorkHistories] = useState([]);
    const cardReducer = useSelector((state) => state.card);
    const tableHeaders = [
        tableHeaderStrings.createdAt,
        tableHeaderStrings.author,
        tableHeaderStrings.history
    ];

    const setupWorkHistoryTable = (workHistory) => {
        const workHistories = cardReducer.requestWriteCard.workHistories;
        workHistories.insert(0, workHistory);
        setWorkHistories(workHistories);
    }

    const showCreateWorkHistoryModal = () => {
        props.showModal(
            <CreateWorkHistoryModal
                onCreateWorkHistorySuccess={ setupWorkHistoryTable }/>
        )
    }

    const renderCreateButton = () => {
        if (!props.isWriteState) { return null; }
        return (
            <styled.TopHeaderContainer>
                <styled.ButtonWrapper>
                    <Button
                        action={ showCreateWorkHistoryModal }
                        width={ 86 }
                        height={ 36 }
                        title={ buttonStrings.add }
                        style={ WhiteButtonStyle }/>
                </styled.ButtonWrapper>
            </styled.TopHeaderContainer>
        )
    }

    const _renderRows = () => {
        let sortedWorkHistories = cardReducer.card.workHistories;
        if (props.isWriteState) {
            sortedWorkHistories = cardReducer.requestWriteCard.workHistories
        }
        sortedWorkHistories = sortedWorkHistories.sort(
            (x,y) => x.date + y.date
        );
        return sortedWorkHistories.map(
            (workHistory, index) => {
                var isMerged = false;
                if (workHistory != sortedWorkHistories.last()) {
                    isMerged = workHistory.date == sortedWorkHistories[index + 1].date
                }
                return [
                    {   
                        isMerged: isMerged,
                        content: (
                            <Text
                                alignment={ alignments.center }
                                fontSize={ 14 }
                                value={ workHistory.date.formatAsDate() }/>
                        )
                    },
                    {   
                        isMerged: isMerged,
                        content: (
                            <Text
                                alignment={ alignments.center }
                                fontSize={ 14 }
                                value={ workHistory.user.name }/>
                        )
                    },
                    {   
                        isMerged: false,
                        content: (
                            <Text
                                alignment={ alignments.center }
                                fontSize={ 14 }
                                value={ workHistory.description}/>
                        )
                    }
                ]
            }
        );
    }

    const renderContent = () => {
        return (
            <styled.Container>
                { renderCreateButton() }
                <MergeTableView
                    hook={ MergeTableViewHook() }
                    headers={ tableHeaders }
                    rows={ _renderRows() }
                    maxHeight={ 300 }
                    style={ CenterBorderedTableListStyle }/>
            </styled.Container>
        )
    }

    useEffect(() => {
        setWorkHistories(
            cardReducer.requestWriteCard.workHistories.sort(
                (x,y) => x.date + y.date
            )
        )
    }, [cardReducer.requestWriteCard.workHistories])

    const render = () => {
        if (props.isHidden) { return null }
        return (
            <CardSection
                title={ titleStrings.workHistory }
                content={ renderContent() } />
        )
    }
    return (render())
});

WorkHistoryList.propTypes = {
    isHidden: PropTypes.bool,
    isWriteState: PropTypes.bool,
    showModal: PropTypes.func,
    handleInputValueChange: PropTypes.func
};

WorkHistoryList.defaultProps = {
    isHidden: false,
    isWriteState: false,
    showModal: () => { return; },
    handleInputValueChange:  () => { return; }
};

export default WorkHistoryList