import { useSelector } from 'react-redux';
import * as searchActions from 'redux/reducers/searchReducer';
import { RequestPagination } from 'models/requests/RequestPagination';
import * as defaultValues from 'constants/defaultValue';
import TableRowPresenter from 'components/core/row-table/TableRowPresenter';
import cardStatus from 'enumerations/cardStatus';
import qs from "qs";
import { RequestSearchProperty } from 'models/requests/RequestSearchProperty';
import searchService from 'redux/actions/searchActions';
import { RequestCreateSearchHistory } from 'models/requests/RequestCreateSearchHistory';
import { Pagination } from 'models/objects/Pagination';

const useSearchResultLayout = () => {
    const searchReducer = useSelector(state => state.search);
    const { requestSearchProperty } = useSelector(state => state.search);

    const onSelectProperty = (propertyId) => {
        _createClickHistoryRecord(propertyId);
        _startSearchDetailPage(propertyId);
    }

    const _createClickHistoryRecord = (propertyId) => {
        searchService.createSearchHistory(
            new RequestCreateSearchHistory(propertyId),
            () => { return }
        )
    }

    const _startSearchDetailPage = (propertyId) => {
        let request = Object.assign(
            new RequestSearchProperty(),
            requestSearchProperty
        );
        const page = searchReducer.requestSearchProperty.pagination.pageSize * searchReducer.currentPage;
        const index = searchReducer.searchResults.findIndex(
            $0 => $0.id == propertyId
        );
        request.pagination = new RequestPagination(
            index + page,
            defaultValues.pageSize
        )
        const query = qs.stringify(request.mapToJson());
        window.open(
            '/search/detail?' + query,
            '_blank',
            'noopener,noreferrer'
        );
    }

    const didPageChanged = (currentPage) => {
        searchActions.onChangePage(currentPage)
        searchActions.updateProperties(
            defaultValues.emptyArray
        );
        const request = Object.assign(
            new RequestSearchProperty(),
            searchReducer.requestSearchProperty
        );
        request.pagination.page = currentPage;
        searchActions.fetchProperties(request);
    }

    const setupLandArea = (property) => {
        const landAreaInMeter = property.getLandArea();
        const landAreaInPy = property.getLandArea().convertToPy();
        return landAreaInMeter.rounded(2).toString().thousandFormat()
            + "("
            + landAreaInPy.rounded(2).toString().thousandFormat()
            + ")";
    }

    const setupFloorArea = (property) => {
        const buildingInformation = property.buildingInformation;
        return buildingInformation.totalArea.rounded(2).toString().thousandFormat()
            + "("
            + buildingInformation.totalAreaInPY.rounded(2).toString().thousandFormat()
            + ")";
    }

    const setupPropertyTableItems = () => {
        return searchReducer.searchResults.map(
            (property, index) => {
                return TableRowPresenter(
                    property.id,
                    [
                        (index + 1) + (searchReducer.requestSearchProperty.pagination.pageSize * searchReducer.currentPage),
                        cardStatus.getTitle(property.status),
                        property.buildingName,
                        property.address,
                        property.ownerInformation.name,
                        setupLandArea(property),
                        setupFloorArea(property),
                        property
                            .priceInformation
                            .totalPrice
                            .toString()
                            .thousandFormat(),
                        property
                            .priceInformation
                            .deposit
                            .toString()
                            .thousandFormat(),
                        property
                            .priceInformation
                            .monthlyIncome
                            .rounded(2)
                            .toString()
                            .thousandFormat(),
                        property
                            .priceInformation
                            .revenue
                            .toString()
                            .thousandFormat(),
                        property.nearestStation
                    ]
                )
            }
        )
    }

    return {
        onSelectProperty,
        didPageChanged,
        setupPropertyTableItems
    }
}

export default useSearchResultLayout;