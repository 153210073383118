import defaultValues from "constants/defaultValues";

class ImageDocument {
  constructor(
    id,
    title = defaultValues.emptyString,
    ids = [],
    isIncluded = true
  ) {
    this.id = id;
    this.title = title;
    this.ids = ids;
    this.isIncluded = isIncluded
  }

  mapToJson() {
    return {
      "title": this.title,
      "ids": this.ids,
      "use_image": this.isIncluded
    }
  }

  mapFromJson(json) {
    if (!json) {
      return this;
    }
    this.id = json['id'];
    this.title = json['title'];
    this.ids = json['ids'];
    this.isIncluded = json['use_image'];
    return this;
  }
}

export default ImageDocument