import styled from '@emotion/styled';
import tw from 'twin.macro';

const Container = styled.div`
    ${ tw`flex my-auto` }
`;
const ActionButton = styled.button`
    ${
        (props) => {
            if (props.isLastItem) {
                return tw`border-r border-t border-b`
            }
            return tw`border`;
        }
    }
    ${tw `flex h-8 w-8 bg-white`}
`;
const PageTitle = styled.p`
    ${tw `my-auto mx-4`}
`;
const ActionIcon = styled.img`
    ${tw `h-2 w-2 m-auto`}
`;

export {
    Container,
    ActionButton,
    PageTitle,
    ActionIcon
}