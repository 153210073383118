import {  makeAutoObservable } from "mobx";
import { RowTableViewModel } from "../row-table/RowTableViewModel";

class TableViewModel {
    rowTableViewModels = []

    constructor(
        rowTableViewModels = []
    ) {
        makeAutoObservable(this);
        this.rowTableViewModels = rowTableViewModels;
    }

    setupTableViewRowModels(columnTableViewModels) {
        this.rowTableViewModels = columnTableViewModels.map((columns) => {
            return new RowTableViewModel(columns)
        });
    }
}

export { TableViewModel };