import React,{useState,useEffect, useCallback} from 'react';
import * as styled from './CenterCard.styled';

import Dropdown from 'components/Map/CenterCard/Dropdown';

import PropTypes from 'prop-types';




const CenterCard = ({ filterData, setFilterData,submitCallback,refreshCallback }) => {

    // const [filterData,setFilterData] = useState(theProps.filterData)




    const validateDecimal = (input) => {
        const decimalRegex = /^\d*\.?\d{0,1}$/;
        return decimalRegex.test(input);
    };

    const onChange_text = (e) => {
        var theValue = e.target.value;
        const targetName = e.target.name;

        
        switch(targetName) {
            case "is_road_side":
                theValue = !filterData.is_road_side;
                break;
            case "is_road_corner":
                theValue = !filterData.is_road_corner;
                break;
            case "status_all":
                theValue = !filterData.status_all;
                break;
            case "status_new":
                theValue = !filterData.status_new;
                break;
            case "status_sale":
                theValue = !filterData.status_sale;
                break;
            case "status_pending":
                theValue = !filterData.status_pending;
                break;
        }

        if(
            (
                (targetName == 'max_floor_m') ||
                (targetName == 'max_floor_py') ||
                (targetName == 'max_land_m') ||
                (targetName == 'max_land_py') ||
                (targetName == 'max_price') ||
                (targetName == 'max_revenue') ||
                (targetName == 'max_road') ||
                (targetName == 'min_floor_m') ||
                (targetName == 'min_floor_py') ||
                (targetName == 'min_land_m') ||
                (targetName == 'min_land_py') ||
                (targetName == 'min_price') ||
                (targetName == 'min_revenue') ||
                (targetName == 'min_road')
            ) && (validateDecimal(theValue) == true)
        ){
            
            setFilterData({
                ...filterData,
                [targetName]: theValue
            });
        }
        else if(
            (
                (targetName == 'max_floor_m') ||
                (targetName == 'max_floor_py') ||
                (targetName == 'max_land_m') ||
                (targetName == 'max_land_py') ||
                (targetName == 'max_price') ||
                (targetName == 'max_revenue') ||
                (targetName == 'max_road') ||
                (targetName == 'min_floor_m') ||
                (targetName == 'min_floor_py') ||
                (targetName == 'min_land_m') ||
                (targetName == 'min_land_py') ||
                (targetName == 'min_price') ||
                (targetName == 'min_revenue') ||
                (targetName == 'min_road')
            ) && (validateDecimal(theValue) == false)
        ){
            setFilterData({
                ...filterData
            });
        }
        else if(
            (targetName != 'max_floor_m') ||
            (targetName != 'max_floor_py') ||
            (targetName != 'max_land_m') ||
            (targetName != 'max_land_py') ||
            (targetName != 'max_price') ||
            (targetName != 'max_revenue') ||
            (targetName != 'max_road') ||
            (targetName != 'min_floor_m') ||
            (targetName != 'min_floor_py') ||
            (targetName != 'min_land_m') ||
            (targetName != 'min_land_py') ||
            (targetName != 'min_price') ||
            (targetName != 'min_revenue') ||
            (targetName != 'min_road')
        ){
            setFilterData({
                ...filterData,
                [targetName]: theValue
            });
        }

        // theProps.onChangeCallback(targetName,theValue)
    };
    
    const onClick_reset = () =>{
        filterData.address = '';
        filterData.is_road_corner = false;
        filterData.is_road_side = false;
        filterData.manager = '';
        filterData.max_floor_m = '';
        filterData.max_floor_py = '';
        filterData.max_land_m = '';
        filterData.max_land_py = '';
        filterData.max_price = '';
        filterData.max_revenue = '';
        filterData.max_road = '';
        filterData.min_floor_m = '';
        filterData.min_floor_py = '';
        filterData.min_land_m = '';
        filterData.min_land_py = '';
        filterData.min_price = '';
        filterData.min_revenue = '';
        filterData.min_road = '';
        filterData.name = '';
        filterData.nearest_station = '';
        filterData.owner = '';
        filterData.road = '';
        filterData.status_all = false;
        filterData.status_new = false;
        filterData.status_pending = false;
        filterData.status_sale = false;
        filterData.LandUsageBody = "선택";

        setFilterData({
            ...filterData
        });

        // theProps.submitCallback(filterData)
        refreshCallback()
    }

    const parentCallback = useCallback((e) => {

        setFilterData({
            ...filterData,
            LandUsageBody: e
        });

    },[filterData]);

    const onClick_submit = () => {
        // submitCallback(filterData)
        submitCallback();
    }

    return(
        <styled.Container>
            <div className='
                flex flex-col md:flex-row 
                place-content-stretch 
                border-b-[1px] border-[#DDDDDD]
            '>
                <styled.leftSide>
                    <div className='px-4 md:px-6 py-2 md:py-5 pt-4 md:pt-10 border-solid border-b-[1px] border-[#DDDDDD]'>
                        <h6 className='font-bold text-lg'>기본정보</h6>

                        <table className='my-2 w-full md:w-none'>
                            <tbody>
                                <tr className='py-2'>
                                    <td className='pr-2 w-[30%]'>물건명</td>
                                    <td>
                                        <styled.inputText 
                                            type='text'
                                            onChange={onChange_text}
                                            name='name'
                                            value={filterData.name}
                                        />
                                    </td>
                                </tr>

                                <tr className='py-2'>
                                    <td className='pr-2 w-[30%]'>소유자</td>
                                    <td>
                                        <styled.inputText 
                                            type='text'
                                            onChange={onChange_text}
                                            name='owner'
                                            value={filterData.owner}
                                        />
                                    </td>
                                </tr>

                                <tr className='py-2'>
                                    <td className='pr-2 w-[30%]'>담당자</td>
                                    <td>
                                        <styled.inputText 
                                            type='text'
                                            onChange={onChange_text}
                                            name='manager'
                                            value={filterData.manager}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className='
                        px-4 md:px-6 
                        py-2 md:py-5 pt-4 
                        md:pt-0
                        border-solid border-b-[1px] border-[#DDDDDD]
                        md:border-b-0
                    '>
                        <h6 className='font-bold text-lg'>금액</h6>

                        <table className='my-2  w-full md:w-none'>
                            <tbody>
                                <tr className='py-2'>
                                    <td className='pr-2 w-[30%]'>금액</td>
                                    <td className='flex justify-between align-center place-items-center'>
                                        <styled.inputNumberContainer>
                                            <styled.inputNumber 
                                                type='text' 
                                                placeholder='최소'
                                                onChange={onChange_text}
                                                name='min_price'
                                                value={filterData.min_price}
                                            />
                                            <h6 className='text-[#777777]'>억</h6>
                                        </styled.inputNumberContainer>

                                        -

                                        <styled.inputNumberContainer>
                                            <styled.inputNumber 
                                                type='text' 
                                                placeholder='최대'
                                                onChange={onChange_text}
                                                name='max_price'
                                                value={filterData.max_price}
                                            />
                                            <h6 className='text-[#777777]'>억</h6>
                                        </styled.inputNumberContainer>
                                    </td>
                                </tr>

                                <tr className='py-2'>
                                    <td className='pr-2 w-[30%]'>연수익률</td>
                                    <td className='flex justify-between align-center place-items-center'>
                                        <styled.inputNumberContainer>
                                            <styled.inputNumber 
                                                type='text' 
                                                placeholder='최소'
                                                onChange={onChange_text}
                                                name='min_revenue'
                                                value={filterData.min_revenue}
                                            />
                                            <h6 className='text-[#777777]'>%</h6>
                                        </styled.inputNumberContainer>

                                        -

                                        <styled.inputNumberContainer>
                                            <styled.inputNumber 
                                                type='text' 
                                                placeholder='최대'
                                                onChange={onChange_text}
                                                name='max_revenue'
                                                value={filterData.max_revenue}
                                            />
                                            <h6 className='text-[#777777]'>% </h6>
                                        </styled.inputNumberContainer>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </styled.leftSide>

                <styled.rightSide>
                    <div className='px-4 md:px-6'>
                        <h6 className='font-bold text-lg'>위치</h6>

                        <table className='my-2  w-full md:w-none'>
                            <tbody>
                                <tr className='py-2'>
                                    <td className='w-[30%]'>주소</td>
                                    <td className='w-[70%]'>
                                        <styled.inputText 
                                            type='text'
                                            onChange={onChange_text}
                                            name='address'
                                            value={filterData.address}
                                        />
                                    </td>
                                </tr>

                                <tr className='py-2'>
                                    <td className='w-[30%]'>주변역</td>
                                    <td className='w-[70%]'>
                                        <styled.inputText 
                                            type='text'
                                            onChange={onChange_text}
                                            name='nearest_station'
                                            value={filterData.nearest_station}
                                        />
                                    </td>
                                </tr>


                                <tr className='py-2'>
                                    <td className='w-[30%]'>도로너비</td>
                                    <td className='w-fit
                                        flex place-items-center items-center
                                    '>
                                        <styled.inputText_mini 
                                            type='text' 
                                            placeholder='이상'
                                            onChange={onChange_text}
                                            name='min_road'
                                            value={filterData.min_road}
                                        />

                                        <span className='mx-1 mb-2'>
                                            -
                                        </span>

                                        <styled.inputText_mini 
                                            type='text' 
                                            placeholder='이하'
                                            onChange={onChange_text}
                                            name='max_road'
                                            value={filterData.max_road}
                                        />
                                        <input className='mb-2 ml-2' type='checkbox' id='roadWidth_side' 
                                            name='is_road_side'
                                            onChange={onChange_text}
                                            checked={filterData.is_road_side}
                                        />
                                        <label className='cursor-pointer ml-1 mr-2 mb-2' htmlFor='roadWidth_side'>양면</label>

                                        <input className='mb-2' type='checkbox' id='roadWidth_corner' 
                                            name='is_road_corner' 
                                            onChange={onChange_text}
                                            checked={filterData.is_road_corner}
                                        />
                                        <label className='cursor-pointer ml-1 mb-2' htmlFor='roadWidth_corner'>코너</label>
                                    </td>
                                </tr>

                                <tr className='py-2'>
                                    <td className='w-[30%]'>용도지역</td>
                                    <td className='w-[70%]'>
                                        <Dropdown
                                            parentCallback={parentCallback}
                                            theValue={filterData.LandUsageBody}
                                        />
                                    </td>
                                </tr>
                                
                                <tr className='py-2'>
                                    <td className='w-[30%]'></td>
                                    <td className='w-[70%]'>
                                        {
                                            (filterData.LandUsageBody == '직접입력') ? (
                                                <styled.inputText 
                                                    type='text'
                                                    onChange={onChange_text}
                                                    name='LandUsageBody_text'
                                                    value={filterData.LandUsageBody_text}
                                                />
                                            ) : null
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </styled.rightSide>
            </div>

            <div className='
                    flex place-content-stretch 
                    flex-col md:flex-row 
            '>
                <styled.leftSide_noBorder>
                    <div className='px-6 py-5 pb-0'>
                        <h6 className='font-bold text-lg'>평수</h6>

                        <table className='my-2 w-full md:w-none'>
                            <tbody>
                                <tr className='py-2'>
                                    <td className='pr-2 w-[30%]'>대지평수</td>
                                    <td className=' w-[100%] flex justify-between align-center place-items-center'>
                                        <styled.inputNumberContainer>
                                            <styled.inputNumber 
                                                type='text' 
                                                placeholder='최소'
                                                onChange={onChange_text}
                                                name='min_land_py'
                                                value={filterData.min_land_py}
                                            />
                                            <h6 className='text-[#777777]'>평</h6>
                                        </styled.inputNumberContainer>

                                        -

                                        <styled.inputNumberContainer>
                                            <styled.inputNumber 
                                                type='text' 
                                                placeholder='최대'
                                                onChange={onChange_text}
                                                name='max_land_py'
                                                value={filterData.max_land_py}
                                            />
                                            <h6 className='text-[#777777]'>평</h6>
                                        </styled.inputNumberContainer>
                                    </td>
                                </tr>

                                <tr className='py-2'>
                                    <td className='pr-2 w-[30%]'></td>
                                    <td className=' w-[100%] flex justify-between align-center place-items-center'>
                                        <styled.inputNumberContainer>
                                            <styled.inputNumber 
                                                type='text' 
                                                placeholder='최소'
                                                onChange={onChange_text}
                                                name='min_land_m'
                                                value={filterData.min_land_m}
                                            />
                                            <h6 className='text-[#777777]'>㎡</h6>
                                        </styled.inputNumberContainer>

                                        -

                                        <styled.inputNumberContainer>
                                            <styled.inputNumber 
                                                type='text' 
                                                placeholder='최대'
                                                onChange={onChange_text}
                                                name='max_land_m'
                                                value={filterData.max_land_m}
                                            />
                                            <h6 className='text-[#777777]'>㎡</h6>
                                        </styled.inputNumberContainer>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </styled.leftSide_noBorder>

                <styled.rightSide_noBorder>
                    <div className='px-6 py-5 pb-0'>
                        <h6 className='font-bold text-lg invisible md:visible'>&nbsp;</h6>

                        <table className='my-2  w-full md:w-none'>
                            <tbody>
                                <tr className='py-2'>
                                    <td className='pr-2  w-[30%]'>건물평수</td>
                                    <td className=' w-[100%] flex justify-between align-center place-items-center'>
                                        <styled.inputNumberContainer>
                                            <styled.inputNumber 
                                                type='text' 
                                                placeholder='최소'
                                                onChange={onChange_text}
                                                name='min_floor_py'
                                                value={filterData.min_floor_py}
                                            />
                                            <h6 className='text-[#777777]'>평</h6>
                                        </styled.inputNumberContainer>

                                        -

                                        <styled.inputNumberContainer>
                                            <styled.inputNumber 
                                                type='text' 
                                                placeholder='최대'
                                                onChange={onChange_text}
                                                name='max_floor_py'
                                                value={filterData.max_floor_py}
                                            />
                                            <h6 className='text-[#777777]'>평</h6>
                                        </styled.inputNumberContainer>
                                    </td>
                                </tr>

                                <tr className='py-2'>
                                    <td className='pr-2'></td>
                                    <td className='flex justify-between align-center place-items-center'>
                                        <styled.inputNumberContainer>
                                            <styled.inputNumber 
                                                type='text' 
                                                placeholder='최소'
                                                onChange={onChange_text}
                                                name='min_floor_m'
                                                value={filterData.min_floor_m}
                                            />
                                            <h6 className='text-[#777777]'>㎡</h6>
                                        </styled.inputNumberContainer>

                                        -

                                        <styled.inputNumberContainer>
                                            <styled.inputNumber 
                                                type='text' 
                                                placeholder='최대'
                                                onChange={onChange_text}
                                                name='max_floor_m'
                                                value={filterData.max_floor_m}
                                            />
                                            <h6 className='text-[#777777]'>㎡</h6>
                                        </styled.inputNumberContainer>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </styled.rightSide_noBorder>
            </div>

            <div className='
                flex place-content-stretch 
                border-solid border-y-[1px] border-[#DDDDDD]
            '>
                <styled.fullSide>
                    <div className='px-6 py-5'>
                        <table className='my-2 w-full md:w-none'>
                            <tbody>
                                <tr className='py-2'>
                                    <td className='pr-2  w-[20%]'>상태</td>
                                    <td className=' w-[100%] flex justify-between align-center place-items-center'>
                                        <input type='checkbox' id='status_all' 
                                            name='status_all'
                                            onChange={onChange_text}
                                            checked={filterData.status_all}
                                        />
                                        <label className='cursor-pointer ml-2 mr-8' htmlFor='status_all'>전체</label>

                                        <input type='checkbox' id='status_new' 
                                            name='status_new'
                                            onChange={onChange_text}
                                            checked={filterData.status_new}
                                        />
                                        <label className='cursor-pointer ml-2 mr-8' htmlFor='status_new'>신규</label>
                                        
                                        <input type='checkbox' id='status_sale' 
                                            name='status_sale'
                                            onChange={onChange_text}
                                            checked={filterData.status_sale}
                                        />
                                        <label className='cursor-pointer ml-2 mr-8' htmlFor='status_sale'>매물</label> 

                                        <input type='checkbox' id='status_pending' 
                                            name='status_pending'
                                            onChange={onChange_text}
                                            checked={filterData.status_pending}
                                        />
                                        <label className='cursor-pointer ml-2 mr-8' htmlFor='status_pending'>보류</label> 
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </styled.fullSide>
            </div>

            <div className='
                flex justify-evenly
            '>
                <button
                    className='
                        w-[50%] p-3
                        bg-[#F9F9F9] hover:bg-[#EEEEEE]
                    '
                    onClick={onClick_reset}
                >
                    초기화
                </button>
                <button
                    className='
                        w-[50%] p-3
                        text-white
                        bg-[#121212] hover:bg-[#717171]
                    '
                    onClick={onClick_submit}
                >
                    검색
                </button>
            </div>
        </styled.Container>
    )
}

CenterCard.propTypes = {
    // your other props validation here
    filterData: PropTypes.object.isRequired,
    setFilterData: PropTypes.object.isRequired,
    submitCallback: PropTypes.object.isRequired,
    refreshCallback: PropTypes.object.isRequired,
};


export default CenterCard;