import styled from "@emotion/styled";
import tw from "twin.macro";

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 1075px;
    height: 765px;
    padding: 3rem;
    background-color: white;
    scale: ${ props => props.scale };
    margin: auto;
`;

export const PrintContainer = styled.div`
    width: 1075px;
    height: 722px;
    background-color: red;
`;

export const BodyContainer = styled.div`
    margin: 2rem;
    overflow: hidden;
    ${
        props => {
            let columnCounts = 1;
            let rowCounts = 1;
            if (props.imageCount == 1) {
                return `
                    display: flex;
                `;
            }
            if (props.imageCount > 1) {
                columnCounts = 2;
            }
            if (props.imageCount == 4) {
                rowCounts = 2;
            }
            return `
                display: grid;
                grid-template-columns: repeat(${ columnCounts }, minmax(0, 1fr));
                grid-template-rows: repeat(${ rowCounts }, minmax(0, 1fr));
            `
        }
    }
    justify-content: center;
    ${ tw`gap-5 flex-1` }
`;

export const Image = styled.img`
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    ${
        props => {
            if (props.src.isEmpty()) {
                return tw`border border-gray-300`;
            }
        }
    }
`;

export const ImageWrapper = styled.div`
    overflow: hidden;
    height: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    ${
        props => {
            if (!props.imageSize.width) {
                return 'width: 100%';
            }
            const aspectRatio = props.imageSize.width / props.imageSize.height;
            return `aspect-ratio: ${ aspectRatio }`;
        }
    };
`;