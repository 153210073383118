import ColumnTable from 'components/core/column-table/ColumnTable';
import employeeService from 'redux/actions/employeeAction';
import * as defaultValues from 'constants/defaultValue';
import { titleStrings } from 'localizations';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { RequestFetchEmployeeLoginHistoryList } from 'models/requests/RequestFetchEmployeeLoginHistoryList';
import { RequestDeleteEmployeeLoginHistory } from 'models/requests/RequestDeleteEmployeeLoginHistory';
import { Pagination } from 'models/objects/Pagination';

const useEmployeeLoginLogPage = () => {
    const [requestFetchList, setRequestFetchList] = useState(
        new RequestFetchEmployeeLoginHistoryList()
    );
    const [pagination, setPagination] = useState(new Pagination());
    const [checkedLoginLogs, setCheckedLoginLogs] = useState(defaultValues.emptyArray);
    const [loginLogs, setLoginLogs] = useState(defaultValues.emptyArray);

    const onStartSearchLoginLog = (
        keyword,
        startDate,
        endDate
    ) => {
        setPagination(new Pagination());
        let request = new RequestFetchEmployeeLoginHistoryList();
        request.keyword = keyword;
        request.startDate = startDate;
        request.endDate = endDate;
        setRequestFetchList(request);
    }

    const onStartDeleteLoginLog = () => {
        employeeService.deleteLoginHistory(
            new RequestDeleteEmployeeLoginHistory(
                checkedLoginLogs.map($0 => $0.id)
            ),
            reloadData
        )
    }

    const onCheckLoginLog = (isCheck, loginLog) => {
        let loginLogs = checkedLoginLogs.filter($0 => $0.id != loginLog.id);
        if (isCheck) {
            loginLogs.push(loginLog);
        }
        setCheckedLoginLogs(loginLogs)
    }

    const onCheckAllLoginLog = () => {
        setCheckedLoginLogs(
            checkedLoginLogs.length == loginLogs.length
                ? defaultValues.emptyArray
                : loginLogs
        );
    }

    const reloadData = () => {
        setLoginLogs(defaultValues.emptyArray);
        setCheckedLoginLogs(defaultValues.emptyArray);
        employeeService.fetchLoginHistoryList(
            requestFetchList,
            _didFetchSucceed
        )
    }

    const _didFetchSucceed = (response) => {
        setLoginLogs(response.loginHistories);
        setPagination(response.pagination)
    }

    const setupTableCheckboxColumn = (key, value, action) => {
        return (
            <ColumnTable
                key={ key }
                handleAction={ action }
                isCheckbox={ true }
                value={ value } />
        )
    }

    const setupTableTextColumn = (key, value) => {
        return (
            <ColumnTable
                key={ key }
                title={ value } />
        )
    }

    const setupTableHeaders = () => {
        return [
            setupTableCheckboxColumn(
                defaultValues.emptyInt,
                !checkedLoginLogs.isEmpty()
                    && checkedLoginLogs.length == loginLogs.length,
                onCheckAllLoginLog
            ),
            setupTableTextColumn(
                defaultValues.emptyInt,
                titleStrings.number
            ),
            setupTableTextColumn(
                defaultValues.emptyInt,
                titleStrings.identityNumber
            ),
            setupTableTextColumn(
                defaultValues.emptyInt,
                titleStrings.username
            ),
            setupTableTextColumn(
                defaultValues.emptyInt,
                titleStrings.name
            ),
            setupTableTextColumn(
                defaultValues.emptyInt,
                titleStrings.lastLoginDate
            ),
            setupTableTextColumn(
                defaultValues.emptyInt,
                titleStrings.ipAddress
            )
        ]
    }

    const setupTableRows = () => {
        return loginLogs.map(
            (loginLog, index) => [
                setupTableCheckboxColumn(
                    defaultValues.emptyInt,
                    checkedLoginLogs.some($0 => $0.id == loginLog.id),
                    (isCheck) => onCheckLoginLog(isCheck, loginLog)
                ),
                setupTableTextColumn(
                    defaultValues.emptyInt,
                    (index + 1) + (requestFetchList.pageSize * pagination.currentPage)
                ),
                setupTableTextColumn(
                    defaultValues.emptyInt,
                    loginLog.employee.id.toString()
                ),
                setupTableTextColumn(
                    defaultValues.emptyInt,
                    loginLog.employee.username
                ),
                setupTableTextColumn(
                    defaultValues.emptyInt,
                    loginLog.employee.name
                ),
                setupTableTextColumn(
                    defaultValues.emptyInt,
                    loginLog.lastLogin
                ),
                setupTableTextColumn(
                    defaultValues.emptyInt,
                    loginLog.ipAddress 
                )
            ]
        )
    }

    const onPageChange = (page) => {
        const request = requestFetchList;
        request.page = page;
        pagination.currentPage = page;
        setPagination(
            Object.assign(
                new Pagination(),
                pagination
            )
        );
        setRequestFetchList(
            Object.assign(
                new RequestFetchEmployeeLoginHistoryList(),
                request
            )
        )
    }

    useEffect(
        () => reloadData(),
        [requestFetchList]
    )

    return {
        setupTableHeaders,
        setupTableRows,
        reloadData,
        onStartSearchLoginLog,
        onStartDeleteLoginLog,
        onPageChange,
        pagination
    }
}

export default useEmployeeLoginLogPage;