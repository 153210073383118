import styled from 'styled-components';
import tw from "twin.macro";

const Container = styled.div`
    width: 450px;
    max-width: 90%;
    max-height: 90%;
    ${tw `flex flex-col m-auto bg-white overflow-y-auto p-8`}
`;

const TitleText = styled.p`
    ${ tw`font-bold text-xl text-left mb-5` }
`;

const FieldContainer = styled.div`
    ${ tw`grid grid-cols-3 mb-2`}
`;

const FieldTitleWrapper = styled.div`
    ${ tw`col-span-1`}
`;

const FieldValueWrapper = styled.div`
    ${ tw`col-span-2`}
`;

const CloseButton = styled.button`
    width: 20px;
    height: 20px;
    ${tw `ml-auto`}
`;

export {
    Container,
    TitleText,
    CloseButton,
    FieldContainer,
    FieldTitleWrapper,
    FieldValueWrapper
}