import defaultValues from "constants/defaultValues";
import * as propertyPrintPages from 'enumerations/propertyPrintPages';

export default class PageCardPreviewProperty {
    key = defaultValues.emptyString;
    type = propertyPrintPages.main
    isExcluded = false;

    constructor(key, type, isExcluded) {
        this.key = key;
        this.type = type;
        this.isExcluded = isExcluded;
    }
}