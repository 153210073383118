import { makeAutoObservable } from "mobx";
import { ColumnTableViewModel } from "../column-table/ColumnTableViewModel";

export class RowTableViewModel {
    columnTableViewModels = []

    constructor(
        columnTableViewModels = []
    ) {
        this.columnTableViewModels = columnTableViewModels
        makeAutoObservable(this);
    }

    setupColumnTableView = () => {
        this.columnTableViewModels.push(new ColumnTableViewModel());
    }
}