import LocalizedStrings from 'react-localization';

export default new LocalizedStrings({
    en:{
        createdAt: "Created At",
        author: "Author",
        history: "History",
        floor: "Floor",
        areaInSquareMeter: "Area(㎡)",
        acreage: "Acreage",
        floorUsage: "Usage",
        numberOfRentals: "Number of Rentals",
        usage: "Usage",
        deposit: "Deposit",
        monthlyPrice: "Monthly Price",
        vatExcluded: "VAT Excluded",
        adminPrice: "Admin Price",
        contractDate: "Contract Date",
        note: "Note",
        rentPerPyeong: "Rate per Pyeong",
        except: "Except"
    },
    kr: {
        createdAt: "날짜",
        author: "작업자",
        history: "작업내역",
        floor: "층",
        areaInSquareMeter: "면적(㎡)",
        acreage: "평수",
        floorUsage: "층별용도",
        numberOfRentals: "임대평수",
        usage: "업종",
        deposit: "보증금",
        monthlyPrice: "월세",
        vatExcluded: "VAT별도",
        adminPrice: "관리비",
        contractDate: "계약일자",
        note: "비고",
        rentPerPyeong: "평당임대료",
        except: "제외"
    }
});
