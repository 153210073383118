export class RequestDeleteCustomer {
    constructor(
        customerIds = []
    ) {
        this.customerIds = customerIds;
    }

    mapToJson() {
        return {
            'ids': this.customerIds
        }
    }
}