import cardServices from 'redux/actions/cardActions';
import * as printCardActions from './printCardReducer';
import { RequestInquiryProperty } from 'models/requests/RequestInquiryCard';
import documentService from 'services/documentService';
import { RequestInquiryDocSetting } from 'models/requests/RequestInquiryDocSetting';

const usePrintCardPage = () => {
  const loadData = (propertyId) => {
    cardServices.inquiryPropertyCardById(
      new RequestInquiryProperty(propertyId),
      (response) => {
        _inquiryDocumentSetting(response.card);
      }
    );
  };

  const _inquiryDocumentSetting = (property) => {
    printCardActions.setupProperty(property);
    reloadDocumentSetting(property.id);
  };

  const reloadDocumentSetting = (propertyId) => {
    documentService.inquiryDocumentSetting(
      new RequestInquiryDocSetting(propertyId),
      didInquiryDocumentSucceed
    );
  };

  const didInquiryDocumentSucceed = (response) => {
    printCardActions.initiatePages(response);
  };

  return {
    loadData,
    reloadDocumentSetting,
  };
};

export default usePrintCardPage;
