import { LandUsage } from "models/objects/LandUsage";
import { RequestPagination } from "./RequestPagination";
import * as paginationSize from "constants/paginationSize";
import * as defaultValues from "constants/defaultValue";

export class RequestSearchProperty {
    constructor(
        pagination = new RequestPagination(
            defaultValues.emptyInt,
            paginationSize.medium
        ),
        status = [],
        name = defaultValues.emptyString,
        owner = defaultValues.emptyString,
        id = defaultValues.emptyString,
        phone = defaultValues.emptyString,
        manager = defaultValues.emptyString,
        address = defaultValues.emptyString,
        streetName = defaultValues.emptyString,
        nearestStation = defaultValues.emptyString,
        isRoadSide,
        isRoadCorner,
        minPrice = defaultValues.emptyString,
        maxPrice = defaultValues.emptyString,
        minRevenue = defaultValues.emptyString,
        maxRevenue = defaultValues.emptyString,
        minLandAreaPy = defaultValues.emptyString,
        maxLandAreaPy = defaultValues.emptyString,
        minLandArea = defaultValues.emptyString,
        maxLandArea = defaultValues.emptyString,
        minFloorAreaPy = defaultValues.emptyString,
        maxFloorAreaPy = defaultValues.emptyString,
        minFloorArea = defaultValues.emptyString,
        maxFloorArea = defaultValues.emptyString,
        usage = new LandUsage(),
    ){
        this.status = status;
        this.name = name;
        this.owner = owner;
        this.id = id;
        this.phone = phone;
        this.manager = manager;
        this.address = address;
        this.streetName = streetName;
        this.nearestStation = nearestStation;
        this.isRoadCorner = isRoadCorner;
        this.isRoadSide = isRoadSide;
        this.minPrice = minPrice;
        this.maxPrice = maxPrice;
        this.minRevenue = minRevenue;
        this.maxRevenue = maxRevenue;
        this.minLandAreaPy = minLandAreaPy;
        this.maxLandAreaPy = maxLandAreaPy;
        this.minLandArea = minLandArea;
        this.maxLandArea = maxLandArea;
        this.minFloorAreaPy = minFloorAreaPy;
        this.maxFloorAreaPy = maxFloorAreaPy;
        this.minFloorArea = minFloorArea;
        this.maxFloorArea = maxFloorArea;
        this.usage = usage;
        this.pagination = pagination;
    }

    mapToJson() {
        const json = {
            "status": this.status,
            "name": this.name,
            "owner": this.owner,
            "id": this.id,
            "phone": this.phone,
            "manager": this.manager,
            "address": this.address,
            "street_address": this.streetName,
            "nearest_station": this.nearestStation,
            "is_road_side": this.isRoadSide,
            "is_road_corner": this.isRoadCorner,
            "usage": this.usage .mapToJson(),
            "page": this.pagination.page,
            "page_size": this.pagination.pageSize
        }
        if (this.minPrice) {
            json["min_price"] = Number(this.minPrice) * 10000;
        }
        if (this.maxPrice) {
            json["max_price"] = Number(this.maxPrice) * 10000;
        }
        if (this.minRevenue) {
            json["min_revenue"] = Number(this.minRevenue);
        }
        if (this.maxRevenue) {
            json["max_revenue"] = Number(this.maxRevenue);
        }
        if (this.minLandAreaPy) {
            json["min_land_py"] = Number(this.minLandAreaPy);
        }
        if (this.maxLandAreaPy) {
            json["max_land_py"] = Number(this.maxLandAreaPy);
        }
        if (this.minLandArea) {
            json["min_land_m"] = Number(this.minLandArea);
        }
        if (this.maxLandArea) {
            json["max_land_m"] = Number(this.maxLandArea);
        }
        if (this.minFloorAreaPy) {
            json["min_floor_py"] = Number(this.minFloorAreaPy);
        }
        if (this.maxFloorAreaPy) {
            json["max_floor_py"] = Number(this.maxFloorAreaPy);
        }
        if (this.minFloorArea) {
            json["min_floor_m"] = Number(this.minFloorArea);
        }
        if (this.maxFloorArea) {
            json["max_floor_m"] = Number(this.maxFloorArea);
        }
        return json;
    }

    mapFromJson(json) {
        this.status = json["status"];
        this.name = json["name"];
        this.owner = json["owner"];
        this.id = json["id"];
        this.phone = json["phone"];
        this.manager = json["manager"];
        this.address = json["address"];
        this.streetName = json["street_address"];
        this.nearestStation = json["nearest_station"];
        this.isRoadSide = json["is_road_side"];
        this.isRoadCorner = json["is_road_corner"];
        this.minPrice = json["min_price"] / 10000;
        this.maxPrice = json["max_price"] / 10000;
        this.minRevenue = json["min_revenue"];
        this.maxRevenue = json["max_revenue"];
        this.minLandAreaPy = json["min_land_py"];
        this.maxLandAreaPy = json["max_land_py"];
        this.minFloorAreaPy = json["min_floor_py"];
        this.maxFloorAreaPy = json["max_floor_py"];
        this.minFloorArea = json["min_floor_m"];
        this.maxFloorArea = json["max_floor_m"];
        this.usage = new LandUsage().mapFromJson(json["usage"]);
        this.pagination = new RequestPagination(
            json["page"],
            json["page_size"]
        )
        return this;
    }
}