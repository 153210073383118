import defaultValues from "constants/defaultValues";

export class Message {
    constructor(
        title = defaultValues.emptyString,
        subtitle = defaultValues.emptyString,
        action = () => { return; }
    ) {
        this.title = title;
        this.subtitle = subtitle;
        this.action = action
    }

    mapFromJson(json) {
        if (!json) { return this; }
        this.title = json['message']['korean'];
        return this;
    }
}