import React, { Component } from 'react'
import PropTypes from 'prop-types';
import defaultValues from 'constants/defaultValues';
import buttonStrings from 'localizations/buttonStrings';
import Form from '../../layouts/forms/Form';
import buttonType from 'enumerations/buttonType';
import * as styled from './MemoContainer.styled';

const MemoContainer = (props) => {
    const renderFormContent = () => {
        return (
            <styled.Container>
                <styled.Header>
                    <styled.Title>
                        { props.title }
                    </styled.Title>
                    <styled.SaveButton
                        disabled={ props.value.isEmpty() }
                        type={ buttonType.submit }>
                        { buttonStrings.save }
                    </styled.SaveButton>
                </styled.Header>
                <div className="flex-1 overflow-hidden">
                    <styled.TextArea
                        name="memo"
                        value={ props.value }
                        onChange={
                            (input) => props.onValueChange(input.target.value)
                        }/>
                </div>
            </styled.Container>
        )
    }

    return (
        <Form
            content={ renderFormContent() }
            onSubmit={ props.onSaveMemo } />
    )
}

MemoContainer.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
    onSaveMemo: PropTypes.func,
    onValueChange: PropTypes.func,
};

MemoContainer.defaultProps = {
    title: defaultValues.emptyString,
    value: defaultValues.emptyString,
    onSaveMemo: defaultValues.emptyFunction,
    onValueChange: defaultValues.emptyFunction
};

export default MemoContainer;