import { Employee } from "models/objects/Employee";
import BaseAction from "./baseAction"

const basePath = "/v1/auth";
export default {
    login: (
        requestLogin,
        onSuccess,
        onFailed
    ) => {
        return BaseAction().post(
            `${basePath}/login`,
            requestLogin.mapToJson(),
            (response) => {
                onSuccess(response.data.user);
            },
            onFailed
        )
    }
}