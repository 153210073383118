import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import * as defaultValues from 'constants/defaultValue';
import Text from 'components/core/text/Text';
import HorizontaLabeledText from 'components/HorizontalLabeledText/HorizontalLabeledText';
import alignments from 'constants/alignments';
import { useSelector } from 'react-redux';
import * as styled from './SummaryPrintPage.styled';
import fieldStrings from 'localizations/fieldStrings';
import PrintPageHeader from 'components/PrintPageHeader/PrintPageHeader';
import HighlightedTextStyle from 'components/core/text/styles/HighlightedTextStyle';

const SummaryPrintPage = (props) => {
  const wrapperRef = useRef(null);
  const containerRef = useRef(null);

  const property = useSelector((state) => state.printProperty.property);

  const renderAmountInformation = () => {
    return (
      <div>
        <PrintPageHeader title={'금액정보'} />
        <styled.CalculationContainer>
          <div>
            <Text
              alignment={alignments.center}
              style={HighlightedTextStyle}
              value={'매매가격'}
            />
            <Text
              alignment={alignments.center}
              style={HighlightedTextStyle}
              value={property.priceInformation.totalPrice.tenThousandFormat()}
            />
          </div>
          <div>
            <Text
              alignment={alignments.center}
              style={HighlightedTextStyle}
              value={'토지 평당가격'}
            />
            <Text
              alignment={alignments.center}
              style={HighlightedTextStyle}
              value={property.getPricePerPy().tenThousandFormat()}
            />
          </div>
          <div>
            <Text
              alignment={alignments.center}
              style={HighlightedTextStyle}
              value={'연면적 평당가격'}
            />
            <Text
              alignment={alignments.center}
              style={HighlightedTextStyle}
              value={property.getPricePerTotalArea().tenThousandFormat()}
            />
          </div>
        </styled.CalculationContainer>
      </div>
    );
  };

  const renderRentalInformation = () => {
    return (
      <div>
        <PrintPageHeader title={'임대정보'} />
        <styled.CalculationContainer>
          <div>
            <Text
              alignment={alignments.center}
              style={HighlightedTextStyle}
              value={'수익률'}
            />
            <Text
              alignment={alignments.center}
              style={HighlightedTextStyle}
              value={
                property.priceInformation.revenue
                  .rounded(2)
                  .toString()
                  .thousandFormat() + '%'
              }
            />
          </div>
          <div>
            <Text
              alignment={alignments.center}
              style={HighlightedTextStyle}
              value={'보증금'}
            />
            <Text
              alignment={alignments.center}
              style={HighlightedTextStyle}
              value={property.priceInformation.deposit.tenThousandFormat()}
            />
          </div>
          <div>
            <Text
              alignment={alignments.center}
              style={HighlightedTextStyle}
              value={'월수입'}
            />
            <Text
              alignment={alignments.center}
              style={HighlightedTextStyle}
              value={property.priceInformation.monthlyIncome.tenThousandFormat()}
            />
            <div className="flex flex-col">
              <div className="mx-auto">
                <Text
                  className="!text-base !opacity-60 !mb-1"
                  value={
                    '(+)월세: ' +
                    property
                      .getMonthlyRent()
                      .rounded(0)
                      .toString()
                      .thousandFormat()
                  }
                />
                <Text
                  className="!text-base !opacity-60 !mb-1"
                  value={
                    '(+)관리비: ' +
                    property.priceInformation.adminPrice
                      .rounded(0)
                      .toString()
                      .thousandFormat()
                  }
                />
                <Text
                  className="!text-base !opacity-60 !mb-1"
                  value={
                    '(-)관리 지출: ' +
                    property.priceInformation.adminRevenueRate
                      .rounded(0)
                      .toString()
                      .thousandFormat()
                  }
                />
              </div>
            </div>
          </div>
        </styled.CalculationContainer>
      </div>
    );
  };

  const renderNotesSection = () => {
    return (
      <div>
        <PrintPageHeader title={'특이사항'} />
        <div>
          <Text
            className="white-space: pre-wrap;"
            fontSize={14}
            value={property.notes}
          />
        </div>
      </div>
    );
  };

  const renderLabeledText = (title, value) => {
    return (
      <HorizontaLabeledText
        title={title}
        values={[value]}
      />
    );
  };

  const renderLandInformation = () => {
    return (
      <div>
        <PrintPageHeader title={'토지정보'} />
        <styled.LandInformationContainer>
          <HorizontaLabeledText
            title={'대지면적'}
            isTitleBold={true}
            isValueBold={true}
            values={[
              property.landInformation.area.thousandFormat(2) +
                '㎡[' +
                property.landInformation.areaPy.thousandFormat(2) +
                ' 평]',
            ]}
          />
          {renderLabeledText('용도지역', property.landInformation.usage)}
          {renderLabeledText(
            '공시지가 합계',
            property.landInformation.officialPrice.tenThousandFormat() +
              '[평당 ' +
              property.landInformation.officialPricePy
                .rounded(0)
                .tenThousandFormat() +
              ']'
          )}
          {renderLabeledText(
            '주변역 / 거리',
            property.nearestStation + ' / ' + property.distanceStation + 'm'
          )}
          {renderLabeledText(
            '도로사항',
            property.landInformation.roadWidth +
              '[' +
              property.landInformation.getRoadPosition() +
              ']'
          )}
        </styled.LandInformationContainer>
      </div>
    );
  };

  const renderBuildingInformation = () => {
    return (
      <div>
        <PrintPageHeader title={'건축물정보'} />
        <styled.LandInformationContainer>
          <HorizontaLabeledText
            title={'연면적'}
            isTitleBold={true}
            isValueBold={true}
            values={[
              property.buildingInformation.totalArea.thousandFormat(2) +
                '㎡ ' +
                '[' +
                property.buildingInformation.totalAreaInPY.thousandFormat(2) +
                ' 평]',
            ]}
          />
          {renderLabeledText(
            '건축면적',
            property.buildingInformation.buildingToCover.thousandFormat() +
              '㎡ ' +
              '[' +
              property.buildingInformation
                .getBuildingToCoverPy()
                .thousandFormat(2) +
              ' 평]'
          )}
          {renderLabeledText('규모', property.buildingInformation.floor)}
          {renderLabeledText(
            '준공연도 / 리모델링',
            property.buildingInformation.builtAt +
              ' / ' +
              property.buildingInformation.getRemodelAt()
          )}
          {renderLabeledText(
            '건폐율 / 용적률',
            property.buildingInformation.totalAreaRatio.percentage(2) +
              ' / ' +
              property.buildingInformation.floorAreaRatio.percentage(2)
          )}
          {renderLabeledText(
            '주차방식 / 대수',
            property.buildingInformation.parking.name +
              ' / ' +
              property.buildingInformation.parking.totalGoverment
          )}
          {renderLabeledText(
            '냉 / 난방식',
            property.buildingInformation.airConditioner.name
          )}
          {renderLabeledText(
            '승강기',
            property.buildingInformation.elevatorStatus
          )}
          {renderLabeledText(
            '위반건축물',
            property.buildingInformation.isIllegalBuilding
              ? fieldStrings.corresponding
              : fieldStrings.notApplicable
          )}
          {renderLabeledText(
            '집합건축물',
            property.buildingInformation.isGroupBuilding
              ? fieldStrings.corresponding
              : fieldStrings.notApplicable
          )}
        </styled.LandInformationContainer>
      </div>
    );
  };

  return (
    <styled.Wrapper ref={wrapperRef}>
      <styled.Container
        ref={containerRef}
        scale={
          wrapperRef.current?.clientWidth / containerRef.current?.clientWidth
        }
        data-id={props.id}>
        <PrintPageHeader
          title={'매물정보'}
          isShowLogo={true}
        />
        <styled.BodyContainer>
          <div className="grid grid-cols-2 gap-x-8">
            <styled.SingleRowContainer>
              {renderAmountInformation()}
            </styled.SingleRowContainer>
            <styled.SingleRowContainer>
              {renderRentalInformation()}
            </styled.SingleRowContainer>
          </div>
          <div className="grid grid-cols-2 gap-x-8">
            <styled.SingleRowContainer>
              {renderLandInformation()}
            </styled.SingleRowContainer>
            <div>{renderBuildingInformation()}</div>
          </div>
          <div className="col-span-2">{renderNotesSection()}</div>
        </styled.BodyContainer>
      </styled.Container>
    </styled.Wrapper>
  );
};

SummaryPrintPage.propTypes = {
  id: PropTypes.string,
};

SummaryPrintPage.defaultProps = {
  id: defaultValues.emptyString,
};

export default SummaryPrintPage;
