import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CardSection from 'components/CardSection/CardSection';
import * as styled from './BuildingForm.styled';
import * as defaultValues from 'constants/defaultValue';
import titleStrings from 'localizations/titleStrings';
import fieldStrings from 'localizations/fieldStrings';
import CheckboxInput from 'components/core/input-checkbox/CheckboxInput';
import DropdownInput from 'components/core/dropdown-input/DropdownInput';
import { BorderedDropdownInputStyle } from 'components/core/dropdown-input/styles';
import sizes from 'constants/sizes';
import operators from 'constants/operators';
import Text from 'components/core/text/Text';
import fieldNames from 'constants/fieldNames';
import textInputType from 'enumerations/textInputType';
import TextInput from 'components/core/text-input/TextInput';
import { BorderedTextInputStyle } from 'components/core/text-input/styles';
import { observer } from 'mobx-react';
import { useDispatch, useSelector } from 'react-redux';
import cardActionTypes from 'redux/types/cardActionTypes';
import cardActions from 'redux/actions/cardActions';
import { BuildingFormViewModel } from './BuildingFormViewModel';
import paddingPositions from 'enumerations/paddingPositions';
import spaces from 'constants/spaces';
import useBuildingForm from './useBuildingForm';
import unitExhanges from 'constants/unitExhanges';

const BuildingForm = observer((props) => {
    const [viewModel] = useState(new BuildingFormViewModel());
    const cardReducer = useSelector((state) => state.card);
    const dispatch = useDispatch();
    const handleInputValueChange = (input) => {
        const buildingInformation = cardReducer.requestWriteCard.buildingInformation;
        buildingInformation[input.name] = input.value;
        props.handleInputValueChange({
            name: fieldNames.buildingInformation,
            value: buildingInformation
        })
    }
    const hook = useBuildingForm(handleInputValueChange);

    const handleCheckIllegalBuilding = () => {
        handleInputValueChange({
            name: fieldNames.isIllegalBuilding,
            value: true
        });
    }

    const handleUncheckIllegalBuilding = () => {
        handleInputValueChange({
            name: fieldNames.isIllegalBuilding,
            value: false
        });
    }

    const handleCheckGroupBuilding = () => {
        handleInputValueChange({
            name: fieldNames.isGroupBuilding,
            value: true
        });
    }

    const handleUncheckGroupBuilding = () => {
        handleInputValueChange({
            name: fieldNames.isGroupBuilding,
            value: false
        });
    }

    const setupTotalArea = () => {
        const totalArea = cardReducer
            .requestWriteCard
            .totalArea(false) * unitExhanges.meterSquareToPyeong
        return totalArea.rounded(2);
    }

    const setupFloorAreaRatio = () => {
        return cardReducer.requestWriteCard.floorAreaRatio().rounded(2)
    }

    const setupPriceTotalArea = () => {
        const rentalPrice = cardReducer.requestWriteCard.priceInformation.getRentalPrice();
        return (rentalPrice / setupTotalArea()).rounded(0)
    }

    const renderContent = () => {
        const buildingInformation = cardReducer.requestWriteCard.buildingInformation;
        return (
            <styled.Container>
                <styled.OuterContentContainer>
                    <styled.HalfContentContainer>
                        <Text value={ fieldStrings.grossFloorArea }/>
                        <TextInput
                            name={ "totalArea" }
                            margin={{ 
                                position: paddingPositions.right,
                                value: spaces.small
                            }}
                            suffixText={ fieldStrings.flat }
                            isDisabled={ true }
                            height={ sizes.textInput.small }
                            style={ BorderedTextInputStyle }
                            onChange={ handleInputValueChange }
                            type={ textInputType.currency }
                            value={ setupTotalArea().toString() }/>
                    </styled.HalfContentContainer>
                </styled.OuterContentContainer>
                <styled.OuterContentContainer>
                    <styled.HalfContentContainer>
                        <Text value={ fieldStrings.remodel }/>
                        <TextInput
                            margin={{ 
                                position: paddingPositions.right,
                                value: spaces.small
                            }}
                            name={ "remodelAt" }
                            onChange={ handleInputValueChange }
                            height={ sizes.textInput.small }
                            style={ BorderedTextInputStyle }
                            value={ cardReducer.requestWriteCard.buildingInformation.remodelAt }/>
                    </styled.HalfContentContainer>
                    <styled.HalfContentContainer isTrailing={ true }>
                        <Text value={ fieldStrings.buildingRatio }/>
                        <TextInput
                            suffixText={ operators.percent }
                            height={ sizes.textInput.small }
                            isDisabled={ true }
                            style={ BorderedTextInputStyle }
                            type={ textInputType.currency }
                            value={ setupFloorAreaRatio().toString() }/>
                    </styled.HalfContentContainer>
                </styled.OuterContentContainer>
                <styled.OuterContentContainer>
                    <div className="flex col-span-1">
                        <Text value={ fieldStrings.purpose } />
                    </div>
                    <div className="col-span-3">
                        <TextInput
                            name={ fieldNames.usage }
                            height={ sizes.textInput.small }
                            onChange={ handleInputValueChange }
                            style={ BorderedTextInputStyle }
                            type={ textInputType.text }
                            value={ cardReducer.requestWriteCard.buildingInformation.usage }/> 
                    </div>
                </styled.OuterContentContainer>
                <styled.OuterContentContainer>
                    <div className="flex col-span-1">
                        <Text value={ `${fieldStrings.airConditioner} / ${fieldStrings.heater}` }/>
                    </div>
                    <div className="col-span-2">
                        <TextInput
                            margin={{ 
                                position: paddingPositions.right,
                                value: spaces.small
                            }}
                            isDisabled={ true }
                            height={ sizes.textInput.small }
                            style={ BorderedTextInputStyle }
                            value={ cardReducer.requestWriteCard.buildingInformation.airConditioner.name }/>
                    </div>
                    <div className="col-span-1">
                        <DropdownInput
                            name={ 'airConditioner' }
                            customValue={
                                cardReducer
                                    .requestWriteCard
                                    .buildingInformation
                                    .airConditioner
                                    .name
                            }
                            margin={{ 
                                position: paddingPositions.left,
                                value: spaces.extraSmall
                            }}
                            height={ sizes.textInput.small }
                            style={ BorderedDropdownInputStyle }
                            options={ viewModel.setupAirConditionerItems() }
                            selectedOption={
                                viewModel.setupSelectedAirConditionerItem(
                                    cardReducer.requestWriteCard.buildingInformation.airConditioner
                                )
                            }
                            onCustomValueChange={ hook.onAirConditionerChange }
                            handleSelectOption={ viewModel.onSelectAirConditioner } />
                    </div>
                </styled.OuterContentContainer>
                <styled.OuterContentContainer>
                    <styled.HalfContentContainer>
                        <Text value={ fieldStrings.builtAt }/>
                        <TextInput
                            margin={{ 
                                position: paddingPositions.right,
                                value: spaces.small
                            }}
                            height={ sizes.textInput.small }
                            style={ BorderedTextInputStyle }
                            // name={ "buildAt" }
                            name={ "permitDate" }
                            onChange={ handleInputValueChange }
                            // value={ cardReducer.requestWriteCard.buildingInformation.buildAt }/>
                            value={ cardReducer.requestWriteCard.buildingInformation.permitDate }/>
                    </styled.HalfContentContainer>
                    <styled.HalfContentContainer isTrailing={ true }>
                        <Text value={ fieldStrings.parkingMethod }/>
                        <DropdownInput
                            name={ 'parkingMethod' }
                            customValue={
                                cardReducer
                                    .requestWriteCard
                                    .buildingInformation
                                    .parkingMethod
                                    .name
                            }
                            height={ sizes.textInput.small }
                            style={ BorderedDropdownInputStyle }
                            options={ viewModel.setupParkingMethodItems() }
                            selectedOption={ 
                                viewModel.setupSelectedParkingMethodItem(
                                    cardReducer.requestWriteCard.buildingInformation.parkingMethod
                                )
                             }
                            onCustomValueChange={ hook.onParkingMethodChange }
                            handleSelectOption={ viewModel.onSelectParkingMethod } />
                    </styled.HalfContentContainer>
                </styled.OuterContentContainer>
                <styled.OuterContentContainer>
                    <styled.HalfContentContainer>
                        <Text value={ fieldStrings.builtWith }/>
                        <TextInput
                            name={ fieldNames.builtWith }
                            margin={{ 
                                position: paddingPositions.right,
                                value: spaces.small
                            }}
                            height={ sizes.textInput.small }
                            style={ BorderedTextInputStyle }
                            onChange={ handleInputValueChange }
                            value={ cardReducer.requestWriteCard.buildingInformation.builtWith } />
                    </styled.HalfContentContainer>
                    <styled.HalfContentContainer isTrailing={ true }>
                        <Text value={ fieldStrings.actualCourt }/>
                        <TextInput
                            name={ "totalParkingGoverment" }
                            height={ sizes.textInput.small }
                            style={ BorderedTextInputStyle }
                            onChange={ handleInputValueChange }
                            value={
                                cardReducer.requestWriteCard.buildingInformation.totalParkingGoverment
                            }/>
                    </styled.HalfContentContainer>
                </styled.OuterContentContainer>
                <styled.OuterContentContainer>
                    <styled.HalfContentContainer>
                        <Text value={ fieldStrings.floor }/>
                        <TextInput
                            margin={{ 
                                position: paddingPositions.right,
                                value: spaces.small
                            }}
                            name={ fieldNames.floor }
                            height={ sizes.textInput.small }
                            onChange={ handleInputValueChange }
                            style={ BorderedTextInputStyle }
                            value={ cardReducer.requestWriteCard.buildingInformation.floor } />
                    </styled.HalfContentContainer>
                    <styled.HalfContentContainer isTrailing={ true }>
                        <Text value={ fieldStrings.ev }/>
                        <TextInput
                            margin={{ 
                                position: paddingPositions.right,
                                value: spaces.small
                            }}
                            name={ fieldNames.elevator }
                            height={ sizes.textInput.small }
                            onChange={ handleInputValueChange }
                            style={ BorderedTextInputStyle }
                            value={ cardReducer.requestWriteCard.buildingInformation.elevator }/>
                    </styled.HalfContentContainer>
                </styled.OuterContentContainer>
                <styled.OuterContentContainer>
                    <styled.HalfContentContainer>
                        <Text value={ fieldStrings.land }/>
                        <TextInput
                            margin={{ 
                                position: paddingPositions.right,
                                value: spaces.small
                            }}
                            isDisabled={ true }
                            height={ sizes.textInput.small }
                            style={ BorderedTextInputStyle }
                            suffixText={ fieldStrings.tenThousand }
                            type={ textInputType.currency }
                            value={ setupPriceTotalArea().toString() } />
                    </styled.HalfContentContainer>
                </styled.OuterContentContainer>
                <styled.OuterContentContainer>
                    <div className="flex col-span-1">
                        <Text value={ `${ fieldStrings.illegalBuilding }` }/>
                    </div>
                    <div className="flex gap-3 col-span-3">
                        <CheckboxInput
                            label={ fieldStrings.corresponding }
                            onChange={ handleCheckIllegalBuilding }
                            checked={ buildingInformation.isIllegalBuilding }/>
                        <CheckboxInput
                            label={ fieldStrings.notApplicable }
                            onChange={ handleUncheckIllegalBuilding }
                            checked={ !buildingInformation.isIllegalBuilding }/>
                    </div>
                </styled.OuterContentContainer>

                <styled.OuterContentContainer>
                    <div className="flex col-span-1">
                        <Text value={ `${ fieldStrings.groupBuilding }` }/>
                    </div>
                    <div className="flex gap-3 col-span-3">
                        <CheckboxInput
                            label={ fieldStrings.corresponding }
                            onChange={ handleCheckGroupBuilding }
                            checked={ buildingInformation.isGroupBuilding }/>
                        <CheckboxInput
                            label={ fieldStrings.notApplicable }
                            onChange={ handleUncheckGroupBuilding }
                            checked={ !buildingInformation.isGroupBuilding }/>
                    </div>
                </styled.OuterContentContainer>
            </styled.Container>
        )
    }

    useEffect(() => {
        dispatch({
            type: cardActionTypes.FETCH_LAND_AIR_CONDITIONERS,
            payload: cardActions.fetchAirConditioners(
                viewModel.didFetchAirConditionerSucceed
            )
        })
        dispatch({
            type: cardActionTypes.FETCH_LAND_PARKING_METHODS,
            payload: cardActions.fetchParkingMethods(
                viewModel.didFetchParkingMethodsSucceed
            )
        })
    }, []);

    useEffect(() => {
        viewModel.onInputValueChange = handleInputValueChange;
    }, [props]);

    return (
        <CardSection
            title={ titleStrings.building }
            content={ renderContent() } />
    )
});

BuildingForm.propTypes = {
    handleInputValueChange: PropTypes.func,
    excludedFloorInformations: PropTypes.array
};

BuildingForm.defaultProps = {
    handleInputValueChange: () => { return; },
    excludedFloorInformations: []
}

export default BuildingForm;