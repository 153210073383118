import { ButtonViewModel } from "components/core/button/ButtonViewModel";
import { TextInputViewModel } from "components/core/text-input/TextInputViewModel";
import * as defaultValues from "constants/defaultValue"
import { makeAutoObservable } from "mobx";

export class TextInputModalViewModel {
    title = defaultValues.emptyString;
    placeholder = defaultValues.emptyString;
    suffix = defaultValues.emptyString;
    textInputViewModel = new TextInputViewModel()
    buttonViewModel = new ButtonViewModel();

    constructor(title) {
        this.title = title;
        makeAutoObservable(this);
    }
}