import React, { useState } from 'react';
import styled from 'styled-components';
import tw from "twin.macro";
import Modal from '../../components/core/modal/Modal';
import PropTypes from 'prop-types';
import CloseIconSrc from './../../assets/close_icon_black.png';
import ImageAlt from '../../constants/imageAlt';
import { commonActionTypes, employeeActionTypes } from 'redux/types';
import { employeeAction } from 'redux/actions';
import CreateChangeManagerForm from 'layouts/forms/CreateChageManagerForm';
import { Employee } from 'models/objects/Employee';
import { RequestFetchEmployeeList } from 'models/requests';
import { observer } from 'mobx-react';
import { useDispatch } from 'react-redux';

const Container = styled.div`
    width: 550px;
    max-width: 90%;
    max-height: 90%;
    ${tw `flex flex-col m-auto bg-white p-8`}
`;

const CloseButton = styled.button`
    width: 20px;
    height: 20px;
    ${tw `ml-auto`}
`;

const ChangeManagerModal = observer((props) => {
    const [employees, setEmployees] = useState([]);
    const dispatch = useDispatch();

    const onCloseModal = () => {
        dispatch({
            type: commonActionTypes.HIDE_MODAL
        })
    }

    const didFetchEmployeeListSucceed = (response) => {
        setEmployees(response.employees);
    }

    const renderContent = () => {
        return (
            <Container>
                <CloseButton
                    onClick={ onCloseModal }>
                    <img
                        src={ CloseIconSrc }
                        alt={ ImageAlt.closeIcon } />
                </CloseButton>
                <p className="font-bold text-xl text-left mb-5">담당자 이전</p>
                <CreateChangeManagerForm
                    onChangeManagerSuccess={ props.onChangeManagerSuccess }
                    selectedOldEmployee={ props.selectedManager }
                    selectedCustomers={ props.selectedCustomers }
                    employeeOptions={ employees } />
            </Container>
        )
    }

    useState(() => {
        dispatch({
            type: employeeActionTypes.FETCH_EMPLOYEE_LIST,
            payload: employeeAction.fetchEmployeeList(
                new RequestFetchEmployeeList(),
                didFetchEmployeeListSucceed
            )
        })
    }, [])

    return (
        <Modal
            content={ renderContent() }
            isShow={ true } />
    );
});

ChangeManagerModal.propTypes = {
    fetchEmployeeList: PropTypes.func,
    changeCustomerManager: PropTypes.func,
    onDismiss: PropTypes.func,
    onChangeManagerSuccess: PropTypes.func,
    selectedManager: PropTypes.object,
    selectedCustomers: PropTypes.array
};

ChangeManagerModal.defaultProps = {
    onEmployeeCreated: () => { return; },
    changeCustomerManager: () => { return; },
    onDismiss: () => { return; },
    onChangeManagerSuccess: () => { return; },
    selectedManager: new Employee(),
    selectedCustomers: []
};

export default ChangeManagerModal;