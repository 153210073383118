import decimalPlaces from "constants/decimalPlaces";
import * as defaultValues from "constants/defaultValue";
import unitExhanges from "constants/unitExhanges";
import { Employee } from "models/objects/Employee";
import { FloorInformation } from "models/objects/FloorInformation";
import { RentalHistory } from "models/objects/RentalHistory";
import { RequestCreatePriceInformation } from "models/requests/RequestCreatePriceInformation";
import { RequestCreateBuildingInformation } from "./RequestCreateBuildingInformation";
import { RequestCreateLand } from "./RequestCreateLand";
import { RequestLandSpesificInformation } from "./RequestCreateLandSpesificInformation";
import { RequestCreateOwnerInformation } from "./RequestCreateOwnerInformation";

export class RequestCreateCard {
    constructor(
        propertyId = defaultValues.emptyInt,
        name = defaultValues.emptyString,
        address = defaultValues.emptyString,
        streetAddress = defaultValues.emptyString,
        nearestStation = defaultValues.emptyString,
        distanceStation = defaultValues.emptyInt,
        notes = defaultValues.emptyString,
        status = defaultValues.emptyInt,
        advertisement = true,
        manager = new Employee(),
        priceInformation = new RequestCreatePriceInformation(),
        landInformation = new RequestCreateLand(),
        ownerInformation = new RequestCreateOwnerInformation(),
        landSpesificInformations = [],
        buildingInformation = new RequestCreateBuildingInformation(),
        workHistories = [],
        floorInformations = [],
        rentalHistories = [],
        imageInformations = []
    ) {
        this.propertyId = propertyId;
        this.name = name;
        this.address = address;
        this.streetAddress = streetAddress;
        this.nearestStation = nearestStation;
        this.distanceStation = distanceStation;
        this.notes = notes;
        this.status = status;
        this.advertisement = advertisement;
        this.manager = manager;
        this.priceInformation = priceInformation;
        this.landInformation = landInformation;
        this.ownerInformation = ownerInformation;
        this.landSpesificInformations = landSpesificInformations;
        this.buildingInformation = buildingInformation;
        this.workHistories = workHistories;
        this.floorInformations = floorInformations;
        this.rentalHistories = rentalHistories;
        this.imageInformations = imageInformations;
    }

    totalArea = (isExcludeBasement = false) => {
        return this.floorInformations.reduce(
            ($0, $1) => {
                if ($1.isExcluded) {
                    return $0;
                }
                if ($1.areaMergedDown) {
                    return $0;
                }
                if (isExcludeBasement && $1.isBasement()) {
                    return $0;
                }
                return $0 + $1.getArea()
            },
            defaultValues.emptyInt
        ).rounded(2)
    }

    landArea = () => {
        var landArea = 0;
        for (const landSpesificInformation of this.landSpesificInformations) {
            landArea = landArea + Number(landSpesificInformation.area);
        }
        return (landArea * unitExhanges.meterSquareToPyeong).rounded(decimalPlaces.areaSize);
    }

    getTotalRentalArea = () => {
        return this.rentalHistories.reduce(
            (item01, item02) => {
                if (item02.isExcluded) {
                    return item01;
                }
                if (item02.isAreaMergedDown) {
                    return item01 + defaultValues.emptyInt;
                }
                return item01 + parseFloat(item02.getRentalArea());
            },
            defaultValues.emptyInt
        )
    }

    getTotalRentalDeposit = () => {
        return this.rentalHistories.reduce(
            (item01, item02) => {
                if (item02.isExcluded) { 
                    return item01;
                }
                if (item02.isDepositMergedDown) {
                    return item01 + defaultValues.emptyInt;
                }
                return item01 + parseFloat(item02.getDeposit());
            },
            defaultValues.emptyInt
        )
    }

    getTotalMonthlyRentalPrice = () => {
        return this.rentalHistories.reduce(
            (item01, item02) => {
                if (item02.isExcluded) { 
                    return item01;
                }
                if (item02.isMonthlyRentalMergedDown) {
                    return item01 + defaultValues.emptyInt;
                }
                return item01 + parseFloat(item02.getMonthlyRental());
            },
            defaultValues.emptyInt
        )
    }

    getTotalRentalAdminFee = () => {
        return this.rentalHistories.reduce(
            (item01, item02) => {
                if (item02.isExcluded){ return item01; }
                if (item02.isAdminPriceMergedDown) {
                    return item01 + defaultValues.emptyInt;
                }
                return item01 + parseFloat(item02.getAdminFee());
            },
            defaultValues.emptyInt
        )
    }

    getTotalFloorArea = () => {
        return this.floorInformations.reduce(
            ($0, $1) => {
                if ($1.isExcluded) {
                    return $0;
                }
                if ($1.areaMergedDown) {
                    return $0 + defaultValues.emptyInt;
                }
                return $0 + parseFloat($1.getArea());
            },
            defaultValues.emptyInt
        )
    }

    pricePerLandArea = () => {
        const pricePerLandArea = this.priceInformation.getRentalPrice() / this.landArea();
        return isNaN(pricePerLandArea)
            ? defaultValues.emptyInt
            : pricePerLandArea.rounded(0)
    }

    floorAreaRatio = () => {
        const totalAreaPy = this.totalArea(true) * unitExhanges.meterSquareToPyeong
        const floorAreaRatio = (totalAreaPy / this.landArea()) * 100;
        return isNaN(floorAreaRatio)
            ? defaultValues.emptyInt
            : floorAreaRatio
    }

    mapFromObject(anyObject) {
        this.propertyId = anyObject.propertyId;
        this.name = anyObject.name;
        this.address = anyObject.address;
        this.streetAddress = anyObject.streetAddress;
        this.nearestStation = anyObject.nearestStation;
        this.distanceStation = anyObject.distanceStation;
        this.advertisement = anyObject.advertisement;
        this.priceInformation = anyObject.priceInformation;
        this.imageInformations = anyObject.imageInformations;
        this.landInformation = anyObject.landInformation;
        this.ownerInformation = anyObject.ownerInformation;
        this.landSpesificInformations = anyObject.landSpesificInformations;
        this.buildingInformation = anyObject.buildingInformation;
        this.workHistories = anyObject.workHistories;
        this.floorInformations = anyObject.floorInformations;
        this.rentalHistories = anyObject.rentalHistories;
        return this;
    }

    mapToJson() {
        return {
            'id': this.propertyId,
            'name': this.name,
            'address': this.address,
            'street_address': this.streetAddress,
            "manager_id": this.manager.id,
            'nearest_station': this.nearestStation,
            'distance_station': this.distanceStation,
            'notes': this.notes,
            'status': this.status,
            'advertisement': this.advertisement,
            'price_information': this.priceInformation.mapToJson(),
            'land_information': this.landInformation.mapToJson(),
            'owner_information': this.ownerInformation.mapToJson(),
            'land_specific_information': this.landSpesificInformations.map((landSpesificInformation) => {
                return new RequestLandSpesificInformation()
                    .mapFromObject(landSpesificInformation)
                    .mapToJson()
            }),
            'building_information': this.buildingInformation.mapToJson(),
            'floor_information': this.floorInformations.map(
                ($0) => $0.mapToJson()
            ),
            'rental_histories': this.rentalHistories.map((rentalHistory) => {
                return rentalHistory.mapToJson()
            }),
            'image_information': this.imageInformations.map((imageInformation) => {
                return imageInformation.mapToJson()
            })
        }
    }
}

RequestCreateCard.prototype.setFloorInformations = function (data) {
    this.floorInformations = [...data];
    for (let index = data.length; index < 20; index++) {
        this.floorInformations.push(new FloorInformation());
    }
}

RequestCreateCard.prototype.setRentalHistory = function (data) {
    this.rentalHistories = [...data];
    for (let index = data.length; index < 20; index++) {
        this.rentalHistories.push(new RentalHistory());
    }
}