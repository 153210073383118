import { makeAutoObservable } from "mobx";
import * as defaultValues from "constants/defaultValue";

export class ButtonViewModel {
    title = defaultValues.emptyString;
    isLoading = false;
    action = null;

    constructor(
        isLoading = false
    ) {
        this.isLoading = isLoading;
        makeAutoObservable(this);
    }

    setTitle(title) {
        this.title = title
    }

    startLoading() {
        this.isLoading = true;
    }

    stopLoading() {
        this.isLoading = false;
    }
}