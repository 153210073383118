import defaultValues from "constants/defaultValues";

export class RequestCreateCustomer {
    constructor(
        managerId = defaultValues.emptyInt,
        name = defaultValues.emptyString,
        phone = defaultValues.emptyString,
        companyPhone = defaultValues.emptyString
    ) {
        this.managerId = managerId;
        this.name = name;
        this.phone = phone;
        this.companyPhone = companyPhone;
    }

    mapToJson() {
        return {
            'manager_id': this.managerId,
            'name': this.name,
            'phone': this.phone,
            'company_phone': this.companyPhone
        }
    }
}