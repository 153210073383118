import React from 'react';
import PropTypes from 'prop-types';
import * as styled from './CardPageContentInnerOwnerInfo.styled';
import CardSection from 'components/CardSection/CardSection';
import titleStrings from 'localizations/titleStrings';
import fieldStrings from 'localizations/fieldStrings';
import HorizontaLabeledText from 'components/HorizontalLabeledText/HorizontalLabeledText';
import HorizontalTextInput from 'components/core/horizontal-text-input/HorizontalTextInput';
import { hintStrings } from 'localizations';
import { useSelector } from 'react-redux';
import fieldNames from 'constants/fieldNames';
import { observer } from 'mobx-react-lite';

const CardPageContentInnerOwnerInfo = observer((props) => {
    const cardReducer = useSelector((state) => state.card);

    const handleInputValueChange = (input) => {
        const ownerInformation = cardReducer.requestWriteCard.ownerInformation;
        ownerInformation[input.name] = input.value;
        props.handleInputValueChange({
            name: fieldNames.ownerInformation,
            value: ownerInformation
        })
    }

    const renderNameField = () => {
        if (props.isWriteState) {
            return (
                <HorizontalTextInput
                    title={ fieldStrings.name }
                    name={ fieldNames.name }
                    onChange={ handleInputValueChange }
                    value={ cardReducer.requestWriteCard.ownerInformation.name }/>
            )
        }
        return (
            <HorizontaLabeledText
                title={ fieldStrings.ownerName }
                isValueBold={ true }
                values={ [cardReducer.card.ownerInformation.name] }/>
        )
    }

    const renderOtherPhoneField = () => {
        if (props.isWriteState) {
            return (
                <HorizontalTextInput
                    title={ fieldStrings.otherPhone }
                    name={ fieldNames.phone }
                    onChange={ handleInputValueChange }
                    value={ cardReducer.requestWriteCard.ownerInformation.phone }/>
            )
        }
        return (
            <HorizontaLabeledText
                title={ fieldStrings.otherPhone }
                values={ [cardReducer.card.ownerInformation.phone] }/>
        )
    }

    const renderMobilePhoneField = () => {
        if (props.isWriteState) {
            return (
                <HorizontalTextInput
                    title={ fieldStrings.mobilePhone }
                    name={ fieldNames.mobile }
                    onChange={ handleInputValueChange }
                    value={ cardReducer.requestWriteCard.ownerInformation.mobile }/>
            )
        }
        return (
            <HorizontaLabeledText
                title={ fieldStrings.phone }
                values={ [cardReducer.card.ownerInformation.mobilePhone] }/>
        )
    }

    const renderForms = () => {
        return (
            <styled.Container>
                { renderNameField() }
                { renderMobilePhoneField() }
                { renderOtherPhoneField() }
                <styled.TextArea
                    placeholder={ hintStrings.pleaseEnterNote }
                    disabled={ !props.isWriteState }
                    value={
                        props.isWriteState
                            ? cardReducer.requestWriteCard.ownerInformation.note
                            : cardReducer.card.ownerInformation.note 
                    }
                    name={ fieldNames.note }
                    onChange={ (event) => handleInputValueChange(event.target) }/>
            </styled.Container>
        )
    }

    const renderContent = () => {
        if (props.isHidden) { return null }
        return (
            <CardSection
                title={ titleStrings.ownerInfo }
                content={ renderForms() } />
        )
    }

    return (
        renderContent()
    )
});

CardPageContentInnerOwnerInfo.propTypes = {
    isHidden: PropTypes.bool,
    isWriteState: PropTypes.bool,
    handleInputValueChange: PropTypes.func
};

CardPageContentInnerOwnerInfo.defaultProps = {
    isHidden: false,
    isWriteState: false,
    handleInputValueChange: () => { return; }
};

export default CardPageContentInnerOwnerInfo;