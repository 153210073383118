import { ResponseUploadImage } from "models/responses/ResponseUploadImage";
import cookiesManager from 'managers/cookies.manager';
import baseAction from "redux/actions/baseAction";

const basePath = "/v1/media";

export default {
    uploadImage: (
        request,
        onSuccess
    ) => {
        return baseAction().upload(
            `${basePath}/upload`,
            request,
            (response) => {
                onSuccess(
                    new ResponseUploadImage().mapFromJson(
                        response.data
                    )
                )
            }
        )
    },
    getImage: (
      request,
      onSuccess,
      onFailed
    ) => {
      return fetch(`${process.env.REACT_APP_API_ROOT}${basePath}/${request.type}/${request.fileName}`, {
        headers: {
          'Authorization': `Bearer ${cookiesManager.getAccessToken()}`
        }
      })
      .then(
        async (response) => {
          const imageBlob = await response.blob();
          onSuccess(URL.createObjectURL(imageBlob))
        }
      )
      .catch(onFailed)
    }
}