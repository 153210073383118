import defaultValues from "constants/defaultValues";
import { LandUsage } from "models/objects/LandUsage";

export class RequestLandSpesificInformation {
    constructor(
        number = defaultValues.emptyString,
        category = defaultValues.emptyString,
        area,
        district = defaultValues.emptyString,
        restriction = defaultValues.emptyString,
        officialPrice,
        usage = new LandUsage()
    ) {
        this.number = number;
        this.category = category;
        this.area = area;
        this.district = district;
        this.restriction = restriction;
        this.officialPrice = officialPrice;
        this.usage = usage;
    }

    getArea() {
        return Number(this.area);
    }

    getOfficialPrice() {
        return Number(this.officialPrice);
    }

    mapFromObject(anyObject) {
        this.number = anyObject.number;
        this.category = anyObject.category;
        this.area = anyObject.area;
        this.district = anyObject.district;
        this.restriction = anyObject.restriction;
        this.officialPrice = anyObject.officialPrice;
        this.usage = anyObject.usage;
        return this;
    }

    mapToJson() {
        return {
            'number': this.number,
            'category': this.category,
            'area': this.area,
            'district': this.district,
            'restriction': this.restriction,
            'official_price': this.officialPrice,
            'usage': this.usage.mapToJson()
        }
    }
}