import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CardSection from 'components/CardSection/CardSection';
import * as styled from './LandForm.styled.';
import titleStrings from 'localizations/titleStrings';
import fieldStrings from 'localizations/fieldStrings';
import CheckboxInput from 'components/core/input-checkbox/CheckboxInput';
import operators from 'constants/operators';
import fieldNames from 'constants/fieldNames';
import textInputType from 'enumerations/textInputType';
import unitExhanges from 'constants/unitExhanges';
import Text from 'components/core/text/Text';
import alignments from 'constants/alignments';
import sizes from 'constants/sizes';
import { observer } from 'mobx-react';
import { LandFormViewModel } from './LandFormViewModel';
import TextInput from 'components/core/text-input/TextInput';
import { BorderedTextInputStyle } from 'components/core/text-input/styles';
import paddingPositions from 'enumerations/paddingPositions';
import spaces from 'constants/spaces';
import defaultValues from 'constants/defaultValues';
import { useSelector } from 'react-redux';
import useLandFormFormPresenter from './useLandFormPresenter';

const LandForm = observer((props) => {
    const [viewModel] = useState(new LandFormViewModel());
    const presenter = useLandFormFormPresenter()
    const cardReducer = useSelector((state) => state.card);
    const requestWriteCard = useSelector((state) => state.card.requestWriteCard);

    const handleInputValueChange = (input) => {
        const landInformation = requestWriteCard.landInformation;
        landInformation[input.name] = input.value;
        props.handleInputValueChange({
            name: fieldNames.landInformation,
            value: landInformation
        })
    }

    const setupLandArea = () => {
        var landArea = 0;
        for (const landSpesificInformation of requestWriteCard.landSpesificInformations) {
            landArea = landArea + parseFloat(landSpesificInformation.getArea());
        }
        if (landArea.isEmpty()) {
            return defaultValues.emptyInt;
        }
        return (landArea * unitExhanges.meterSquareToPyeong).rounded(2);
    }

    const setupTotalOfficialPrice = () => {
        return (requestWriteCard.landSpesificInformations.reduce(
            (x, y) => x + parseFloat(y.getArea()) * parseFloat(y.getOfficialPrice()), 0
        ) / 10000).rounded(0);
    }

    const setupTotalOfficialPriceInPy = () => {
        return (setupTotalOfficialPrice() / setupLandArea()).rounded(0)
    }

    const setupBuildingCoverRatio = () => {
        if (setupLandArea().isEmpty()) { return defaultValues.emptyInt; }
        const maxFloor = Math.max(...requestWriteCard.floorInformations.map(
            (floorInformation) => {
                if (floorInformation.isExcluded) {
                    return null;
                }
                if (floorInformation.isBasement()) {
                    return null;
                }
                return floorInformation.getArea();
            }
        ));
        return (((maxFloor * unitExhanges.meterSquareToPyeong) / setupLandArea()) * 100).rounded(2)
    }
    
    const renderUsageField = () => {
        return (
            <div className="col-span-3">
                <TextInput
                    isDisabled={ true }
                    height={ sizes.textInput.small }
                    style={ BorderedTextInputStyle }
                    value={ presenter.getLandUsages() }/>
            </div>
        )
    }

    const renderContent = () => {
        return (
            <styled.Container>
                <styled.OuterContentContainer>
                    <styled.HalfContentContainer>
                        <Text value={ fieldStrings.area }/>
                        <TextInput
                            name={ "landArea" }
                            margin={{ 
                                position: paddingPositions.right,
                                value: spaces.small
                            }}
                            suffixText={ fieldStrings.flat }
                            isDisabled={ true }
                            height={ sizes.textInput.small }
                            style={ BorderedTextInputStyle }
                            type={ textInputType.currency }
                            onChange={ handleInputValueChange }
                            value={ setupLandArea().toString() }/>
                    </styled.HalfContentContainer>
                </styled.OuterContentContainer>
                <styled.OuterContentContainer>
                    <styled.HalfContentContainer>
                        <Text value={ fieldStrings.nomination }/>
                        <TextInput
                            margin={{ 
                                position: paddingPositions.right,
                                value: spaces.small
                             }}
                            isDisabled={ true }
                            height={ sizes.textInput.small }
                            name={ fieldNames.category }
                            onChange={ handleInputValueChange }
                            style={ BorderedTextInputStyle }
                            value={ presenter.getLandCategories() }/>
                    </styled.HalfContentContainer>
                    <styled.HalfContentContainer isTrailing={ true }>
                        <Text
                            value={ fieldStrings.buildingCoverRatio }/>
                        <TextInput
                            isDisabled={ true }
                            height={ sizes.textInput.small }
                            suffixText={ operators.percent }
                            type={ textInputType.currency }
                            value={ `${ setupBuildingCoverRatio() }` }/>
                    </styled.HalfContentContainer>
                </styled.OuterContentContainer>
                <styled.OuterContentContainer>
                    <div className="col-span-1">
                        <Text
                            alignment={ alignments.left }
                            type={ textInputType.currency }
                            value={ fieldStrings.landUsage }/>
                    </div>
                    { renderUsageField() }
                </styled.OuterContentContainer>
                <styled.OuterContentContainer>
                    <styled.HalfContentContainer>
                        <Text value={ fieldStrings.totalMarketPlace }/>
                        <TextInput
                            margin={{ 
                                position: paddingPositions.right,
                                value: spaces.small
                            }}
                            suffixText={ fieldStrings.tenThousand }
                            isDisabled={ true }
                            height={ sizes.textInput.small }
                            type={ textInputType.currency }
                            value={ setupTotalOfficialPrice().toString() }/>
                    </styled.HalfContentContainer>
                    <styled.HalfContentContainer>
                        <Text value={ fieldStrings.officialPricePy }/>
                        <TextInput
                            margin={{ 
                                position: paddingPositions.right,
                                value: spaces.small
                            }}
                            suffixText={ fieldStrings.tenThousand }
                            isDisabled={ true }
                            height={ sizes.textInput.small }
                            type={ textInputType.currency }
                            value={ setupTotalOfficialPriceInPy().toString() }/>
                    </styled.HalfContentContainer>
                </styled.OuterContentContainer>
                <styled.OuterContentContainer>
                    <styled.HalfContentContainer>
                        <Text value={ fieldStrings.roadWidth }/>
                        <TextInput
                            margin={{ 
                                position: paddingPositions.right,
                                value: spaces.small
                            }}
                            height={ sizes.textInput.small }
                            name={ fieldNames.roadWidth }
                            onChange={ handleInputValueChange }
                            style={ BorderedTextInputStyle }
                            value={ requestWriteCard.landInformation.roadWidth }/>
                    </styled.HalfContentContainer>
                    <styled.HalfContentContainer isTrailing={ true }>
                        <styled.RoadCheckboxesContainer>
                            <CheckboxInput
                                name={ fieldNames.isCorner }
                                checked={ requestWriteCard.landInformation.isCorner }
                                label={ fieldStrings.corner }
                                onChange={ (value) => {
                                    handleInputValueChange({
                                        name: fieldNames.isCorner,
                                        value: value
                                    })
                                } }/>
                            <CheckboxInput
                                name={ fieldNames.isSide }
                                checked={ requestWriteCard.landInformation.isSide }
                                label={ fieldStrings.bothSides }
                                onChange={ (value) => {
                                    handleInputValueChange({
                                        name: fieldNames.isSide,
                                        value: value
                                    })
                                } }/>
                        </styled.RoadCheckboxesContainer>
                    </styled.HalfContentContainer>
                </styled.OuterContentContainer>
            </styled.Container>
        )
    }

    useEffect(() => {
        viewModel.onInputChange = handleInputValueChange
    }, [props])

    useEffect(
        () => {
            presenter.setRequestWriteCard(
                cardReducer.requestWriteCard
            )
        },
        [cardReducer]
    )

    return (
        <CardSection
            title={ titleStrings.land }
            content={ renderContent() } />
    )
})

LandForm.propTypes = {
    values: PropTypes.object,
    handleInputValueChange: PropTypes.func
};

LandForm.defaultProps = {
    values: {},
    handleInputValueChange: () => { return; }
}

export default LandForm;