import React from 'react';
import * as styled from './PrintCardPage.styled';
import PrintCardForm from 'layouts/PrintCardForm/PrintCardForm';
import PrintCardPreview from 'layouts/PrintCardPreview/PrintCardPreview';
import usePrintCardPage from './usePrintCardPage';
import { useEffect } from 'react';
import * as defaultValues from 'constants/defaultValue';
import { useParams } from 'react-router';

const PrintCardPage = () => {
    const { propertyId } = useParams();
    const hook = usePrintCardPage();

    useEffect(
        () => hook.loadData(propertyId),
        defaultValues.emptyArray
    )

    return (
        <styled.Container>
            <PrintCardForm didDocumentSaved={
                () => hook.reloadDocumentSetting(propertyId)
            }/>
            <PrintCardPreview/>
        </styled.Container>
    )
}

export default PrintCardPage;