import defaultValues from "constants/defaultValues";
import cookiesManager from "managers/cookies.manager";
import { makeAutoObservable } from "mobx";
import { FloorInformation } from "models/objects/FloorInformation";
import { RentalHistory } from "models/objects/RentalHistory";
import { RequestCreateCard } from "models/requests/RequestCreateCard";
import { RequestWriteImageInformation } from "models/requests/RequestWriteImageInformation";
import { useDispatch } from "react-redux";
import cardActionTypes from "redux/types/cardActionTypes";

export class CardWriteViewModel {
    dispatch = useDispatch();
    landUsages = [];
    requestCreateCard = new RequestCreateCard();

    constructor() {
        makeAutoObservable(this);
    }

    setupDefaultCardEditRequest = (card) => {
        const requestEditCard = new RequestCreateCard();
        requestEditCard.propertyId = card.id;
        requestEditCard.name = card.buildingName;
        requestEditCard.address = card.address;
        requestEditCard.streetAddress = card.streetAddress;
        requestEditCard.nearestStation = card.nearestStation;
        requestEditCard.distanceStation = card.distanceStation;
        requestEditCard.notes = card.notes;
        requestEditCard.advertisement = card.isAdvertisementAllowed;
        requestEditCard.status = card.status;
        requestEditCard.manager = card.manager;
        requestEditCard.priceInformation = card.priceInformation.convertToEditRequest();
        requestEditCard.ownerInformation = card.ownerInformation.convertToEditRequest();
        requestEditCard.landInformation = card.landInformation.convertToEditRequest();
        requestEditCard.buildingInformation = card.buildingInformation.convertToEditRequest();
        requestEditCard.workHistories = card.workHistories;
        requestEditCard.landSpesificInformations = card.landSpesificInformations;
        requestEditCard.setFloorInformations(card.floorInfos);
        requestEditCard.setRentalHistory(card.rentalHistories);
        requestEditCard.imageInformations = card.images.map((image) => {
            return new RequestWriteImageInformation(
                image.id,
                image.url
            );
        });
        this.dispatch({
            type: cardActionTypes.UPDATE_WRITE_CARD_REQUEST,
            payload: requestEditCard
        });
    }

    setupDefaultCardCreateRequest = () => {
        const requestEditCard = new RequestCreateCard();
        if (!cookiesManager.getCurrentUser().isAdmin) {
            requestEditCard.manager = cookiesManager.getCurrentUser();
        }
        for (let index = defaultValues.emptyInt; index < 20; index++) {
            requestEditCard.floorInformations.push(new FloorInformation())
        }
        for (let index = defaultValues.emptyInt; index < 20; index++) {
            requestEditCard.rentalHistories.push(new RentalHistory())
        }
        
        this.dispatch({
            type: cardActionTypes.UPDATE_WRITE_CARD_REQUEST,
            payload: requestEditCard
        });
    }

    setupEditCardRequest = (requestCreateCard) => {
        this.requestCreateCard = requestCreateCard;
    }

    didFetchLandUsagesSucceed = (landUsages) => {
        this.landUsages = landUsages;
    }
}