import LocalizedStrings from 'react-localization';

export default new LocalizedStrings({
    en:{
        search: "Search",
        createdAt: "Created at",
        name: "Name",
        username: "Username",
        phone: "Phone",
        companyPhone: "Company Phone",
        manager: "Manager",
        number: "Number",
        notice: "Notice",
        completed: "Completed",
        error: "Error",
        password: "Password",
        email: "Email",
        rank: "Rank",
        correction: "Correction",
        memo: "Memo",
        identityNumber: "ID",
        lastLoginDate: "Last Login Date",
        ipAddress: "IP Address",
        createEmployee: "Create Employee",
        editEmployee: "Edit Employee",
        address: "Address",
        priceInfo: "Price Info",
        landInfo: "Land Info",
        ownerInfo: "Owner Info",
        note: "Note",
        buildingInfo: "Building Info",
        workHistory: "Work History",
        unit: "Unit",
        squareMeter: "㎡",
        pyeong: "Pyeong",
        tenThousandWon: "10,000 won",
        of: "of",
        page: "Page",
        itemNumber: "Item Number",
        itemDetails: "Item Details",
        lease: "Lease",
        land: "Land",
        landLedger: "Land Ledger",
        building: "Building",
        floorInformation: "Floor Info",
        rentalHistory: "Rental History",
        employeeName: "Name",
        enterSalesAmount: "Enter Sales Amount",
        enterNewAddress: "Enter New Address",
        savePropertyPreviewConfirmTitle: "Are you sure want to save the changes?",
        savePropertyConfirmationTitle: "변경사항을 저장 하시겠습니까?",
        deleteCustomerTitle: "Are you sure want to delete the customer?",
        deletePropertyConfirmation: "Are you sure you want to delete the card?",
        subject: "Subject",
        print: "Print"
    },
    kr: {
        search: "고객등록",
        createdAt: "등록일자",
        name: "대표명",
        username: "아이디",
        phone: "핸드폰",
        companyPhone: "회사전화",
        manager: "담당자",
        number: "번호",
        notice: "안내",
        completed: "등록완료",
        error: "오류",
        password: "비밀번호",
        email: "이메일",
        rank: "등급",
        correction: "수정",
        memo: "메모",
        identityNumber: "사번",
        lastLoginDate: "접속일시",
        ipAddress: "접근아이피",
        createEmployee: "회원등록",
        editEmployee: "회원정보 수정",
        address: "주소",
        priceInfo: "금액정보",
        landInfo: "토지정보",
        ownerInfo: "소유자정보",
        note: "특이사항",
        buildingInfo: "건축물정보",
        workHistory: "작업내역",
        unit: "단위",
        squareMeter: "㎡",
        pyeong: "평",
        tenThousandWon: "만원",
        of: "of",
        page: "Page",
        itemNumber: "물건번호",
        itemDetails: "물건내역",
        lease: "금액정보",
        land: "토지",
        landLedger: "토지대장",
        building: "건물",
        floorInformation: "건축물 대장",
        rentalHistory: "임대내역",
        employeeName: "이름",
        enterSalesAmount: "매매금액 입력",
        enterNewAddress: "신규 주소입력",
        savePropertyPreviewConfirmTitle: "변경사항을 저장 하시겠습니까? (Save?)",
        savePropertyConfirmationTitle: "변경사항을 저장 하시겠습니까?",
        deleteCustomerTitle: "고객을 삭제하시겠습니까?",
        deletePropertyConfirmation: "카드를 삭제하시겠습니까?",
        subject: "제목",
        print: "인쇄",
    }
});
