import React from 'react';
import PropTypes from 'prop-types';
import CardSection from 'components/CardSection/CardSection';
import * as styled from './ItemDetailsForm.styled';
import titleStrings from 'localizations/titleStrings';
import HorizontalTextInput from 'components/core/horizontal-text-input/HorizontalTextInput';
import fieldStrings from 'localizations/fieldStrings';
import Button from 'components/core/button/Button';
import buttonStrings from 'localizations/buttonStrings';
import { WhiteButtonStyle } from 'components/core/button/styles';
import sizes from 'constants/sizes';
import TextArea from 'components/core/text-area/TextArea';
import Text from 'components/core/text/Text';
import CheckboxInput from 'components/core/input-checkbox/CheckboxInput';
import TextInput from 'components/core/text-input/TextInput';
import { BorderedTextInputStyle } from 'components/core/text-input/styles';
import { hintStrings } from 'localizations';
import fieldNames from 'constants/fieldNames';
import { observer } from 'mobx-react-lite';
import defaultValues from 'constants/defaultValues';
import { useSelector } from 'react-redux';
import useItemDetailsFormPresenter from './useItemDetailsFormPresenter';
import { useEffect } from 'react';

const ItemDetailsForm = observer((props) => {
    const cardReducer = useSelector((state) => state.card);
    const requestWriteCard = useSelector((state) => state.card.requestWriteCard);
    const hook = useItemDetailsFormPresenter();
    const handleEnableAdvertisement = () => {
        props.handleInputValueChange(
            {
                name: "advertisement",
                value: true
            }
        )
    }

    const handleDisableAdvertisement = () => {
        props.handleInputValueChange(
            {
                name: "advertisement",
                value: false
            }
        )
    }

    const renderContent = () => {
        return (
            <styled.Container>
                <styled.TopContainer>
                    <HorizontalTextInput 
                        title={ fieldStrings.productName }
                        trailingView = {(
                            <styled.MapButtonWrapper>
                                <Button
                                    action={ hook.openNaverMap }
                                    title={ buttonStrings.map }
                                    width={ sizes.button.largest }
                                    style={ WhiteButtonStyle } />
                            </styled.MapButtonWrapper>
                        )}
                        name={ fieldNames.name }
                        onChange={ props.handleInputValueChange }
                        value={ requestWriteCard.name }/>
                    <HorizontalTextInput 
                        title={ fieldStrings.address }
                        name={ fieldNames.address }
                        onChange={ props.handleInputValueChange }
                        value={ requestWriteCard.address }/>
                    <HorizontalTextInput 
                        title={ fieldStrings.streetAddress }
                        name={ fieldNames.streetAddress }
                        onChange={ props.handleInputValueChange }
                        value={ requestWriteCard.streetAddress }/>
                    <HorizontalTextInput 
                        title={ fieldStrings.nearStation }
                        trailingView = {(
                            <styled.MapButtonWrapper>
                                <TextInput
                                    placeholder={ hintStrings.distance }
                                    suffixText={ "m" }
                                    width={ sizes.textInput.large }
                                    style={ BorderedTextInputStyle }
                                    name={ fieldNames.distanceStation }
                                    onChange={ props.handleInputValueChange }
                                    value={ hook.getStationDistance() }/>
                            </styled.MapButtonWrapper>
                        )}
                        name={ fieldNames.nearestStation }
                        onChange={ props.handleInputValueChange }
                        value={ requestWriteCard.nearestStation }/>
                    <styled.AdvertisementContainer>
                        <Text
                            column={ 1 }
                            value={ fieldStrings.advertisement }/>
                        <styled.AdvertisementChecklistContainer>
                            <CheckboxInput
                                label={ fieldStrings.possible }
                                onChange={ handleEnableAdvertisement }
                                checked={ requestWriteCard.advertisement }/>
                            <CheckboxInput
                                label={ fieldStrings.notPossible }
                                onChange={ handleDisableAdvertisement }
                                checked={ !requestWriteCard.advertisement }/>
                        </styled.AdvertisementChecklistContainer>
                    </styled.AdvertisementContainer>
                </styled.TopContainer>
                <styled.ImageWrapper>
                    <styled.Image
                        src={
                            requestWriteCard.imageInformations.isEmpty()
                                ? defaultValues.emptyString
                                : requestWriteCard.imageInformations[0].imageUrl
                        }/>
                </styled.ImageWrapper>
                <TextArea
                    height={ sizes.textArea.cardItemDetail.height }
                    handleChange={
                        (value) => {
                            props.handleInputValueChange(
                                {
                                    name: "notes",
                                    value: value
                                }
                            )
                        }
                    }
                    value={ requestWriteCard.notes } />
            </styled.Container>
        )
    }

    useEffect(
        () => {
            hook.setRequestWriteCard(
                cardReducer.requestWriteCard
            )
        },
        [cardReducer]
    )

    return (
        <CardSection
            title={ titleStrings.itemDetails }
            content={ renderContent() } />
    )
});

ItemDetailsForm.propTypes = {
    handleInputValueChange: PropTypes.func,
    onChangePropertyImage: PropTypes.func
};

ItemDetailsForm.defaultProps = {
    handleInputValueChange: () => { return; },
    onChangePropertyImage: () => { return; }
};

export default ItemDetailsForm;