import React, { useState } from 'react';
import Button from '../../components/core/button/Button';
import PropTypes from 'prop-types';
import {
    DarkGrayButtonStyle
} from 'components/core/button/styles';
import Sizes from 'constants/sizes';
import tw from 'twin.macro';
import styled from 'styled-components';
import TextInput from 'components/core/text-input/TextInput';
import SearchIconGray from 'assets/search_icon_gray.png';
import CalendarIconGray from 'assets/calendar_icon_gray.png';
import { SmallBorderedTextInputStyle } from 'components/core/text-input/styles';
import colors from 'constants/colors';
import buttonStrings from 'localizations/buttonStrings';
import buttonType from 'enumerations/buttonType';
import { hintStrings } from 'localizations';
import textInputType from 'enumerations/textInputType';
import { observer } from 'mobx-react-lite';
import defaultValues from 'constants/defaultValues';
import { useDispatch } from 'react-redux';
import { employeeActionTypes } from 'redux/types';
import { employeeAction } from 'redux/actions';
import { RequestFetchEmployeeList } from 'models/requests';
import lengths from 'constants/lengths';
import dateFormats from 'constants/dateFormats';
import moment from 'moment';
import * as dateFormatterHelper from "helpers/dateFormatterHelper";

const FilterWrapper = styled.div`
    ${tw`flex gap-6`}
`;

const SearchEmployeeForm = observer((props) => {
    const [keyword, setKeyword] = useState(defaultValues.emptyString);
    const [startDate, setStartDate] = useState(defaultValues.emptyString);
    const [endDate, setEndDate] = useState(defaultValues.emptyString);

    const onSubmit = () => {
        const startDateUtc = dateFormatterHelper.formatToUTC(
            startDate,
            dateFormats.iso8601
        )
        const _endDate = moment(
            endDate,
            dateFormats.iso8601
        )
        .utc()
        .add(86399, 'seconds')
        .toISOString();
        props.onSubmit(
            keyword, 
            startDateUtc,
            _endDate
        )
    }

    return (
        <FilterWrapper>
            <TextInput
                name="keyword"
                leftIcon={ SearchIconGray }
                placeholder={ hintStrings.enterSearchKeyword }
                width={ 200 }
                style={ SmallBorderedTextInputStyle }
                onChange={ (input) => setKeyword(input.value) }
                value={ keyword } />
            <TextInput
                name="startDate"
                rightIcon={ CalendarIconGray }
                placeholder={ hintStrings.selectDate }
                type={ textInputType.date }
                width={ 175 }
                style={ SmallBorderedTextInputStyle }
                onChange={ (input) => setStartDate(input.value) } />
            <TextInput
                name="endDate"
                rightIcon={ CalendarIconGray }
                placeholder={ hintStrings.selectDate }
                type={ textInputType.date }
                width={ 175 }
                style={ SmallBorderedTextInputStyle }
                onChange={ (input) => setEndDate(input.value) } />
            <Button
                action={ onSubmit }
                backgroundColor={ colors.gray.darker }
                height={ Sizes.button.small }
                style={ DarkGrayButtonStyle }
                title={ buttonStrings.confirm }
                type={ buttonType.submit } />
        </FilterWrapper>
    )
})

SearchEmployeeForm.propTypes = {
    onSubmit: PropTypes.func
};

SearchEmployeeForm.defaultProps = {
    onSubmit: () => { return; }
};

export default SearchEmployeeForm;