import * as defaultValues from "constants/defaultValue";
import { AirConditioner } from "models/objects/AirConditioner";
import { Card } from "models/objects/Card";
import { LandUsage } from "models/objects/LandUsage";
import { ParkingMethod } from "models/objects/ParkingMethod";
import { WorkHistory } from "models/objects/WorkHistory";
import { ResponseInquiryCard } from "models/responses/ResponseInquiryCard";
import { ResponseUploadImage } from "models/responses/ResponseUploadImage";
import BaseAction from "./baseAction"

const basePath = "/v1/property";
export default {
    inquiryPropertyCard: (
        request,
        onSuccess = defaultValues.emptyFunction
    ) => {
        return BaseAction().get(
            `${basePath}/my`,
            request.mapToJson(),
            (response) => {
                onSuccess(
                    new ResponseInquiryCard().mapFromJson(response.data)
                )
            }
        )
    },
    createPropertyCard: (
        request,
        onSuccess,
        onFailed
    ) => {
        return BaseAction().post(
            `${basePath}/create`,
            request.mapToJson(),
            (response) => {
                try {
                    onSuccess(
                        new Card().mapFromJson(
                            response.data.property
                        )
                    )
                } catch (error) {
                    onFailed(error.message);
                }
            },
            (error) => {
                onFailed(error);
            }
        )
    },
    updatePropertyCard: (
        request,
        onSuccess,
        onFailed
    ) => {
        return BaseAction().patch(
            `${basePath}/update`,
            request.mapToJson(),
            onSuccess,
            (error) => {
                onFailed(error);
            }
        )
    },
    updatePropertyImage: (
        request,
        onSuccess
    ) => {
        return BaseAction().patch(
            `${basePath}/update/images`,
            request.mapToJson(),
            onSuccess
        )
    },
    deletePropertyCard: (
        request,
        onSuccess,
        onFailed
    ) => {
        return BaseAction().delete(
            `${basePath}/delete`,
            request.mapToJson(),
            onSuccess,
            onFailed
        )
    },
    fetchLandUsages: (
        onSuccess
    ) => {
        return BaseAction().get(
            `${basePath}/dropdown/land-usage`,
            null,
            (response) => {
                onSuccess(
                    response.data.map((data) => {
                        return new LandUsage().mapFromJson(data)
                    })
                );
            }
        )
    },
    fetchAirConditioners: (
        onSuccess
    ) => {
        return BaseAction().get(
            `${basePath}/dropdown/air-conditioning`,
            null,
            (response) => {
                onSuccess(
                    response.data.map((data) => {
                        return new AirConditioner().mapFromJson(data)
                    })
                );
            }
        )
    },
    fetchParkingMethods: (
        onSuccess
    ) => {
        return BaseAction().get(
            `${basePath}/dropdown/parking`,
            null,
            (response) => {
                onSuccess(
                    response.data.map((data) => {
                        return new ParkingMethod().mapFromJson(data)
                    })
                );
            }
        )
    },
    createWorkHistory: (
        request,
        onSuccess
    ) => {
        return BaseAction().post(
            `${basePath}/add-history`,
            request.mapToJson(),
            (response) => {
                onSuccess(
                    new WorkHistory().mapFromJson(response.data)
                )
            }
        )
    },
    uploadCardImage: (
        request,
        onSuccess
    ) => {
        return BaseAction().post(
            `${basePath}/upload-media`,
            request,
            (response) => {
                onSuccess(
                    new ResponseUploadImage().mapFromJson(response.data)
                )
            }
        )
    },
    inquiryPropertyCardById: (
        request,
        onSuccess
    ) => {
        return BaseAction().get(
            `${basePath}/get`,
            request.mapToJson(),
            (response) => {
                onSuccess(
                    new ResponseInquiryCard().mapFromJson(response.data)
                )
            }
        )
    },
    updatePropertyPrice: (
        request,
        onSuccess
    ) => {
        return BaseAction().patch(
            `${basePath}/update/price`,
            request.mapToJson(),
            onSuccess
        )
    },
    viewProperty: (
        request,
        onSuccess
    ) => {
        return BaseAction().post(
            `${basePath}/add-view`,
            request.mapToJson(),
            onSuccess
        )
    }
}