export class RequestUpdateImagePath {
    constructor(imageId, ids) {
        this.imageId = imageId;
        this.ids = ids;
    }

    mapToJson() {
        return {
            'image_id': this.imageId,
            'ids': this.ids
        }
    }
}