import React, { useState } from 'react';
import TextInput from '../../components/core/text-input/TextInput';
import Button from '../../components/core/button/Button';
import PropTypes from 'prop-types';
import { MediumTextInputStyle } from '../../components/core/text-input/styles/index';
import UserIconGray from 'assets/user_icon_gray.png';
import LockIconGray from 'assets/lock_icon_gray.png';
import { DarkGrayButtonStyle } from 'components/core/button/styles';
import CheckboxInput from 'components/core/input-checkbox/CheckboxInput';
import buttonType from 'enumerations/buttonType';
import styled from 'styled-components';
import tw from 'twin.macro';
import fieldStrings from 'localizations/fieldStrings';
import textInputType from 'enumerations/textInputType';
import defaultValues from 'constants/defaultValues';
import { ButtonViewModel } from 'components/core/button/ButtonViewModel';
import { observer } from 'mobx-react-lite';
import sizes from 'constants/sizes';
import { RequestLogin } from 'models/requests/RequestLogin';
import { useDispatch } from 'react-redux';
import { authActionTypes, otherActionTypes } from 'redux/types';
import { authAction, otherAction } from 'redux/actions';
import cookiesManager from 'managers/cookies.manager';
import { toast } from 'react-toastify';

const Form = styled.form`
  ${tw`space-y-6`}
`;

const LoginForm = observer((props) => {
  const [buttonViewModel] = useState(new ButtonViewModel());
  const [username, setUsername] = useState(defaultValues.emptyString);
  const [password, setPassword] = useState(defaultValues.emptyString);
  const [isAdminChecked, setIsPartner] = useState(false);
  const dispatch = useDispatch();

  const onUsernameInputChange = (input) => {
    setUsername(input.value);
  };

  const onPasswordInputChange = (input) => {
    setPassword(input.value);
  };

  const handleAdminCheckboxChange = () => {
    setIsPartner(!isAdminChecked);
  };

  const renderUsernameInput = () => {
    return (
      <TextInput
        name="username"
        leftIcon={UserIconGray}
        placeholder="ID"
        onChange={onUsernameInputChange}
        onTapEnter={onStartLogin}
        value={username}
        style={MediumTextInputStyle}
      />
    );
  };

  const renderPasswordInput = () => {
    return (
      <TextInput
        name="password"
        type={textInputType.password}
        leftIcon={LockIconGray}
        placeholder="PASSWORD"
        onChange={onPasswordInputChange}
        onTapEnter={onStartLogin}
        value={password}
        style={MediumTextInputStyle}
      />
    );
  };

  const renderIsAdminCheckbox = () => {
    return (
      <CheckboxInput
        name="isAdmin"
        checked={isAdminChecked}
        onChange={handleAdminCheckboxChange}
        label={fieldStrings.adminDeclaration}
      />
    );
  };

  const renderLoginButton = () => {
    return (
      <Button
        action={onStartLogin}
        height={sizes.button.large}
        style={DarkGrayButtonStyle}
        title="로그인하기"
        width={sizes.button.full}
        type={buttonType.button}
        viewModel={buttonViewModel}
      />
    );
  };

  const login = (response) => {
    const requestLogin = new RequestLogin(username, password, false);
    dispatch({
      type: authActionTypes.LOGIN,
      payload: authAction.login(requestLogin, didLoginSucceed, didLoginFailed),
    });
  };

  const didLoginSucceed = (response) => {
    cookiesManager.setIsPartner(isAdminChecked);
    cookiesManager.setCurrentUser(response);
  };

  const didLoginFailed = (error) => {
    buttonViewModel.stopLoading();
    toast(error);
  };

  const onStartLogin = () => {
    buttonViewModel.startLoading();
    login();
  };

  return (
    <Form className={props.className}>
      {renderUsernameInput()}
      {renderPasswordInput()}
      {renderIsAdminCheckbox()}
      {renderLoginButton()}
    </Form>
  );
});

LoginForm.propTypes = {
  className: PropTypes.string,
  onLoginFailed: PropTypes.func,
  values: PropTypes.object,
};

LoginForm.defaultProps = {
  onLoginFailed: () => {
    return;
  },
  values: {
    username: defaultValues.emptyString,
    password: defaultValues.emptyString,
    isAdminChecked: false,
  },
};

export default LoginForm;
