import React, { useEffect } from 'react';
import NavigationBar from '../../layouts/NavigationBar/NavigationBar';
import DashboardRoutes from 'routes/dashboardRoutes';
import { connect } from 'react-redux';
import SuccessModal from 'layouts/modals/SuccessModal';
import * as styled from './DashboardPage.styled';
import Modal from 'components/core/modal/Modal';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as defaultValues from 'constants/defaultValue';
import sizes from 'constants/sizes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';

const mapStateToProps = (state) => ({
  ...state.common,
});

const DashboardPage = () => {
  const history = useHistory();
  const commonReducer = useSelector((state) => state.common);

  const renderNavigationBar = () => {
    if (history.location.pathname == '/card/print') {
      return;
    }
    return <NavigationBar />;
  };

  const renderSuccessModal = () => {
    if (commonReducer.successMessage.title.isEmpty()) {
      return;
    }
    return (
      <SuccessModal
        message={commonReducer.successMessage}
        isShowing={true}
        primaryButtonAction={commonReducer.successMessage.action}
      />
    );
  };

  const renderCustomModal = () => {
    if (!commonReducer.modalContent) return null;
    return (
      <Modal
        zIndex={commonReducer.zIndex}
        content={commonReducer.modalContent}
        isShow={true}
      />
    );
  };

  return (
    <styled.Wrapper>
      <ToastContainer />
      {renderCustomModal()}
      {renderSuccessModal()}
      {renderNavigationBar()}
      <styled.ContentWrapper
        paddingTop={
          history.location.pathname == '/card/print'
            ? defaultValues.emptyInt
            : sizes.navigationBar.height
        }>
        <DashboardRoutes />
      </styled.ContentWrapper>
    </styled.Wrapper>
  );
};

export default connect(mapStateToProps)(DashboardPage);
