import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import * as styled from './FloorInfoPrintPage.styled';
import PrintPageHeader from 'components/PrintPageHeader/PrintPageHeader';
import MergeTableView from 'components/core/merge-table/MergeTableView';
import { tableHeaderStrings } from 'localizations';
import alignments from 'constants/alignments';
import Text from 'components/core/text/Text';
import { useSelector } from 'react-redux';
import unitExhanges from 'constants/unitExhanges';
import InactiveTextStyle from 'components/core/text/styles/InactiveTextStyle';
import PlainTextStyle from 'components/core/text/styles/PlainTextStyle';
import MergeTableViewHook from 'components/core/merge-table/MergeTableViewHook';
import * as defaultValues from 'constants/defaultValue';
import HighlightedTextStyle from 'components/core/text/styles/HighlightedTextStyle';

const FloorInfoPrintPage = (props) => {
  const wrapperRef = useRef(null);
  const containerRef = useRef(null);
  const { property, floorInfoPages } = useSelector(
    (state) => state.printProperty
  );

  const _tableHeaders = [
    tableHeaderStrings.floor,
    tableHeaderStrings.areaInSquareMeter,
    tableHeaderStrings.acreage,
    tableHeaderStrings.floorUsage,
  ];

  const _renderTextColumn = (key, value, isExcluded) => {
    return (
      <Text
        key={key}
        alignment={alignments.center}
        value={value}
        style={isExcluded ? InactiveTextStyle : PlainTextStyle}
      />
    );
  };

  const _renderTableRow = (floorInformation, index) => {
    return [
      {
        isMerged: floorInformation.floorMergedDown,
        content: _renderTextColumn(
          floorInformation.id,
          floorInformation.floor,
          floorInformation.isExcluded
        ),
      },
      {
        isMerged: floorInformation.areaMergedDown,
        content: _renderTextColumn(
          floorInformation.id,
          floorInformation.area.thousandFormat(2),
          floorInformation.isExcluded
        ),
      },
      {
        isMerged: floorInformation.areaMergedDown,
        content: _renderTextColumn(
          floorInformation.id,
          floorInformation.getAreaInPy().thousandFormat(2),
          floorInformation.isExcluded
        ),
      },
      {
        isMerged: floorInformation.usageMergedDown,
        content: _renderTextColumn(
          floorInformation.id,
          floorInformation.usage,
          floorInformation.isExcluded
        ),
      },
    ];
  };

  const _renderRows = () => {
    return props.floorInformations.map((floorInformation, index) => {
      return _renderTableRow(floorInformation, index);
    });
  };

  const _renderFooters = () => {
    const latestPage = floorInfoPages.last();
    if (latestPage && props.id != latestPage.key) {
      return [];
    }
    return [
      <Text
        key={1}
        alignment={alignments.center}
        style={HighlightedTextStyle}
        value="합계"
      />,
      <Text
        key={2}
        alignment={alignments.center}
        style={HighlightedTextStyle}
        value={property.getFloorArea().toString().thousandFormat() + '(㎡)'}
      />,
      <Text
        key={3}
        alignment={alignments.center}
        style={HighlightedTextStyle}
        value={
          (property.getFloorArea() * unitExhanges.meterSquareToPyeong)
            .rounded(2)
            .toString()
            .thousandFormat() + '평'
        }
      />,
      <div key={4} />,
    ];
  };

  return (
    <styled.Wrapper ref={wrapperRef}>
      <styled.Container
        ref={containerRef}
        scale={
          wrapperRef.current?.clientWidth / containerRef.current?.clientWidth
        }
        data-id={props.id}>
        <PrintPageHeader
          title="건축물대장"
          isShowLogo={true}
        />
        <styled.BodyContainer>
          <Text
            alignment={alignments.right}
            margin={{ position: 'bottom', value: 8 }}
            value={'[단위: ㎡ / 평]'}
          />
          <MergeTableView
            hook={MergeTableViewHook()}
            headers={_tableHeaders}
            rows={_renderRows()}
            columnClasses="h-10 text-xs"
            footerClasses="h-10 text-xs"
            footerColumns={_renderFooters()}
            headerClassses="h-10"
          />
        </styled.BodyContainer>
      </styled.Container>
    </styled.Wrapper>
  );
};

FloorInfoPrintPage.propTypes = {
  id: PropTypes.string,
  floorInformations: PropTypes.array,
};

FloorInfoPrintPage.defaultProps = {
  id: defaultValues.emptyString,
  floorInformations: defaultValues.emptyArray,
};

export default FloorInfoPrintPage;
