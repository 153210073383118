import styled from "styled-components";
import tw from "twin.macro";

const Container = styled.div`
    ${ tw`flex flex-col gap-5 py-4 w-full` }
`;
const MonthlyInterestWrapper = styled.div`
    ${ tw`col-span-2` }
`;
const OuterContentContainer = styled.div`
    ${ tw`grid grid-cols-4` }
`;
const HalfContentContainer = styled.div`
    ${ tw`grid grid-cols-2 col-span-2` }
    ${ props => props.isTrailing && tw`ml-2` }
`;
const IsFixCheckboxWrapper = styled.div`
    ${ tw`flex ml-auto col-span-2` }
`;
const RoadCheckboxesContainer = styled.div`
    ${ tw`flex space-x-4 col-span-2` }
`;

export {
    Container,
    OuterContentContainer,
    MonthlyInterestWrapper,
    HalfContentContainer,
    IsFixCheckboxWrapper,
    RoadCheckboxesContainer
}