import React,{useState} from 'react';
import * as styled from './RightCard.styled';
import { AdjustmentsVerticalIcon, ArrowPathIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'

const RightCard = (props) => {
    const theProps = props; 
    
    const [thestate, setTheState] = useState({
        mapTypeId: "normal",
        cadastral: false,
        distance: false,
        roadView: false,
        classNormal: `bg-[#333333] text-[#FFFFFF] 
        hover:bg-[#333333] hover:text-[white] hover:border-[#333333] 
        border-solid border-[#333333]
        p-2 
        `,
        classSatelit: `bg-[#FFFFFF] text-[#333333]
        hover:bg-[#333333] hover:text-[white] hover:border-[#333333] 
        border-solid border-[#333333]
        p-2 
        `,
    })
    
    const onTrigger = (event) => {
        theProps.parentCallback(event);
    }

    

    const changeMapType_to_Normal = () => {
        var stateTemp = thestate;
        stateTemp.mapTypeId = "normal";
        stateTemp.classNormal = `bg-[#333333] text-[#FFFFFF] 
                                    hover:bg-[#333333] hover:text-[white] hover:border-[#333333] 
                                    border-solid border-[#333333]
                                    p-2 
                                `;
        stateTemp.classSatelit = `bg-[#FFFFFF] text-[#333333]
                                    hover:bg-[#333333] hover:text-[white] hover:border-[#333333] 
                                    border-solid border-[#333333]
                                    p-2 
                                `,
        setTheState(stateTemp)
        onTrigger("normal");
    }

    const changeMapType_to_Satelit = () => {
        var stateTemp = thestate;
        stateTemp.mapTypeId = "satellite";
        stateTemp.classNormal = `bg-[#FFFFFF] text-[#333333]
                                    hover:bg-[#333333] hover:text-[white] hover:border-[#333333] 
                                    border-solid border-[#333333]
                                    p-2 
                                `,
        stateTemp.classSatelit = `bg-[#333333] text-[#FFFFFF] 
                                    hover:bg-[#333333] hover:text-[white] hover:border-[#333333] 
                                    border-solid border-[#333333]
                                    p-2 
                                `;
        setTheState(stateTemp)
        onTrigger("satellite");
    }

    const handlingClickCadastral = () => {
        var stateTemp = thestate;
        stateTemp.cadastral = !stateTemp.cadastral;
        setTheState(stateTemp)
        theProps.parentCallback_Cadastral();
        changeMapType_to_Normal();
    }

    const handlingClickRoadView = () => {
        var stateTemp = thestate;
        stateTemp.distance = !stateTemp.distance;
        setTheState(stateTemp)
        theProps.parentCallback_RoadView();
    }

    const handlingClickDistanceArea = (e) => {
        theProps.parentCallback_DistanceArea(e);
    }


    return (
        <styled.Container>
            <div className='flex p-2 pl-0 justify-end'>
                <styled.ButtonView
                    onClick={handlingClickCadastral}
                >
                    지적도
                </styled.ButtonView>

                <styled.ButtonView
                    onClick={()=>{
                        handlingClickDistanceArea('distance')
                    }}
                >
                    거리재기
                </styled.ButtonView>

                <styled.ButtonView
                    onClick={()=>{
                        handlingClickDistanceArea('area')
                    }}
                >
                    면적재기
                </styled.ButtonView>

                <styled.ButtonView
                    onClick={handlingClickRoadView}
                >
                    로드뷰
                </styled.ButtonView>
            </div>
            
            <div className='flex p-2 justify-end w-fit absolute right-[1.15rem]'>
                <styled.MapType 
                    className={thestate.classNormal + 'outline-0'}
                    onClick={changeMapType_to_Normal}
                >
                    일반
                </styled.MapType>

                <styled.MapType 
                    className={thestate.classSatelit + 'outline-0'} 
                    onClick={changeMapType_to_Satelit}
                >
                    위성
                </styled.MapType>
            </div>
        </styled.Container>
    )
   
}

export default RightCard;