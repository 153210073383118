import styled from "@emotion/styled";

const Container = styled.div`
    display: flex;
`;
const FormContainer = styled.div`
    width: 25%;
`;
const TableContainer = styled.div`
    width: 75%;
`;

export {
    Container,
    FormContainer,
    TableContainer
}