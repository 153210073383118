import * as defaultValues from "constants/defaultValue";

export class RentalHistory {
    constructor(
        id = defaultValues.emptyInt,
        floor = defaultValues.emptyString,
        areaInPY = defaultValues.emptyString,
        usage = defaultValues.emptyString,
        deposit = defaultValues.emptyString,
        monthlyFee = defaultValues.emptyString,
        adminPrice = defaultValues.emptyString,
        rentalDate = defaultValues.emptyString,
        note = defaultValues.emptyString,
        isFloorMergedDown = false,
        isAreaMergedDown = false,
        isUsageMergedDown = false,
        isDepositMergedDown = false,
        isMonthlyRentalMergedDown = false,
        isMonthlyRentalPyMergedDown = false,
        isAdminPriceMergedDown = false,
        isRentalDateMergedDown = false,
        isNoteMergedDown = false,
        isExcluded = false
    ) {
        this.id = id;
        this.floor = floor;
        this.areaInPY = areaInPY;
        this.usage = usage;
        this.deposit = deposit;
        this.monthlyFee = monthlyFee;
        this.adminPrice = adminPrice;
        this.rentalDate = rentalDate;
        this.note = note;
        this.isFloorMergedDown = isFloorMergedDown;
        this.isAreaMergedDown = isAreaMergedDown;
        this.isUsageMergedDown = isUsageMergedDown;
        this.isDepositMergedDown = isDepositMergedDown;
        this.isMonthlyRentalMergedDown = isMonthlyRentalMergedDown;
        this.isMonthlyRentalPyMergedDown = isMonthlyRentalPyMergedDown;
        this.isAdminPriceMergedDown = isAdminPriceMergedDown;
        this.isRentalDateMergedDown = isRentalDateMergedDown;
        this.isNoteMergedDown = isNoteMergedDown;
        this.isExcluded = isExcluded;
    }

    mapFromJson(json) {
        if (!json) { return this; }
        this.id = json['id'];
        this.floor = json['floor'];
        this.areaInPY = json['area'];
        this.usage = json['usage'];
        this.deposit = json['deposit'];
        this.monthlyFee = json['monthly_rental'];
        this.adminPrice = json['admin_price'];
        this.rentalDate = json['rental_date'];
        this.note = json['note'];
        this.isFloorMergedDown = json['floor_merge_down'];
        this.isAreaMergedDown = json['area_merge_down'];
        this.isUsageMergedDown = json['usage_merge_down'];
        this.isDepositMergedDown = json['deposit_merge_down'];
        this.isMonthlyRentalMergedDown = json['monthly_rental_merge_down'];
        this.isMonthlyRentalPyMergedDown = json['monthly_rental_py_merge_down'];
        this.isAdminPriceMergedDown = json['admin_price_merge_down'];
        this.isRentalDateMergedDown = json['rental_date_merge_down'];
        this.isNoteMergedDown = json['note_merge_down'];
        this.isExcluded = json['is_exclude'];
        return this;
    }

    mapToJson() {
        return {
            'id': this.id,
            'floor': this.floor,
            'area': this.getRentalArea(),
            'usage': this.usage,
            'deposit': this.getDeposit(),
            'monthly_rental': this.getMonthlyRental(),
            'admin_price': this.getAdminFee(),
            'rental_date': this.rentalDate,
            'note': this.note,
            'is_exclude': this.isExcluded,
            'floor_merge_down': this.isFloorMergedDown,
            'area_merge_down': this.isAreaMergedDown,
            'usage_merge_down': this.isUsageMergedDown,
            'deposit_merge_down': this.isDepositMergedDown,
            'monthly_rental_merge_down': this.isMonthlyRentalMergedDown,
            'admin_price_merge_down': this.isAdminPriceMergedDown,
            'rental_date_merge_down': this.isRentalDateMergedDown,
            'note_merge_down': this.isNoteMergedDown
        }
    }

    getRentalArea = () => {
        return this.areaInPY === defaultValues.emptyString
            ? defaultValues.emptyInt
            : this.areaInPY
    }

    getDeposit = () => {
        return this.deposit === defaultValues.emptyString
            ? defaultValues.emptyInt
            : this.deposit
    }

    getMonthlyRental = () => {
        return this.monthlyFee === defaultValues.emptyString
            ? defaultValues.emptyInt
            : this.monthlyFee
    }

    getAdminFee = () => {
        return this.adminPrice === defaultValues.emptyString
            ? defaultValues.emptyInt
            : this.adminPrice
    }

    getRentPerPy = () => {
        const rentFee = (this.getMonthlyRental()/this.getRentalArea()).rounded(2);
        if (!isFinite(rentFee)){
            return defaultValues.emptyInt;
        }
        return rentFee;
    }

    getIsEmpty() {
        return this.floor.isEmpty()
            && this.areaInPY.isEmpty()
            && this.usage.isEmpty()
            && this.deposit.isEmpty()
            && this.monthlyFee.isEmpty()
            && this.adminPrice.isEmpty()
            && this.rentalDate.isEmpty()
            && this.note.isEmpty()
    }

    _mergeFlags = {
        [1]: "isFloorMergedDown",
        [2]: "isAreaMergedDown",
        [3]: "isUsageMergedDown",
        [4]: "isDepositMergedDown",
        [5]: "isMonthlyRentalMergedDown",
        [6]: "isAdminPriceMergedDown",
        [7]: "isRentalDateMergedDown",
        [8]: "isNoteMergedDown"
    }

    _nameByIndex = {
        [1]: {
            name: "floor",
            mergeName: "isFloorMergedDown"
        },
        [2]: {
            name: "areaInPY",
            mergeName: "isAreaMergedDown"
        },
        [3]: {
            name: "usage",
            mergeName: "isUsageMergedDown"
        },
        [4]: {
            name: "deposit",
            mergeName: "isDepositMergedDown"
        },
        [5]: {
            name: "monthlyFee",
            mergeName: "isMonthlyRentalMergedDown"
        },
        [6]: {
            name: "adminPrice",
            mergeName: "isAdminPriceMergedDown"
        },
        [7]: {
            name: "rentalDate",
            mergeName: "isRentalDateMergedDown"
        },
        [8]: {
            name: "note",
            mergeName: "isNoteMergedDown"
        }
    }
    
    getNameByIndex(index) {
        return this._nameByIndex[index];
    }
    
    getMergeKeyByName(name) {
        for (const columnIndex of Object.keys(this._nameByIndex)) {
            if (this._nameByIndex[columnIndex].name != name) { continue; }
            return this._nameByIndex[columnIndex].mergeName;
        }
    }

    setAsMerged(column) {
        this[this._mergeFlags[column]] = true;
    }

    setAsUnMerge(column) {
        this[this._mergeFlags[column]] = false;
    }
}