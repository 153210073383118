import defaultValues from "constants/defaultValues";

Array.prototype.isEmpty = function () {
    return this.length == defaultValues.emptyInt;
};

Array.prototype.isValidIndex = function (index) {
    if (this.isEmpty()) {
        return false
    }
    return index < this.length;
};

Array.prototype.first = function () {
    if (!this.isValidIndex(0)) {
        return null;
    }
    return this[0];
};

Array.prototype.last = function () {
    if (!this.isValidIndex(this.length - 1)) {
        return null;
    }
    return this[this.length - 1];
};

Array.prototype.insert = function ( index, ...items ) {
    this.splice( index, 0, ...items );
};