import defaultValues from "constants/defaultValues";
import { LandUsage } from "models/objects/LandUsage";

export class LandSpesificInformation {
    constructor(
        number = defaultValues.emptyInt,
        category = defaultValues.emptyString,
        area = defaultValues.emptyInt,
        district = defaultValues.emptyString,
        restriction = defaultValues.emptyString,
        officialPrice = defaultValues.emptyInt,
        usage = new LandUsage()
    ) {
        this.number = number;
        this.category = category;
        this.area = area;
        this.district = district;
        this.restriction = restriction;
        this.officialPrice = officialPrice;
        this.usage = usage;
    }

    getArea() {
        return Number(this.area);
    }

    getOfficialPrice() {
        return Number(this.officialPrice);
    }

    mapFromJson(json) {
        if (!json) { return this; }
        this.number = json['number'];
        this.category = json['category'];
        this.area = json['area'];
        this.district = json['district'];
        this.restriction = json['restriction'];
        this.officialPrice = json['official_price'];
        this.usage = new LandUsage().mapFromJson(json['usage']);
        return this;
    }
}