import * as defaultValues from "constants/defaultValue";
import { CardViewLog } from "models/objects/CardViewLog";
import { Pagination } from "models/objects/Pagination";

export class ResponseFetchCardLogList {
    constructor(
        viewLogs = defaultValues.emptyArray,
        pagination = new Pagination()
    ) {
        this.viewLogs = viewLogs;
        this.pagination = pagination;
    }

    mapFromJson(json) {
        if (!json) { return this; }
        this.viewLogs = json.data.map(
            ($0) => new CardViewLog().mapFromJson($0)
        );
        this.pagination = new Pagination(
            Number(json['currentPage']),
            json['totalItems'],
            json['totalPages']
        );
        return this;
    }
}