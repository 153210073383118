import React from 'react';
import * as defaultValues from "constants/defaultValue";
import unitExhanges from "constants/unitExhanges";
import formStates from "enumerations/formStates";
import { tableHeaderStrings } from "localizations";
import { RequestCreateCard } from "models/requests/RequestCreateCard";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as cardActions from "redux/reducers/cardReducer";
import CheckboxInput from 'components/core/input-checkbox/CheckboxInput';

const useFloorInfoFormPresenter = () => {
    const cardReducer = useSelector(state => state.card);
    const [tableHeaders, setTableHeaders] = useState(defaultValues.emptyArray)
    const [floorInformations, setFloorInformations] = useState(defaultValues.emptyArray);
    const [requestWriteCard, setRequestWriteCard] = useState(new RequestCreateCard());
    const isAllExcluded = () => {
        if (floorInformations.isEmpty()) { return false; }
        const totalExcluded = floorInformations.filter(
            $0 => $0.isExcluded
        ).length
        return totalExcluded== floorInformations.length;
    }

    const setupTableHeaders = () => {
        let tableHeaders = [
            tableHeaderStrings.floor,
            tableHeaderStrings.areaInSquareMeter,
            tableHeaderStrings.acreage,
            tableHeaderStrings.floorUsage
        ];
        if (cardReducer.formState != formStates.read) {
            tableHeaders.insert(
                tableHeaderStrings.except,
                (
                    <CheckboxInput
                        key={ 0 }
                        checked={ isAllExcluded() }
                        onChange={ _excludeAllRow }/>
                )
            );
        }
        setTableHeaders(tableHeaders);
    }
    
    const _excludeAllRow = () => {
        const _isAllExcluded= isAllExcluded();
        cardReducer
            .requestWriteCard
            .floorInformations = floorInformations.map(
            $0 => {
                $0.isExcluded = !_isAllExcluded;
                return $0
            }
        )
        cardActions.updateRequestCreateCard(
            Object.assign(
                new RequestCreateCard(),
                cardReducer.requestWriteCard
            )
        )
    }

    const setupData = () => {
        setFloorInformations(
            cardReducer.formState == formStates.read 
                ? cardReducer.card.floorInfos
                : cardReducer.requestWriteCard.floorInformations
        )
    }

    const getAreaInPy = (floorInformation) => {
        return (floorInformation.getArea() * unitExhanges.meterSquareToPyeong)
            .rounded(2)
            .toString()
            .thousandFormat();
    }

    const getTotalArea = () => {
        if (cardReducer.formState == formStates.read) {
            return cardReducer.card
                .getFloorArea()
                .rounded(2);
        }
        return requestWriteCard
            .getTotalFloorArea()
            .rounded(2);
    }

    const getTotalAreaInPy = () => {
        if (cardReducer.formState == formStates.read) {
            const totalArea = cardReducer.card.getFloorArea();
            return (totalArea * unitExhanges.meterSquareToPyeong).rounded(2);
        }
        const totalArea = requestWriteCard.getTotalFloorArea();
        return (totalArea * unitExhanges.meterSquareToPyeong).rounded(2);
    }

    const excludeFloorInfo = (index) => {
        const isExcluded = cardReducer
            .requestWriteCard
            .floorInformations[index]
            .isExcluded
        cardReducer
            .requestWriteCard
            .floorInformations[index]
            .isExcluded = !isExcluded;
        cardActions.updateRequestCreateCard(
            Object.assign(
                new RequestCreateCard(),
                cardReducer.requestWriteCard
            )
        )
    }

    const _mergeColumn = (mergedColumn, mergedColumns) => {
        let value = defaultValues.emptyInt;
        let columnName = floorInformations[mergedColumn.row].getNameByIndex(mergedColumn.column);
        for (const mergedColumn of mergedColumns) {
            if (floorInformations[mergedColumn.row][columnName.name].isEmpty()){
                continue;
            }
            value = floorInformations[mergedColumn.row][columnName.name];
            break;
        }
        floorInformations[mergedColumn.row][columnName.mergeName] = mergedColumn.isMerged;
        floorInformations[mergedColumn.row][columnName.name] = value;
    }

    const _unmergeColumn = (index, mergedColumn) => {
        let columnName = floorInformations[mergedColumn.row].getNameByIndex(mergedColumn.column);
        floorInformations[mergedColumn.row][columnName.mergeName] = false;
        if (index != defaultValues.emptyInt) {
            floorInformations[mergedColumn.row][columnName.name] = defaultValues.emptyString;
        }
    }

    const onMergeSuccess = (mergedColumns) => {
        for (const mergedColumn of mergedColumns) {
            _mergeColumn(mergedColumn, mergedColumns);
        }
        cardActions.onMergeFloorInformationColumn(floorInformations);
    }

    const onUnmergeSuccess = (columns) => {
        for (const [index, mergedColumn] of columns.entries()) {
            _unmergeColumn(index, mergedColumn);
        }
        cardActions.onMergeFloorInformationColumn(floorInformations);
    }

    useEffect(
        () => {
            setupTableHeaders();
            setupData();
        },
        [cardReducer]
    )

    return {
        tableHeaders,
        floorInformations,
        requestWriteCard,
        getAreaInPy,
        getTotalArea,
        getTotalAreaInPy,
        setRequestWriteCard,
        excludeFloorInfo,
        onMergeSuccess,
        onUnmergeSuccess
    }
}
export default useFloorInfoFormPresenter;