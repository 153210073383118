import styled from "@emotion/styled";

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 1075px;
    height: 765px;
    padding: 3rem;
    background-color: white;
    scale: ${ props => props.scale };
    margin: auto;
`;

export const BodyContainer = styled.div``;