import styled from 'styled-components';
import tw from "twin.macro"

const Wrapper = styled.div`
    ${tw `flex justify-between py-6 px-8 bg-secondary-light` }
`;
const PaginationContainer = styled.div`
    ${tw `flex`}
`;
const PaginationLeadingButton = styled.button`
    ${tw `flex h-8 w-8 border bg-white`}
`;
const PaginationTrailingButton = styled.button`
    ${tw `flex h-8 w-8 border-r border-t border-b bg-white`}
`;
const PaginationTitle = styled.p`
    ${tw `my-auto mx-4`}
`;
const PaginationActionIcon = styled.img`
    ${tw `h-2 w-2 m-auto`}
`;
const ActionContainer = styled.div`
    ${tw `flex gap-2 my-auto`}
`;
export {
    Wrapper,
    PaginationContainer,
    PaginationLeadingButton,
    PaginationTrailingButton,
    PaginationTitle,
    PaginationActionIcon,
    ActionContainer
}