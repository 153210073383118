import LocalizedStrings from 'react-localization';

export default new LocalizedStrings({
    en:{
        pleaseFillTheBlanks: "Please fill the blanks.",
    },
    kr: {
        pleaseFillTheBlanks: "최소 한개의 값을 입력해주세요"
    }
});
