import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import PropTypes from 'prop-types';
import defaultValues from 'constants/defaultValues';
import textInputType from 'enumerations/textInputType';
import DatePicker from 'react-datepicker';
import dateFormats from 'constants/dateFormats';
import alignments from 'constants/alignments';
import Text from 'components/core/text/Text';
import DisabledTextStyle from '../text/styles/DisabledTextStyle';
import paddingPositions from 'enumerations/paddingPositions';
import moment from 'moment';
import { useState } from 'react';
import TextInputPresenter from './TextInputPresenter';
import { observer } from 'mobx-react-lite';
import { TextInputViewModel } from './TextInputViewModel';

const InnerContainer = styled.div`
  width: ${(props) => (props.width == 0 ? '100%' : props.width + 'px')};
  ${(props) => !props.minWidth.isEmpty() && `min-width: ${props.minWidth}px;`}
  ${(props) => !props.maxWidth.isEmpty() && `max-width: ${props.maxWidth}px;`}
    height: ${(props) =>
    props.height.isEmpty() ? `100%` : `${props.height}px`};
  ${tw`flex rounded bg-white  overflow-hidden`}
  ${(props) => props.isDisabled && tw`bg-secondary`}
    ${(props) => props.style.containerStyle}
`;

const OuterContainer = styled.div`
  ${(props) => {
    if (props.margin.value.isEmpty()) {
      return null;
    }
    return `margin-${props.margin.position}:${props.margin.value}px;`;
  }}
  flex: 1;
  opacity: ${(props) => props.style.opacity};
`;

const StyledInput = styled.input`
  text-align: ${(props) => props.alignment};
  ${tw`bg-transparent outline-none w-full px-3`}
`;

const InnerTrailingLabelWrapper = styled.div`
  ${tw`my-auto pr-3`}
`;

const TextInput = observer((props) => {
  const inputRef = useRef();
  const [cursorPosition, setCursorPosition] = useState(0);
  const presenter = TextInputPresenter();
  const [value, setValue] = useState(props.value);

  const onKeyDown = (event) => {
    if (event.key == 'Enter') {
      props.onTapEnter();
      return;
    }
    if (
      props.type != textInputType.number ||
      event.key == 'Backspace' ||
      event.metaKey
    ) {
      return;
    }
  };

  useEffect(() => {
    if (inputRef.current === undefined) {
      return;
    }
    inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
  }, [cursorPosition]);

  const getCursorPosition = (newValue, event) => {
    const formattedOldValue = props.value.toString().thousandFormat();
    const formattedValue = newValue.toString().thousandFormat();
    let cursorPosition = event.target.selectionStart;
    const oldValueCommaCount =
      formattedOldValue.indexOf(',') > 0
        ? formattedOldValue.split(',').filter(($0) => $0 !== '').length - 1
        : defaultValues.emptyInt;
    const commaCount =
      formattedValue.indexOf(',') > 0
        ? formattedValue.split(',').filter(($0) => $0 !== '').length - 1
        : defaultValues.emptyInt;
    // Delete handler
    if (event.nativeEvent.data == null) {
      if (oldValueCommaCount > commaCount && cursorPosition !== 1) {
        cursorPosition -= 1;
      }
      return cursorPosition;
    }
    if (oldValueCommaCount < commaCount) {
      cursorPosition += commaCount;
    }
    // Previous char is comma handler
    if (
      formattedOldValue.includes(',') &&
      formattedValue[cursorPosition - 1] === ','
    ) {
      cursorPosition -= commaCount;
    }
    return cursorPosition;
  };

  const handleChangeValue = (event) => {
    let newValue = event.target.value;
    if (props.type == textInputType.currency) {
      newValue = newValue.removethousandFormat();
      setCursorPosition(getCursorPosition(newValue, event));
      if (isNaN(newValue)) {
        event.preventDefault();
        return;
      }
    }
    props.onChange({
      name: event.target.name,
      value: newValue,
    });
    props.viewModel.value = newValue;
    setValue(newValue);
  };

  const handleDateSelect = (name, selectedDate) => {
    setValue(selectedDate);
    props.onChange({
      name: name,
      value: moment(selectedDate).format(dateFormats.date),
    });
  };

  const handleSelectedDateRange = (name, selectedDates) => {
    setValue(selectedDates);
    props.onChange({
      name: name,
      value: [
        moment(selectedDates[0]).format(dateFormats.date),
        moment(selectedDates[1]).format(dateFormats.date),
      ],
    });
  };

  const renderLeftIcon = () => {
    if (!props.leftIcon) {
      return;
    }
    const StyledLeftIcon = styled.img`
      ${tw`my-auto ml-3`}
      ${props.style.iconWidth};
      ${props.style.iconHeight};
    `;
    return (
      <StyledLeftIcon
        src={props.leftIcon}
        alt=""
      />
    );
  };

  const renderRightIcon = () => {
    if (!props.rightIcon) {
      return;
    }
    const StyledRightIcon = styled.img`
      ${tw`my-auto mr-3`}
      ${props.style.iconWidth};
      ${props.style.iconHeight};
    `;
    return (
      <StyledRightIcon
        src={props.rightIcon}
        alt=""
      />
    );
  };

  const rendersuffixText = () => {
    if (props.suffixText.isEmpty()) {
      return;
    }
    return (
      <InnerTrailingLabelWrapper>
        <Text
          value={props.suffixText}
          style={DisabledTextStyle}
        />
      </InnerTrailingLabelWrapper>
    );
  };

  const renderContent = () => {
    const style = {
      width: props.width == 0 ? '100%' : `${props.width} px`,
    };
    if (props.type == textInputType.dateRange) {
      return (
        <DatePicker
          name={props.name}
          dateFormat={dateFormats.datePicker}
          startDate={
            Array.isArray(value) ? value[0] : defaultValues.emptyString
          }
          selected={Array.isArray(value) ? value[0] : defaultValues.emptyString}
          endDate={Array.isArray(value) ? value[1] : defaultValues.emptyString}
          placeholderText={props.placeholder}
          onChange={(value) => {
            handleSelectedDateRange(props.name, value);
          }}
          selectsRange
        />
      );
    }
    if (props.type == textInputType.date) {
      return (
        <DatePicker
          dateFormat={dateFormats.datePicker}
          selected={value}
          placeholderText={props.placeholder}
          onChange={(value) => {
            handleDateSelect(props.name, value);
          }}
        />
      );
    }
    return (
      <StyledInput
        ref={inputRef}
        autoFocus={props.isAutoFocus}
        onKeyDown={onKeyDown}
        onChange={handleChangeValue}
        alignment={props.alignment}
        disabled={props.isDisabled}
        placeholder={props.placeholder}
        style={style}
        name={props.name}
        type={
          props.type == textInputType.password ? props.type : textInputType.text
        }
        value={setupValue()}
      />
    );
  };

  const setupValue = () => {
    if (props.type == textInputType.currency) {
      if (isNaN(props.value)) {
        return defaultValues.emptyInt;
      }
      return props.value.toString().thousandFormat();
    }
    return props.value;
  };

  const renderComponent = () => {
    return (
      <OuterContainer
        margin={props.margin}
        style={props.style}>
        <InnerContainer
          height={props.height}
          minWidth={props.minWidth}
          maxWidth={props.maxWidth}
          width={props.width}
          style={props.style}
          onClick={presenter._onSingleClick}
          isDisabled={props.isDisabled}>
          {renderLeftIcon()}
          {renderContent()}
          {renderRightIcon()}
          {rendersuffixText()}
        </InnerContainer>
      </OuterContainer>
    );
  };

  return renderComponent();
});

TextInput.propTypes = {
  alignment: PropTypes.string,
  isHideValue: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isAutoFocus: PropTypes.bool,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.number,
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
  height: PropTypes.number,
  style: PropTypes.object,
  margin: PropTypes.object,
  onChange: PropTypes.func,
  onTapEnter: PropTypes.func,
  onSingleClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  value: PropTypes.string,
  viewModel: PropTypes.object,
  suffixText: PropTypes.string,
};

TextInput.defaultProps = {
  alignment: alignments.left,
  isHideValue: false,
  isDisabled: false,
  isAutoFocus: false,
  width: defaultValues.emptyInt,
  minWidth: defaultValues.emptyInt,
  maxWidth: defaultValues.emptyInt,
  height: defaultValues.emptyInt,
  placeholder: defaultValues.emptyString,
  leftIcon: defaultValues.emptyString,
  rightIcon: defaultValues.emptyString,
  name: defaultValues.emptyString,
  type: textInputType.text,
  margin: {
    position: paddingPositions.left,
    value: defaultValues.emptyInt,
  },
  style: {
    containerStyle: defaultValues.emptyString,
    iconStyle: defaultValues.emptyString,
  },
  onChange: () => {
    return;
  },
  onTapEnter: () => {
    return;
  },
  onSingleClick: () => {
    return;
  },
  onDoubleClick: null,
  value: defaultValues.emptyString,
  viewModel: new TextInputViewModel(),
  suffixText: defaultValues.emptyString,
};

export default TextInput;
