import React from 'react';
import PropTypes from 'prop-types';
import {
    DarkGrayButtonStyle, WhiteButtonStyle
} from './styles';
import defaultValues from "constants/defaultValues";
import Spaces from 'constants/spaces';
import Sizes from 'constants/sizes';
import loadingWhiteLottie from 'resources/lotties/loading-white.json';
import loadingDarkLottie from 'resources/lotties/loading-dark.json';
import Lottie from "lottie-react";
import buttonType from 'enumerations/buttonType';
import sizes from 'constants/sizes';
import { ButtonViewModel } from './ButtonViewModel';
import { observer } from 'mobx-react-lite';
import * as styled from './Button.styled';

const Button = observer((props) => {
    const renderContent = () => {
        if (props.viewModel.isLoading || props.isLoading) {
            return (
                <div className="m-auto">
                    <Lottie
                        style={{
                            height: props.height,
                            heiwidthht: props.height
                        }}
                        loop={ true }
                        autoplay={ true }
                        animationData={
                            props.style == WhiteButtonStyle
                                ? loadingDarkLottie
                                : loadingWhiteLottie
                        } />
                </div>
            )
        }
        if (props.icon) {
            return (
                <styled.Container>
                    <styled.Icon
                        src={ props.icon }
                        isIncludeTitle={ !props.title.isEmpty() } />
                    <styled.Title titleColor={ props.style.titleColor }>
                        { props.title }
                    </styled.Title>
                </styled.Container>
            )
        }
        return (
            <styled.Container className="my-auto">
                <styled.Title
                    titleColor={ props.style.titleColor }
                    isUnderlined={ props.style.isUnderlined }>
                    { props.title }
                    { props.viewModel.title }
                </styled.Title>
            </styled.Container>
        )
    }

    return (
        <styled.Wrapper
            marginLeft={ props.marginLeft }
            marginRight={ props.marginRight }
            horizontalPadding={ props.horizontalPadding }
            height={ props.height }
            width={ props.width }
            disabled={
                props.isDisabled
                    || props.viewModel.isLoading
                    || props.isLoading
            }
            onClick={
                props.viewModel.action == null
                    ? props.action
                    : props.viewModel.action
            }
            type={ props.type }
            isRounded={ props.isRounded }
            style={ props.style }>
            <styled.Container className="flex my-auto">
                { renderContent() }
            </styled.Container>
        </styled.Wrapper>
    );
});

Button.propTypes = {
    action: PropTypes.func,
    icon: PropTypes.string,
    isDisabled: PropTypes.bool,
    title: PropTypes.string,
    isRounded: PropTypes.bool,
    isLoading: PropTypes.bool,
    height: PropTypes.number,
    horizontalPadding: PropTypes.number,
    style: PropTypes.object,
    type: PropTypes.string,
    viewModel: PropTypes.object,
    marginLeft: PropTypes.string,
    marginRight: PropTypes.string,
    width: PropTypes.number
};

Button.defaultProps = {
    action: () => { return; },
    icon: "",
    isDisabled: false,
    height: defaultValues.emptyInt,
    horizontalPadding: Spaces.medium,
    isRounded: true,
    isLoading: false,
    title: defaultValues.emptyString,
    style: DarkGrayButtonStyle,
    type: buttonType.button,
    viewModel: new ButtonViewModel(),
    marginLeft: defaultValues.emptyString,
    marginRight: defaultValues.emptyString,
    width: defaultValues.emptyInt
};

export default Button;