import styled from 'styled-components';
import tw from 'twin.macro';

const Wrapper = styled.div`
    ${tw`
        text-sm
        h-full 
    `} 
    color: #121212;
`;

const Container = styled.div`
    ${ tw`
        relative
        flex flex-col 
        overflow-hidden 
        h-full
    ` }
`;

export {
    Wrapper,
    Container
}