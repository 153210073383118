import React from 'react';
import Spaces from 'constants/spaces';
import TableList from 'components/core/table-list/TableList';
import EmployeePageHeader from 'layouts/EmployeeLayouts/EmployeePageHeader';
import useEmployeeLoginLogPage from './useEmployeeLoginLogPage';
import * as styled from './EmployeeLoginLogPage.styled';

const EmployeeLoginLogPage = () => {
    const hook = useEmployeeLoginLogPage();

    return (
        <styled.Container>
            <EmployeePageHeader
                pagination={ hook.pagination }
                onPageChange={ hook.onPageChange }
                onStartSearchAction={ hook.onStartSearchLoginLog }
                onStartDelete={ hook.onStartDeleteLoginLog } />
            <TableList
                className="col-span-2"
                datas={ hook.setupTableRows() }
                firstColumnPadding={ Spaces.small }
                headers={ hook.setupTableHeaders() } />
        </styled.Container>
    )
}

export default EmployeeLoginLogPage;