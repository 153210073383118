import defaultValues from "constants/defaultValues";
import fieldNames from "constants/fieldNames";
import { makeAutoObservable } from "mobx";
import { LandUsage } from "models/objects/LandUsage";

export class LandFormViewModel {
    landUsages = [];
    selectedUsage = new LandUsage();

    constructor(
        onInputChange = () => { return }
    ) {
        makeAutoObservable(this);
        this.onInputChange = onInputChange;
    }

    setLandUsages = (landUsages) => {
        this.landUsages = landUsages;
    }

    setSelectedUsage = (selectedUsage) => {
        this.selectedUsage = selectedUsage;
    }

    onDropdownChange = (item) => {
        const landUsage = new LandUsage(
            item.key,
            item.value
        );
        this.selectedUsage = item.key == defaultValues.emptyInt
            ? new LandUsage(
                defaultValues.emptyInt,
                defaultValues.emptyString,
                true
            )
            : landUsage
        this.onInputChange({
            name: fieldNames.usage,
            value: this.selectedUsage
        })
    }
}