import formStates from "enumerations/formStates";

const UPDATE_FORM_STATE = "cardPage/UPDATE_FORM_STATE";

const initialState = {
  formState: formStates.read,
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
      case UPDATE_FORM_STATE:
        return{
          formState: action.formState
        }
      default: return state;
    }
}