import * as defaultValues from "constants/defaultValue";
import textInputType from "enumerations/textInputType";
import { makeAutoObservable } from "mobx";

export class TextInputViewModel {
    type = textInputType.text
    value = defaultValues.emptyString

    constructor() {
        makeAutoObservable(this);
    }
}