import * as defaultValues from "constants/defaultValue";

export default class RequestGetImage {
  constructor(
    type = defaultValues.emptyString,
    fileName = defaultValues.emptyString
) {
    this.type = type;
    this.fileName = fileName;
  }
}