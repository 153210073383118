import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as styled from './BuildingInformation.styled';
import titleStrings from 'localizations/titleStrings';
import fieldStrings from 'localizations/fieldStrings';
import CardSection from 'components/CardSection/CardSection';
import HorizontaLabeledText from 'components/HorizontalLabeledText/HorizontalLabeledText';
import { connect } from 'react-redux';
import { Card } from 'models/objects/Card';
import decimalPlaces from 'constants/decimalPlaces';
import unitExhanges from 'constants/unitExhanges';

const mapStateToProps = (state) => ({
  ...state.card,
});

class CardPageContentInnerBuildingInfo extends Component {
  static get propTypes() {
    return {
      card: PropTypes.object,
    };
  }

  static defaultProps = {
    card: new Card(),
  };

  renderContent = () => {
    const buildingInformation = this.props.card.buildingInformation;
    return (
      <styled.Container>
        <HorizontaLabeledText
          title={fieldStrings.totalArea}
          isValueBold={true}
          values={[
            `${buildingInformation.totalArea.thousandFormat(
              decimalPlaces.areaSize
            )} ㎡
                        [${buildingInformation.totalAreaInPY.thousandFormat(
                          decimalPlaces.areaSize
                        )}PY]`,
          ]}
        />
        <HorizontaLabeledText
          title={fieldStrings.firstFloorArea}
          values={[
            `${buildingInformation.buildingToCover.thousandFormat()} ㎡
                        [${(
                          buildingInformation.buildingToCover /
                          unitExhanges.pyeongToMeterSquare
                        ).thousandFormat(decimalPlaces.areaSize)}PY]`,
          ]}
        />
        <HorizontaLabeledText
          title={fieldStrings.floors}
          values={[buildingInformation.floor]}
        />
        <HorizontaLabeledText
          title={`${fieldStrings.builtAt} / ${fieldStrings.remodel}`}
          // values={[
          //   `
          //               ${buildingInformation.builtAt} / ${
          //     buildingInformation.remodelAt.isEmpty()
          //       ? '-'
          //       : buildingInformation.remodelAt
          //   } `,
          // ]}
          values={[
            `
                        ${buildingInformation.permitDate} / ${
              buildingInformation.remodelAt.isEmpty()
                ? '-'
                : buildingInformation.remodelAt
            } `,
          ]}
        />
        <HorizontaLabeledText
          title={`${fieldStrings.buildingCoverageRatio} / ${fieldStrings.floorRatio}`}
          values={[
            buildingInformation.totalAreaRatio.percentage(2) +
              '/' +
              buildingInformation.floorAreaRatio.percentage(2),
          ]}
        />
        <HorizontaLabeledText
          title={`${fieldStrings.parkingMethod} / ${fieldStrings.numberOfCars}`}
          values={[
            `${buildingInformation.parking.name}/${buildingInformation.parking.totalGoverment}`,
          ]}
        />
        <HorizontaLabeledText
          title={`${fieldStrings.airConditioner} / ${fieldStrings.heater}`}
          values={[buildingInformation.airConditioner.name]}
        />
        <HorizontaLabeledText
          title={fieldStrings.lift}
          values={[buildingInformation.elevatorStatus]}
        />
        <HorizontaLabeledText
          title={fieldStrings.illegalBuilding}
          values={[
            buildingInformation.isIllegalBuilding
              ? fieldStrings.corresponding
              : fieldStrings.notApplicable,
          ]}
        />
        <HorizontaLabeledText
          title={fieldStrings.groupBuilding}
          values={[
            buildingInformation.isGroupBuilding
              ? fieldStrings.corresponding
              : fieldStrings.notApplicable,
          ]}
        />
      </styled.Container>
    );
  };

  render() {
    return (
      <CardSection
        title={titleStrings.buildingInfo}
        content={this.renderContent()}
      />
    );
  }
}

export default connect(mapStateToProps, null)(CardPageContentInnerBuildingInfo);
