import React from 'react';
import PropTypes from 'prop-types';
import * as styled from './Text.styled';
import defaultValues from 'constants/defaultValues';
import alignments from 'constants/alignments';
import PlainTextStyle from './styles/PlainTextStyle';
import colors from 'constants/colors';
import paddingPositions from 'enumerations/paddingPositions';
import sizes from 'constants/sizes';

const Text = (props) => {
    return (
        <styled.Content
            alignment={ props.alignment }
            className={ props.className }
            margin={ props.margin }
            fontSize={ props.fontSize }
            customStyle={ props.style }
            width={ props.width }>
            { props.value }
        </styled.Content>
    )
}

Text.propTypes = {
    alignment: PropTypes.string,
    column: PropTypes.number,
    className: PropTypes.string,
    textColor: PropTypes.string,
    margin: PropTypes.object,
    fontSize: PropTypes.number,
    trailingPadding: PropTypes.number,
    value: PropTypes.string,
    width: PropTypes.number,
    style: PropTypes.object
};

Text.defaultProps = {
    alignment: alignments.left,
    column: defaultValues.emptyInt,
    className: defaultValues.emptyString,
    textColor: colors.black,
    margin: {
        position: paddingPositions.left,
        value: defaultValues.emptyInt
    },
    fontSize: 16,
    trailingPadding: defaultValues.emptyInt,
    title: defaultValues.emptyString,
    width: defaultValues.emptyInt,
    style: PlainTextStyle
};

export default Text;