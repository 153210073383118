import React, { Component } from 'react'
import styled from 'styled-components';
import tw from "twin.macro"
import PropTypes from 'prop-types';
import zIndex from 'constants/zIndex';

const Wrapper = styled.div`
    display: ${ props => props.isShow ? ' block' : 'none' };
    z-index: ${(props) => props.zIndex};
    ${tw `fixed inset-0 w-full h-full`}
`;

const Container = styled.div`
    max-height: 100%;
    ${tw `flex relative h-full w-full`}
`;

const Overlay = styled.div`
    ${tw `absolute inset-0 bg-black opacity-50 z-30`}
`;

const ContentContainer = styled.div`
    ${tw `flex relative h-full m-auto z-40`}
`;

class Modal extends Component {
    static get propTypes() { 
        return {
            zIndex: PropTypes.number,
            content: PropTypes.element,
            isShow: PropTypes.bool
        }; 
    }

    static defaultProps = {
        zIndex: zIndex.defaultModal,
        isShow: true
    };

    render() {
        if (!this.props.isShow){ return null; }
        return (
            <Wrapper
                zIndex={ this.props.zIndex }
                isShow={ this.props.isShow }>
                <Container>
                    <Overlay />
                    <ContentContainer>
                         { this.props.content }
                    </ContentContainer>
                </Container>
            </Wrapper>
        );
    }
}

export default Modal;