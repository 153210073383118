export default {
    INQUIRY_PROPERTY_CARD: "INQUIRY_PROPERTY_CARD",
    RESET_PROPERTY_CARD: "RESET_PROPERTY_CARD",
    UPDATE_PROPERTY_CARD: "UPDATE_PROPERTY_CARD",
    CREATE_PROPERTY_CARD: "CREATE_PROPERTY_CARD",
    DELETE_PROPERTY_CARD: "DELETE_PROPERTY_CARD",
    FETCH_LAND_USAGES: "FETCH_LAND_USAGES",
    FETCH_LAND_AIR_CONDITIONERS: "FETCH_LAND_AIR_CONDITIONERS",
    FETCH_LAND_PARKING_METHODS: "FETCH_LAND_PARKING_METHODS",
    CREATE_WORK_HISTORY: "CREATE_WORK_HISTORY",
    UPLOAD_PROPERTY_IMAGE: "UPLOAD_PROPERTY_IMAGE",
    UPDATE_WRITE_CARD_REQUEST: "UPDATE_WRITE_CARD_REQUEST",
    UPDATE_SELECTED_PROPERTY: "UPDATE_SELECTED_PROPERTY",
    UPDATE_PAGINATION: "UPDATE_PAGINATION",
    RESET_STATE: "RESET_STATE",
    UPDATE_SEARCHED_CARD: "UPDATE_SEARCHED_CARD"
}