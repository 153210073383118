import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Employee } from 'models/objects/Employee';
import CardPageContentInnerOwnerInfo from 'layouts/PropertyCardLayouts/CardPageContentInnerOwnerInfo/CardPageContentInnerOwnerInfo';
import * as styled from './CardPageContentInnerWriteState.styled';
import ItemDetailsForm from 'layouts/PropertyCardLayouts/ItemDetailsForm/ItemDetailsForm';
import LeaseForm from 'layouts/PropertyCardLayouts/LeaseForm/LeaseForm';
import LandForm from 'layouts/PropertyCardLayouts/LandForm/LandForm';
import LandLedgerForm from 'layouts/PropertyCardLayouts/LandLedgerForm/LandLedgerForm';
import BuildingForm from 'layouts/PropertyCardLayouts/BuildingForm/BuildingForm';
import WorkHistoryList from 'layouts/PropertyCardLayouts/WorkHistoryList/WorkHistoryList';
import FloorInfoListForm from 'layouts/PropertyCardLayouts/FloorInfoForm/FloorInfoForm';
import CardPageContentInnerRentalHistoryForm from 'layouts/PropertyCardLayouts/RentalHistoryForm/RentalHistoryForm';
import { observer } from 'mobx-react';
import { useDispatch } from 'react-redux';
import { commonActionTypes } from 'redux/types';
import { CardWriteViewModel } from './CardWriteViewModel';
import cardActions from 'redux/actions/cardActions';
import cardActionTypes from 'redux/types/cardActionTypes';
import { Card } from 'models/objects/Card';
import { useSelector } from 'react-redux';
import zIndex from 'constants/zIndex';
import formStates from 'enumerations/formStates';
import cookiesManager from 'managers/cookies.manager';

const CardPageContentInnerWriteState = observer((props) => {
  const cardReducer = useSelector((state) => state.card);
  const requestWriteCard = useSelector((state) => state.card.requestWriteCard);
  const [viewModel] = useState(new CardWriteViewModel());
  const [excludedFloorInformations, setExcludedFloorInformations] = useState(
    []
  );
  const [excludedRentalHistories, setExcludedRentalHistories] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();

  const handleInputValueChange = (input) => {
    requestWriteCard[input.name] = input.value;
    dispatch({
      type: cardActionTypes.UPDATE_WRITE_CARD_REQUEST,
      payload: requestWriteCard,
    });
  };

  const showCreateWorkHistoryModal = (payload) => {
    dispatch({
      type: commonActionTypes.SHOW_MODAL,
      payload: {
        zIndex: zIndex.defaultModal,
        content: payload,
      },
    });
  };

  const fetchLandUsages = () => {
    dispatch({
      type: cardActionTypes.FETCH_LAND_USAGES,
      payload: cardActions.fetchLandUsages(viewModel.didFetchLandUsagesSucceed),
    });
  };

  useEffect(() => {
    window.addEventListener('resize', () => {
      setScreenWidth(window.innerWidth);
    });
    fetchLandUsages();
    if (cardReducer.formState == formStates.edit) {
      viewModel.setupDefaultCardEditRequest(props.currentCard);
    }
    props.onEditRequestChange(viewModel.requestCreateCard);
  }, []);

  const renderOwnerInfo = () => {
    if (cookiesManager.getIsPartner()) {
      return null;
    }
    return (
      <styled.FormContainer width={screenWidth * 0.22}>
        <CardPageContentInnerOwnerInfo
          isWriteState={true}
          values={viewModel.requestCreateCard}
          handleInputValueChange={handleInputValueChange}
        />
      </styled.FormContainer>
    );
  };

  const renderWorkHistory = () => {
    if (cookiesManager.getIsPartner()) {
      return null;
    }
    return (
      <styled.FormContainer width={screenWidth * 0.22}>
        <WorkHistoryList
          isWriteState={true}
          workHistories={viewModel.requestCreateCard.workHistories}
          showModal={showCreateWorkHistoryModal}
          handleInputValueChange={handleInputValueChange}
        />
      </styled.FormContainer>
    );
  };

  return (
    <div>
      <styled.TopContainer>
        <styled.FormContainer width={screenWidth * 0.26}>
          <styled.TopSideContainer>
            <ItemDetailsForm
              handleInputValueChange={handleInputValueChange}
              onChangePropertyImage={props.onChangePropertyImage}
            />
          </styled.TopSideContainer>
        </styled.FormContainer>
        <styled.FormContainer>
          <div className="flex gap-8">
            <styled.FormContainer width={screenWidth * 0.52}>
              <div className="grid grid-cols-2 gap-8">
                <LeaseForm
                  values={viewModel.requestCreateCard}
                  excludedRentalHistories={excludedRentalHistories}
                  handleInputValueChange={handleInputValueChange}
                />
                <LandForm
                  values={viewModel.requestCreateCard}
                  handleInputValueChange={handleInputValueChange}
                />
              </div>
            </styled.FormContainer>
            {renderOwnerInfo()}
          </div>
          <div className="flex gap-8">
            <styled.FormContainer width={screenWidth * 0.52}>
              <div className="grid grid-cols-2 gap-8">
                <LandLedgerForm
                  values={viewModel.requestCreateCard}
                  landUsages={viewModel.landUsages}
                  handleInputValueChange={handleInputValueChange}
                />
                <BuildingForm
                  values={viewModel.requestCreateCard}
                  excludedFloorInformations={excludedFloorInformations}
                  handleInputValueChange={handleInputValueChange}
                />
              </div>
            </styled.FormContainer>
            {renderWorkHistory()}
          </div>
        </styled.FormContainer>
      </styled.TopContainer>
      <styled.BottomContainer>
        <styled.BottomLeadingWrapper>
          <FloorInfoListForm
            handleInputValueChange={handleInputValueChange}
            excludedFloorInformations={excludedFloorInformations}
            onExcludeFloorInformations={(floorInformation) => {
              if (
                excludedFloorInformations.some((x) => x == floorInformation)
              ) {
                setExcludedFloorInformations(
                  excludedFloorInformations.filter((x) => x != floorInformation)
                );
                return;
              }
              setExcludedFloorInformations([
                ...excludedFloorInformations,
                floorInformation,
              ]);
            }}
          />
        </styled.BottomLeadingWrapper>
        <styled.BottomTrailingWrapper>
          <CardPageContentInnerRentalHistoryForm
            requestCreateCard={viewModel.requestCreateCard}
            excludedRentalHistories={excludedRentalHistories}
            handleInputValueChange={handleInputValueChange}
            onExcludeCell={(rentalHistory) => {
              if (excludedRentalHistories.some((x) => x == rentalHistory)) {
                setExcludedRentalHistories(
                  excludedRentalHistories.filter((x) => x != rentalHistory)
                );
                return;
              }
              setExcludedRentalHistories([
                ...excludedRentalHistories,
                rentalHistory,
              ]);
            }}
          />
        </styled.BottomTrailingWrapper>
      </styled.BottomContainer>
    </div>
  );
});

CardPageContentInnerWriteState.propTypes = {
  currentUser: PropTypes.object,
  currentCard: PropTypes.object,
  showModal: PropTypes.func,
  onEditRequestChange: PropTypes.func,
  onChangePropertyImage: PropTypes.func,
};

CardPageContentInnerWriteState.defaultProps = {
  currentUser: new Employee(),
  currentCard: new Card(),
  onEditRequestChange: () => {
    return;
  },
  onChangePropertyImage: () => {
    return;
  },
};

export default CardPageContentInnerWriteState;
