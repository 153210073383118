import {
    customerActionTypes
} from 'redux/types';
import {
    ResponseFetchCustomerList
} from "models/responses/ResponseFetchCustomerList";
import { customerAction } from 'redux/actions';
import { store } from 'configs/store';
import defaultValues from 'constants/defaultValues';

const ON_CHANGE_PAGE = "customer/ON_CHANGE_PAGE";

const states = (state, action) => {
    return {
        [customerActionTypes.FETCH_CUSTOMER_LIST]: () => {
            return {
                ...state,
                responseFetchCustomerList: action.payload
                    ? new ResponseFetchCustomerList().mapFromJson(action.payload.data)
                    : new ResponseFetchCustomerList(),
                error: action.error ? action.payload : null
            }
        },
        [customerActionTypes.SEARCH_CUSTOMER_LIST]: () => {
            return {
                ...state,
                responseSearchCustomerList: action.payload
                    ? new ResponseFetchCustomerList().mapFromJson(action.payload.data)
                    : new ResponseFetchCustomerList(),
                isSearchInProgress: false,
                isSearchCompleted: true,
                error: action.error ? action.payload : null
            }
        },
        [customerActionTypes.ON_SEARCH_CUSTOMER_LIST]: () => {
            return {
                ...state,
                isSearchInProgress: true,
                isSearchCompleted: false
            }
        },
        [ON_CHANGE_PAGE]: () => {
            return {
                ...state,
                currentPage: action.payload
            }
        }
    }
}

export default (state = {}, action) => {
    if (!(action.type in states(state, action))) {
        return {
            ...state,
            currentPage: state.currentPage || defaultValues.emptyInt
        };
    }
    return states(state, action)[action.type]();
}

export const onChangePage = (page) => {
    store.dispatch(
        {
            type: ON_CHANGE_PAGE,
            payload: page
        }
    )
}