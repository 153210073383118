import React from 'react';
import PropTypes from 'prop-types';
import { Employee } from 'models/objects/Employee';
import CardPageContentInnerAddress from 'layouts/PropertyCardLayouts/CardPageContentInnerAddress/CardPageContentInnerAddress';
import PriceInformations from 'layouts/PropertyCardLayouts/PriceInformations/PriceInformations';
import CardPageContentInnerLandInfo from 'layouts/PropertyCardLayouts/LandInformation/LandInformation';
import CardPageContentInnerOwnerInfo from 'layouts/PropertyCardLayouts/CardPageContentInnerOwnerInfo/CardPageContentInnerOwnerInfo';
import CardPageContentInnerNote from 'layouts/PropertyCardLayouts/CardPageContentInnerNote/CardPageContentInnerNote';
import CardPageContentInnerBuildingInfo from 'layouts/PropertyCardLayouts/BuildingInformation/BuildingInformation';
import WorkHistoryList from 'layouts/PropertyCardLayouts/WorkHistoryList/WorkHistoryList';
import * as styled from './CardPageContentInnerReadState.styled';
import cookiesManager from 'managers/cookies.manager';
import FloorInfoListForm from '../FloorInfoForm/FloorInfoForm';
import RentalHistoryForm from '../RentalHistoryForm/RentalHistoryForm';

const CardPageContentInnerReadState = () => {
  const isCurrentUserPartner = cookiesManager.getIsPartner();

  return (
    <div>
      <styled.TopContainer>
        <styled.TopLeadingContainer>
          <CardPageContentInnerAddress />
        </styled.TopLeadingContainer>
        <styled.TopTrailingContainer isAdmin={!isCurrentUserPartner}>
          <PriceInformations isAdmin={!isCurrentUserPartner} />
          <CardPageContentInnerLandInfo />
          <CardPageContentInnerOwnerInfo isHidden={isCurrentUserPartner} />
          <CardPageContentInnerNote />
          <CardPageContentInnerBuildingInfo />
          <WorkHistoryList isHidden={isCurrentUserPartner} />
        </styled.TopTrailingContainer>
      </styled.TopContainer>
      <styled.BottomContainer>
        <styled.BottomLeadingWrapper>
          <FloorInfoListForm />
        </styled.BottomLeadingWrapper>
        <styled.BottomTrailingWrapper>
          <RentalHistoryForm />
        </styled.BottomTrailingWrapper>
      </styled.BottomContainer>
    </div>
  );
};

CardPageContentInnerReadState.propTypes = {
  currentUser: PropTypes.object,
};

CardPageContentInnerReadState.defaultProps = {
  currentUser: new Employee(),
};

export default CardPageContentInnerReadState;
