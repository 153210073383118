import * as defaultValues from 'constants/defaultValue';
import * as commonActions from 'redux/reducers/commonReducer';
import { useState } from 'react';
import employeeMenuType from 'enumerations/employeeMenuType';

const useEmployeePage = () => {
  const [tabIndex, setTabIndex] = useState(defaultValues.emptyInt);

  const setupTabButtons = () => {
    return [
      {
        title: '회원목록',
        path: 'list',
        action: _startEmployeeListPage,
      },
      {
        title: '로그인접속내역',
        path: 'login-log',
        action: _startEmployeeLoginLogPage,
      },
      {
        title: '카드 열람내역',
        path: 'card-log',
        action: _startEmployeeCardLogPage,
      },
    ];
  };

  const _startEmployeeListPage = () => {
    setTabIndex(employeeMenuType.employeeList);
    commonActions.redirectTo('/employee/list');
  };

  const _startEmployeeLoginLogPage = () => {
    setTabIndex(employeeMenuType.loginHistory);
    commonActions.redirectTo('/employee/login-log');
  };

  const _startEmployeeCardLogPage = () => {
    setTabIndex(employeeMenuType.cardViewLog);
    commonActions.redirectTo('/employee/card-log');
  };

  return {
    tabIndex,
    setupTabButtons,
  };
};

export default useEmployeePage;
