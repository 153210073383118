import defaultValues from "constants/defaultValues";

export class RequestFetchCard {
    constructor(
        page = defaultValues.emptyInt
    ) {
        this.page = page;
    }

    mapToJson() {
        return {
            'page': this.page
        }
    }
}