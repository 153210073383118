import styled from "styled-components";
import tw from "twin.macro";

const Container = styled.div`
    ${ tw`flex flex-col space-y-2.5 h-full` }
`;
const UnitDescriptionText = styled.p`
    ${ tw`text-right` }
`;
const TableWrapper = styled.div`
    height: 300px;
`;
const RightNavigationMenu = styled.div`
    ${ tw`flex gap-5` }
`;

export {
    Container,
    UnitDescriptionText,
    TableWrapper,
    RightNavigationMenu
}