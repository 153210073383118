import inputTypes from "./inputTypes";

const enums = {
    status: "status",
    name: "name",
    owner: "owner",
    id: "id",
    phone: "phone",
    manager: "manager",
    address: "address",
    streetName: "streetName",
    nearestStation: "nearestStation",
    roadWidth: "roadWidth",
    price: "price",
    revenue: "revenue",
    landArea: "landArea",
    landAreaPy: "landAreaPy",
    floorArea: "floorArea",
    floorAreaPy: "floorAreaPy",
    isRoadSide: "isRoadSide",
    isRoadCorner: "isRoadCorner",
    usage: "usage"
}

export default {
    enums: enums,
    inputGroups: [
        {
            key: enums.status,
            displayTitle: "상태",
            type: inputTypes.unspecified
        },
        {
            key: enums.name,
            displayTitle: "물건명",
            type: inputTypes.textInput
        },
        {
            key: enums.owner,
            displayTitle: "소유자(;)",
            type: inputTypes.textInput
        },
        {
            key: enums.id,
            displayTitle: "물건번호",
            type: inputTypes.textInput
        },
        {
            key: enums.phone,
            displayTitle: "전화번호",
            type: inputTypes.textInput
        },
        {
            key: enums.manager,
            displayTitle: "담당자",
            type: inputTypes.textInput
        },
        {
            key: enums.address,
            displayTitle: "주소(;)",
            type: inputTypes.textInput
        },
        {
            key: enums.streetName,
            displayTitle: "도로명",
            type: inputTypes.textInput
        },
        {
            key: enums.nearestStation,
            displayTitle: "주변역(;)",
            type: inputTypes.textInput
        },
        {
            key: enums.roadWidth,
            displayTitle: "도로",
            type: inputTypes.unspecified
        },
        {
            key: enums.price,
            displayTitle: "매물금액",
            type: inputTypes.range
        },
        {
            key: enums.revenue,
            displayTitle: "연수익률",
            type: inputTypes.range
        },
        {
            key: enums.landAreaPy,
            displayTitle: "대지평수",
            type: inputTypes.range
        },
        {
            key: enums.landArea,
            displayTitle: "",
            type: inputTypes.range
        },
        {
            key: enums.floorAreaPy,
            displayTitle: "건물평수",
            type: inputTypes.range
        },
        {
            key: enums.floorArea,
            displayTitle: "",
            type: inputTypes.range
        },
        {
            key: enums.usage,
            displayTitle: "용도지역",
            type: inputTypes.unspecified
        }
    ],
    rangeInputs: {
        [enums.price]: {
            minimumName: "minPrice",
            maximumName: "maxPrice",
            suffixText: "억"
        },
        [enums.revenue]: {
            minimumName: "minRevenue",
            maximumName: "maxRevenue",
            suffixText: "％"
        },
        [enums.landAreaPy]: {
            minimumName: "minLandAreaPy",
            maximumName: "maxLandAreaPy",
            suffixText: "평"
        },
        [enums.landArea]: {
            minimumName: "minLandArea",
            maximumName: "maxLandArea",
            suffixText: "㎡"
        },
        [enums.floorAreaPy]: {
            minimumName: "minFloorAreaPy",
            maximumName: "maxFloorAreaPy",
            suffixText: "평"
        },
        [enums.floorArea]: {
            minimumName: "minFloorArea",
            maximumName: "maxFloorArea",
            suffixText: "㎡"
        }
    },
    checkboxes: {
        [enums.isRoadSide]: {
            title: "양면"
        },

        [enums.isRoadCorner]: {
            title: "코너"
        }
    }
}