import defaultValues from "constants/defaultValues";
import { RequestCreateOwnerInformation } from "models/requests/RequestCreateOwnerInformation";

export class OwnerInformation {
    constructor(
        id = defaultValues.emptyInt,
        name = defaultValues.emptyString,
        phone = defaultValues.emptyString,
        mobilePhone = defaultValues.emptyString,
        age = defaultValues.emptyInt,
        note = defaultValues.emptyString,
    ) {
        this.id = id;
        this.name = name;
        this.phone = phone;
        this.mobilePhone = mobilePhone;
        this.age = age;
        this.note = note;
    }

    mapFromJson(json) {
        if (!json) { return this; }
        this.id = json['id'];
        this.name = json['name'];
        this.phone = json['phone'];
        this.mobilePhone = json['mobile'];
        this.age = json['age'];
        this.note = json['note'];
        return this;
    }

    convertToEditRequest() {
        const requestCreateOwnerInformation = new RequestCreateOwnerInformation()
        requestCreateOwnerInformation.name = this.name;
        requestCreateOwnerInformation.phone = this.phone;
        requestCreateOwnerInformation.mobile = this.mobilePhone;
        requestCreateOwnerInformation.note = this.note;
        return requestCreateOwnerInformation
    }
}