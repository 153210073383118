import styled from '@emotion/styled';
import tw from 'twin.macro';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 1075px;
  height: 765px;
  padding: 3rem;
  background-color: white;
  scale: ${(props) => props.scale};
  overflow: hidden;
  margin: auto;
`;

export const BodyContainer = styled.div`
  padding-top: 2rem;
  overflow: hidden;
  ${tw`flex flex-col gap-y-3 flex-1`}
`;

export const BodyColumnContainer = styled.div`
  overflow: hidden;
  ${tw`grid grid-rows-2`}
`;

export const SingleRowContainer = styled.div`
  overflow: hidden;
  ${tw`row-span-1`}
`;

export const DoubleRowContainer = styled.div`
  ${tw`row-span-2`}
`;

export const CalculationContainer = styled.div`
  ${tw`grid grid-cols-3`}
`;

export const LandInformationContainer = styled.div`
  ${tw`space-y-2`}
`;
