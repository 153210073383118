import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as styled from './TextArea.styled';
import defaultValues from 'constants/defaultValues';

class TextArea extends Component {
    static get propTypes() {
        return { 
            height: PropTypes.number,
            marginBottom: PropTypes.number,
            handleChange: PropTypes.func
        };
    }

    static defaultProps = {
        height: defaultValues.emptyInt,
        marginBottom: defaultValues.emptyInt,
        handleChange: () => { return; }
    };

    onChange = (event) => {
        this.props.handleChange(event.target.value)
    }

    render() {
        return (
            <styled.Content
                {...this.props}
                onChange={ this.onChange }/>
        )
    }
}

export default TextArea;