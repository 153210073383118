import defaultValues from "constants/defaultValues";

export class RequestCreateOwnerInformation {
    constructor(
        name = defaultValues.emptyString,
        phone = defaultValues.emptyString,
        mobile = defaultValues.emptyString,
        note = defaultValues.emptyString
    ) {
        this.name = name;
        this.phone = phone;
        this.mobile = mobile;
        this.note = note;
    }

    mapToJson() {
        return {
            'name': this.name,
            'phone': this.phone,
            'mobile': this.mobile,
            'note': this.note
        }
    }
}