import React from 'react';
import * as styled from './CardSection.styled';
import PropTypes from 'prop-types';
import defaultValues from 'constants/defaultValues';

const CardSection = (props) => {
  return (
    <styled.Container>
      <styled.HeaderContainer>
        <styled.HeaderTitle>{props.title}</styled.HeaderTitle>
        {props.rightNavigationMenu}
      </styled.HeaderContainer>
      <styled.ContentWrapper>{props.content}</styled.ContentWrapper>
    </styled.Container>
  );
};

CardSection.propTypes = {
  title: PropTypes.string,
  content: PropTypes.element,
  rightNavigationMenu: PropTypes.element,
};

CardSection.defaultProps = {
  title: defaultValues.emptyString,
  content: null,
  rightNavigationMenu: null,
};

export default CardSection;
