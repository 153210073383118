import defaultValues from "constants/defaultValues";

export class LandUsage {
    constructor(
        id = defaultValues.emptyInt,
        name = defaultValues.emptyString,
        isCustom = false
    ) {
        this.id = id;
        this.name = name;
        this.isCustom = isCustom;
    }

    isEmpty = () => {
        return this.id == defaultValues.emptyInt
            && this.name == defaultValues.emptyString
            && !this.isCustom
    }

    mapFromJson(json) {
        if (!json) { return this; }
        this.id = json['id'];
        this.name = json['name'];
        this.isCustom = json['is_custom'];
        return this;
    }

    mapToJson() {
        return {
            "id": this.id,
            "name": this.name
        }
    }
}