import React from 'react';
import * as defaultValues from 'constants/defaultValue';
import * as styled from './SearchHistoryLayout.styled';
import { PrimaryTableListStyle } from "components/core/table-list/styles";
import TableList from "components/core/table-list/TableList"
import Text from 'components/core/text/Text';
import alignments from 'constants/alignments';
import Button from 'components/core/button/Button';
import LinkButtonStyle from 'components/core/button/styles/LinkButtonStyle';
import useSearchHistoryLayout from './useSearchHistoryLayout';
import { useEffect } from 'react';
import buttonStrings from 'localizations/buttonStrings';

const SearchHistoryLayout = () => {
    const hook = useSearchHistoryLayout();

    const renderText = (key, value) => {
        return (
            <Text
                key={ key }
                alignment={ alignments.center }
                value={ value }/>
        )
    }

    const renderDeleteButton = (key, title, historyId) => {
        return (
            <styled.DeleteButtonWrapper key={ key }>
                <Button
                    title={ title }
                    marginLeft={ "auto" }
                    marginRight={ "auto" }
                    style={ LinkButtonStyle }
                    action={
                        () => {
                            return historyId == null
                                ? hook.onStartClearHistory()
                                : hook.onStartDeleteHistory(historyId)
                        }
                    }/>
            </styled.DeleteButtonWrapper>
        )
    }

    const setupHistoryItems = () => {
        const historyItems = hook.propertyHistories.map(
            (propertyHistory, index) => [
                renderText(
                    index,
                    propertyHistory.property.buildingName
                ),
                renderText(
                    index,
                    propertyHistory.property.address
                ),
                renderDeleteButton(
                    index,
                    buttonStrings.delete,
                    propertyHistory.id
                )
            ]
        )
        return historyItems;
    }

    useEffect(
        () => hook.loadData(),
        defaultValues.emptyArray
    )

    return (
        <TableList
            maximumHeight={ 300 }
            datas={ setupHistoryItems() }
            headers={ 
                [
                    renderText(
                        defaultValues.emptyInt,
                        "물건명"
                    ),
                    renderText(
                        defaultValues.emptyInt,
                        "주소"
                    ),
                    renderDeleteButton(
                        defaultValues.emptyInt,
                        "전체삭제"
                    )
                ]
            }
            style={ PrimaryTableListStyle }
            onDoubleClickRow={ hook.onStartSearchDetail }  />
    )
}
export default SearchHistoryLayout;