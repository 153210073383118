import defaultValues from "constants/defaultValues";
import { Employee } from "./Employee";

export class WorkHistory {
    constructor(
        id = defaultValues.emptyInt,
        user = new Employee(),
        description = defaultValues.emptyString,
        date = defaultValues.emptyString
    ) {
        this.id = id;
        this.user = user;
        this.description = description;
        this.date = date;
    }

    mapFromJson(json) {
        if (!json) { return this; }
        this.id = json['id'];
        this.user = json['user'];
        this.description = json['description'];
        this.date = json['date'];
        return this;
    }
}