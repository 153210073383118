import { Employee } from "models/objects/Employee";

export class RequestUpdateEmployee {
    constructor(
        employee = new Employee()
    ) {
        this.employee = employee;
    }

    mapToJson() {
        return {
            'id': this.employee.id,
            'name': this.employee.name,
            'password': this.employee.password,
            'phone_number': this.employee.phoneNumber,
            'email': this.employee.email,
            'role': this.employee.role.mapToJson(),
            'mac_address': this.employee.macAddress
        }
    }
}