import React, { useState } from 'react';
import LoginBackgroundImage from 'assets/login_background_grayscale.png';
import HorizontalLogo from 'assets/horizontal_logo_black.png';
import LoginForm from 'layouts/forms/LoginForm';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import defaultValues from 'constants/defaultValues';
import sizes from 'constants/sizes';
import AlertDialogModal from 'layouts/modals/AlertDialogModal';
import { ButtonStrings, titleStrings } from 'localizations';
import Button from 'components/core/button/Button';
import { WhiteButtonStyle } from 'components/core/button/styles';
import { observer } from 'mobx-react';
import tw from 'twin.macro';

const Wrapper = styled.div`
  background-image: url(${LoginBackgroundImage});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const LoginFormWrapper = styled.div`
  width: 500px;
  height: 700px;
  max-width: 90%;
  max-height: 90%;
`;

const LogoImage = styled.img`
  width: 300px;
`;

const LoginPage = observer(() => {
  const [errorMessage, setErrorMessage] = useState(defaultValues.emptyString);

  const resetState = () => {
    setErrorMessage(defaultValues.emptyString);
  };

  const showErrorMessage = (error) => {
    setErrorMessage(error.message.korean);
  };

  const renderErrorModal = () => {
    return (
      <AlertDialogModal
        isShowing={!errorMessage.isEmpty()}
        title={titleStrings.error}
        subtitle={errorMessage}
        primaryButton={
          <Button
            action={resetState}
            height={sizes.button.small}
            style={WhiteButtonStyle}
            title={ButtonStrings.confirm}
          />
        }
      />
    );
  };
  return (
    <Wrapper className="flex h-full">
      {renderErrorModal()}
      <LoginFormWrapper className="flex m-auto bg-white bg-opacity-75 overflow-auto">
        <div className="m-auto p-16">
          <LogoImage
            className="mx-auto mb-20"
            src={HorizontalLogo}
            alt="Property Partners Vertical Logo Black"
          />
          <LoginForm
            className="mx-5"
            onLoginFailed={showErrorMessage}
          />
        </div>
      </LoginFormWrapper>
    </Wrapper>
  );
});

LoginPage.propTypes = {
  inProgress: PropTypes.bool,
  resetState: PropTypes.func,
  onSubmit: PropTypes.func,
  inquiryIpAddress: PropTypes.func,
  ipAddress: PropTypes.string,
};

LoginPage.defaultProps = {
  inProgress: false,
  resetState: () => {
    return;
  },
  onSubmit: () => {
    return;
  },
  ipAddress: defaultValues.emptyString,
};

export default LoginPage;
