import {
    authActionTypes,
    commonActionTypes
} from 'redux/types';

const states = (state, action) => {
    return {
        [authActionTypes.LOGIN]: () => { 
            return {
                ...state,
                inProgress: false,
                error: action.error ? action.payload : null
            }
        },
        [commonActionTypes.RESET]: () => {
            return { 
                ...state,
                inProgress: false,
                error: null
            }
        }
    }
}

export default (
    state = {
        inProgress: false
    },
    action
) => {
    if (!(action.type in states(state, action))) {
        return state;
    }
    return states(state, action)[action.type]();
}