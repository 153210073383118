import styled from "styled-components";
import tw from "twin.macro";

const Wrapper = styled.div`
    ${tw`flex flex-col h-full overflow-hidden`}
`;

const TabWrapper = styled.div`
    min-height: 72px;
    ${tw`flex bg-primary`}
`;

const ContentWrapper = styled.div`
    ${tw`flex-1 overflow-hidden`}
`;

export {
    Wrapper,
    TabWrapper,
    ContentWrapper
}