import React from 'react';
import Button from 'components/core/button/Button';
import TableList from 'components/core/table-list/TableList';
import DropdownInput from 'components/core/dropdown-input/DropdownInput';
import MemoContainer from 'components/MemoForm/MemoContainer';
import {
  DarkGrayButtonStyle,
  WhiteButtonStyle,
} from 'components/core/button/styles';
import { BorderedDropdownInputStyle } from 'components/core/dropdown-input/styles/index';
import Spaces from 'constants/spaces';
import * as defaultValues from 'constants/defaultValue';
import SearchIcon from 'assets/search_icon_black.png';
import AddPersonIconWhite from 'assets/add_person_icon_white.png';
import sizes from 'constants/sizes';
import titleStrings from 'localizations/titleStrings';
import cookiesManager from 'managers/cookies.manager';
import buttonStrings from 'localizations/buttonStrings';
import * as styled from './CustomerPage.styled';
import useCustomerPage from './useCustomerPage';
import PaginationView from 'components/PaginationView/PaginationView';
import { useSelector } from 'react-redux';

const CustomerPage = () => {
  const hook = useCustomerPage();
  const { currentPage } = useSelector((state) => state.customer);

  const renderManagerDropdownInput = () => {
    if (!cookiesManager.getCurrentUser().isAdmin) {
      return null;
    }
    let options = [
      {
        key: defaultValues.emptyInt,
        value: buttonStrings.all,
      },
      ...hook.employees.map((employee) => {
        return {
          key: employee.id,
          value: employee.name,
        };
      }),
    ];
    return (
      <styled.ManagerInputContainer>
        <DropdownInput
          handleSelectOption={hook.setSelectedEmployeeItem}
          options={options}
          selectedOption={hook.selectedEmployeeItem}
          style={BorderedDropdownInputStyle}
          width={sizes.managerInputDropdown.width}
        />
      </styled.ManagerInputContainer>
    );
  };

  const renderChangeManagerButton = () => {
    if (
      !cookiesManager.getCurrentUser().isAdmin ||
      hook.selectedEmployeeItem.key.isEmpty()
    ) {
      return null;
    }
    return (
      <styled.CustomerAction onClick={hook.showChangeButtonModal}>
        {buttonStrings.changeManager}
      </styled.CustomerAction>
    );
  };

  const _renderDeleteButton = () => {
    if (!cookiesManager.getCurrentUser().isAdmin) {
      return null;
    }
    return (
      <styled.CustomerAction onClick={hook.showDeleteConfirmationModal}>
        {buttonStrings.delete}
      </styled.CustomerAction>
    );
  };

  const _renderPagination = () => {
    return (
      <PaginationView
        page={currentPage}
        totalPages={hook.pagination.totalPages}
        onChange={hook.onPageChange}
      />
    );
  };

  return (
    <styled.Wrapper>
      <div className="flex px-8 mb-4 justify-between">
        {renderManagerDropdownInput()}
        {_renderPagination()}
        <div className="flex gap-6">
          <Button
            action={hook.showSearchCustomerModal}
            height={sizes.button.small}
            icon={SearchIcon}
            style={WhiteButtonStyle}
            title="검색"
          />
          <Button
            action={hook.showCreateCustomerModal}
            height={sizes.button.small}
            icon={AddPersonIconWhite}
            style={DarkGrayButtonStyle}
            title="고객등록"
          />
        </div>
      </div>
      <hr />
      <p className="text-left px-8 my-4">
        총 <b>{hook.customersCount}</b>개
      </p>
      <div className="grid grid-cols-2 flex-1 overflow-hidden">
        <div className="flex flex-col overflow-hidden">
          <TableList
            firstColumnPadding={Spaces.small}
            isClickable={true}
            handleAction={hook.onSelectCustomer}
            headers={hook.setupTableHeaders()}
            datas={hook.setupTableRows()}
          />
          <styled.CustomerActionContainer>
            {_renderDeleteButton()}
            {renderChangeManagerButton()}
          </styled.CustomerActionContainer>
        </div>
        <div className="grid grid-cols-2">
          <MemoContainer
            title="조건"
            value={hook.selectedCustomer.condition}
            onValueChange={hook.onConditionChange}
            onSaveMemo={hook.onSaveCondition}
          />
          <MemoContainer
            title={titleStrings.memo}
            value={hook.selectedCustomer.memo}
            onValueChange={hook.onMemoChange}
            onSaveMemo={hook.onSaveMemo}
          />
        </div>
      </div>
    </styled.Wrapper>
  );
};

export default CustomerPage;
