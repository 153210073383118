
import styled from "styled-components";
import tw from "twin.macro";

const Content = styled.textarea`
    resize: none;
    min-height: ${ (props) => props.height }px;
    ${tw `outline-none w-full text-left p-3 flex-1 border border-secondary`}
    ${ 
        (props) => props.disabled
            ? tw`bg-secondary-light`
            : tw`bg-white`
    }
    ${(props) => {
        return props.marginTop && `margin-top: ${props.marginTop}px;`
    }}
    ${(props) => {
        return props.marginBottom && `margin-bottom: ${props.marginBottom}px;`
    }}
`;

export { Content }