import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { ConnectedRouter } from 'connected-react-router';
import './App.css';
import LoginPage from './pages/LoginPage/LoginPage';
import { history, store } from 'configs/store';
import DashboardPage from 'pages/DashboardPage/DashboardPage';
import cookiesManager from 'managers/cookies.manager';
import defaultValues from 'constants/defaultValues';
import 'extensions/stringExtension';
import 'extensions/arrayExtension';
import 'extensions/numberExtension';
import { Switch, Route, Redirect } from 'react-router';
import { commonActionTypes } from 'redux/types';

const App = () => {
  const dispatch = useDispatch();
  const commonReducer = useSelector(state => state.common)

  useEffect(
    () => {
      if (!commonReducer.redirectTo) { return; }
      store.dispatch(push(commonReducer.redirectTo));
      window.scrollTo(
        defaultValues.emptyInt,
        defaultValues.emptyInt
      );
      dispatch({ type: commonActionTypes.REDIRECT })
    },
    [commonReducer.redirectTo]
  )

  return (
    <ConnectedRouter history={ history }>
      <div className="App">
        <Switch> 
          <Route exact path="/" render={
            () => (
            <Redirect to={ cookiesManager.getAccessToken() ? "/map" : "/login" }/>
          )} />
          <Route path="/login" component={ LoginPage } />
          <Route exact path="/*" component={ DashboardPage } />
        </Switch>
      </div>
    </ConnectedRouter>
  )
}

App.propTypes = {
  error: PropTypes.object,
  history: PropTypes.object,
  redirectTo: PropTypes.string,
  onRedirect: PropTypes.func
};

App.defaultProps = {
  error: {
    message: {
      korean: defaultValues.emptyString
    }
  }
};

export default App;