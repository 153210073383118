import styled from 'styled-components';
import tw from 'twin.macro';


const Container = styled.div`
    ${tw`
        absolute 
        top-0 right-0
        py-4 pr-4 pl-0
        z-[1]
    `}
`;

const ButtonView = styled.button`
    ${tw`
        bg-[#F9F9F9] hover:bg-[#EEEEEE] min-w-[4rem] outline-none
        rounded-md shadow-md
        border-solid border-[1px] border-[#DDDDDD]
        ml-2 p-2
    `};
`;

const MapType = styled.button`
    ${tw`
        hover:bg-[#333333] hover:text-[white]
        border-solid border-[#333333]
        py-2 px-4
    `};
`;

export {
    Container,
    ButtonView,
    MapType
}