import { useEffect, useState } from "react";
import { RequestSearchProperty } from "models/requests/RequestSearchProperty";
import searchService from "redux/actions/searchActions";
import * as searchActions from "redux/reducers/searchReducer";
import cardStatus from "enumerations/cardStatus";
import PaginationViewPresenter from "components/PaginationView/PaginationViewPresenter";
import { RequestPagination } from "models/requests/RequestPagination";
import TableRowPresenter from "components/core/row-table/TableRowPresenter";
import { useDispatch } from "react-redux";
import * as defaultValues from "constants/defaultValue";
import { useSelector } from "react-redux";
import searchActionTypes from "redux/types/searchActionTypes";

const SearchResultLayoutPresenter = () => {
    const dispatch = useDispatch();
    const searchReducer = useSelector(state => state.search);
    const { requestSearchProperty } = useSelector(state => state.search);
    const tableHeaders = [
        "번호",
        "상태",
        "물건명",
        "주소",
        "소유자",
        "대지(㎡/평)",
        "연면적(㎡/평)",
        "매매가",
        "보증금",
        "월수입",
        "수익률(%)",
        "주변역"
    ]
    const paginationViewPresenter = PaginationViewPresenter();
    const [propertyTableItems, setPropertyTableItems] = useState([]);
    const [searchResults, setSearchResults] = useState(defaultValues.emptyArray);

    const loadData = () => {
        searchService.fetchSearchProperty(
            requestSearchProperty,
            didFetchSearchPropertySucceed
        )
    }

    const resetData = () => {
        dispatch({
            type: searchActionTypes.UPDATE_SEARCH_REQUEST,
            payload: new RequestSearchProperty()
        })
        reloadData(
            new RequestSearchProperty()
        );
    }

    const reloadData = (request) => {
        searchActions.reloadData(request);
    }

    const didFetchSearchPropertySucceed = (response) => {
        searchActions.updatePagination(response.pagination);
        searchActions.updateProperties(response.properties);
        setupPropertyTableItems(response.properties);
    }

    const setupLandArea = (property) => {
        const landAreaInMeter = property.getLandArea();
        const landAreaInPy = property.getLandArea().convertToPy();
        return landAreaInMeter.rounded(2).toString().thousandFormat()
            + "("
            + landAreaInPy.rounded(2).toString().thousandFormat()
            + ")";
    }

    const setupFloorArea = (property) => {
        const buildingInformation = property.buildingInformation;
        return buildingInformation.totalArea.rounded(2).toString().thousandFormat()
            + "("
            + buildingInformation.totalAreaInPY.rounded(2).toString().thousandFormat()
            + ")";
    }

    const setupPropertyTableItems = (properties) => {
        setPropertyTableItems(
            properties.map(
                (property, index) => TableRowPresenter(
                    property.id,
                    [
                        index + 1,
                        cardStatus.getTitle(property.status),
                        property.buildingName,
                        property.address,
                        property.ownerInformation.name,
                        setupLandArea(property),
                        setupFloorArea(property),
                        property
                            .priceInformation
                            .totalPrice
                            .toString()
                            .thousandFormat(),
                        property
                            .priceInformation
                            .deposit
                            .toString()
                            .thousandFormat(),
                        property
                            .getMonthlyRent()
                            .rounded(2)
                            .toString()
                            .thousandFormat(),
                        property
                            .priceInformation
                            .revenue
                            .toString()
                            .thousandFormat(),
                        property.nearestStation
                    ]
                )
            )
        );
    }

    useEffect(
        () => loadData(),
        defaultValues.emptyArray
    )

    useEffect(
        () => {
            setSearchResults(searchReducer.searchResults);
            // setupPropertyTableItems(searchReducer.searchResults)
        },
        [searchReducer.searchResults]
    )

    return {
        resetData,
        reloadData,
        propertyTableItems,
        paginationViewPresenter,
        tableHeaders
    }
}

export default SearchResultLayoutPresenter;