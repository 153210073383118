import ImagePageSize from 'enumerations/ImagePageSize';
import * as printPageActions from 'pages/PrintCardPage/printCardReducer';
import documentService from 'services/documentService';
import { RequestUpdateDocSetting } from 'models/requests/RequestUpdateDocSetting';
import { useSelector } from 'react-redux';
import etcService from 'services/etcService';
import RequestDocImage from 'models/requests/RequestDocImage';
import { useState } from 'react';
import * as propertyPrintPages from 'enumerations/propertyPrintPages';
import {
  RequestUpdateImagePath,
  RequestUpdateImageTitle,
  RequestUpdatePrintStatus,
} from 'models/requests';
import { defaultValues } from 'constants';

const useImagePageInput = (props) => {
  const { property, pages, imagePages } = useSelector(
    (state) => state.printProperty
  );
  const countSettings = [
    { key: 1, value: '이미지 1' },
    { key: 2, value: '이미지 2' },
    { key: 4, value: '이미지 4' },
  ];
  const sizeSettings = [
    ImagePageSize.w480h640,
    ImagePageSize.w640h480,
    ImagePageSize.w655h750,
    ImagePageSize.original,
  ];
  const exclusionOptions = [
    { key: 0, value: 'O' },
    { key: 1, value: 'X' },
  ];
  var [inputDebouncer, setInputDebouncer] = useState(null);

  const getIsFormDisabled = () => {
    return props.id === defaultValues.emptyInt.toString();
  };

  const setupSizeSettingOptions = () => {
    return sizeSettings.map(($0) => $0.optionItem);
  };

  const onCheckInput = () => {
    printPageActions.onCheckImageForm(props.id);
  };

  const onChangeTitle = (input) => {
    printPageActions.changeImageTitle(props.id, input.value);
    clearTimeout(inputDebouncer);
    setInputDebouncer(
      setTimeout(() => {
        const request = new RequestUpdateImageTitle();
        request.imageId = props.id;
        request.title = input.value;
        documentService.updateImageTitle(
          request,
          () => {
            return;
          },
          () => {
            return;
          }
        );
      }, 500)
    );
  };

  const saveDocumentSetting = (onSuccess) => {
    const mainPage = pages.find(($0) => $0.type == propertyPrintPages.main);
    const summaryPage = pages.find(
      ($0) => $0.type == propertyPrintPages.summary
    );
    const rentalHistoryPage = pages.find(
      ($0) => $0.type == propertyPrintPages.rentalHistory
    );
    const floorInfoPage = pages.find(
      ($0) => $0.type == propertyPrintPages.floorInfo
    );
    documentService.saveDocumentSetting(
      new RequestUpdateDocSetting(
        Number(property.id),
        imagePages.map(
          ($0) =>
            new RequestDocImage(
              $0.title,
              $0.imagePaths.map((imagePath) => imagePath.id),
              $0.isPrintable
            )
        ),
        !mainPage.isExcluded,
        !summaryPage.isExcluded,
        !rentalHistoryPage.isExcluded,
        !floorInfoPage.isExcluded
      ),
      (response) => {
        onSuccess(response);
        return;
      }
    );
  };

  const onChangeImageSize = (input) => {
    printPageActions.changeImageSize(
      props.id,
      sizeSettings.find(($0) => $0.id == input.key)
    );
  };

  const onUpdateImageCount = (item) => {
    printPageActions.changeImageCount(props.id, item.key);
  };

  const onUpdateExclusion = (setting) => {
    const pageIndex = pages.findIndex(($0) => $0.key == props.id);
    pages[pageIndex].isExcluded = setting.key;
    printPageActions.handleUpdatePageOrder(pages);

    const request = new RequestUpdatePrintStatus();
    request.propertyId = property.id;
    request.key = 'image';
    request.isIncluded = setting.key == defaultValues.emptyInt;
    request.imageId = pages[pageIndex].key;

    documentService.updatePrintStatus(
      request,
      () => {
        return;
      },
      () => {
        return;
      }
    );
  };

  const onImageChange = (index, imageFile) => {
    const formData = new FormData();
    formData.append('file', imageFile);
    formData.append('type', 'document');

    const _imagePages = [...imagePages];
    const pageIndex = _imagePages.findIndex(($0) => $0.id == props.id);
    const _imagePaths = [..._imagePages[pageIndex].imagePaths];

    etcService.uploadImage(formData, (response) => {
      _imagePaths[index].id = response.id;
      _imagePaths[index].url = response.imageUrl;
      _imagePages[pageIndex].imagePaths = _imagePaths;

      const request = new RequestUpdateImagePath();
      request.imageId = props.id;
      request.ids = _imagePaths.map((imagePath) => imagePath.id);
      documentService.updateImagePath(
        request,
        () => {
          printPageActions.updateImagePages(_imagePages);
        },
        () => {
          return;
        }
      );
    });
  };

  return {
    countSettings,
    exclusionOptions,
    onChangeTitle,
    onImageChange,
    onUpdateExclusion,
    onUpdateImageCount,
    onCheckInput,
    onChangeImageSize,
    setupSizeSettingOptions,
    getIsFormDisabled,
  };
};
export default useImagePageInput;
