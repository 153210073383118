import React from 'react';
import TableList from 'components/core/table-list/TableList';
import MemoContainer from 'components/MemoForm/MemoContainer';
import Spaces from 'constants/spaces';
import titleStrings from 'localizations/titleStrings';
import * as styled from './EmployeeListPage.styled';
import useEmployeeListPage from './useEmployeeListPage';
import EmployeePageHeader from 'layouts/EmployeeLayouts/EmployeePageHeader';
import sizes from 'constants/sizes';
import { WhiteButtonStyle } from 'components/core/button/styles';
import buttonStrings from 'localizations/buttonStrings';
import AddPersonIconBlack from 'assets/add_person_icon_black.png';
import Button from 'components/core/button/Button';

const EmployeeListPage = () => {
  const hook = useEmployeeListPage();

  const renderCreateEmployeeButton = () => {
    return (
      <Button
        action={() => hook.showEmployeeForm()}
        height={sizes.button.small}
        icon={AddPersonIconBlack}
        style={WhiteButtonStyle}
        title={buttonStrings.createMember}
      />
    );
  };

  return (
    <styled.Container>
      <EmployeePageHeader
        pagination={hook.pagination}
        onPageChange={hook.onPageChange}
        onStartSearchAction={hook.onStartSearchEmployee}
        onStartDelete={hook.onStartDeleteEmployee}
        customButtons={[renderCreateEmployeeButton()]}
      />
      <styled.TableContainer>
        <TableList
          className="col-span-3"
          isClickable={true}
          handleAction={hook.onSelectEmployee}
          datas={hook.setupEmployeeRows()}
          firstColumnPadding={Spaces.small}
          headers={hook.setupEmployeeHeaders()}
        />
        <MemoContainer
          title={titleStrings.memo}
          value={hook.selectedEmployee.memo}
          onSaveMemo={hook.onSaveMemo}
          onValueChange={hook.onMemoValueChange}
        />
      </styled.TableContainer>
    </styled.Container>
  );
};

export default EmployeeListPage;
