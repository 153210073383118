import React, { useState } from 'react'
import PropTypes from 'prop-types';
import titleStrings from 'localizations/titleStrings';
import AlertDialogModal from './AlertDialogModal';
import dialogMessageStrings from 'localizations/dialogMessageStrings';
import Button from 'components/core/button/Button';
import sizes from 'constants/sizes';
import {
    DarkGrayButtonStyle,
    WhiteButtonStyle
} from 'components/core/button/styles';
import buttonStrings from 'localizations/buttonStrings';
import zIndex from 'constants/zIndex';
import { observer } from 'mobx-react';
import { useDispatch } from 'react-redux';
import { ButtonViewModel } from 'components/core/button/ButtonViewModel';
import { commonActionTypes, customerActionTypes } from 'redux/types';
import { RequestCreateCustomer } from 'models/requests/RequestCreateCustomer';
import { customerAction } from 'redux/actions';

const CreateCustomerConfirmationModal = observer((props) => {
    const [mainButtonViewModel] = useState(new ButtonViewModel());
    const dispatch = useDispatch();

    const onStartCreateCustomer = () => {
        mainButtonViewModel.startLoading()
        dispatch({
            type: customerActionTypes.CREATE_CUSTOMER,
            payload: customerAction.createCustomer(
                props.requestCreateCustomer,
                didCreateCustomerSucceed
            )
        });
    }

    const didCreateCustomerSucceed = () => {
        mainButtonViewModel.stopLoading();
        dispatch({
            type: commonActionTypes.HIDE_MODAL
        });
        props.onCreateCustomerSuccess();
    }

    const hideModal = () => {
        dispatch({
            type: commonActionTypes.HIDE_MODAL
        });
    }

    const confirmationPrimaryButton = () => {
        return (
            <Button
                action={ onStartCreateCustomer }
                height={ sizes.button.small }
                style={ WhiteButtonStyle }
                title={ buttonStrings.yes }
                viewModel={ mainButtonViewModel }/>
        );
    }

    const confirmationSecondaryButton = () => {
        return (
            <Button
                action={ hideModal }
                height={ sizes.button.small }
                style={ DarkGrayButtonStyle }
                title={ buttonStrings.no } />
        );
    }

    return (
        <AlertDialogModal
            isShowing={ true }
            zIndex={ zIndex.createCustomerConfirmation }
            title={ titleStrings.notice }
            subtitle={ dialogMessageStrings.createCustomerConfirmation }
            primaryButton={ confirmationPrimaryButton() }
            secondaryButton={ confirmationSecondaryButton() } />
    )
});

CreateCustomerConfirmationModal.propTypes = {
    onCreateCustomerSuccess: PropTypes.func,
    requestCreateCustomer: PropTypes.object
};

CreateCustomerConfirmationModal.defaultProps = {
    onCreateCustomerSuccess: () => { return; },
    requestCreateCustomer: new RequestCreateCustomer()
};

export default CreateCustomerConfirmationModal;