export class RequestUpdateImageTitle {
    constructor(imageId, title) {
        this.imageId = imageId;
        this.title = title;
    }

    mapToJson() {
        return {
            'image_id': this.imageId,
            'title': this.title
        }
    }
}