import styled from "styled-components";
import tw from "twin.macro";

const FieldContainer = styled.div`
    ${ tw`grid grid-cols-3 text-left` }
`;
const FieldColumnText = styled.div`
    color: ${ (props) => props.colorInHex };
    text-align: ${ (props) => props.alignment };
    ${ (props) => props.isBold && tw`font-extrabold` }
    ${ 
        (props) => props.column == 1
            ? tw`col-span-1`
            : tw`col-span-2` 
    }
`;

export {
    FieldContainer,
    FieldColumnText
}