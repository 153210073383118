import React, { useEffect, useState } from 'react';
import Button from '../../components/core/button/Button';
import PropTypes from 'prop-types';
import { DarkGrayButtonStyle } from 'components/core/button/styles';
import Sizes from 'constants/sizes';
import { errorMessagesStrings, FieldStrings } from 'localizations';
import HorizontalTextInput from 'components/core/horizontal-text-input/HorizontalTextInput';
import tw from 'twin.macro';
import styled from 'styled-components';
import buttonType from 'enumerations/buttonType';
import sizes from 'constants/sizes';
import DropdownInput from 'components/core/dropdown-input/DropdownInput';
import { BorderedDropdownInputStyle } from 'components/core/dropdown-input/styles';
import cookiesManager from 'managers/cookies.manager';
import * as defaultValues from 'constants/defaultValue';
import { Employee } from 'models/objects/Employee';
import { observer } from 'mobx-react-lite';
import { ButtonViewModel } from 'components/core/button/ButtonViewModel';
import { useDispatch } from 'react-redux';
import {
  commonActionTypes,
  customerActionTypes,
  employeeActionTypes,
} from 'redux/types';
import { customerAction, employeeAction } from 'redux/actions';
import {
  RequestFetchCustomerList,
  RequestFetchEmployeeList,
} from 'models/requests';
import buttonStrings from 'localizations/buttonStrings';
import { RequestCreateCustomer } from 'models/requests/RequestCreateCustomer';
import CreateCustomerConfirmationModal from 'layouts/modals/CreateCustomerConfirmationModal';
import zIndex from 'constants/zIndex';
import { Message } from 'models/objects/Message';
import { RequestCheckCustomer } from 'models/requests/RequestCheckCustomer';
import Text from 'components/core/text/Text';
import HighlightedRedTextStyle from 'components/core/text/styles/HighlightedRedTextStyle';
import paddingPositions from 'enumerations/paddingPositions';
import spaces from 'constants/spaces';

const InputContainer = styled.div`
  ${tw`grid grid-cols-2 my-5 gap-y-3 gap-x-12`}
`;

const CreateCustomerForm = observer((props) => {
  const [name, setName] = useState(defaultValues.emptyString);
  const [mobilePhone, setMobilePhone] = useState(defaultValues.emptyString);
  const [companyPhone, setCompanyPhone] = useState(defaultValues.emptyString);
  const [selectedManager, setSelectedManager] = useState(() => {
    const currentUser = cookiesManager.getCurrentUser();
    return currentUser;
  });
  const [managers, setManagers] = useState([]);
  const [errorMessage, setErrorMessage] = useState(defaultValues.emptyString);
  const [isUniqueData, setIsUniqueData] = useState(false);
  const [buttonViewModel] = useState(new ButtonViewModel());
  const dispatch = useDispatch();

  const renderNameInput = () => {
    return (
      <HorizontalTextInput
        name={'name'}
        title={FieldStrings.representativeName}
        onChange={(input) => setName(input.value)}
        value={name}
      />
    );
  };

  const renderPhoneInput = () => {
    return (
      <HorizontalTextInput
        name={'phone'}
        title={FieldStrings.phone}
        onChange={(input) => setMobilePhone(input.value)}
        value={mobilePhone}
      />
    );
  };

  const renderCompanyPhoneInput = () => {
    return (
      <HorizontalTextInput
        name={'companyPhone'}
        title={FieldStrings.companyPhone}
        onChange={(input) => setCompanyPhone(input.value)}
        value={companyPhone}
      />
    );
  };

  const setupEmployeeOptionItems = () => {
    return managers.map((manager) => {
      return {
        key: manager.id,
        value: manager.name,
      };
    });
  };

  const handlerSelectEmployee = (item) => {
    const selectedEmployee = managers.find(
      (employee) => employee.id == item.key
    );
    setSelectedManager(selectedEmployee);
    props.onEmployeeSelected(selectedEmployee);
  };

  const renderManagerInput = () => {
    return (
      <DropdownInput
        name={'manager'}
        labelTitle={FieldStrings.manager}
        selectedOption={{
          key: selectedManager.id,
          value: selectedManager.name,
        }}
        options={setupEmployeeOptionItems()}
        handleSelectOption={handlerSelectEmployee}
        style={BorderedDropdownInputStyle}
      />
    );
  };

  const onTapMainButton = () => {
    setErrorMessage(defaultValues.emptyString);
    if (name.isEmpty() && mobilePhone.isEmpty() && companyPhone.isEmpty()) {
      setErrorMessage(errorMessagesStrings.pleaseFillTheBlanks);
      return;
    }
    if (isUniqueData) {
      showCreateWorkHistoryModal();
      return;
    }
    buttonViewModel.startLoading();
    onStartSearchCustomer();
  };

  const showCreateWorkHistoryModal = () => {
    dispatch({
      type: commonActionTypes.SHOW_MODAL,
      payload: {
        zIndex: zIndex.createCustomerConfirmation,
        content: (
          <CreateCustomerConfirmationModal
            requestCreateCustomer={
              new RequestCreateCustomer(
                selectedManager.id,
                name,
                mobilePhone,
                companyPhone
              )
            }
            onCreateCustomerSuccess={onCreateCustomerSuccess}
          />
        ),
      },
    });
  };

  const onCreateCustomerSuccess = () => {
    dispatch({
      type: commonActionTypes.SHOW_SUCCESS_MESSAGE,
      payload: new Message(
        '등록완료',
        '등록이 완료되었습니다.',
        props.onCreateCustomerSuccess
      ),
    });
    return;
  };

  const onStartSearchCustomer = () => {
    props.onFetchCustomerSuccess(defaultValues.emptyArray);
    dispatch({
      type: customerActionTypes.SEARCH_CUSTOMER_LIST,
      payload: customerAction.checkDuplication(
        new RequestCheckCustomer(mobilePhone, companyPhone),
        didFetchCustomerListSucceed
      ),
    });
  };

  const didFetchCustomerListSucceed = (response) => {
    buttonViewModel.stopLoading();
    if (response.customers.isEmpty()) {
      buttonViewModel.setTitle(buttonStrings.input);
      setIsUniqueData(true);
      return;
    }
    props.onFetchCustomerSuccess(response.customers);
  };

  const renderSearchButton = () => {
    return (
      <Button
        action={onTapMainButton}
        height={Sizes.button.small}
        style={DarkGrayButtonStyle}
        type={buttonType.submit}
        width={sizes.button.full}
        viewModel={buttonViewModel}
      />
    );
  };

  const setupButtonViewModel = () => {
    buttonViewModel.setTitle(buttonStrings.checkDuplication);
  };

  const loadData = () => {
    dispatch({
      type: employeeActionTypes.FETCH_EMPLOYEE_LIST,
      payload: employeeAction.fetchEmployeeList(
        new RequestFetchEmployeeList(),
        didFetchEmployeeListSucceed
      ),
    });
  };

  const didFetchEmployeeListSucceed = (response) => {
    if (!response.employees.isEmpty()) {
      setSelectedManager(cookiesManager.getCurrentUser());
    }
    setManagers(response.employees);
  };

  useEffect(() => {
    setupButtonViewModel();
    loadData();
  }, []);

  return (
    <div>
      <Text
        margin={{
          position: paddingPositions.top,
          value: spaces.small,
        }}
        style={HighlightedRedTextStyle}
        value={errorMessage}
      />
      <InputContainer>
        {renderNameInput()}
        {renderPhoneInput()}
        {renderCompanyPhoneInput()}
        {renderManagerInput()}
      </InputContainer>
      {renderSearchButton()}
    </div>
  );
});

CreateCustomerForm.propTypes = {
  onFetchCustomerSuccess: PropTypes.func,
  onCreateCustomerSuccess: PropTypes.func,
  onEmployeeSelected: PropTypes.func,
};

CreateCustomerForm.defaultProps = {
  onFetchCustomerSuccess: defaultValues.emptyFunction,
  onCreateCustomerSuccess: defaultValues.emptyFunction,
  onEmployeeSelected: defaultValues.emptyFunction,
};

export default CreateCustomerForm;
