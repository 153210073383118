import { defaultValues } from 'constants';

const useImagePrintPage = (propsImagePaths) => {
  const getImageUrl = (index) => {
    if (index > propsImagePaths.length - 1) {
      return;
    }
    return propsImagePaths[index].url;
  };

  return { getImageUrl };
};
export default useImagePrintPage;
