import DropdownInputViewModel from "components/core/dropdown-input/DropdownInputViewModel";
import defaultValues from "constants/defaultValues";
import cardStatus from "enumerations/cardStatus";
import searchFormTypes from "enumerations/searchFormTypes";
import { LandUsage } from "models/objects/LandUsage";
import { RequestSearchProperty } from "models/requests/RequestSearchProperty";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import cardActions from "redux/actions/cardActions";
import * as searchActions from "redux/reducers/searchReducer";
import searchActionTypes from "redux/types/searchActionTypes";

const SearchFormLayoutPresenter = () => {
    const [usageItems, setUsageItems] = useState(defaultValues.emptyArray)
    const [
        requestSearchProperty,
        setRequestSearchProperty
    ] = useState(new RequestSearchProperty())
    const dispatch = useDispatch();
    const history = useHistory();
    const searchReducer = useSelector(state => state.search);
    const getIsStatusCheck = (status) => {
        const cardStatuses = cardStatus.statuses.filter(x => x !== undefined)
        const isSelectedAll = requestSearchProperty.status.length == cardStatuses.length;
        if (status === undefined && isSelectedAll) {
            return true;
        }
        return requestSearchProperty.status.some(x => x == status);
    }

    const loadData = () => {
        cardActions.fetchLandUsages(
            didFetchUsagesSucceed
        )
    }

    const didFetchUsagesSucceed = (response) => {
        const usageItems = response.map(
            (usage) => {
                const dropDownViewModel = new DropdownInputViewModel()
                dropDownViewModel.key = usage.id
                dropDownViewModel.value = usage.name
                return dropDownViewModel
            }
        );
        setUsageItems(usageItems);
    }

    const resetState = () => {
        setRequestSearchProperty(
            new RequestSearchProperty()
        )
    }
    
    const onSelectUsage = (item) => {
        onInputChange(
            {
                name: searchFormTypes.enums.usage,
                value: new LandUsage(
                    item.key,
                    item.value
                )
            }
        )
    }

    const onInputChange = (input) => {
        let _requestSearchProperty = Object.assign(
            new RequestSearchProperty(),
            requestSearchProperty
        );
        _requestSearchProperty[input.name] = input.value;
        searchActions.updateSearchRequest(
            _requestSearchProperty
        );
        setRequestSearchProperty(_requestSearchProperty);
    }

    const onRoadCheckboxChange = (isChecked, name) => {
        onInputChange(
            {
                name: name,
                value: isChecked
            }
        )
    }

    const checkSelectedStatus = (status) => {
        let selectedStatus = searchReducer.requestSearchProperty.status;
        if (status === undefined) {
            const cardStatuses = cardStatus.statuses.filter(x => x != undefined).map(x => x);
            return cardStatuses.length == selectedStatus.length
                ? []
                : cardStatuses
        }
        let index = selectedStatus.findIndex(x => x === status);
        if (index >= defaultValues.emptyInt) {
            selectedStatus.splice(index, 1);
            return selectedStatus;
        }
        selectedStatus.push(status);
        return selectedStatus;
    }

    const onStatusChange = (status) => {
        onInputChange(
            {
                name: searchFormTypes.enums.status,
                value: checkSelectedStatus(status)
            }
        )
    }

    const onListenNavigation = (location) => {
        if (history.action == "POP" 
            || location.pathname == "/search/detail") { return; }
        dispatch({
            type: searchActionTypes.UPDATE_SEARCH_REQUEST,
            payload: new RequestSearchProperty()
        })
    }

    useEffect(
        () => {
            loadData();
            setRequestSearchProperty(
                searchReducer.requestSearchProperty
            )
            const navigationListener = history.listen(onListenNavigation)
            return () => {
                navigationListener()
            }
        },
        defaultValues.emptyArray
    )

    return {
        resetState,
        getIsStatusCheck,
        onInputChange,
        onStatusChange,
        onRoadCheckboxChange,
        onSelectUsage,
        usageItems,
        requestSearchProperty
    }
}

export default SearchFormLayoutPresenter;