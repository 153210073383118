import defaultValues from "constants/defaultValues";

export class RequestUpdateCustomerMemo {
    constructor(
        customerId = defaultValues.emptyString,
        memo = defaultValues.emptyString
    ) {
        this.customerId = customerId;
        this.memo = memo;
    }

    mapToJson() {
        return {
            'id': this.customerId,
            'memo': this.memo
        }
    }
}