import { ButtonViewModel } from "components/core/button/ButtonViewModel";
import * as defaultValues from "constants/defaultValue";
import { Employee } from "models/objects/Employee";
import { Role } from "models/objects/Role";
import { RequestCreateEmployee } from "models/requests/RequestCreateEmployee";
import { RequestUpdateEmployee } from "models/requests/RequestUpdateEmployee";
import { useEffect } from "react";
import { useState } from "react";
import employeeService from "redux/actions/employeeAction";
import * as employeeActions from "redux/reducers/employeeReducer";
import * as commonActions from "redux/reducers/commonReducer";

const useCreateEmployeeForm = () => {
    const buttonViewModel = new ButtonViewModel();
    const [employee, setEmployee] = useState(new Employee());
    const [roles, setRoles] = useState(defaultValues.emptyArray);
    const [selectedRole, setSelectedRole] = useState(defaultValues.emptyArray);
    const [
        onCreateEmployeeSuccess,
        setOnCreateEmployeeSuccess
    ] = useState(() => () => { return; });
    const [errorMessage, setErrorMessage] = useState(defaultValues.emptyString);

    const onInputChange = (input) => {
        const _employee = Object.assign(
            new Employee(),
            employee
        )
        _employee[input.name] = input.value;
        setEmployee(_employee);
    }

    const onSelectRole = (item) => {
        setSelectedRole(item);
        onInputChange({
            name: "role",
            value: new Role(
                item.key,
                item.value
            )
        })
    }

    const onInputRole = (input) => {
        onInputChange({
            name: input.name,
            value: new Role(
                defaultValues.emptyInt,
                input.value
            )
        })
    }

    const fetchAllRoles = () => {
        employeeService.fetchAllRoleList(
            didFetchRolesSucceed
        )
    }

    const didFetchRolesSucceed = (response) => {
        setRoles(response.roles)
    }

    const onStartCreateEmployee = () => {
        buttonViewModel.startLoading();
        setErrorMessage(defaultValues.emptyString)
        if (employee.id.isEmpty()) {
            createEmployee()
            return;
        }
        updateEmployee();
    }

    const updateEmployee = () => {
        employeeService.updateEmployee(
            new RequestUpdateEmployee(
                employee
            ),
            didSubmissionSucceed
        )
    }

    const createEmployee = () => {
        employeeService.createEmployee(
            new RequestCreateEmployee(
                employee
            ),
            didSubmissionSucceed,
            didSubmissionFailed
        )
    }

    const didSubmissionSucceed = () => {
        buttonViewModel.stopLoading();
        commonActions.hideModal();
        employeeActions.reloadData();
    }

    const didSubmissionFailed = (response) => {
        buttonViewModel.stopLoading();
        setErrorMessage(
            response.message.korean
        );
    }

    useEffect(
        () => fetchAllRoles(),
        defaultValues.emptyArray
    )

    return {
        setEmployee,
        setOnCreateEmployeeSuccess,
        onInputChange,
        onSelectRole,
        onStartCreateEmployee,
        onInputRole,
        employee,
        buttonViewModel,
        roles,
        selectedRole,
        errorMessage
    }
}

export default useCreateEmployeeForm;