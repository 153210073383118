import React from 'react';
import * as styled from './HorizontalLabeledText.styled';
import PropTypes from 'prop-types';
import colors from 'constants/colors';
import defaultValues from 'constants/defaultValues';
import alignments from 'constants/alignments';

const HorizontaLabeledText = (props) => {
  const renderValues = () => {
    if (props.values.length == 1) {
      return (
        <styled.FieldColumnText
          colorInHex={props.valueColorInHex}
          isBold={props.isValueBold}>
          {props.values[defaultValues.emptyInt]}
        </styled.FieldColumnText>
      );
    }
    return props.values.map((value, index) => {
      return (
        <styled.FieldColumnText
          key={index}
          alignment={index == 1 && alignments.right}
          column={1}>
          {value}
        </styled.FieldColumnText>
      );
    });
  };

  return (
    <styled.FieldContainer>
      <styled.FieldColumnText
        column={1}
        className={props.isTitleBold && 'font-bold'}>
        {props.title}
      </styled.FieldColumnText>
      {renderValues()}
    </styled.FieldContainer>
  );
};

HorizontaLabeledText.propTypes = {
  title: PropTypes.string,
  isTitleBold: PropTypes.bool,
  isValueBold: PropTypes.bool,
  valueColorInHex: PropTypes.string,
  values: PropTypes.array,
};

HorizontaLabeledText.defaultProps = {
  title: defaultValues.emptyString,
  isTitleBold: false,
  isValueBold: false,
  valueColorInHex: colors.black,
  values: [],
};

export default HorizontaLabeledText;
