import { store } from 'configs/store';
import * as defaultValues from 'constants/defaultValue';
import { Card } from 'models/objects/Card';
import { Pagination } from 'models/objects/Pagination';
import { RequestCreateCard } from 'models/requests/RequestCreateCard';
import { RequestFetchCard } from 'models/requests/RequestFetchCard';
import cardActions from 'redux/actions/cardActions';
import cardActionTypes from 'redux/types/cardActionTypes';
import searchActions from 'redux/actions/searchActions';
import { RequestSearchProperty } from 'models/requests/RequestSearchProperty';
import formStates from 'enumerations/formStates';
import queryString from 'query-string';
import { RequestPagination } from 'models/requests/RequestPagination';
import cardStatus from 'enumerations/cardStatus';
import cookiesManager from 'managers/cookies.manager';

const SETUP_PROPERTY = "card/SETUP_PROPERTY";
const SETUP_INITIAL_PAGE = "card/SETUP_INITIAL_PAGE";
const UPDATE_PAGINATION = "card/UPDATE_PAGINATION";
const START_CREATE_PROPERTY = "card/START_CREATE_PROPERTY";
const CHANGE_FORM_STATE = "card/CHANGE_FORM_STATE";
const UPDATE_PRICE = "card/UPDATE_PRICE";
const REVERT_PAGINATION = "card/REVERT_PAGINATION";

const states = (state, action) => {
    return {
        [cardActionTypes.INQUIRY_PROPERTY_CARD]: () => {
            const response = action.payload.data;
            const property = new Card().mapFromJson(response['data']);
            return {
                ...state,
                card: property,
                price: property.priceInformation.totalPrice,
                pagination: new Pagination(
                    state.pagination.currentPage,
                    response.totalItems,
                    response.totalPages
                )
            }
        },
        [SETUP_PROPERTY]: () => {
            const property = action.payload.card;
            return {
                ...state,
                card: property,
                price: property.priceInformation.totalPrice,
                pagination: action.payload.pagination
            }
        },
        [UPDATE_PAGINATION]: () => {
            return {
                ...state,
                pagination: action.payload
            }
        },
        [SETUP_INITIAL_PAGE]: () => {
            return {
                ...state,
                initialPage: action.payload
            }
        },
        [cardActionTypes.UPDATE_WRITE_CARD_REQUEST]: () => {
            return {
                ...state,
                requestWriteCard: action.payload ?? new RequestCreateCard()
            }
        },
        [cardActionTypes.RESET_PROPERTY_CARD]: () => {
            return {
                ...state,
                card: new Card()
            }
        },
        [cardActionTypes.RESET_STATE]: () => {
            return {
                ...state,
                card: new Card(),
                pagination: new Pagination(),
                formState: formStates.read
            }
        },
        [cardActionTypes.UPDATE_SELECTED_PROPERTY]: () => {
            return {
                ...state,
                card: action.payload.card,
                pagination: action.payload.pagination
            }
        },
        [cardActionTypes.UPDATE_PAGINATION]: () => {
            return {
                ...state,
                pagination: action.payload
            }
        },
        [cardActionTypes.UPDATE_SEARCHED_CARD]: () => {
            return {
                ...state,
                pagination: action.payload
            }
        },
        [START_CREATE_PROPERTY]: () => {
            return {
                ...state,
                oldCurrentPage: action.payload.oldCurrentPage,
                pagination: action.payload.pagination
            }
        },
        [CHANGE_FORM_STATE]: () => {
            return {
                ...state,
                formState: action.payload
            }
        },
        [UPDATE_PRICE]: () => {
            return {
                ...state,
                price: action.payload
            }
        },
        [REVERT_PAGINATION]: () => {
            return {
                ...state,
                initialPage: action.payload.currentPage,
                pagination: action.payload.pagination
            }
        }
    }
}

export default (state = {
    requestWriteCard: new RequestCreateCard()
}, action) => {
    if (!(action.type in states(state, action))) {
        return {
            ...state,
            card: state.card || new Card(),
            totalPages: state.totalPages || defaultValues.emptyInt,
            selectedProperty: state.selectedProperty,
            pagination: state.pagination || new Pagination(defaultValues.page),
            initialPage: state.initialPage || defaultValues.page,
            oldCurrentPage: state.oldCurrentPage || defaultValues.page,
            formState: state.formState || formStates.read,
            price: state.price || defaultValues.emptyInt
        };
    }
    return states(state, action)[action.type]();
}

export const resetCard = () => ({
    type: cardActionTypes.RESET_PROPERTY_CARD
})

export const flushData = () => (
   store.dispatch(
        {
            type: cardActionTypes.RESET_STATE
        }
   )
)

export const updateCurrentPage = (currentPage) => {
    let pagination = store.getState().card.pagination;
    pagination.currentPage = currentPage;
    updatePagination(pagination);
}

export const updatePagination = (pagination) => {
    store.dispatch(
        {
            type: UPDATE_PAGINATION,
            payload: pagination
        }
    )
}

export const onCancelCreateProperty = () => {
    let pagination = store.getState().card.pagination;
    pagination.totalItems--;
    pagination.totalPages--;
    setupInitialPage(pagination.currentPage);
    store.dispatch(
        {
            type: UPDATE_PAGINATION,
            payload: pagination
        }
    )
}

export const inquiryProperty = (location, currentPage) => {
    const query = queryString.parse(location.search);
    if (location.pathname == "/search/detail") {
        let request = Object.assign(
            new RequestSearchProperty(),
            store.getState().search.requestSearchProperty
        );
        request.pagination = new Pagination();
        request.pagination.page = query.page;
        request.pagination.pageSize = defaultValues.pageSize;
        searchActions.fetchSearchProperty(
            request,
            (response) => {
                let pagination = response.pagination;
                pagination.currentPage = currentPage
                setupProperty(
                    response.properties.first(),
                    pagination
                )
            }
        )
        return;
    }
    if (location.pathname == "/search/history") {
        const currentPage = Number(query.page);
        setupInitialPage(currentPage);
        searchActions.fetchSearchHistories(
            new RequestPagination(
                currentPage,
                defaultValues.pageSize
            ),
            (response) => {
                if (response.propertyHistories.isEmpty()) { return; }
                let pagination = response.pagination;
                pagination.currentPage = currentPage;
                setupProperty(
                    response.propertyHistories.first().property,
                    pagination
                )
            }
        );
        return;
    }
    store.dispatch(
        {
            type: cardActionTypes.INQUIRY_PROPERTY_CARD,
            payload: cardActions.inquiryPropertyCard(
                new RequestFetchCard(currentPage)
            )
        }
    )
}

export const inquirySaleProperty = (currentPage) => {
    store.dispatch(
        {
            type: cardActionTypes.INQUIRY_PROPERTY_CARD,
            payload: cardActions.inquiryPropertyCard(
                new RequestFetchCard(currentPage)
            )
        }
    )
}

export const reloadData = (location) => {
    flushData();
    inquiryProperty(location);
}

export const setupProperty = (property, pagination) => {
    store.dispatch({
        type: SETUP_PROPERTY,
        payload: {
            card: property,
            pagination: pagination
        }
    })
}

export const updateRequestCreateCard = (requestCreateCard) => {
    store.dispatch(
        {
            type: cardActionTypes.UPDATE_WRITE_CARD_REQUEST,
            payload: requestCreateCard
        }
    )
}

export const setupInitialPage = (initialPage) => {
    store.dispatch(
        {
            type: SETUP_INITIAL_PAGE,
            payload: initialPage
        }
    )
}

export const createPropertySucceed = (
    property
) => {
    setupProperty(
        property,
        store.getState().card.pagination
    );
}

export const revertPagination = (oldPageNumber) => {
    var pagination = store.getState().card.pagination;
    pagination.totalPages = pagination.totalPages - 1;
    pagination.currentPage = oldPageNumber;
    store.dispatch(
        {
            type: REVERT_PAGINATION,
            payload: {
                currentPage: pagination.totalPages,
                pagination: pagination
            }
        }
    )
}

export const deletePropertySucceed = () => {
    const pagination = store.getState().card.pagination;
    pagination.totalPages = pagination.totalPages - 1;
    pagination.totalItems = pagination.totalPages;
    setupInitialPage(pagination.currentPage);
    updatePagination(pagination);
}

export const changeFormState = (formState) => {
    if (formState == formStates.create) {
        const request = store.getState().card.requestWriteCard;
        request.manager = cookiesManager.getCurrentUser();
        updateRequestCreateCard(request)
    }
    store.dispatch(
        {
            type: CHANGE_FORM_STATE,
            payload: formState
        }
    );
}

export const updatePrice = (price) => {
    store.dispatch(
        {
            type: UPDATE_PRICE,
            payload: Number(price)
        }
    )
}

export const onMergeFloorInformationColumn = (floorInformations) => {
    const request = store.getState().card.requestWriteCard;
    request.floorInformations = floorInformations;
    store.dispatch(
        {
            type: cardActionTypes.UPDATE_WRITE_CARD_REQUEST,
            payload: request
        }
    )
}

export const onMergeRentalColumn = (rentalHistories) => {
    const request = store.getState().card.requestWriteCard;
    request.rentalHistories = rentalHistories;
    store.dispatch(
        {
            type: cardActionTypes.UPDATE_WRITE_CARD_REQUEST,
            payload: request
        }
    )
}

export const reloadSearchData = (request, onSuccess) => {
    searchActions.fetchSearchProperty(
      request,
        (response) => {
            let pagination = response.pagination;
            pagination.currentPage = pagination.totalPages;
            pagination.totalPages = pagination.totalItems;
            setupProperty(
                response.properties.first(),
                pagination
            )
            onSuccess(response);
        }
    )
}