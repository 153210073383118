import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { etcService } from 'services';
import { RequestGetImage } from 'models/requests';
import defaultValues from 'constants/defaultValues';

const useMainPrintPage = () => {
  const property = useSelector((state) => state.printProperty.property);
  const [imageUrl, setImageUrl] = useState();

  const getImageUrl = () => {
    if (property.images.length == defaultValues.emptyInt) {
      return defaultValues.emptyString;
    }
    return property.images[0].url.includes('document')
      ? imageUrl
      : property.images[0].url;
  };

  useEffect(() => {
    if (property.images < 1) {
      return;
    }
    const request = new RequestGetImage();
    request.type = 'document';
    request.fileName = property.images[0].url.split('/').at(-1);
    etcService.getImage(
      request,
      (imageUrl) => setImageUrl(imageUrl),
      (error) => {
        return;
      }
    );
  }, [property.images]);

  return {
    getImageUrl,
  };
};

export default useMainPrintPage;
