import * as defaultValues from "constants/defaultValue";
import ImagePageSize from "enumerations/ImagePageSize";

export default class ImagePageInputProperty {
    id = defaultValues.emptyString;
    title = defaultValues.emptyString;
    size = ImagePageSize.original;
    imagePaths = [];
    isPrintable = true;

    constructor(id, title, imagePaths, isPrintable) {
        this.id = id;
        this.title = title;
        this.imagePaths = imagePaths;
        this.isPrintable = isPrintable
    }
}