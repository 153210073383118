import styled from "styled-components";
import tw from "twin.macro";

const Container = styled.div`
`;
const TopContainer = styled.div`
    ${ tw`flex gap-8 mb-10` }
`;
const RegularCardWrapper = styled.div`
    ${ tw`bg-red-500` }
`;
const TopSideContainer = styled.div`
    ${ tw`flex flex-col` }
`;
const TopCenterContainer = styled.div`
    width: 60%;
    ${ tw`grid grid-cols-2 gap-6 flex-1` }
`;
const BottomContainer = styled.div`
    ${ tw`grid grid-cols-3 gap-6 mb-10` }
`;
const BottomLeadingWrapper = styled.div`
    ${ tw`col-span-1` }
`;
const BottomTrailingWrapper= styled.div`
    ${ tw`col-span-2` }
`;
const FormContainer = styled.div`
    min-width: calc(${ props => props.width }px - 4rem);
    ${ tw`flex flex-col gap-8` }
`;

export {
    Container,
    TopContainer,
    FormContainer,
    RegularCardWrapper,
    TopSideContainer,
    TopCenterContainer,
    BottomContainer,
    BottomLeadingWrapper,
    BottomTrailingWrapper
}