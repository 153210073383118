import React from 'react';
import * as styled from './SearchPage.styled';
import SearchFormLayout from 'layouts/SearchLayouts/SearchFormLayout/SearchFormLayout';
import SearchHistoryLayout from 'layouts/SearchLayouts/SearchHistoryLayout/SearchHistoryLayout';
import SearchResultLayout from 'layouts/SearchLayouts/SearchResultLayout/SearchResultLayout';
import SearchResultLayoutPresenter from 'layouts/SearchLayouts/SearchResultLayout/SearchResultLayoutPresenter';
import { useSelector } from 'react-redux';
import * as searchActions from 'redux/reducers/searchReducer';
import defaultValues from 'constants/defaultValues';
import { RequestSearchProperty } from 'models/requests/RequestSearchProperty';

const SearchPage = () => {
    const searchResultPresenter = SearchResultLayoutPresenter()
    const { requestSearchProperty } = useSelector(state => state.search);

    return (
        <styled.Container>
            <styled.FormContainer>
                <SearchFormLayout
                    onResetForm={ searchResultPresenter.resetData }
                    onStartSearch={
                        () => {
                            const request = Object.assign(
                                new RequestSearchProperty(),
                                requestSearchProperty
                            )
                            request.pagination.page = defaultValues.emptyInt;
                            searchActions.onChangePage(defaultValues.emptyInt);
                            searchResultPresenter.reloadData(
                                requestSearchProperty
                            )
                        }
                    }/>
                <SearchHistoryLayout />
            </styled.FormContainer>
            <styled.TableContainer>
                <SearchResultLayout
                    presenter={ searchResultPresenter }/>
            </styled.TableContainer>
        </styled.Container>
    )
}

export default SearchPage;