import LocalizedStrings from 'react-localization';

export default new LocalizedStrings({
    en:{
        createCustomerConfirmation: "Are you sure to add customer?",
        customerAdded: "Cusstomer is added"
    },
    kr: {
        createCustomerConfirmation: "신규 등록하시겠습니까?",
        customerAdded: "등록이 완료되었습니다"
    }
});
