import { RequestCreateCard } from "models/requests/RequestCreateCard";
import { useState } from "react";

const useLandFormFormPresenter = () => {
    const [requestWriteCard, setRequestWriteCard] = useState(new RequestCreateCard());

    const getLandUsages = () => {
        let landUsages = requestWriteCard.landSpesificInformations.map(
            landSpesificInformation => landSpesificInformation.usage.name
        ).filter(x => x !== "");
        landUsages = new Set(landUsages); 
        return Array.from(landUsages).join(', ');
    }

    const getLandCategories = () => {
        let landCategories = requestWriteCard.landSpesificInformations.map(
            landSpesificInformation => landSpesificInformation.category
        ).filter(x => x !== "");
        landCategories = new Set(landCategories);
        return Array.from(landCategories).join(', ');
    }

    return {
        getLandUsages,
        getLandCategories,
        setRequestWriteCard
    }
}
export default useLandFormFormPresenter;