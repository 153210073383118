import { ResponseFetchEmployeeList } from "models/responses";
import { ResponseFetchCardLogList } from "models/responses/ResponseFetchCardLogList";
import { ResponseFetchEmployeeLoginHistory } from "models/responses/ResponseFetchEmployeeLoginHistory";
import { ResponseFetchRoleList } from "models/responses/ResponseFetchRoleList";
import BaseAction from "./baseAction"

const basePath = "/v1/employee";
export default {
    fetchEmployeeList: (
        request,
        onSuccess
    ) => {
        return BaseAction().get(
            `${basePath}/list`,
            request.mapToJson(),
            (response) => {
                onSuccess(
                    new ResponseFetchEmployeeList()
                        .mapFromJson(response.data)
                );
            }
        )
    },
    fetchLoginHistoryList: (
        request,
        onSuccess
    ) => {
        return BaseAction().get(
            `${basePath}/sessions`,
            request.mapToJson(),
            (response) => {
                onSuccess(
                    new ResponseFetchEmployeeLoginHistory()
                        .mapFromJson(response.data)
                );
            }
        )
    },
    deleteLoginHistory: (
        request,
        onSuccess
    ) => {
        return BaseAction().delete(
            `${basePath}/sessions/delete`,
            request.mapToJson(),
            onSuccess
        )
    },
    createEmployee: (
        request,
        onSuccess,
        onFailed
    ) => {
        return BaseAction().post(
            `${basePath}/create`,
            request.mapToJson(),
            onSuccess,
            onFailed
        )
    },
    updateEmployee: (
        request,
        onSuccess
    ) => {
        return BaseAction().patch(
            `${basePath}/update`,
            request.mapToJson(),
            onSuccess
        )
    },
    deleteEmployee: (
        request,
        onSuccess
    ) => {
        return BaseAction().delete(
            `${basePath}/delete`,
            request.mapToJson(),
            onSuccess
        )
    },
    updateEmployeeMemo: (
        request,
        onSuccess = () => { return; }
    ) => {
        return BaseAction().patch(
            `${basePath}/update-memo`,
            request.mapToJson(),
            onSuccess
        )
    },
    fetchAllRoleList: (
        onSuccess = () => { return; }
    ) => {
        return BaseAction().get(
            `${basePath}/role/list`,
            {},
            (response) => {
                onSuccess(
                    new ResponseFetchRoleList()
                        .mapFromJson(response.data)
                );
            }
        )
    },
    fetchCardLogList: (
        request,
        onSuccess = () => { return; }
    ) => {
        return BaseAction().get(
            `${basePath}/view-log`,
            request.mapToJson(),
            (response) => {
                onSuccess(
                    new ResponseFetchCardLogList().mapFromJson(
                        response.data
                    )
                );
            }
        )
    },
    deleteCardLogs: (
        request,
        onSuccess
    ) => {
        return BaseAction().delete(
            `${basePath}/view-log/delete`,
            request.mapToJson(),
            onSuccess
        )
    },
}