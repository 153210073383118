import LocalizedStrings from 'react-localization';

export default new LocalizedStrings({
    en:{
        username: "Username",
        representativeName: "Name",
        name: "Name",
        password: "Password",
        phone: "Phone",
        companyPhone: "Company Phone",
        manager: "Manager",
        email: "Email",
        rank: "Rank",
        ipAddress: "IP",
        adminDeclaration: "I am admin",
        paid: "Paid",
        salePrice: "Sale Price",
        landPrice: "Land Price",
        buildingPrice: "Building Price",
        pricePerPY: "Price per PY",
        revenue: "Revenue",
        deposit: "Deposit",
        monthlyRent: "Monthly Rent",
        managementFee: "Management Fee",
        totalIncome: "Total Income",
        loanPrice: "Loan Price",
        land: "Land",
        landArea: "Land Area",
        landUsage: "Land Usage",
        nearStation: "Near Station",
        distance: "Distance",
        totalOfficialPrice: "Official Price",
        road: "Road",
        mobilePhone: "Telephone",
        otherPhone: "Other Phone",
        totalArea:  "Total Area",
        firstFloorArea: "First Floor Area",
        floors: "Floors",
        builtAt: "Built At",
        remodel: "Remodel",
        buildingRatio: "Building Ratio",
        parkingMethod:  "Parking Method",
        numberOfCars: "Number of Cars",
        airConditioner: "Air Conditioning",
        heater: "Heater",
        lift: "Lift",
        illegalBuilding: "Illegal Building",
        groupBuilding: "Group Building",
        designation: "Designation",
        address: "Address",
        streetAddress: "St. Address",
        advertisement: "Advertisement",
        possible: "Possible",
        notPossible: "Not Possible",
        totalFloorAreaInPyeong: "Total Floor Area (pyeong)",
        purchaseFunds: "Purchase Funds",
        monthlyROI: "Monthly ROI",
        monthlyPrice: "Monthly Price",
        annualROI: "Annual ROI",
        managementIncome: "Management Income",
        interestRate: "Interest Rate",
        monthlyInterest: "Monthly Interest",
        monthlyIncome: "Monthly Income",
        debtRatio: "Debt Ratio",
        area: "Area",
        nomination: "Nomination",
        buildingCoverRatio: "Building Ratio",
        totalMarketPlace: "Total Market Price",
        officialPricePy: "Official Price Py",
        roadWidth: "Road Width",
        grossFloorArea: "Gross Floor Area",
        isFix: "Is Fix",
        purpose: "Purpose",
        builtWith: "Built With",
        actualCourt: "Court(Actual)",
        exterior: "Exterior",
        floor: "Floor",
        ev: "EV",
        buildingValue: "Building Value",
        flat: "Flat",
        corresponding: "Corresponding",
        notApplicable: "Not Applicable",
        tenThousand: "만",
        corner: "Corner",
        bothSides: "Both Sides",
        number: "number",
        areaInSquareMeter: "Area(㎡)",
        earthArea: "Earth Area",
        restrictions: "Restrictions",
        officialPrice: "Official Price",
        krw: "KRW",
        productName: "Product Name",
        createdDate: "Created Date",
        writer: "Writer",
        employeeName: "Name",
        ownerName: "Name",
        buildingCoverageRatio: "Building Ratio"
    },
    kr: {
        username: "아이디",
        representativeName: "대표명",
        name: "성명",
        password: "비밀번호",
        phone: "핸드폰",
        companyPhone: "회사전화",
        manager: "담당자",
        email: "이메일",
        rank: "등급",
        ipAddress: "IP",
        adminDeclaration: "Partners 고객입니다",
        paid: "입금가",
        salePrice: "매매금액",
        landPrice: "토지 평당가격",
        buildingPrice: "건물가격",
        pricePerPY: "연면적 평당가격",
        revenue: "수익률",
        deposit: "보증금",
        monthlyRent: "월세",
        managementFee: "관리비",
        totalIncome: "월수입",
        loanPrice: "대출원금",
        land: "연면적 평당가격",
        landArea: "대지면적",
        landUsage: "용도지역",
        nearStation: "주변역",
        distance: "거리",
        totalOfficialPrice: "공시지가 합계",
        road: "도로사항",
        mobilePhone: "핸드폰",
        otherPhone: "기타전화",
        totalArea:  "연면적",
        firstFloorArea: "건축면적",
        floors: "규모",
        builtAt: "준공연도",
        remodel: "리모델링",
        buildingRatio: "용적률",
        floorRatio: "용적률",
        parkingMethod: "주차방식",
        numberOfCars: "대수",
        airConditioner: "냉",
        heater: "난방식",
        lift: "승강기",
        illegalBuilding: "위반건출물",
        groupBuilding: "집합건축물",
        designation: "명칭",
        address: "주소",
        streetAddress: "도로명",
        advertisement: "광고",
        possible: "가능",
        notPossible: "불가능",
        totalFloorAreaInPyeong: "토지평당가격",
        purchaseFunds: "구입자금",
        monthlyROI: "월수익률",
        monthlyPrice: "월세",
        annualROI: "연수익률",
        managementIncome: "관리 지출",
        interestRate: "이자율",
        monthlyInterest: "월이자액",
        monthlyIncome: "월수입",
        debtRatio: "부채비율",
        area: "토지면적",
        nomination: "지목",
        buildingCoverRatio: "건폐율",
        totalMarketPlace: "총공시지가",
        officialPricePy: "평당 공시지가",
        roadWidth: "도로너비",
        grossFloorArea: "연면적",
        isFix: "고정",
        purpose: "용도",
        builtWith: "구조",
        actualCourt: "주차대수",
        exterior: "외관",
        floor: "규모",
        ev: "EV",
        buildingValue: "건물가치",
        flat: "평",
        corresponding: "해당",
        notApplicable: "미해당",
        tenThousand: "만",
        corner: "코너",
        bothSides: "양면",
        number: "지번",
        areaInSquareMeter: "면적(㎡)",
        earthArea: "지구지역",
        restrictions: "제한사항",
        officialPrice: "공시지가",
        krw: "원",
        productName: "물건명",
        createdDate: "작성일",
        writer: "작성자",
        employeeName: "이름",
        ownerName: "성명",
        buildingCoverageRatio: "건폐율"
    }
});
