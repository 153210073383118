import styled from '@emotion/styled';
import colors from 'constants/colors';
import * as imageOrientations from 'enumerations/imageOrientations';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const Container = styled.div`
  position: relative;
  left: 0;
  top: 0;
  width: 1075px;
  height: 765px;
  overflow: hidden;
  scale: ${(props) => props.scale};
  margin: auto;
`;

export const BackgroundImage = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: white;
`;

export const CoverImage = styled.div`
  background-image: url(${(props) => props.image});
  background-position: center;
  background-size: ${(props) =>
    props.imageOrientation.key == imageOrientations.horizontal
      ? 'contain'
      : 'cover'};
  background-repeat: no-repeat;
`;

export const ShadowShape = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  width: ${(props) =>
    props.imageOrientation.key == imageOrientations.horizontal ? '60%' : '50%'};
  height: 100%;
  border-bottom: 773px solid ${colors.gray.dark};
  border-right: 100px solid transparent;
`;

export const BackgroundShape = styled.div`
  position: absolute;
  width: ${(props) =>
    props.imageOrientation.key == imageOrientations.horizontal
      ? 'calc(60% - 16px)'
      : 'calc(50% - 16px)'};
  height: 100%;
  border-bottom: 773px solid white;
  border-right: 100px solid transparent;
`;

export const DarkOverlay = styled.div`
  position: absolute;
  width: 60%;
  right: 0;
  bottom: 0;
  border-bottom: 200px solid black;
  border-left: 645px solid transparent;
  opacity: 0.75;
`;

export const OverlayContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  text-align: left;
  padding: 3rem;
`;

export const LogoImage = styled.img`
  width: 60%;
`;

export const PropertyInfoContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`;

export const MiddleContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const DisclaimerContainer = styled.div``;

export const DisclaimerListItem = styled.ul`
  position: relative;
  list-style: none;
  margin-left: 15px;
  :before {
    content: '*';
    position: absolute;
    left: -15px;
  }
`;
