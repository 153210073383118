import styled from 'styled-components';
import tw from "twin.macro"
import colors from 'constants/colors';

const Wrapper = styled.div`
    display: ${ (props) => props.isHidden ? 'none' : 'block' };
    height: ${
        (props) => {
            return props.maximumHeight.isEmpty()
                ? '100%'
                : `${ props.maximumHeight }px` 
        }
    };
    ${tw `overflow-y-auto overflow-x-hidden` }
`;

const Table = styled.table`
    ${tw `w-full`}
`;

const Row = styled.tr`
    ${ (props) => props.isClickable &&
        `
            cursor: pointer;
            &:hover {
                background: ${ colors.gray.light };
            }
        `
    }
`;

const ColumnHeader = styled.th`
    height: 50px;
    width: ${props => (props.index == 0 && 75)}px;
    padding-left: ${props => (props.index == 0 && props.firstColumnPadding)}px;
    ${tw `sticky top-0 font-normal`}
    ${(props) => props.style.headerStyle}
`;

const TableDataCell = styled.td`
    height: 50px;
    padding-left: ${props => (props.index == 0 && props.firstColumnPadding)}px;
    ${tw `py-3 border-b`}
    ${(props) => props.style.bodyStyle}
`;

export {
    Wrapper,
    Table,
    Row,
    ColumnHeader,
    TableDataCell
}