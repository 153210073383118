import commonActionTypes from "redux/types/commonActionTypes";

function isPromise(v) {
    return v && typeof v.then === 'function';
} 

const promiseMiddleware = store => next => action => {
    if (!isPromise(action.payload)) {
        next(action);
        return;
    }
    store.dispatch({
        type: commonActionTypes.ASYNC_START,
        subtype: action.type
    });
    action.payload.then(
        res => {
          action.payload = res;
          if (action.payload
                && action.payload['status_code'] >= 400) {
              action.error = true;
          }
          store.dispatch(action);
        }
    );
    return;
};

export default promiseMiddleware;