import defaultValues from "constants/defaultValues";

export class SearchPropertyResult {
    constructor(
        id = defaultValues.emptyInt,
        name = defaultValues.emptyString,
        statusId = defaultValues.emptyInt,
        address = defaultValues.emptyString,
        streetAddress = defaultValues.emptyString,
        owner = defaultValues.emptyString,
        landArea = defaultValues.emptyInt,
        floorArea = defaultValues.emptyInt,
        price = defaultValues.emptyInt,
        deposit = defaultValues.emptyInt,
        monthlyRent = defaultValues.emptyInt,
        revenue = defaultValues.emptyInt,
        nearestStation = defaultValues.emptyString
    ) {
        this.id = id;
        this.name = name;
        this.statusId = statusId;
        this.address = address;
        this.streetAddress = streetAddress;
        this.owner = owner;
        this.landArea = landArea;
        this.floorArea = floorArea;
        this.price = price;
        this.deposit = deposit;
        this.monthlyRent = monthlyRent;
        this.revenue = revenue;
        this.nearestStation = nearestStation;
    }

    mapFromJson(json) {
        if (!json) { return this; }
        this.id = json['id'];
        this.name = json['building_name'];
        this.statusId = json['status'];
        this.address = json['address'];
        this.streetAddress = json['street_address'];
        this.owner = json['owner'];
        this.landArea = json['land_area'];
        this.floorArea = json['floor_area'];
        this.price = json['price'];
        this.deposit = json['deposit'];
        this.monthlyRent = json['monthly_rent'];
        this.revenue = json['revenue'];
        this.nearestStation = json['nearest_station'];
        return this;
    }
}