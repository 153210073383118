import React, { Fragment,useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import * as styled from './Dropdown.styled';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

export default function Example(props) {
    const theProps = props;

    const [theSelected, setSelected] = useState("Price")


    return (
        <Menu as="div" 
            className="
                mr-4 w-full lg:mr-0 md:mr-0
                relative inline-block text-left 
            "
        >
            <div>
                <Menu.Button 
                    className="
                        inline-flex w-full justify-between rounded-md bg-white 
                        px-4 py-2
                        border border-gray-300 hover:bg-[#F9F9F9] 
                        focus:outline-none
                    "
                >
                    {
                        (theSelected == 'Price') ? '금액' : 
                        (theSelected == 'Revenue') ? '수익률' : 
                        (theSelected == 'Land Area') ? '대지면적' : 
                        (theSelected == 'Floor Area') ? '연면적' : 
                        (theSelected == 'Land Price') ? '대지평당가' : ''
                    }
                    <ChevronDownIcon className="-mr-1 ml-8 w-5" aria-hidden="true" color='#707070'/>
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items 
                    className="
                        absolute w-full rounded-md bg-white shadow-lg
                        origin-top-left left-0
                        border border-[#DDDDDD]
                        focus:outline-none
                    "
                >
                    <div>
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    onClick={()=>{
                                        setSelected("Price")
                                        theProps.parentCallback("price");
                                    }}
                                    className={classNames(
                                        active ? 'bg-[#F9F9F9]' : '',
                                        'block px-4 py-2 cursor-pointer'
                                    )}
                                >
                                    금액
                                </a>
                            )}
                        </Menu.Item>

                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    onClick={()=>{
                                        setSelected("Revenue")
                                        theProps.parentCallback("revenue");
                                    }}
                                    className={classNames(
                                        active ? 'bg-[#F9F9F9]' : '',
                                        'block px-4 py-2 cursor-pointer'
                                    )}
                                >
                                    수익률
                                </a>
                            )}
                        </Menu.Item>

                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    onClick={()=>{
                                        setSelected("Land Area")
                                        theProps.parentCallback("land_area");
                                    }}
                                    className={classNames(
                                        active ? 'bg-[#F9F9F9]' : '',
                                        'block px-4 py-2 cursor-pointer'
                                    )}
                                >
                                    대지면적
                                </a>
                            )}
                        </Menu.Item>

                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    onClick={()=>{
                                        setSelected("Floor Area")
                                        theProps.parentCallback("floor_area");
                                    }}
                                    className={classNames(
                                        active ? 'bg-[#F9F9F9]' : '',
                                        'block px-4 py-2 cursor-pointer'
                                    )}
                                >
                                    연면적
                                </a>
                            )}
                        </Menu.Item>

                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    onClick={()=>{
                                        setSelected("Land Price")
                                        theProps.parentCallback("land_price");
                                    }}
                                    className={classNames(
                                        active ? 'bg-[#F9F9F9]' : '',
                                        'block px-4 py-2 cursor-pointer'
                                    )}
                                >
                                    대지평당가
                                </a>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}