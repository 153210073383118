import defaultValues from "constants/defaultValues";
import { useState } from "react";

const DropdownInputPresenter = () => {
    const [isShowOptions, setIsShowOptions] = useState(false);
    const [isInputEditable, setIsInputEditable] = useState(false);
    let totalClicks = defaultValues.emptyInt;

    const onClick = () => {
        totalClicks+=1;
        if (totalClicks == 2) {
            totalClicks = defaultValues.emptyInt;
            setIsShowOptions(false);
            setIsInputEditable(!isInputEditable);
            return;
        }
        setTimeout(
            () => {
                if (totalClicks.isEmpty()
                    || totalClicks > 1) {
                    return;
                }
                setIsShowOptions(!isShowOptions);
                totalClicks = defaultValues.emptyInt;
            }, 200
        )
    }

    return {
        onClick,
        setIsShowOptions,
        setIsInputEditable,
        isShowOptions,
        isInputEditable
    }
}

export default DropdownInputPresenter;