import ImageDocument from "models/objects/ImageDocument";

export class ResponseAddImageDocument {
  constructor(
    imageDocument
  ) {
    this.imageDocument = imageDocument;
  }

  mapFromJson(json) {
    if (!json) {
      return this;
    }
    this.imageDocument = new ImageDocument().mapFromJson(json)
    return this;
  }
}