
import styled from 'styled-components';
import tw from "twin.macro"

export const Container = styled.div`
    width: 700px;
    max-width: 90%;
    max-height: 90%;
    ${tw `flex flex-col m-auto bg-white p-8`}
`;

export const CloseButton = styled.button`
    width: 20px;
    height: 20px;
    ${tw `ml-auto`}
`;

export const EmptyStateContainer = styled.div`
    height: 100px;
    ${tw `flex`}
`;

export const HorizontalLine = styled.div`
    ${tw `w-full h-px my-7 bg-secondary`}
`;