export class RequestDeleteEmployee {
    constructor(
        employeeIds = []
    ) {
        this.employeeIds = employeeIds;
    }

    mapToJson() {
        return {
            'ids': this.employeeIds
        }
    }
}