import styled from "styled-components";
import tw from "twin.macro";

const Container = styled.div`
    ${ tw`space-y-6 w-full h-full` }
`;
const TopContainer = styled.div`
    ${ tw`grid gap-3 pt-4` }
`;
const ImageWrapper = styled.div`
    width: 100%;
    padding-bottom: 100%;
    ${ tw`block relative bg-secondary` }
`;
const Image = styled.img`
    object-fit: contain;
    ${ (props) => props.src.isEmpty() && tw`hidden` }
    ${ tw`absolute h-full w-full inset-0 bg-white` }
`;
const AdvertisementContainer = styled.div`
    ${tw`grid grid-cols-4`}
`;
const AdvertisementChecklistContainer = styled.div`
    ${tw`flex gap-3 col-span-3`}
`;
const MapButtonWrapper = styled.div`
    ${tw`flex-1`}
`;

export {
    Container,
    TopContainer,
    ImageWrapper,
    Image,
    MapButtonWrapper,
    AdvertisementContainer,
    AdvertisementChecklistContainer
}