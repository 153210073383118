import styled from "styled-components";
import tw from "twin.macro";

const Container = styled.div`
    ${ tw`w-full h-full` }
`;
const TopContainer = styled.div`
    ${ tw`flex justify-between py-4` }
`;
const AddressContainer = styled.div`
    ${ tw`text-left my-auto` }
`;
const MapActionContainer = styled.div`
    ${ tw`flex gap-2.5` }
`;
const ActionButton = styled.button`
    ${ tw`h-12 w-12` }
`;
const ImageWrapper = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 100%;
`;

const Image = styled.img`
    position: absolute;
    object-fit: contain;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

export {
    Container,
    TopContainer,
    AddressContainer,
    MapActionContainer,
    ActionButton,
    ImageWrapper,
    Image
}