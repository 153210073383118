import React from 'react';
import { RequestCreateCard } from 'models/requests/RequestCreateCard';
import * as defaultValues from 'constants/defaultValue';
import { useState } from 'react';
import rentalHistoryColumns from 'enumerations/rentalHistoryColumns';
import cardActionTypes from 'redux/types/cardActionTypes';
import { useDispatch } from 'react-redux';
import * as cardActions from 'redux/reducers/cardReducer';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import formStates from 'enumerations/formStates';
import { tableHeaderStrings } from 'localizations';
import CheckboxInput from 'components/core/input-checkbox/CheckboxInput';

const useRentalHistoryViewModel = () => {
  const dispatch = useDispatch();
  const [rentalHistories, setRentalHistories] = useState(
    defaultValues.emptyArray
  );
  const cardReducer = useSelector((state) => state.card);
  const [tableHeaders, setTableHeaders] = useState(defaultValues.emptyArray);
  const [requestWriteCard, setRequestWriteCard] = useState(
    new RequestCreateCard()
  );
  const [excludedCells, setExcludedCells] = useState([]);
  const isAllExcluded = () => {
    if (rentalHistories.isEmpty()) {
      return false;
    }
    const totalExcluded = rentalHistories.filter(($0) => $0.isExcluded).length;
    return totalExcluded == rentalHistories.length;
  };

  const setupTableHeaders = () => {
    let tableHeaders = [
      tableHeaderStrings.floor,
      tableHeaderStrings.numberOfRentals,
      tableHeaderStrings.usage,
      tableHeaderStrings.deposit,
      `${tableHeaderStrings.monthlyPrice}`,
      `${tableHeaderStrings.adminPrice}`,
      tableHeaderStrings.contractDate,
      tableHeaderStrings.note,
      tableHeaderStrings.rentPerPyeong,
    ];
    if (cardReducer.formState != formStates.read) {
      tableHeaders.insert(
        tableHeaderStrings.except,
        <CheckboxInput
          key={0}
          checked={isAllExcluded()}
          onChange={_excludeAllRow}
        />
      );
    }
    setTableHeaders(tableHeaders);
  };

  const onExcludeRentalHistory = (index) => {
    const isExcluded =
      cardReducer.requestWriteCard.rentalHistories[index].isExcluded;
    cardReducer.requestWriteCard.rentalHistories[index].isExcluded =
      !isExcluded;
    cardActions.updateRequestCreateCard(
      Object.assign(new RequestCreateCard(), cardReducer.requestWriteCard)
    );
  };

  const _excludeAllRow = () => {
    const _isAllExcluded = isAllExcluded();
    cardReducer.requestWriteCard.rentalHistories = rentalHistories.map(($0) => {
      $0.isExcluded = !_isAllExcluded;
      return $0;
    });
    cardActions.updateRequestCreateCard(
      Object.assign(new RequestCreateCard(), cardReducer.requestWriteCard)
    );
  };

  const getAreaInPy = (index) => {
    if (!requestWriteCard.rentalHistories.isValidIndex(index)) {
      return defaultValues.emptyString;
    }
    return requestWriteCard.rentalHistories[index].areaInPY;
  };

  const getDeposit = (index) => {
    if (!requestWriteCard.rentalHistories.isValidIndex(index)) {
      return defaultValues.emptyString;
    }
    return requestWriteCard.rentalHistories[index].deposit;
  };

  const getMonthlyFee = (index) => {
    if (!requestWriteCard.rentalHistories.isValidIndex(index)) {
      return defaultValues.emptyString;
    }
    return requestWriteCard.rentalHistories[index].monthlyFee;
  };

  const getAdminPrice = (index) => {
    if (!requestWriteCard.rentalHistories.isValidIndex(index)) {
      return defaultValues.emptyString;
    }
    return requestWriteCard.rentalHistories[index].adminPrice;
  };

  const getRentFee = (rentalHistory) => {
    const rentFee = (
      rentalHistory.getMonthlyRental() / rentalHistory.getRentalArea()
    ).rounded(2);
    if (!isFinite(rentFee)) {
      return defaultValues.emptyInt;
    }
    return rentFee;
  };

  const getTotalAreaInPy = () => {
    if (cardReducer.formState == formStates.read) {
      return cardReducer.card.getRentalTotalArea();
    }
    return requestWriteCard.getTotalRentalArea();
  };

  const getTotalMonthlyFee = () => {
    if (cardReducer.formState == formStates.read) {
      return cardReducer.card.getMonthlyRent().rounded(2);
    }
    return requestWriteCard.getTotalMonthlyRentalPrice().rounded(2);
  };

  const setupTotalDeposit = () => {
    if (cardReducer.formState == formStates.read) {
      return cardReducer.card
        .getRentalTotalDeposit()
        .rounded(2)
        .toString()
        .thousandFormat();
    }
    return requestWriteCard
      .getTotalRentalDeposit()
      .rounded(2)
      .toString()
      .thousandFormat();
  };

  const setupTotalAdminPrice = () => {
    if (cardReducer.formState == formStates.read) {
      return cardReducer.card
        .getRentalTotalAdminPrice()
        .rounded(2)
        .toString()
        .thousandFormat();
    }
    return requestWriteCard
      .getTotalRentalAdminFee()
      .rounded(2)
      .toString()
      .thousandFormat();
  };

  const setupTotalRentFee = () => {
    return (getTotalMonthlyFee() / getTotalAreaInPy())
      .rounded(2)
      .toString()
      .thousandFormat();
  };

  const didMergeSucceed = (selectedCells) => {
    for (const cell of selectedCells) {
      const rentalHistory =
        requestWriteCard.rentalHistories[selectedCells.first().row];
      const columnName = rentalHistoryColumns.columnFormName(cell.column);
      const latestRow = selectedCells.last().row;
      for (let indexSpan = 0; indexSpan < cell.span; indexSpan++) {
        const index = cell.row + indexSpan;
        requestWriteCard.rentalHistories[index][columnName] =
          rentalHistory[columnName];
        if (index < latestRow) {
          requestWriteCard.rentalHistories[index].setAsMerged(cell.column);
        }
      }
    }
    dispatch({
      type: cardActionTypes.UPDATE_WRITE_CARD_REQUEST,
      payload: requestWriteCard,
    });
    setRequestWriteCard(requestWriteCard);
  };

  const didUnmergeSucceed = (unMergedRows) => {
    for (const [index, unMergedRow] of unMergedRows.entries()) {
      requestWriteCard.rentalHistories[unMergedRow.row].setAsUnMerge(
        unMergedRow.column
      );
      if (index == defaultValues.emptyInt) {
        continue;
      }
      const columnName = rentalHistoryColumns.columnFormName(
        unMergedRow.column
      );
      requestWriteCard.rentalHistories[unMergedRow.row][columnName] =
        defaultValues.emptyString;
    }
  };

  const setupData = () => {
    setRentalHistories(
      cardReducer.formState == formStates.read
        ? cardReducer.card.rentalHistories
        : cardReducer.requestWriteCard.rentalHistories
    );
  };

  const _mergeFloorColumn = (mergedColumn, mergedColumns) => {
    let value = 0;
    let columnName = rentalHistories[mergedColumn.row].getNameByIndex(
      mergedColumn.column
    );
    for (const mergedColumn of mergedColumns) {
      if (rentalHistories[mergedColumn.row][columnName.name].isEmpty()) {
        continue;
      }
      value = rentalHistories[mergedColumn.row][columnName.name];
      break;
    }
    rentalHistories[mergedColumn.row][columnName.mergeName] =
      mergedColumn.isMerged;
    rentalHistories[mergedColumn.row][columnName.name] = value;
  };

  const _unmergeFloorColumn = (index, mergedColumn) => {
    let columnName = rentalHistories[mergedColumn.row].getNameByIndex(
      mergedColumn.column
    );
    rentalHistories[mergedColumn.row][columnName.mergeName] = false;
    if (index != defaultValues.emptyInt) {
      rentalHistories[mergedColumn.row][columnName.name] =
        defaultValues.emptyString;
    }
  };

  const onMergeSuccess = (mergedColumns) => {
    for (const mergedColumn of mergedColumns) {
      _mergeFloorColumn(mergedColumn, mergedColumns);
    }
    cardActions.onMergeRentalColumn(rentalHistories);
  };

  const onUnmergeSuccess = (columns) => {
    for (const [index, mergedColumn] of columns.entries()) {
      _unmergeFloorColumn(index, mergedColumn);
    }
    cardActions.onMergeRentalColumn(rentalHistories);
  };

  useEffect(() => {
    setupTableHeaders();
    setupData();
  }, [cardReducer]);

  return {
    getAreaInPy,
    getDeposit,
    getMonthlyFee,
    getAdminPrice,
    getTotalAreaInPy,
    getTotalMonthlyFee,
    getRentFee,
    setupTotalDeposit,
    onExcludeRentalHistory,
    setRequestWriteCard,
    setupTotalAdminPrice,
    setupTotalRentFee,
    setExcludedCells,
    didMergeSucceed,
    didUnmergeSucceed,
    onMergeSuccess,
    onUnmergeSuccess,
    requestWriteCard,
    rentalHistories,
    excludedCells,
    tableHeaders,
  };
};

export default useRentalHistoryViewModel;
