export class RequestDeleteImageDocument {
  constructor(propertyId, ids) {
    this.propertyId = propertyId;
    this.ids = ids;
  }

  mapToJson() {
    return {
      "property_id": this.propertyId,
      "ids": this.ids,
    };
  }
}