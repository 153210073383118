import { Pagination } from "models/objects/Pagination";
import { PropertyHistory } from "models/objects/PropertyHistory";

export class ResponseFetchSearchHistories {
    constructor(
        propertyHistories = [],
        pagination = new Pagination()
    ) {
        this.propertyHistories = propertyHistories;
        this.pagination = pagination;
    }

    mapFromJson(json) {
        if (!json && !json['data']) { return this; }
        this.propertyHistories = json['data'].map(
            (roleJson) => new PropertyHistory().mapFromJson(roleJson)
        )
        this.pagination = new Pagination(
            json['currentPage'],
            json['totalItems'],
            json['totalPages']
        );
        return this;
    }
}