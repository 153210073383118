import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/core/button/Button';
import { WhiteButtonStyle } from 'components/core/button/styles';
import DropdownInput from 'components/core/dropdown-input/DropdownInput';
import { BorderedDropdownInputStyle } from 'components/core/dropdown-input/styles';
import CheckboxInput from 'components/core/input-checkbox/CheckboxInput';
import { BorderedTextInputStyle } from 'components/core/text-input/styles';
import TextInput from 'components/core/text-input/TextInput';
import Text from 'components/core/text/Text';
import separators from 'constants/separators';
import sizes from 'constants/sizes';
import cardStatus from 'enumerations/cardStatus';
import inputTypes from 'enumerations/inputTypes';
import searchFormTypes from 'enumerations/searchFormTypes';
import textInputType from 'enumerations/textInputType';
import * as styled from './SearchFormLayout.styled';
import SearchFormLayoutPresenter from './SearchFormLayoutPresenter';
import { LandUsage } from 'models/objects/LandUsage';
import buttonStrings from 'localizations/buttonStrings';

const SearchFormLayout = (props) => {
    const presenter = SearchFormLayoutPresenter()
    const inputGroups = searchFormTypes.inputGroups;

    const renderStatusCheckboxes = (inputGroup) => {
        const checkboxInputs = cardStatus.statuses.map(
            (status) => {
                return (
                    <CheckboxInput
                        key={ status }
                        name={ inputGroup.key }
                        label={ cardStatus.getTitle(status) }
                        onChange={ () => presenter.onStatusChange(status) }
                        checked={ presenter.getIsStatusCheck(status) }/>
                )
            }
        );
        return (
             <styled.StatusCheckboxContainer>
                { checkboxInputs }
             </styled.StatusCheckboxContainer>
        )
    }

    const renderRoadWithInputs = () => {
        return (
            <styled.RoadWidthInputContainer>
                <styled.RoadWidthCheckboxContainer>
                    <CheckboxInput
                        key={ searchFormTypes.enums.isRoadSide }
                        name={ searchFormTypes.enums.isRoadSide }
                        label={ searchFormTypes.checkboxes[searchFormTypes.enums.isRoadSide].title }
                        onChange={
                            (isChecked) => {
                                presenter.onRoadCheckboxChange(
                                    isChecked,
                                    searchFormTypes.enums.isRoadSide
                                )
                            }
                        }
                        checked={ presenter.requestSearchProperty.isRoadSide }/>
                    <CheckboxInput
                        key={ searchFormTypes.enums.isRoadCorner }
                        name={ searchFormTypes.enums.isRoadCorner }
                        label={ searchFormTypes.checkboxes[searchFormTypes.enums.isRoadCorner].title }
                        onChange={
                            (isChecked) => {
                                presenter.onRoadCheckboxChange(
                                    isChecked,
                                    searchFormTypes.enums.isRoadCorner
                                )
                            }
                        }
                        checked={ presenter.requestSearchProperty.isRoadCorner }/>
                </styled.RoadWidthCheckboxContainer>
            </styled.RoadWidthInputContainer>
        )
    }

    const renderRangeInput = (
        minimumName,
        maximumName,
        suffixText,
        minimumPlaceholder = "최소",
        maximumPlaceholder = "최대"
    ) => {
        return (
            <styled.RangeContainer>
                <styled.RangeInputWrapper>
                    {
                        renderTextInput(
                            minimumName,
                            minimumPlaceholder,
                            textInputType.currency,
                            suffixText
                        )
                    }
                </styled.RangeInputWrapper>
                <styled.RangeSeparator>
                    { separators.hypen }
                </styled.RangeSeparator>
                <styled.RangeInputWrapper>
                    {
                        renderTextInput(
                            maximumName,
                            maximumPlaceholder,
                            textInputType.currency,
                            suffixText
                        )
                    }
                </styled.RangeInputWrapper>
            </styled.RangeContainer>
        )
    }

    const renderUsageInput = () => {
        return (
            <DropdownInput
                selectedOption={{
                    key: presenter.requestSearchProperty.usage.id,
                    value: presenter.requestSearchProperty.usage.name
                }}
                onCustomValueChange={
                    (input) => {
                        let landUsage = new LandUsage()
                        landUsage.name = input.value;
                        landUsage.isCustom = true;
                        presenter.onInputChange(
                            {
                                name: input.name,
                                value: landUsage
                            }
                        )
                    }
                }
                name={ "usage" }
                customValue={ presenter.requestSearchProperty.usage.name }
                options={ presenter.usageItems }
                height={ sizes.textInput.small }
                handleSelectOption={ presenter.onSelectUsage }
                style={ BorderedDropdownInputStyle }/>
        )
    }

    const renderTextInput = (
        key,
        placeholder,
        type = textInputType.text,
        suffixText
    ) => {
       return (
            <TextInput
                key={ key }
                name={ key }
                height={ sizes.textInput.small }
                onChange={ presenter.onInputChange }
                onTapEnter={ props.onStartSearch }
                placeholder={ placeholder }
                suffixText={ suffixText }
                style={ BorderedTextInputStyle }
                type={ type }
                value={ presenter.requestSearchProperty[key] }/>
       )
    }

    const renderInput = (inputGroup) => {
        if (inputGroup.type == inputTypes.unspecified) {
            if (inputGroup.key == searchFormTypes.enums.status) {
                return renderStatusCheckboxes(inputGroup);
            }
            if (inputGroup.key == searchFormTypes.enums.roadWidth) {
                return renderRoadWithInputs();
            }
            if (inputGroup.key == searchFormTypes.enums.usage) {
                return renderUsageInput(inputGroup);
            }
            return;
        }
        if (inputGroup.type == inputTypes.range) {
            const rangeInput = searchFormTypes.rangeInputs[inputGroup.key];
            return renderRangeInput(
                rangeInput.minimumName,
                rangeInput.maximumName,
                rangeInput.suffixText
            );
        }
        return renderTextInput(inputGroup.key);
    }

    const renderInputGroup = (inputGroup, index) => {
        return (
            <styled.InputContainer key={ index }>
                <styled.LabelWrapper>
                    <Text value={ inputGroup.displayTitle }/>
                </styled.LabelWrapper>
                <styled.InputFieldWrapper>
                    { renderInput(inputGroup) }
                </styled.InputFieldWrapper>
            </styled.InputContainer>
        )
    }

    const renderForm = () => {
        return (
            <styled.FormContainer>
                {
                    inputGroups.map(
                        (inputGroup, index) => {
                            return renderInputGroup(inputGroup, index)
                        }
                    )
                }
            </styled.FormContainer>
        )
    }

    const renderActions = () => {
        return (
            <styled.ButtonContainer>
                <Button
                    title={ buttonStrings.reset }
                    height={ sizes.button.small }
                    style={ WhiteButtonStyle }
                    action={
                        () => {
                            presenter.resetState();
                            props.onResetForm();
                        }
                    }/>
                <Button
                    title="검색"
                    height={ sizes.button.small }
                    action={ props.onStartSearch }/>
            </styled.ButtonContainer>
        )
    }

    return (
        <styled.Container>
            { renderActions() }
            { renderForm(presenter.requestSearchProperty) }
        </styled.Container>
    )
}

SearchFormLayout.propTypes = {
    onStartSearch: PropTypes.func,
    onResetForm: PropTypes.func
};

SearchFormLayout.defaultProps = {
    onStartSearch: () => { return; },
    onResetForm: () => { return; }
};

export default SearchFormLayout;