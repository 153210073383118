import React, { Fragment, useEffect, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import * as styled from './Dropdown.styled';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Dropdowns(props) {
    
    const list_dropDown = [
        "제1종전용주거지역",
        "제2종전용주거지역",
        "제1종일반주거지역",
        "제2종일반주거지역",
        "제3종일반주거지역",
        "준주거지역",
        "중심상업지역",
        "일반상업지역",
        "근린상업지역",
        "유통상업지역",
        "전용공업지역",
        "일반공업지역",
        "준공업지역",
        "보전녹지지역",
        "생산녹지지역",
        "자연녹지지역",
        "직접입력"
    ];

    const theProps = props;

    const [theSelected, setSelected] = useState("선택")

    return (
        <Menu as="div" className="relative inline-block text-left w-full">
            <div>
                <Menu.Button 
                    className="
                        inline-flex w-full justify-between rounded-md bg-white 
                        px-2 py-1
                        border border-[#DDDDDD] hover:bg-[#F9F9F9] 
                        focus:outline-none
                    "
                >
                    {(theProps.theValue ? theProps.theValue : '선택')}
                    <ChevronDownIcon className="-mr-1 ml-8 w-5" aria-hidden="true" color='#707070'/>
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items 
                    className="
                        absolute w-full rounded-md bg-white shadow-lg
                        origin-top-left left-0
                        border border-[#DDDDDD]
                        focus:outline-none
                    "
                >
                    <div className='max-h-[200px] overflow-y-scroll'>
                        {
                            list_dropDown.map((d,index) => (
                                <Menu.Item
                                    key={"dropdownCenter"+index}
                                >
                                    {({ active }) => (
                                        <a
                                            onClick={()=>{
                                                setSelected(d)
                                                theProps.parentCallback(d);
                                            }}
                                            className={'block px-4 py-2 cursor-pointer hover:bg-[#F9F9F9]'}
                                        >
                                            {d}
                                        </a>
                                    )}
                                </Menu.Item>
                            ))
                        } 
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}