import defaultValues from "constants/defaultValues";

export class RequestUpdateCustomerCondition {
    constructor(
        customerId = defaultValues.emptyString,
        condition = defaultValues.emptyString
    ) {
        this.customerId = customerId;
        this.condition = condition;
    }

    mapToJson() {
        return {
            'id': this.customerId,
            'condition': this.condition
        }
    }
}