import styled from "styled-components";
import tw from "twin.macro";

export const Container = styled.div`
    ${ tw`` }
`;

export const TopContainer = styled.div`
    ${ tw`space-y-2.5 py-4 border-b border-secondary-dark` }
`;

export const BottomContainer = styled.div`
    ${ tw`space-y-2.5 pt-4` }
`;

export const InfoContainer = styled.div`
    ${ tw`grid grid-cols-3 text-left` }
`;

export const TitleLabel = styled.p`
    ${ tw`col-span-1` }
`;

export const ValueContainer = styled.div`
    ${ tw`flex col-span-2` }
`;

export const ValueLabel = styled.p`
    ${
        (props) => {
            let styles = [
                `color: ${props.color}`,
                `font-weight: bold`
            ];
            return styles.join(';');
        }
    }
`;

export const EditPriceButton = styled.button`
    margin-left: 0.5rem;
`;

export const EditIcon = styled.img`
    width: 24px;
    height: 24px;
`;