import React, { Component } from 'react';
import PropTypes from 'prop-types';
import defaultValues from 'constants/defaultValues';
import styled from 'styled-components';
import sizes from 'constants/sizes';
import tw from 'twin.macro';

const Container = styled.div`
  ${tw`flex my-auto`}
`;

const Checkbox = styled.input`
  width: ${sizes.checkbox.small}px;
  height: ${sizes.checkbox.small}px;
  ${(props) => props.disabled && tw`bg-secondary`}
  ${tw`my-auto`}
`;

const Label = styled.label`
  ${(props) => !props.label && tw`mx-auto`}
  ${tw`flex text-gray-500 my-auto`}
`;

const Title = styled.span`
  ${tw`pl-3`}
`;

class CheckboxInput extends Component {
  static get propTypes() {
    return {
      disabled: PropTypes.bool,
      name: PropTypes.string,
      label: PropTypes.string,
      onChange: PropTypes.func,
      checked: PropTypes.bool,
    };
  }

  static defaultProps = {
    disabled: false,
    name: defaultValues.emptyString,
    label: defaultValues.emptyString,
    onChange: () => {
      return;
    },
    checked: false,
  };

  onChange = () => {
    this.props.onChange(!this.props.checked);
  };

  resetState = () => {
    this.setState({
      value: false,
    });
  };

  renderCheckbox = () => {
    return (
      <Checkbox
        disabled={this.props.disabled}
        onChange={this.onChange}
        name={this.props.name}
        checked={this.props.checked}
        value={this.props.checked}
        type="checkbox"
      />
    );
  };

  renderTitle = () => {
    if (!this.props.label) {
      return;
    }
    return <Title>{this.props.label}</Title>;
  };

  renderLabel = () => {
    return (
      <Label label={this.props.label}>
        {this.renderCheckbox()}
        {this.renderTitle()}
      </Label>
    );
  };

  render() {
    return <Container>{this.renderLabel()}</Container>;
  }
}

export default CheckboxInput;
