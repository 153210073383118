import defaultValues from "constants/defaultValues";

export class ResponseUploadImage {
    constructor(
        id = defaultValues.emptyInt,
        imageUrl = defaultValues.emptyString,
        type = defaultValues.emptyString
    ) {
        this.id = id;
        this.imageUrl = imageUrl;
        this.type = type;
    }

    mapToJson() {
        return{
            "id": this.id,
            "url": this.imageUrl,
            "type": this.type
        }
    }

    mapFromJson(json) {
        if (!json) { return this; }
        this.id = json['id'];
        this.imageUrl = json['url'];
        this.type = json['type'];
        return this;

    }
}