import React from 'react';
import Button from '../../components/core/button/Button';
import {
    DarkGrayButtonStyle,
    LightGrayButtonStyle
} from '../../components/core/button/styles';
import EmployeeRoutes from 'routes/employeeRoutes';
import * as styled from './EmployeePage.styled';
import useEmployeePage from './useEmployeePage';
import { useHistory } from 'react-router';

const EmployeePage = () => {
    const hook = useEmployeePage();
    const history = useHistory();

    const renderTabButtons = () => {
        const currentPath = history.location.pathname.split('/').last()
        return hook.setupTabButtons().map(
            (value, index) => (
                <Button
                    key={ index }
                    action={ value.action }
                    isRounded={ false }
                    style={
                        value.path == currentPath
                            ? LightGrayButtonStyle
                            : DarkGrayButtonStyle
                    }
                    title={ value.title }/>
            )
        );
    }

    return (
        <styled.Wrapper>
            <styled.TabWrapper>
                { renderTabButtons() }
            </styled.TabWrapper>
            <EmployeeRoutes/>
        </styled.Wrapper>
    );
}

export default EmployeePage;