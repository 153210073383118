const ImagePageSize = {
    w480h640: {
        id: 0,
        width: 480,
        height: 640,
        optionItem: {
            key: 0,
            value: "480*640"
        }
    },
    w640h480: {
        id: 1,
        width: 640,
        height: 480,
        optionItem: {
            key: 1,
            value: "640*480"
        }
    },
    w655h750: {
        id: 2,
        width: 655,
        height: 750,
        optionItem: {
            key: 2,
            value: "655*750"
        }
    },
    original: {
        id: 3,
        width: null,
        height: null,
        optionItem: {
            key: 3,
            value: "원본사이즈"
        }
    }
}
export default ImagePageSize;