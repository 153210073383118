const START_LOADING = 'confirmationModal/START_LOADING';
const STOP_LOADING = 'confirmationModal/STOP_LOADING';

const initialState = {
    isLoading: false,
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
      case START_LOADING:
        return {
            ...state,
            isLoading: true
        }
    case STOP_LOADING:
        return {
            ...state,
            isLoading: false
        }
      default: return state;
    }
}

export function startLoading() {
    return { type: START_LOADING };
}

export function stopLoading() {
    return { type: STOP_LOADING };
}