import React, { useState } from 'react'
import TableList from 'components/core/table-list/TableList'
import * as commonActions from 'redux/reducers/commonReducer';
import { CenterBorderedTableListStyle } from 'components/core/table-list/styles/index';
import Modal from 'components/core/modal/Modal';
import PropTypes from 'prop-types';
import CloseIconSrc from 'assets/close_icon_black.png';
import ImageAlt from 'constants/imageAlt';
import {
    ButtonStrings,
    titleStrings
} from 'localizations/index';
import CreateCustomerForm from 'layouts/forms/CreateCustomerForm';
import { ResponseFetchCustomerList } from 'models/responses';
import { Employee } from 'models/objects/Employee';
import { observer } from 'mobx-react-lite';
import * as styled from './CreateCustomerModal.styled';

const CreateCustomerModal = observer((props) => {
    const [customerTableItems, setCustomerTableItems] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(new Employee());

    const customerListHeaders = [
        titleStrings.createdAt,
        titleStrings.name,
        titleStrings.phone,
        titleStrings.companyPhone,
        titleStrings.manager
    ];
    
    const onMainButtonPressed = (customers) => {
        setupCustomerTableItems(customers);
    }

    const setupCustomerTableItems = (customers) => {
        var customerTableItems = [];
        for (const customer of customers) {
            customerTableItems.push(
                [
                    customer.createdAt,
                    customer.name,
                    customer.phone,
                    customer.companyPhone,
                    customer.manager.name
                ]
            )
        }
        setCustomerTableItems(customerTableItems);
    };

    const onEmployeeSelected = (employee) => {
        setSelectedEmployee(employee);
    }

    const renderCustomerTableList = () => {
        return (
            <TableList
                datas={ customerTableItems }
                headers={ customerListHeaders }
                style={ CenterBorderedTableListStyle } />
        )
    }

    const content = () => {
        return (
            <styled.Container>
                <styled.CloseButton
                    onClick={ commonActions.hideModal }>
                    <img
                        src={ CloseIconSrc }
                        alt={ ImageAlt.closeIcon } />
                </styled.CloseButton>
                <p className="font-bold text-xl text-left">
                    { titleStrings.search }
                </p>
                <CreateCustomerForm
                    buttonTitle={ ButtonStrings.checkDuplication }
                    selectedEmployee={ selectedEmployee }
                    onEmployeeSelected={ onEmployeeSelected }
                    onFetchCustomerSuccess={ onMainButtonPressed }
                    onCreateCustomerSuccess={ props.onCreateCustomerSuccess }/>
                <styled.HorizontalLine />
                { renderCustomerTableList() }
            </styled.Container>
        )
    }

    return (
        <Modal
            content={ content() }
            isShow={ props.isShowing } />
    );
});

CreateCustomerModal.propTypes = {
    isShowing: PropTypes.bool,
    isSearchInProgress: PropTypes.bool,
    onCheckCustomerDuplication: PropTypes.func,
    onCreateCustomer: PropTypes.func,
    fetchEmployeeList: PropTypes.func,
    responseSearchCustomerList: PropTypes.object
};

CreateCustomerModal.defaultProps = {
    onCheckCustomerDuplication: () => { return; },
    onCreateCustomerSuccess: () => { return; },
    fetchEmployeeList: () => { return; },
    responseSearchCustomerList: new ResponseFetchCustomerList()
};

export default CreateCustomerModal;