import * as defaultValues from "constants/defaultValue";
import { AirConditioner } from "models/objects/AirConditioner";
import { ParkingMethod } from "models/objects/ParkingMethod";
import { RequestCreateCard } from "models/requests/RequestCreateCard";
import { useState } from "react";

const useBuildingForm = (onInputChange) => {
    const onAirConditionerChange = (input) => {
        onInputChange(
            {
                name: input.name,
                value: new AirConditioner(
                    defaultValues.emptyInt,
                    input.value
                )
            }
        )
    }

    const onParkingMethodChange = (input) => {
        onInputChange(
            {
                name: input.name,
                value: new ParkingMethod(
                    defaultValues.emptyInt,
                    input.value
                )
            }
        )
    }

    return {
        onAirConditionerChange,
        onParkingMethodChange
    }
}
export default useBuildingForm;