import defaultValues from "constants/defaultValues";
import formStates from "enumerations/formStates";
import { RequestCreateCard } from "models/requests/RequestCreateCard";
import { useState } from "react";
import { useSelector } from "react-redux";

const useItemDetailsFormPresenter = () => {
    const [requestWriteCard, setRequestWriteCard] = useState(new RequestCreateCard());
    const {
        card: property,
        formState,
        requestWriteCard: request
    } = useSelector(state => state.card);

    const getStationDistance = () => {
        const stationDistance = requestWriteCard.distanceStation;
        return stationDistance == defaultValues.emptyInt
            ? defaultValues.emptyString
            : stationDistance
    }

    const openNaverMap = () => {
        const address = formState == formStates.read
            ? property.address
            : request.address
        window.open(
            "https://map.naver.com/v5/search/" + address
        );
    }

    return {
        getStationDistance,
        openNaverMap,
        setRequestWriteCard
    }
}
export default useItemDetailsFormPresenter;