import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextInput from '../text-input/TextInput';
import { SmallBorderedTextInputStyle } from '../text-input/styles/index';
import defaultValues from 'constants/defaultValues';
import * as styled from './HorizontalTextInput.styled';
import textInputType from 'enumerations/textInputType';

class HorizontalTextInput extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
  }

  static get propTypes() {
    return {
      name: PropTypes.string,
      title: PropTypes.string,
      value: PropTypes.string,
      className: PropTypes.string,
      type: PropTypes.string,
      onChange: PropTypes.func,
      isDisabled: PropTypes.bool,
      titleColumn: PropTypes.number,
      inputColumn: PropTypes.number,
      leadingView: PropTypes.element,
      trailingView: PropTypes.element,
      suffixText: PropTypes.string,
    };
  }

  static defaultProps = {
    name: defaultValues.emptyString,
    title: defaultValues.emptyString,
    value: defaultValues.emptyString,
    className: defaultValues.emptyString,
    type: textInputType.text,
    isDisabled: false,
    onChange: () => {
      return;
    },
    titleColumn: 1,
    inputColumn: 3,
    leadingView: null,
    trailingView: null,
    suffixText: defaultValues.emptyString,
  };

  renderTitleLabel = () => {
    if (this.props.title.isEmpty()) {
      return null;
    }
    return (
      <styled.TitleSpan column={this.props.titleColumn}>
        {this.props.title}
      </styled.TitleSpan>
    );
  };

  renderTextInput = () => {
    return (
      <TextInput
        ref={this.textInput}
        name={this.props.name}
        style={SmallBorderedTextInputStyle}
        isDisabled={this.props.isDisabled}
        type={this.props.type}
        value={this.props.value}
        onChange={this.props.onChange}
        suffixText={this.props.suffixText}
      />
    );
  };

  render() {
    return (
      <styled.Container
        totalColumn={this.props.titleColumn + this.props.inputColumn}>
        {this.renderTitleLabel()}
        <styled.TrailingContainer column={this.props.inputColumn}>
          {this.props.leadingView}
          <styled.InputSpan>{this.renderTextInput()}</styled.InputSpan>
          {this.props.trailingView}
        </styled.TrailingContainer>
      </styled.Container>
    );
  }
}

export default HorizontalTextInput;
