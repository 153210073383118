import React from 'react';
import PropTypes from 'prop-types';
import * as styled from './CardPageTopMenu.styled';
import AddIconWhite from 'assets/add_icon_white.png';
import sizes from 'constants/sizes';
import Button from 'components/core/button/Button';
import { WhiteButtonStyle } from 'components/core/button/styles';
import buttonStrings from 'localizations/buttonStrings';
import { observer } from 'mobx-react-lite';
import { useDispatch, useSelector } from 'react-redux';
import { commonActionTypes } from 'redux/types';
import PaginationView from 'components/PaginationView/PaginationView';
import { useEffect } from 'react';
import useCardTopMenu from './useCardTopMenu';
import * as defaultValues from 'constants/defaultValue';

const CardPageTopMenu = observer((props) => {
  const cardReducer = useSelector((state) => state.card);
  const commonReducer = useSelector((state) => state.common);
  const hook = useCardTopMenu();
  const dispatch = useDispatch();

  const startMapPage = () => {
    dispatch({
      type: commonActionTypes.START_DESTINATION_PAGE,
      payload: {
        destination: '/map',
        data: null,
      },
    });
  };

  useEffect(() => {
    hook.setSavePropety(() => props.onShowSavingConfirmation);
  }, [props]);

  useEffect(() => {
    if (commonReducer.redirectData) {
      if (!cardReducer.pagination.totalPages.isEmpty()) {
        dispatch({ type: commonActionTypes.RESET });
      }
    }
  }, [cardReducer.pagination]);

  return (
    <styled.Wrapper>
      <PaginationView
        onChange={hook.onStartPageChange}
        initialPage={
          cardReducer.pagination.totalPages.isEmpty()
            ? defaultValues.emptyInt
            : hook.currentPage
        }
        totalPages={cardReducer.pagination.totalPages}
      />
      <styled.ActionContainer>
        <Button
          title={buttonStrings.newInput}
          icon={AddIconWhite}
          height={sizes.button.small}
          action={hook.showInputAddressPopup}
        />
        <Button
          title={buttonStrings.map}
          height={sizes.button.small}
          style={WhiteButtonStyle}
          action={startMapPage}
        />
        <Button
          action={hook.startPrintCardPage}
          title={buttonStrings.photo + '/' + buttonStrings.print}
          height={sizes.button.small}
          style={WhiteButtonStyle}
        />
      </styled.ActionContainer>
    </styled.Wrapper>
  );
});

CardPageTopMenu.propTypes = {
  onShowSavingConfirmation: PropTypes.func,
  onChangePagination: PropTypes.func,
};

CardPageTopMenu.defaultProps = {
  onShowSavingConfirmation: () => {
    return;
  },
  onChangePagination: defaultValues.emptyFunction,
};

export default CardPageTopMenu;
