import styled from 'styled-components';
import tw from 'twin.macro';

const Container = styled.div`
    ${tw`
        rounded-md shadow-md text-left text-sm
        z-[10]
        bg-white 
        border-solid border-t-[1px] border-[#DDDDDD]
        max-h-[65vh] overflow-y-scroll md:max-h-[100vh]
    `}
`;

const leftSide = styled.div`
    ${tw`
        w-full md:w-[50%]
        border-solid border-r-[1px] border-[#DDDDDD]
    `}
`;

const leftSide_noBorder = styled.div`
    ${tw`
        w-full md:w-[50%]
    `}
`;


const rightSide = styled.div`
    ${tw`
        w-full md:w-[50%]
        py-2 md:py-10
    `}
`;

const rightSide_noBorder = styled.div`
    ${tw`
        w-full md:w-[50%]
    `}
`;

const fullSide = styled.div`
    ${tw`
        w-[100%]
    `}
`;

const inputText = styled.input`
    ${tw`
        w-full rounded-md
        p-1 mb-2
        border-[1px] border-[#DDDDDD]
        focus:outline-none
    `}
`;

const inputText_mini = styled.input`
    ${tw`
        w-full rounded-md
        p-1 mb-2 w-[38px]
        border-[1px] border-[#DDDDDD]
        focus:outline-none
    `}
`;

const inputNumberContainer = styled.div`
    ${tw`
        w-[42%] rounded-md
        mb-2 px-1
        flex place-items-center
        border-[1px] border-[#DDDDDD]
    `}
`;

const inputNumber = styled.input`
    ${tw`
        w-5/6 h-[80%]
        p-1
        border-none
        focus:outline-none 
    `}
`;

export {
    Container,
    leftSide,
    leftSide_noBorder,
    rightSide,
    rightSide_noBorder,
    fullSide,
    inputText,
    inputText_mini,
    inputNumberContainer,
    inputNumber
}