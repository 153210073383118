import styled from "styled-components";
import tw from "twin.macro";

const Container = styled.div`
    ${ tw`flex flex-col space-y-2.5 pt-4 h-full` }
`;
const TextArea = styled.textarea`
    resize: none;
    ${tw `outline-none w-full text-left p-3 flex-1 border border-secondary`}
    ${ 
        (props) => props.isWriteState
            ? tw`bg-secondary-light`
            : tw`bg-white`
    }
`;

export {
    Container,
    TextArea
}