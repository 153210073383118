import dateFormats from "constants/dateFormats";
import defaultValues from "constants/defaultValues";
import lengths from "constants/lengths";
import moment from "moment";
import { RequestPagination } from "./RequestPagination";

export class RequestFetchCardLogList {
    constructor(
        keyword = defaultValues.emptyString,
        startDate = defaultValues.emptyString,
        endDate = defaultValues.emptyString,
        pagination = new RequestPagination()
    ) {
        this.keyword = keyword;
        this.page = defaultValues.emptyInt;
        this.startDate = startDate;
        this.endDate = endDate;
        this.pagination = pagination;
    }

    mapFromObject(anyObject) {
        this.keyword = anyObject.keyword;
        this.startDate = anyObject.startDate;
        this.endDate = anyObject.endDate;
        return this;
    }

    mapToJson() {
        return {
            'keyword': this.keyword,
            'page': this.pagination.page,
            'page_size': this.pagination.pageSize,
            'start_date': this.startDate,
            'end_date': this.endDate
        }
    }
}