import { store } from 'configs/store';
import { Card } from 'models/objects/Card';
import * as propertyPrintPages from 'enumerations/propertyPrintPages';
import * as defaultValues from 'constants/defaultValue';
import { Employee } from 'models/objects/Employee';
import * as imageOrientations from 'enumerations/imageOrientations';
import ImagePageInputProperty from 'components/ImagePageInput/ImagePageInputProperty';
import PageCardPreviewProperty from 'layouts/PrintCardPreview/PageCardPreviewProperty';
import { v4 as uuidv4 } from 'uuid';
import { Image } from 'models/objects/Image';
import documentService from 'services/documentService';

const SETUP_PROPERTY = 'print-card/SETUP_PROPERTY';
const SETUP_PRINT_PAGES = 'print-card/SETUP_PRINT_PAGES';
const DELETE_IMAGE_PAGE = 'print-card/DELETE_IMAGE_PAGE';
const IMAGE_PAGE_CHANGED = 'print-card/IMAGE_PAGE_CHANGED';
const SELECT_MANAGER = 'print-card/SELECT_MANAGER';
const SELECT_IMAGE = 'print-card/SELECT_IMAGE';
const SELECT_IMAGE_ORIENTATION = 'print-card/SELECT_IMAGE_ORIENTATION';
const UPDATE_IMAGE_PAGE = 'print-card/UPDATE_IMAGE_PAGE';
const CHECK_IMAGE_PAGE = 'print-card/CHECK_IMAGE_PAGE';
const UPDATE_PAGE_ORDER = 'print-card/UPDATE_PAGE_ORDER';
const UPDATE_DOCUMENT_SETTING = 'print-card/UPDATE_DOCUMENT_SETTING';
const ADD_IMAGE_PAGE = 'print-card/ADD_IMAGE_PAGE';
const ADD_IMAGE_COMPLETED = 'print-card/ADD_IMAGE_COMPLETED';

const states = (state, action) => {
  return {
    [SETUP_PROPERTY]: () => {
      return {
        ...state,
        property: action.payload,
      };
    },
    [UPDATE_DOCUMENT_SETTING]: () => {
      return {
        ...state,
        documentSetting: action.payload,
      };
    },
    [SETUP_PRINT_PAGES]: () => {
      return {
        ...state,
        pages: action.payload.pages,
        rentalHistoryPages: action.payload.rentalHistoryPages,
        floorInfoPages: action.payload.floorInfoPages,
        imagePages: action.payload.imagePages,
      };
    },
    [DELETE_IMAGE_PAGE]: () => {
      return {
        ...state,
        pages: action.payload.pages,
        imagePages: action.payload.imagePages,
      };
    },
    [IMAGE_PAGE_CHANGED]: () => {
      return {
        ...state,
        imagePages: action.payload.imagePages,
      };
    },
    [SELECT_MANAGER]: () => {
      return {
        ...state,
        manager: action.payload,
      };
    },
    [SELECT_IMAGE]: () => {
      return {
        ...state,
        imageUrl: action.payload,
      };
    },
    [SELECT_IMAGE_ORIENTATION]: () => {
      return {
        ...state,
        imageOrientation: action.payload,
      };
    },
    [UPDATE_IMAGE_PAGE]: () => {
      return {
        ...state,
        imagePages: action.payload,
      };
    },
    [CHECK_IMAGE_PAGE]: () => {
      return {
        ...state,
        checkedImagePages: action.payload,
      };
    },
    [UPDATE_PAGE_ORDER]: () => {
      return {
        ...state,
        pages: action.payload,
      };
    },
    [ADD_IMAGE_PAGE]: () => {
      return {
        ...state,
        isAddingImage: true,
        pages: action.payload.pages,
        imagePages: action.payload.imagePages,
      };
    },
    [ADD_IMAGE_COMPLETED]: () => {
      return {
        ...state,
        isAddingImage: false,
        pages: action.payload.pages,
        imagePages: action.payload.imagePages,
      };
    },
  };
};

export default (state = {}, action) => {
  if (!(action.type in states(state, action))) {
    return {
      ...state,
      property: state.property || new Card(),
      pages: state.pages || defaultValues.emptyArray,
      imagePages: state.imagePages || defaultValues.emptyArray,
      rentalHistoryPages: state.rentalHistoryPages || defaultValues.emptyArray,
      floorInfoPages: state.floorInfoPages || defaultValues.emptyArray,
      manager: state.manager || new Employee(),
      imageUrl:
        state.imageUrl ||
        'https://i.picsum.photos/id/20/3670/2462.jpg?hmac=CmQ0ln-k5ZqkdtLvVO23LjVAEabZQx2wOaT4pyeG10I',
      imageOrientation: state.imageOrientation || imageOrientations.horizontal,
      checkedImagePages: state.checkedImagePages || defaultValues.emptyArray,
      isAddingImage: state.isAddingImage || false,
    };
  }
  return states(state, action)[action.type]();
};

export const setupProperty = (property) => {
  store.dispatch({
    type: SETUP_PROPERTY,
    payload: property,
  });
};

export const setupPrintPages = (
  pages,
  rentalHistoryPages,
  floorInfoPages,
  imagePages
) => {
  store.dispatch({
    type: SETUP_PRINT_PAGES,
    payload: {
      pages: pages,
      rentalHistoryPages: rentalHistoryPages,
      floorInfoPages: floorInfoPages,
      imagePages: imagePages,
    },
  });
};

export const initiatePages = (response) => {
  const property = store.getState().printProperty.property;
  let pages = defaultValues.emptyArray;
  let rentalHistoryPages = defaultValues.emptyArray;
  let floorInfoPages = defaultValues.emptyArray;
  let imagePages = defaultValues.emptyArray;

  pages = [
    ...pages,
    new PageCardPreviewProperty(
      uuidv4(),
      propertyPrintPages.main,
      !response.useMain
    ),
    new PageCardPreviewProperty(
      uuidv4(),
      propertyPrintPages.summary,
      !response.useSummary
    ),
    new PageCardPreviewProperty(
      uuidv4(),
      propertyPrintPages.rentalHistory,
      !response.useRentalHistory
    ),
    new PageCardPreviewProperty(
      uuidv4(),
      propertyPrintPages.floorInfo,
      !response.useFloorArea
    ),
  ];
  // Setup rental history pages
  const limit = 16;
  for (
    let index = 0;
    index < Math.ceil(property.rentalHistories.length / limit);
    index++
  ) {
    const id = uuidv4();
    const rentalHistories = property.rentalHistories.slice(
      index * limit,
      index * limit + limit
    );
    rentalHistoryPages = [
      ...rentalHistoryPages,
      {
        key: id,
        datas: rentalHistories,
      },
    ];
  }
  for (
    let index = 0;
    index < Math.ceil(property.floorInfos.length / limit);
    index++
  ) {
    const id = uuidv4();
    const floorInfos = property.floorInfos.slice(
      index * limit,
      index * limit + limit
    );
    floorInfoPages = [
      ...floorInfoPages,
      {
        key: id,
        datas: floorInfos,
      },
    ];
  }
  for (const image of response.images) {
    const imageId = String(image.id);
    pages = [
      ...pages,
      new PageCardPreviewProperty(
        imageId,
        propertyPrintPages.image,
        !image.useImage
      ),
    ];
    let imagePaths = [...image.paths];
    if (image.paths.length == defaultValues.emptyInt) {
      imagePaths = [new Image()];
    }
    imagePages = [
      ...imagePages,
      new ImagePageInputProperty(
        imageId,
        image.title,
        imagePaths,
        image.useImage
      ),
    ];
  }
  let sortedPages = pages;
  if (!response.pagesOrder.includes('')) {
    sortedPages = [];
    for (const pageOrder of response.pagesOrder) {
      const page = pages.find(($0) => {
        if (
          !pageOrder.includes(propertyPrintPages.image) &&
          $0.type != propertyPrintPages.image
        ) {
          return pageOrder.includes($0.type);
        }
        const imageId = pageOrder.replace(propertyPrintPages.image, '');
        return imageId == $0.key;
      });
      if (page) {
        sortedPages.push(page);
      }
    }
  }
  setupPrintPages(sortedPages, rentalHistoryPages, floorInfoPages, imagePages);
};

export const removePrintPages = () => {
  const pages = store.getState().printProperty.pages;
  const imagePages = store.getState().printProperty.imagePages;
  const checkedImagePages = store.getState().printProperty.checkedImagePages;
  if (checkedImagePages.isEmpty()) {
    return;
  }
  store.dispatch({
    type: DELETE_IMAGE_PAGE,
    payload: {
      pages: pages.filter(
        ($0) => !checkedImagePages.some(($1) => $0.key == $1)
      ),
      imagePages: imagePages.filter(
        ($0) => !checkedImagePages.some(($1) => $0.id == $1)
      ),
    },
  });
};

export const selectManager = (manager) => {
  store.dispatch({
    type: SELECT_MANAGER,
    payload: manager,
  });
};

export const selectImage = (imageUrl) => {
  const property = store.getState().printProperty.property;
  property.images = [new Image(0, imageUrl)];
  setupProperty(property);
};

export const selectImageOrientation = (imageOrientation) => {
  store.dispatch({
    type: SELECT_IMAGE_ORIENTATION,
    payload: imageOrientation,
  });
};

export const changeImageCount = (id, imageCount) => {
  const imagePages = store
    .getState()
    .printProperty.imagePages.map((imagePage) => imagePage);
  const pageIndex = imagePages.findIndex(($0) => $0.id == id);
  if (imagePages[pageIndex].imagePaths.length < imageCount) {
    let different = imageCount - imagePages[pageIndex].imagePaths.length;
    for (let index = 0; index < different; index++) {
      imagePages[pageIndex].imagePaths = [
        ...imagePages[pageIndex].imagePaths,
        new Image(),
      ];
    }
  }
  if (imagePages[pageIndex].imagePaths.length > imageCount) {
    let different = imagePages[pageIndex].imagePaths.length - imageCount;
    imagePages[pageIndex].imagePaths = imagePages[pageIndex].imagePaths.splice(
      defaultValues.emptyInt,
      imagePages[pageIndex].imagePaths.length - different
    );
  }
  store.dispatch({
    type: UPDATE_IMAGE_PAGE,
    payload: [...imagePages],
  });
};

export const changeImageTitle = (id, title) => {
  const imagePages = store.getState().printProperty.imagePages;
  const pageIndex = imagePages.findIndex(($0) => $0.id == id);
  imagePages[pageIndex].title = title;
  store.dispatch({
    type: UPDATE_IMAGE_PAGE,
    payload: imagePages,
  });
};

export const changeImageSize = (id, imageSize) => {
  const imagePages = store.getState().printProperty.imagePages;
  const pageIndex = imagePages.findIndex(($0) => $0.id == id);
  imagePages[pageIndex].size = imageSize;
  store.dispatch({
    type: UPDATE_IMAGE_PAGE,
    payload: imagePages,
  });
};

export const onCheckImageForm = (key) => {
  let checkedImagePages = store.getState().printProperty.checkedImagePages;
  if (checkedImagePages.includes(key)) {
    checkedImagePages = checkedImagePages.filter(($0) => $0 != key);
  } else {
    checkedImagePages = [...checkedImagePages, key];
  }
  store.dispatch({
    type: CHECK_IMAGE_PAGE,
    payload: checkedImagePages,
  });
};
export const handleUpdatePageOrder = (pages) => {
  store.dispatch({
    type: UPDATE_PAGE_ORDER,
    payload: pages,
  });
};

export const updatePropertyStatus = (type, isExcluded) => {
  const pages = store.getState().printProperty.pages;
  const pageIndex = pages.findIndex(($0) => $0.type == type);
  pages[pageIndex].isExcluded = isExcluded;
  store.dispatch({
    type: UPDATE_PAGE_ORDER,
    payload: [...pages],
  });
};

export const updateImagePages = (imagePages) => {
  store.dispatch({
    type: IMAGE_PAGE_CHANGED,
    payload: {
      imagePages: [...imagePages],
    },
  });
};

export const resetCheckedImage = () => {
  store.dispatch({
    type: CHECK_IMAGE_PAGE,
    payload: [],
  });
};

export const addImageDocument = () => {
  const pages = store.getState().printProperty.pages;
  const imagePages = store.getState().printProperty.imagePages;

  store.dispatch({
    type: ADD_IMAGE_PAGE,
    payload: {
      pages: [
        ...pages,
        new PageCardPreviewProperty(
          defaultValues.emptyInt.toString(),
          propertyPrintPages.image,
          false
        ),
      ],
      imagePages: [
        ...imagePages,
        new ImagePageInputProperty(
          defaultValues.emptyInt.toString(),
          defaultValues.emptyString,
          [new Image()],
          true
        ),
      ],
    },
  });
};

export const didImageAddedSucceed = (imageDocument) => {
  const _pages = [...store.getState().printProperty.pages];
  const pageIndex = _pages.findIndex(
    (page) => page.key === defaultValues.emptyInt.toString()
  );
  _pages[pageIndex].key = imageDocument.id.toString();

  const _imagePages = [...store.getState().printProperty.imagePages];
  const imagePageIndex = _imagePages.findIndex(
    (imagePage) => imagePage.id === defaultValues.emptyInt.toString()
  );
  _imagePages[imagePageIndex].id = imageDocument.id.toString();

  store.dispatch({
    type: ADD_IMAGE_COMPLETED,
    payload: {
      pages: _pages,
      imagePages: _imagePages,
    },
  });
};

export const didImageAddedFailed = () => {
  const _pages = [...store.getState().printProperty.pages];
  const _imagePages = [...store.getState().printProperty.imagePages];

  store.dispatch({
    type: ADD_IMAGE_COMPLETED,
    payload: {
      pages: [
        ..._pages.filter(
          (page) => page.key === defaultValues.emptyInt.toString()
        ),
      ],
      imagePages: [
        ..._imagePages.filter(
          (imagePage) => imagePage.id === defaultValues.emptyInt.toString()
        ),
      ],
    },
  });
};
