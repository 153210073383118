import {
  RequestFetchEmployeeList,
  RequestUpdatePrintStatus,
} from 'models/requests';
import { useEffect } from 'react';
import { useState } from 'react';
import * as defaultValues from 'constants/defaultValue';
import employeeService from 'redux/actions/employeeAction';
import * as printPropertySlice from 'pages/PrintCardPage/printCardReducer';
import { useSelector } from 'react-redux';
import * as imageOrientations from 'enumerations/imageOrientations';
import cookiesManager from 'managers/cookies.manager';
import cardService from 'redux/actions/cardActions';
import etcService from 'services/etcService';
import { RequestUpdatePropertyImage } from 'models/requests/RequestUpdatePropertyImage';
import { useParams } from 'react-router';
import * as propertyPrintPages from 'enumerations/propertyPrintPages';
import documentService from 'services/documentService';

const useMainPageForm = () => {
  const { propertyId } = useParams();
  const [employees, setEmployees] = useState([]);
  const [selectedManagerOption, setSelectedManagerOption] = useState({
    key: 0,
    value: '',
  });
  const [managerOptions, setManagerOptions] = useState([]);
  const orientationOptions = [
    { key: imageOrientations.vertical, value: '가로' },
    { key: imageOrientations.horizontal, value: '세로' },
  ];
  const { property, pages } = useSelector((state) => state.printProperty);
  const mainPage = pages.find(($0) => $0.type == propertyPrintPages.main);

  const loadData = () => {
    employeeService.fetchEmployeeList(
      new RequestFetchEmployeeList(),
      didFetchEmployeeListSucceed
    );
  };

  const didFetchEmployeeListSucceed = (response) => {
    setEmployees(response.employees);
    const currentManager = cookiesManager.getCurrentUser();
    setSelectedManagerOption({
      key: currentManager.id,
      value: currentManager.name,
    });
    printPropertySlice.selectManager(currentManager);
    setManagerOptions(
      response.employees.map((employee) => {
        return {
          key: employee.id,
          value: employee.name,
        };
      })
    );
  };

  const onSelectManager = (option) => {
    setSelectedManagerOption(option);
    printPropertySlice.selectManager(
      employees.find(($0) => $0.id == option.key)
    );
  };

  const onImageChange = (imageFile) => {
    const formData = new FormData();
    formData.append('file', imageFile);
    formData.append('type', 'document');
    etcService.uploadImage(formData, didUploadImageSucceed);
    printPropertySlice.selectImage(URL.createObjectURL(imageFile));
    return;
  };

  const didUploadImageSucceed = (response) => {
    cardService.updatePropertyImage(
      new RequestUpdatePropertyImage(property.id, response.mapToJson()),
      () => {
        return;
      }
    );
  };

  const onUpdatePrintStatus = (setting) => {
    printPropertySlice.updatePropertyStatus(
      propertyPrintPages.main,
      setting.key
    );
    const pageIndex = pages.findIndex(
      ($0) => $0.type == propertyPrintPages.main
    );
    pages[pageIndex].isExcluded = setting.key;

    const request = new RequestUpdatePrintStatus();
    request.propertyId = propertyId;
    request.key = 'main';
    request.isIncluded = setting.key == defaultValues.emptyInt;

    documentService.updatePrintStatus(
      request,
      () => {
        return;
      },
      () => {
        return;
      }
    );
  };

  useEffect(() => {
    loadData();
    printPropertySlice.selectImageOrientation(orientationOptions.first());
  }, defaultValues.emptyArray);

  return {
    property,
    managerOptions,
    selectedManagerOption,
    orientationOptions,
    mainPage,
    onSelectManager,
    onImageChange,
    onUpdatePrintStatus,
  };
};

export default useMainPageForm;
