import React, { useEffect, useState } from 'react';
import Button from '../../components/core/button/Button';
import PropTypes from 'prop-types';
import {
    DarkGrayButtonStyle
} from 'components/core/button/styles';
import Sizes from 'constants/sizes';
import tw from 'twin.macro';
import styled from 'styled-components';
import sizes from 'constants/sizes';
import buttonStrings from 'localizations/buttonStrings';
import TextInput from 'components/core/text-input/TextInput';
import { BorderedTextInputStyle } from 'components/core/text-input/styles';
import ArrowRightIconBlack from 'assets/arrow_right_icon_black.png';
import DropdownInput from 'components/core/dropdown-input/DropdownInput';
import { BorderedDropdownInputStyle } from 'components/core/dropdown-input/styles';
import { Employee } from 'models/objects/Employee';
import DropdownInputViewModel from 'components/core/dropdown-input/DropdownInputViewModel';
import { observer } from 'mobx-react';
import { ButtonViewModel } from 'components/core/button/ButtonViewModel';
import { useDispatch } from 'react-redux';
import { commonActionTypes, customerActionTypes } from 'redux/types';
import { RequestUpdateCustomerManager } from 'models/requests/RequestUpdateCustomerManager';
import { customerAction } from 'redux/actions';

const Container = styled.div`
    ${tw `flex flex-col gap-5`}
`;

const InputContainer = styled.div`
    ${tw `grid grid-cols-7 gap-3`}
`;

const TextInputWrapper = styled.div`
    ${tw `col-span-3`}
`;

const SeparatorIcon = styled.img`
    height: ${ sizes.image.small }px;
    width: ${ sizes.image.small }px;
    ${tw `m-auto`}
`;

const CreateChangeManagerForm = observer((props) => {
    const [buttonViewModel] = useState(new ButtonViewModel());
    const [selectedNewManager, setSelectedNewManager] = useState(new Employee());
    const dispatch = useDispatch();

    const renderOldManagerInput = () => {
        return (
            <TextInputWrapper>
                <TextInput
                    height={ sizes.textInput.small }
                    isDisabled={ true }
                    value={ props.selectedOldEmployee.name }
                    style={ BorderedTextInputStyle } />
            </TextInputWrapper>
        )
    }

    const onSelectNewManager = (item) => {
        const manager = props.employeeOptions.find((employee) => {
            return employee.id == item.key
        });
        setSelectedNewManager(manager);
        return
    }

    const renderNewManagerInput = () => {
        return (
            <TextInputWrapper>
                <DropdownInput
                    options={ setupEmployeeOptionItems() }
                    selectedOption={{
                        key: selectedNewManager.id,
                        value: selectedNewManager.name
                    }}
                    height={ sizes.textInput.small }
                    handleSelectOption={ onSelectNewManager }
                    style={ BorderedDropdownInputStyle } />
            </TextInputWrapper>
        )
    }

    const renderSeparatorIcon = () => {
        return (
            <SeparatorIcon
                src={ ArrowRightIconBlack }
                alt="" />
        )
    }

    const onStartUpdateManager = () => { 
        buttonViewModel.startLoading()
        dispatch({
            type: customerActionTypes.UPDATE_CUSTOMER_MANAGER,
            payload: customerAction.changeCustomerManager(
                new RequestUpdateCustomerManager(
                    props.selectedCustomers.map((customer) => customer.id),
                    selectedNewManager.id
                ),
                didUpdateManagerSucceed
            )
        })
    }

    const didUpdateManagerSucceed = () => {
        buttonViewModel.stopLoading()
        dispatch({
            type: commonActionTypes.HIDE_MODAL
        });
        props.onChangeManagerSuccess()
        return
    }

    const setupEmployeeOptionItems = () => {
        return props.employeeOptions.map(
            (employee) => {
                const dropDownViewModel = new DropdownInputViewModel()
                dropDownViewModel.key = employee.id
                dropDownViewModel.value = employee.name
                return dropDownViewModel
            }
        )
    }

    useEffect(() => {
        if(props.employeeOptions.isEmpty()) { return; }
        setSelectedNewManager(props.employeeOptions[0])
    }, [props.employeeOptions]);

    return (
        <Container>
            <InputContainer>
                { renderOldManagerInput() }
                { renderSeparatorIcon() }
                { renderNewManagerInput() }
            </InputContainer>
            <Button
                action={ onStartUpdateManager }
                height={ Sizes.button.small }
                style={ DarkGrayButtonStyle }
                title={ buttonStrings.edit }
                width={ sizes.button.full }
                viewModel={ buttonViewModel } />
        </Container>
    )
});

CreateChangeManagerForm.propTypes = {
    reset: PropTypes.func,
    buttonTitle: PropTypes.string,
    selectedOldEmployee: PropTypes.object,
    selectedCustomers: PropTypes.array,
    employeeOptions: PropTypes.array,
    onChangeManagerSuccess: PropTypes.func
};

CreateChangeManagerForm.defaultProps = {
    reset: () => { return; },
    employeeOptions: [],
    selectedCustomers: [],
    selectedOldEmployee: new Employee(),
    onChangeManagerSuccess: PropTypes.func
};

export default CreateChangeManagerForm;