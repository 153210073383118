import React from 'react'
import PropTypes from 'prop-types';
import { PrimaryTableListStyle } from './styles/index';
import defaultValues from "constants/defaultValues";
import * as styled from "./TableList.styled";
import { TableViewModel } from './TableViewModel';
import TableListPresenter from './TableListPresenter';

const TableList = (props) => {
    const presenter = TableListPresenter(props.onDoubleClickRow);
    
    const renderHeaders = () => {
        return props.headers.map(
            function( object, index ) {
                return (
                    <styled.ColumnHeader
                        {...props}
                        key={ index }
                        index={ index }>
                        {object}
                    </styled.ColumnHeader>
                )
            }.bind(this)
        )
    };

    const renderColumns = (row, columns) => {
        if (!Array.isArray(props.datas[defaultValues.emptyInt])) {
            return (
                <styled.TableDataCell
                    {...props}
                    key={ row }
                    index={ row }
                    onClick={
                        () => {
                            props.handleAction(row)
                        }
                    }>
                { columns }
            </styled.TableDataCell>
            )
        }
        return (
            columns.map( function( object, index){
                return (
                    <styled.TableDataCell
                        { ...props }
                        key={ index }
                        index={ index }
                        onClick={
                            () => {
                                presenter.onClick(object.key);
                                props.handleAction(object.key)
                            }
                        }>
                        { object }
                    </styled.TableDataCell>
                )
            }.bind(this))
        )
    };

    const renderViewModelColumns = (columnTableViewModels) => {
        return (
            columnTableViewModels.map( function(object, index){
                return (
                    <styled.TableDataCell
                        { ...props }
                        key={ index }
                        index={ index }
                        onClick={ object.action }>
                        { object.customView }
                    </styled.TableDataCell>
                )
            }.bind(this))
        )
    }

    const renderRows = () => {
        if (!props.viewModel.rowTableViewModels.isEmpty()) {
            return props.viewModel.rowTableViewModels.map((object, index) => {
                return (
                    <styled.Row
                        { ...props }
                        key={ index }>
                        { renderViewModelColumns(object.columnTableViewModels) }
                    </styled.Row>
                )
            });
        }
        if (!props.items.isEmpty()) {
            return props.items.map(
                (item, index) => {
                    return (
                        <styled.Row
                            { ...props }
                            key={ index }
                            onClick={
                                () => {
                                    presenter.onClick(item.key);
                                    props.onSingleClickRow(item.key);
                                }
                            }>
                            {
                                item.values.map(
                                    (value, index) => renderColumns(index, value)
                                )
                            }
                        </styled.Row>
                    )
                }
            );
        }
        return props.datas.map( function( object, i ) {
            return (
                <styled.Row
                    { ...props }
                    key={ i }>
                    { renderColumns(i, object) }
                </styled.Row>
            )
        }.bind(this));
    };
    
    return (
        <styled.Wrapper
             className={ props.className }
             maximumHeight={ props.maximumHeight }>
            <styled.Table className="position-relative">
                 <thead>
                     <tr>
                         { renderHeaders() }
                     </tr>
                 </thead>
                 <tbody>
                     { renderRows() }
                 </tbody>
             </styled.Table>
        </styled.Wrapper>
     )
}

TableList.propTypes = {
    isClickable: PropTypes.bool,
    isHidden: PropTypes.bool,
    headers: PropTypes.array,
    alignment: PropTypes.string,
    datas: PropTypes.array,
    items: PropTypes.array,
    firstColumnPadding: PropTypes.number,
    className: PropTypes.string,
    style: PropTypes.object,
    handleAction: PropTypes.func,
    onSingleClickRow: PropTypes.func,
    onDoubleClickRow: PropTypes.func,
    maximumHeight: PropTypes.number,
    viewModel: PropTypes.object
};

TableList.defaultProps = {
    isClickable: false,
    isHidden: false,
    headers: [],
    datas: [],
    items: [],
    className: "",
    firstColumnPadding: 0,
    style: PrimaryTableListStyle,
    maximumHeight: defaultValues.emptyInt,
    handleAction: () => { return; },
    onSingleClickRow: () => { return; },
    onDoubleClickRow: () => { return; },
    viewModel: new TableViewModel()
};

export default TableList;