import styled from "@emotion/styled";
import colors from "constants/colors";
import sizes from "constants/sizes";
import tw from "twin.macro";

export const Container = styled.div`
    min-width: 70%;
    padding: 3rem;
    margin-left: 30%;
    position: relative;
    overflow-y: auto;
`;

export const PagesContainer = styled.div`
    width: 100%;
    ${ tw`space-y-10` }
`;

export const PageWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    height: ${ props => props.height }px;
    border: ${ sizes.border.small }px solid ${ colors.gray.default };
    overflow: hidden;
`;