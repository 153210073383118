import defaultValues from "constants/defaultValues";

export class RequestUpdateOrder {
    constructor(
        propertyId = defaultValues.emptyInt,
        pagesOrder = defaultValues.emptyArray
    ) {
        this.propertyId = propertyId;
        this.pagesOrder = pagesOrder;
    }

    mapToJson() {
        return {
            'property_id': this.propertyId,
            'pages_order': this.pagesOrder
        }
    }
}