import styled from "@emotion/styled";
import colors from "constants/colors";
import sizes from "constants/sizes";
import tw from "twin.macro";

export const Container = styled.div`
    position: absolute;
    width: 30%;
    height: 100%;
    border-top-style: none;
    border-bottom-style: none;
    border-left-style: none;
    border-right-style: solid;
    border-color: ${ colors.gray.light };
    border-width: ${ sizes.border.extraLarge }px;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
`;

export const ActionsContainer = styled.div`
    display: flex;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 1.5rem;
    justify-content: space-between;
`;

export const LeadingActionsContainer = styled.div`
    display: flex;
    column-gap: 1rem;
`;

export const HeaderContainer = styled.div`
    display: flex;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background: ${ colors.gray.default };
    justify-content: space-between;
    ${ tw`grid grid-cols-5 gap-3 px-3` }
`;

export const SideHeaderWrapper = styled.div`
    ${ tw`col-span-1` }
`;

export const MiddleHeaderWrapper = styled.div`
    ${ tw`col-span-3` }
`;

export const PageSettingWrapper = styled.div`
    ${ tw`col-span-1` }
`;

export const PageSettingContainer = styled.div`
    overflow-y: auto;
    height: 100%;
    padding-bottom: 1rem;
    ${ tw`px-3 my-3` }
`;

export const InputWrapper = styled.div`
    ${ tw` pb-3` }
`