import styled from 'styled-components';
import tw from 'twin.macro';

const Container = styled.div`
  ${tw`grid text-left w-full`}
  ${(props) =>
    `grid-template-columns: repeat(${props.totalColumn}, minmax(0, 1fr));`}
`;
const TitleSpan = styled.p`
  ${(props) => `grid-column: span ${props.column} / span ${props.column}`};
  ${tw`whitespace-nowrap my-auto overflow-hidden`}
`;
const TrailingContainer = styled.div`
  ${(props) => `grid-column: span ${props.column} / span ${props.column}`};
  ${tw`flex gap-3`}
`;
const InputSpan = styled.div`
  ${tw`w-full`}
`;

export { Container, TitleSpan, InputSpan, TrailingContainer };
