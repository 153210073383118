import { Pagination } from "models/objects/Pagination";
import { Role } from "models/objects/Role";

export class ResponseFetchRoleList {
    constructor(
        roles = [],
        pagination = new Pagination()
    ) {
        this.roles = roles;
        this.pagination = pagination;
    }

    mapFromJson(json) {
        if (!json) { return this; }
        this.roles = json.data.map(
            (roleJson) => new Role().mapFromJson(roleJson)
        )
        this.pagination = new Pagination(
            json['currentPage'],
            json['totalItems'],
            json['totalPages']
        );
        return this;
    }
}