import {
    authActionTypes
} from 'redux/types';

const states = (state, action) => {
    return {
        [authActionTypes.LOGIN_SUCCEED]: () => {
            return {
                ...state,
                user: action.payload
                    ? action.payload.user
                    : {}
            }
        }
    }
}

export default (state = {}, action) => {
    if (!(action.type in states(state, action))) {
        return state;
    }
    return states(state, action)[action.type]();
}