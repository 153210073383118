import { useSelector } from "react-redux";
import * as commonActions from "redux/reducers/commonReducer"

const useCardAddress = () => {
    const property = useSelector(state => state.card.card);

    const onOpenNaverMap = () => {
        window.open(
            "https://map.naver.com/v5/search/" + property.address
        );
    }

    const onOpenKakaoMap = () => {
        window.open(
            "http://map.daum.net/link/search/" + property.address
        );
    }

    const onStartMapPage = () => {
        commonActions.redirectTo("/map?address=" + property.address);
    }

    return {
        onOpenNaverMap,
        onOpenKakaoMap,
        onStartMapPage
    }
}

export default useCardAddress;