import * as defaultValues from "constants/defaultValue";
import lengths from "constants/lengths";

export class RequestFetchEmployeeLoginHistoryList {
    constructor(
        keyword = defaultValues.emptyString,
        page = defaultValues.page,
        pageSize = lengths.pageSize,
        startDate = defaultValues.emptyString,
        endDate = defaultValues.emptyString
    ) {
        this.keyword = keyword;
        this.page = page;
        this.pageSize = pageSize;
        this.startDate = startDate;
        this.endDate = endDate;
    }

    mapFromObject(anyObject) {
        this.keyword = anyObject.keyword;
        this.startDate = anyObject.startDate;
        this.endDate = anyObject.endDate;
        return this;
    }

    mapToJson() {
        return {
            'keyword': this.keyword,
            'page': this.page,
            'page_size': this.pageSize,
            'start_date': this.startDate,
            'end_date': this.endDate
        }
    }
}