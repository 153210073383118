import defaultValues from "constants/defaultValues";

export class RequestUpdatePrintStatus {
  constructor(
    propertyId = defaultValues.emptyInt,
    key = defaultValues.emptyString,
    imageId,
    isIncluded = false
  ) {
    this.propertyId = propertyId;
    this.key = key;
    this.imageId = imageId;
    this.isIncluded = isIncluded;
  }

  mapToJson() {
    const json = {
      'property_id': this.propertyId,
      'key': this.key,
      'used': this.isIncluded
    }
    if (this.imageId !== undefined) {
      json['image_id'] = this.imageId
    }
    return json;
  }
}