import * as defaultValues from "constants/defaultValue";
import lengths from "constants/lengths";
import { RequestDeleteSearchHistory } from "models/requests/RequestDeleteSearchHistory";
import { RequestPagination } from "models/requests/RequestPagination";
import { useState } from "react";
import searchServices from "redux/actions/searchActions";
import * as commonActions from "redux/reducers/commonReducer";

const useSearchHistoryLayout = () => {
    const [
        propertyHistories,
        setPropertyHistories
    ] = useState([])

    const loadData = () => {
        searchServices.fetchSearchHistories(
            new RequestPagination(
                defaultValues.page,
                lengths.pageSize
            ),
            didFetchSucceed
        )
    }

    const didFetchSucceed = (response) => {
        setPropertyHistories(
            response.propertyHistories
        )
    }

    const onStartDeleteHistory = (id) => {
        searchServices.deleteSearchHistory(
            new RequestDeleteSearchHistory(id),
            loadData
        )
        return;
    }

    const onStartClearHistory = () => {
        searchServices.deleteSearchHistory(
            new RequestDeleteSearchHistory(),
            loadData
        )
        return;
    }

    const onStartSearchDetail = (index) => {
        commonActions.redirectTo(
            "/search/history?page={0}".format(index)
        )
    }

    return {
        onStartDeleteHistory,
        onStartClearHistory,
        onStartSearchDetail,
        loadData,
        propertyHistories
    }
}

export default useSearchHistoryLayout;