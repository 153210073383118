import defaultValues from "constants/defaultValues";

const TableRowPresenter = (
    key = defaultValues.emptyInt,
    values = defaultValues.emptyArray
) => {
    return {
        key,
        values
    }
}

export default TableRowPresenter;