import defaultValues from "constants/defaultValues";
import { AirConditioner } from "models/objects/AirConditioner";
import { ParkingMethod } from "models/objects/ParkingMethod";

export class RequestCreateBuildingInformation {
    constructor(
        totalArea = null,
        remodelAt = defaultValues.emptyString,
        usage = defaultValues.emptyString,
        buildAt = defaultValues.emptyString,
        builtWith = defaultValues.emptyString,
        floor = defaultValues.emptyString,
        elevator = defaultValues.emptyInt,
        landPrice = defaultValues.emptyInt,
        totalParkingGoverment,
        totalParkingPersonal,
        isIllegalBuilding = false,
        isGroupBuilding = false,
        airConditioner = new AirConditioner(),
        parkingMethod = new ParkingMethod(),
        permitDate = defaultValues.emptyString,
    ) {
        this.totalArea = totalArea;
        this.remodelAt = remodelAt;
        this.usage = usage;
        this.buildAt = buildAt;
        this.builtWith = builtWith;
        this.floor = floor;
        this.elevator = elevator;
        this.landPrice = landPrice;
        this.totalParkingGoverment = totalParkingGoverment;
        this.totalParkingPersonal = totalParkingPersonal;
        this.isIllegalBuilding = isIllegalBuilding;
        this.isGroupBuilding = isGroupBuilding;
        this.airConditioner = airConditioner;
        this.parkingMethod = parkingMethod;
        this.permitDate = permitDate;
    }

    getTotalParkingGoverment() {
        return Number(this.totalParkingGoverment || defaultValues.emptyInt);
    }

    getTotalParkingPersonal() {
        return Number(this.totalParkingPersonal || defaultValues.emptyInt);
    }

    mapToJson() {
        return {
            'custom_total_area': this.totalArea,
            'remodel_at': this.remodelAt,
            'usage': this.usage,
            'build_at': this.buildAt,
            'build_with': this.builtWith,
            'floor': this.floor,
            'elevator': this.elevator,
            'land_price': this.landPrice,
            'total_parking_gov': this.getTotalParkingGoverment(),
            'total_parking_personal': this.getTotalParkingPersonal(),
            'illegal_building': this.isIllegalBuilding,
            'group_building': this.isGroupBuilding,
            'air': this.airConditioner.mapToJson(),
            'parking': this.parkingMethod.mapToJson(),
            'permit_date': this.permitDate,
        }
    }
}