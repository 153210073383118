import defaultValues from "constants/defaultValues";
import roleType from "enumerations/roleType";
import { Role } from "models/objects/Role";

export class Employee {
    constructor(
        id = defaultValues.emptyInt,
        username = defaultValues.emptyString,
        password = defaultValues.emptyString,
        name = defaultValues.emptyString,
        email = defaultValues.emptyString,
        phoneNumber = defaultValues.emptyString,
        macAddress = defaultValues.emptyString,
        role = new Role(),
        memo = defaultValues.emptyString,
        condition = defaultValues.emptyString,
    ) {
        this.id = id;
        this.username = username;
        this.password = password;
        this.name = name;
        this.email = email;
        this.phoneNumber = phoneNumber;
        this.macAddress = macAddress;
        this.role = role;
        this.memo = memo;
        this.condition = condition;
        this.isAdmin = this.role.id == roleType.admin;
    }

    mapFromJson(json) {
        if (!json) { return this; }
        this.id = json['id'];
        this.username = json['username'];
        this.password = json['password'];
        this.name = json['name'];
        this.email = json['email'];
        this.phoneNumber = json['phone_number'];
        this.macAddress = json['mac_address'];
        this.role = new Role().mapFromJson(json['role']);
        this.memo = json['memo'];
        this.isAdmin = this.role.id == roleType.admin;
        return this;
    }
}