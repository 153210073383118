import styled from "styled-components";
import tw from "twin.macro";

const Container = styled.div`
    ${ tw`flex flex-col space-y-2.5 h-full` }
`;
const UnitDescriptionText = styled.p`
    ${ tw`text-right` }
`;
const TableWrapper = styled.div`
    height: 300px;
`;
const RightNavigationMenu = styled.div`
    ${ tw`flex gap-5` }
`;

const TableColumn = styled.td`
    ${(props) => props.isSelected && 'outline: green solid 1px;'}
    width: ${props => props.colWidth};
`;

const TextSpan = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export {
    Container,
    UnitDescriptionText,
    TableWrapper,
    RightNavigationMenu,
    TableColumn,
    TextSpan
}