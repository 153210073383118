import defaultValues from "constants/defaultValues";
import { Image } from "./Image";

export class DocumentImage {
    constructor(
        id = defaultValues.emptyInt,
        title = defaultValues.emptyString,
        paths = defaultValues.emptyArray,
        useImage = true
    ) {
        this.id = id;
        this.title = title;
        this.paths = paths;
        this.useImage = useImage;
    }

    mapFromJson(json) {
        if (!json) { return this; }
        this.id = json['id'];
        this.title = json['title'];
        this.paths = json['paths'].map(
            $0 => new Image().mapFromJson($0)
        );
        this.useImage = json['use_image'];
        return this;
    }
}