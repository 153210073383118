import defaultValues from "constants/defaultValues";

export class RequestWriteImageInformation {
    constructor(
        id = defaultValues.emptyInt,
        imageUrl = defaultValues.emptyString,
        imageFile = null
    ) {
        this.id = id;
        this.imageUrl = imageUrl;
        this.imageFile = imageFile;
    }

    mapToJson() {
        return {
            'id': this.id,
            'url': this.imageUrl
        }
    }
}