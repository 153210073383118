import React from 'react';
import PropTypes from 'prop-types';
import DropdownInput from 'components/core/dropdown-input/DropdownInput';
import { BorderedDropdownInputStyle } from 'components/core/dropdown-input/styles';
import TextInput from 'components/core/text-input/TextInput';
import Text from 'components/core/text/Text';
import FileInput from 'components/FileInput/FileInput';
import alignments from 'constants/alignments';
import * as styled from './ImagePageInput.styled';
import useImagePageInput from './useImagePageInput';
import * as defaultValues from 'constants/defaultValue';
import ImagePageInputProperty from './ImagePageInputProperty';
import { useSelector } from 'react-redux';
import CheckboxInput from 'components/core/input-checkbox/CheckboxInput';
import ImagePageSize from 'enumerations/ImagePageSize';
import { toast } from 'react-toastify';

const ImagePageInput = (props = ImagePageInputProperty()) => {
  const hook = useImagePageInput(props);
  const pages = useSelector((state) => state.printProperty.pages);
  const checkedImagePages = useSelector(
    (state) => state.printProperty.checkedImagePages
  );

  const renderImageSizeDropdown = () => {
    if (props.imagePaths.length > 1) {
      return null;
    }
    return (
      <styled.SizeDropdownWrapper>
        <DropdownInput
          isEnabled={!hook.getIsFormDisabled()}
          selectedOption={props.size.optionItem}
          options={hook.setupSizeSettingOptions()}
          style={BorderedDropdownInputStyle}
          handleSelectOption={hook.onChangeImageSize}
        />
      </styled.SizeDropdownWrapper>
    );
  };

  const renderSelectImageInputs = () => {
    return props.imagePaths.map((imagePath, index) => (
      <styled.InputContainer key={index}>
        <styled.CheckboxWrapper />
        <styled.ImageSelectionContainer>
          <styled.ImageSelectionTitle>
            <Text
              alignment={alignments.center}
              value={'이미지'}
            />
          </styled.ImageSelectionTitle>
          <styled.ImageSelectionFileUpload imageCount={props.imagePaths.length}>
            <FileInput
              disabled={hook.getIsFormDisabled()}
              fileUrl={imagePath.url}
              onFileChange={(imageFile) => hook.onImageChange(index, imageFile)}
              onError={(errorMessage) => toast.error(errorMessage)}
            />
          </styled.ImageSelectionFileUpload>
          {renderImageSizeDropdown()}
        </styled.ImageSelectionContainer>
      </styled.InputContainer>
    ));
  };

  return (
    <styled.Container>
      <styled.InputContainer>
        <styled.CheckboxWrapper>
          <CheckboxInput
            disabled={hook.getIsFormDisabled()}
            checked={checkedImagePages.includes(props.id)}
            onChange={hook.onCheckInput}
          />
        </styled.CheckboxWrapper>
        <styled.TitleWrapper>
          <TextInput
            isDisabled={hook.getIsFormDisabled()}
            alignment={alignments.center}
            placeholder={'제목 입력'}
            onChange={hook.onChangeTitle}
            value={props.title}
          />
        </styled.TitleWrapper>
        <styled.CountSettingWrapper>
          <DropdownInput
            isEnabled={!hook.getIsFormDisabled()}
            options={hook.countSettings}
            selectedOption={hook.countSettings.find(
              ($0) => $0.key == props.imagePaths.length
            )}
            handleSelectOption={hook.onUpdateImageCount}
            style={BorderedDropdownInputStyle}
          />
        </styled.CountSettingWrapper>
        <styled.ExclusionSettingWrapper>
          <DropdownInput
            isEnabled={!hook.getIsFormDisabled()}
            selectedOption={
              pages.find(($0) => $0.key == props.id).isExcluded
                ? hook.exclusionOptions[1]
                : hook.exclusionOptions[0]
            }
            options={hook.exclusionOptions}
            handleSelectOption={hook.onUpdateExclusion}
            style={BorderedDropdownInputStyle}
          />
        </styled.ExclusionSettingWrapper>
      </styled.InputContainer>
      {renderSelectImageInputs()}
    </styled.Container>
  );
};

ImagePageInput.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.object,
  imagePaths: PropTypes.array,
};

ImagePageInput.defaultProps = {
  id: defaultValues.emptyString,
  title: defaultValues.emptyString,
  size: ImagePageSize.original,
  imagePaths: defaultValues.emptyArray,
};

export default ImagePageInput;
