import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Form extends Component {
    static get propTypes() { 
        return { 
            content: PropTypes.element,
            onSubmit: PropTypes.func
        }; 
    }

    static defaultProps = {
        content: null,
        onSubmit: () => { return; }
    }

    submitForm = (event) => {
        event.preventDefault();
        var values = {};
        for(const element of event.target){
            if (!element.name){ continue;}
            if (element.type == "checkbox") {
                values[element.name] = element.value == "true"
                continue;
            }
            values[element.name] = element.value;
        }
        this.props.onSubmit(values);
    }

    render() {
        return (
            <form onSubmit={ this.submitForm }>
                { this.props.content }
            </form>
        )
    }
}

export default Form;